/** @format */

export default {
    "american express": {
        name: "Amex",
        color: "green",
    },
    visa: {
        name: "Visa",
        color: "lime",
    },
    diners: {
        name: "Diners",
        color: "orange",
    },
    discover: {
        name: "Discover",
        color: "purple",
    },
    jcb: {
        name: "Jcb",
        color: "red",
    },
    jcb15: {
        name: "Jcb",
        color: "red",
    },
    maestro: {
        name: "Maestro",
        color: "yellow",
    },
    mastercard: {
        name: "Mastercard",
        color: "lightblue",
    },
    unionpay: {
        name: "Unipay",
        color: "cyan",
    },
};