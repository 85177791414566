<!-- @format -->

<template>
	<div class="cargo-details-wrapper mt-4">
		<div class="cargo-details mb-4">
			<h2>Cargo Details</h2>
			<div class="pa-4 mt-4 cargo-back">
				<div class="cargo-details-table-wrapper">
					<div>
						<v-data-table
							:headers="tableHeader"
							:items="shipmentItem.cargoDetailsData"
							mobile-breakpoint="769"
							:page="1"
							fixed-header
							:items-per-page="100"
							hide-default-footer
							hide-default-header
							style="box-shadow: none !important"
						>
							<template v-slot:header="{ props: { headers } }">
								<thead>
									<tr>
										<th
											v-for="(item, index) in headers"
											:key="index"
											class="op-"
											role="column-header"
											:aria-label="item.text"
											:style="
												`color: #69758C !important;padding-right: 12px;padding-left: 12px !important;width: ${item.width};text-align: ${item.textAlign}`
											"
											scope="col"
										>
											{{ item.text }}
											<span
												class="required-asterisk"
												v-if="
													item.text == 'Total Volume' ||
														item.text == 'Total Weight' ||
														item.text == 'Incoterm'
												"
												>*</span
											>
										</th>
									</tr>
								</thead>
							</template>
							<template v-slot:[`item.consignee`]="{ item }">
								<v-select
									class="text-fields selected-input-field selected-fields"
									:items="filteredConsigneeOptions"
									height="40px"
									outlined
									item-text="name"
									item-value="id"
									hide-details="auto"
									v-model="item.consignee"
									disabled
								/>
							</template>
							<template v-slot:[`item.shipper`]="{ item }">
								<v-select
									class="text-fields selected-input-field selected-fields"
									:items="filteredShipperOptions"
									height="40px"
									outlined
									item-text="name"
									item-value="id"
									hide-details="auto"
									v-model="item.shipper"
									disabled
								/>
							</template>
							<template v-slot:[`item.total_carton`]="{ item }">
								<v-text-field
									height="40px"
									color="#002F44"
									type="number"
									dense
									placeholder="0"
									outlined
									hide-details="auto"
									v-model="item.total_carton"
									class="input-item"
									min="0"
								/>
							</template>
							<template v-slot:[`item.total_volume`]="{ item }">
								<v-text-field
									height="40px"
									color="#002F44"
									type="number"
									dense
									placeholder="0"
									outlined
									hide-details="auto"
									v-model="item.total_volume"
									class="input-item"
									min="0"
									:rules="fieldRequired"
									suffix="CBM"
									validate-on-blur
								/>
							</template>
							<template v-slot:[`item.total_weight`]="{ item }">
								<v-text-field
									height="40px"
									color="#002F44"
									type="number"
									dense
									placeholder="0"
									outlined
									hide-details="auto"
									v-model="item.total_weight"
									class="input-item"
									min="0"
									:rules="fieldRequired"
									suffix="KG"
									validate-on-blur
								/>
							</template>
							<template v-slot:[`item.commodity_description`]="{ item }">
								<v-text-field
									height="40px"
									color="#002F44"
									type="text"
									dense
									placeholder="Enter Description"
									outlined
									hide-details="auto"
									v-model="item.commodity_description"
									class="input-item"
								/>
							</template>
							<template v-slot:[`item.incoterm`]="{ item }">
								<v-select
									class="text-fields selected-input-field"
									:items="getShipmentTerms"
									item-text="name"
									item-value="id"
									placeholder="Incoterm"
									outlined
									hide-details="auto"
									height="40px"
									v-model="item.incoterm"
									:rules="[(v) => !!v || '']"
									validate-on-blur
								>
									<template v-slot:item="{ item }">
										<span class="booking-tooltip-wrapper ">
											<v-tooltip left>
												<template v-slot:activator="{ on, attrs }">
													<span v-bind="attrs" v-on="on" class="incoterm-name">
														{{ item.name }}
													</span>
												</template>
												<span>{{ incotermTooltip(item.name) }}</span>
											</v-tooltip>
										</span>
									</template>
								</v-select>
							</template>
						</v-data-table>
					</div>
					<div
						class="total-amount pa-4 d-flex"
						v-if="shipmentItem.cargoDetailsData.length > 0"
					>
						<span> Total Cartons: {{ totalCartons() }} </span>
						<span> Total Volume: {{ totalVolume() }} </span>
						<span> Total Weight: {{ totalWeight() }} </span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ShipmentTableHeader from "../js/ShipmentTableHeader";
import shipmentBookingItem from "../js/ShipmentBooking";
import _ from "lodash";
import { mapGetters } from "vuex";
import axios from "axios";
import globalMethods from "@/utils/globalMethods";
export default {
	name: "CargoDetails",
	props: ["filteredConsigneeOptions", "filteredShipperOptions"],
	data: () => ({
		headers: ShipmentTableHeader.cargoDetailsHeader.data,
		shipper: ShipmentTableHeader.cargoDetailsHeader.shipper,
		consignee: ShipmentTableHeader.cargoDetailsHeader.consignee,
		shipmentItem: shipmentBookingItem,
		fieldRequired: [
			(value) => {
				if (value !== "") {
					if (value <= 0) {
						return false;
					} else {
						return true;
					}
				} else {
					return false;
				}
			},
		],
	}),
	mounted() {
		this.$store.dispatch("fetchTerms");
	},
	computed: {
		...mapGetters(["getShipmentTerms"]),
		tableHeader() {
			let dynamicHeader;
			if (this.shipmentItem.userRole == "consignee") {
				dynamicHeader = [...this.headers, ...this.shipper];
			} else {
				dynamicHeader = [...this.headers, ...this.consignee];
			}
			return _.orderBy(dynamicHeader, ["sort_order"], ["asc"]);
		},

		console: () => console,
	},
	methods: {
		...globalMethods,
		totalVolume() {
			let total = 0;
			this.shipmentItem.cargoDetailsData.forEach((item) => {
				let int = item.total_volume ? parseFloat(item.total_volume) : 0;
				total += int;
			});
			let totalCBM = total.toFixed(2);
			try {
				if (totalCBM > 0 || this.shipmentItem.requestBookingToken) {
					if (this.source) this.source.cancel("cancel_previous_request");
					this.source = axios.CancelToken.source();
					this.$store
						.dispatch("booking/calculateRequiredContainers", {
							cbm: totalCBM,
							cancelToken: this.source.token,
						})
						.then((response) => {
							this.shipmentItem.containerItems.forEach((item, key) => {
								this.shipmentItem.containerItems[key].how_many = 0;
								this.shipmentItem.containerItems[key].checked = false;
								response.forEach((res) => {
									if (res.size == item.size.replace(/'/g, " ")) {
										this.shipmentItem.containerItems[key].how_many = res.qty;
										this.shipmentItem.containerItems[key].checked = true;
									}
								});
							});
						});
				}
			} catch (e) {
				if (e !== "cancel_previous_request");
			}

			return total.toFixed(2) + "CBM";
		},
		totalWeight() {
			let total = 0;
			this.shipmentItem.cargoDetailsData.forEach((item) => {
				let int = item.total_weight ? parseFloat(item.total_weight) : 0;
				total += int;
			});
			return total.toFixed(2) + " KG";
		},
		totalCartons() {
			let total = 0;
			this.shipmentItem.cargoDetailsData.forEach((item) => {
				let int = item.total_carton ? parseFloat(item.total_carton) : 0;
				total += int;
			});
			return total;
		},
		incotermTooltip(name) {
			if (name == "Exworks") {
				return "Ex-Works";
			} else if (name == "FOB") {
				return "Free on Board";
			} else if (name == "CIF") {
				return "Cost, Insurance, and Freight";
			} else if (name == "FCA") {
				return "Free Carrier";
			} else if (name == "FCR") {
				return "Forwarder's Cargo Receipt";
			} else if (name == "DAP") {
				return "Delivered at Place";
			} else if (name == "DDU") {
				return "Delivered Duty Unpaid";
			} else if (name == "DDP") {
				return "Delivered Duty Paid";
			}
		},
	},
};
</script>

<style lang="scss">
@import "./scss/CargoDetail.scss";
</style>
