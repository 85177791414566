<!-- @format -->

<template>
	<div class="shipment-container-wrapper shipment-details-card">
		<h3>
			Containers
		</h3>
		<div class="shipment-containers-table">
			<v-data-table
				:headers="
					getDetails.booking_confirmed == 1
						? bookingContainerHeader
						: containerHeader
				"
				:items="getDetails.containers"
				hide-default-footer
				class="container-table"
				mobile-breakpoint="0"
			>
				<template v-slot:[`item.index`]="{ item, index }">
					<span :class="item.capacity">{{ index + 1 }}</span>
				</template>
				<template v-slot:[`item.capacity`]="{ item }">
					<span>{{ item.capacity }} CBM </span>
				</template>
			</v-data-table>
		</div>
	</div>
</template>

<script>
export default {
	name: "ShipmentContainer",
	props: ["getDetails"],
	data: () => ({
		containerHeader: [
			{
				text: "S/N",
				align: "start",
				sortable: false,
				value: "index",
				width: "15%",
				fixed: true,
			},
			{
				text: "Size",
				value: "size",
				align: "right",
				sortable: false,
				width: "20%",
				fixed: true,
			},
			{
				text: "Quantity",
				value: "quantity",
				align: "right",
				sortable: false,
				width: "25%",
				fixed: true,
			},
			{
				text: "Capacity",
				value: "capacity",
				align: "right",
				sortable: false,
				width: "40%",
				fixed: true,
			},
		],
		bookingContainerHeader: [
			{
				text: "S/N",
				align: "start",
				sortable: false,
				value: "index",
				width: "5%",
				fixed: true,
			},
			{
				text: "Number",
				value: "name",
				align: "left",
				sortable: false,
				width: "20%",
				fixed: true,
			},
			{
				text: "Size",
				value: "size",
				align: "right",
				sortable: false,
				width: "15%",
				fixed: true,
			},
			{
				text: "Volume",
				value: "volume",
				align: "right",
				sortable: false,
				width: "20%",
				fixed: true,
			},
			{
				text: "Weight",
				value: "weight",
				align: "right",
				sortable: false,
				width: "20%",
				fixed: true,
			},
			{
				text: "Carton Count",
				value: "carton",
				align: "right",
				sortable: false,
				width: "20%",
				fixed: true,
			},
		],
	}),
};
</script>

<style lang="scss">
@import "../../../assets/scss/pages_scss/shipment/shipmentDetails/ShipmentContainer.scss";
</style>
