<!-- @format -->

<template>
	<div>
		<div v-resize="onResize" v-if="reference !== 'booking-request-form'">
			<v-dialog
				v-model="dialog"
				:content-class="'create-edit-shipment-dialog-wrapper'"
				persistent
			>
				<BookingShipmentContainer
					:key="componentKey"
					:isBookingShipmentDialog="true"
					:className="'booking-shipment-dialog-wrapper'"
					:isMobile="isMobile"
					:shipmentData="shipmentData"
					:userDetails="userDetails"
					:reference="reference"
					:useTemplateId="useTemplateId"
					:keepEditingTemplateId="keepEditingTemplateId"
					@submittedRequest="submittedRequest"
					@missingEmailRequest="missingEmailRequest"
					@submitedDraftRequest="saveAsDraft"
					@bookingTemplateRequest="bookingRequestTemplateDialog = true"
					@replaceTemplateDialog="replaceTemplateDialog"
					@updateBookingTemplateRequest="updateBookingTemplateRequest"
					@close="close"
					@showConfirmRoleModal="showConfirmRoleModalDialog"
				/>
			</v-dialog>
		</div>
		<div
			class="booking-request-form-wrapper-v2"
			id="shipment-booking-request-form-v2"
			v-resize="onResize"
			v-if="reference === 'booking-request-form'"
		>
			<BookingShipmentContainer
				:key="componentKey"
				:isBookingShipmentDialog="false"
				:className="'booking-shipment-dialog-wrapper'"
				:isMobile="isMobile"
				:shipmentData="shipmentData"
				:userDetails="userDetails"
				:reference="reference"
				:useTemplateId="useTemplateId"
				:keepEditingTemplateId="keepEditingTemplateId"
				@submittedRequest="submittedRequest"
				@missingEmailRequest="missingEmailRequest"
				@submitedDraftRequest="saveAsDraft"
				@bookingTemplateRequest="bookingRequestTemplateDialog = true"
				@replaceTemplateDialog="replaceTemplateDialog"
				@updateBookingTemplateRequest="updateBookingTemplateRequest"
				@close="close"
				@showConfirmRoleModal="showConfirmRoleModalDialog"
				@resetForm="resetForm"
				@saveAsDefaultTemplate="saveAsDefaultTemplate"
			/>
		</div>

		<ConfirmRoleModal
			v-if="showConfirmRoleModal"
			:isMobile="isMobile"
			:show.sync="showConfirmRoleModal"
			@close="showConfirmRoleModal = false"
			@submit="handleConfirmRole"
			@cancelRole="cancelRole"
			:currentRole="shipmentItem.userRole"
		>
			<template v-slot:title>
				<GenericIcon iconName="warning" color="#BC4410" />
			</template>
			<template v-slot:actions="{ footer }">
				<div class="d-flex footer">
					<v-btn
						:disabled="footer.submitLoading"
						class="save-btn btn-blue mr-2"
						text
						@click="footer.submit"
					>
						<span>Change Role</span>
					</v-btn>
					<v-btn
						class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
						text
						@click="footer.cancelRole"
					>
						<span>Cancel</span>
					</v-btn>
				</div>
			</template>
		</ConfirmRoleModal>

		<SubmitRequestModal
			:submitShipmentId="submitShipmentId"
			:requestConsolidation="requestConsolidation"
			:isMobile="isMobile"
			:bookingShipmentDialogView.sync="bookingShipmentDialogView"
			:submitRequestLoading.sync="submitRequestLoading"
			:showDialog.sync="showSubmittedRequestDialog"
			@close="showSubmittedRequestDialog = false"
			@submit="handleSubmit"
			@notificationError="notificationsError"
			:submitRequestModalLoading.sync="submitRequestModalLoading"
			:isBookingInviteForm="false"
			:showBookingRequestSubmittedModal.sync="showBookingRequestSubmittedModal"
			:isDraftRequest="isDraftRequest"
		>
			<template v-slot:title>
				<h2>
					Submit Request
				</h2>
			</template>
			<template v-slot:actions="{ footer }">
				<div class="d-flex footer">
					<v-btn
						:disabled="footer.submitRequestLoading || footer.disableSubmit"
						class="save-btn btn-blue mr-2"
						text
						@click="footer.submit"
					>
						<span>Submit</span>
					</v-btn>
					<v-btn
						class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
						text
						@click="footer.close"
					>
						<span>Cancel</span>
					</v-btn>
				</div>
			</template>
		</SubmitRequestModal>

		<BookingTemplateDialog
			v-if="bookingRequestTemplateDialog"
			:showDialog="bookingRequestTemplateDialog"
			@close="bookingRequestTemplateDialog = false"
			@saveAsTemplate="saveAsTemplate"
		/>

		<ConfirmDialog
			:dialogData.sync="existTemplateNameDialog"
			v-if="existTemplateNameDialog"
		>
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Template with same Name already exist</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					There is already a template with similar name
					<strong>{{ templateData.template_name }}</strong
					>, Do you want to replace or save as a copy?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					@click="changeTemplateName()"
					text
					:disabled="createUpdateLoading"
				>
					Change Name
				</v-btn>

				<v-btn
					class="btn-white"
					text
					@click="confirmReplaceTemplate(existTemplateId)"
					:disabled="createUpdateLoading"
				>
					Replace
				</v-btn>

				<v-btn
					class="btn-white"
					text
					@click="saveAsCopy(existTemplateId)"
					:disabled="createUpdateLoading"
				>
					Save as copy
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog
			:dialogData.sync="confirmReplaceTemplateDialog"
			v-if="confirmReplaceTemplateDialog"
		>
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Current info will be replaced</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Are you sure of using <strong>{{ replaceTemplateData.name }}</strong
					>template? All information of the current booking form will be
					replaced with template information.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					@click="useTemplate(replaceTemplateData.id)"
					text
				>
					Use Template
				</v-btn>

				<v-btn
					class="btn-white"
					text
					@click="keepEditingTemplate(replaceTemplateData.oldTemplateId)"
				>
					Keep Editing
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="missingEmailAddressDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Missing Email Address</h2>
			</template>

			<template v-slot:dialog_content>
				<p class="email-desc">
					To add ‘{{ missingEmailsData.name }}’ as
					{{
						missingEmailsData.userRole == "shipper" ? "consignee" : "shipper"
					}}, we need their email address. Please add it below. You will not be
					asked to input their email again in future.
				</p>
				<div class="input-field-wrapper shipper-consignee-email-input">
					<p class="input-field-label mb-0">
						{{
							missingEmailsData.userRole == "shipper" ? "consignee" : "shipper"
						}}
						Email address
					</p>
					<v-text-field
						v-model="missingEmailAddress"
						:placeholder="
							`Type ${
								missingEmailsData.userRole === 'shipper'
									? 'consignee'
									: 'shipper'
							} email address`
						"
						:height="40"
						width="200px"
						type="text"
						dense
						outlined
						hide-details="auto"
						:rules="emailRules"
					/>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="submitMissingEmail(missingEmailsData.id)"
					:disabled="getUpdateUserEmailsLoading"
				>
					<span>Save & Submit</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="
						(missingEmailAddressDialog = false), (missingEmailAddress = '')
					"
					:disabled="getUpdateUserEmailsLoading"
				>
					<span>Cancel</span>
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>

<script>
import BookingShipmentContainer from "../BookingShipment/BookingShipmentContainer.vue";
import SubmitRequestModal from "../../Dialog/FormShipmentDialog/Modals/SubmitRequestModal.vue";
import globalMethods from "@/utils/globalMethods";
import shipmentBookingItem from "./js/ShipmentBooking";
import _ from "lodash";
import ConfirmRoleModal from "../../Dialog/FormShipmentDialog/Modals/ConfirmRoleModal.vue";
import GenericIcon from "../../Icons/GenericIcon.vue";
import { mapGetters, mapActions } from "vuex";
import BookingTemplateDialog from "./SubComponents/BookingTemplateDialog.vue";
import ConfirmDialog from "../../Dialog/GlobalDialog/ConfirmDialog.vue";
import iziToast from "izitoast";

export default {
	name: "BookingShipmentDialogV2",
	props: ["show", "reference", "shipmentData", "editTemplateId"],
	components: {
		BookingShipmentContainer,
		SubmitRequestModal,
		ConfirmRoleModal,
		GenericIcon,
		BookingTemplateDialog,
		ConfirmDialog,
	},
	data: () => ({
		shipmentItem: shipmentBookingItem,
		isMobile: false,
		componentKey: 0,
		submitShipmentId: "",
		bookingShipmentDialogView: false,
		submitRequestLoading: false,
		showSubmittedRequestDialog: false,
		submitRequestModalLoading: false,
		submitLoading: false,
		showConfirmRoleModal: false,
		prevRole: "",
		showBookingRequestSubmittedModal: false,
		requestConsolidation: null,
		missingEmailAddressDialog: false,
		emailRules: [
			(v) => !!v || "",
			(v) =>
				!v ||
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
				"E-mail must be valid",
		],
		missingEmailsData: {},
		missingEmailAddress: [],
		bookingRequestTemplateDialog: false,
		existTemplateNameDialog: false,
		userDetails: {},
		existTemplateId: null,
		templateData: null,
		confirmReplaceTemplateDialog: false,
		useTemplateId: null,
		replaceTemplateData: null,
		keepEditingTemplateId: null,
		isDraftRequest: false,
	}),
	async created() {
		try {
			// setTimeout(async () => {
			this.userDetails = await JSON.parse(this.getUser);
			// }, 3000);
		} catch (e) {
			// setTimeout(async () => {
			this.userDetails = await this.getUser;
			// }, 3000);
		}

		this.editTemplateId !== null
			? (this.useTemplateId = this.editTemplateId)
			: null;

		if (!this.fromBookingReqestForm) {
			this.fetchBookingTemplate();
		}
	},
	computed: {
		...mapGetters({
			getAllDraftShipments: "getAllDraftShipments",
			getAllPendingQuoteShipments: "getAllPendingQuoteShipments",
			getUpdateUserEmailsLoading: "booking/getUpdateUserEmailsLoading",
			getUser: "getUser",
			getAllPoPage: "po/getAllPoPage",
		}),
		dialog: {
			get() {
				if (this.show === true) this.forceRerender();
				return this.show;
			},
			set(value) {
				this.$emit("update:show", value);
			},
		},
		createUpdateLoading() {
			let create = this.$store.getters[
				"bookingTemplate/getCreateBookingTemplateLoading"
			];
			let update = this.$store.getters[
				"bookingTemplate/getUpdateBookingTemplateLoading"
			];
			return create || update;
		},
		fromBookingReqestForm() {
			return this.reference === "booking-request-form";
		},
	},
	methods: {
		...mapActions([
			"fetchShipments",
			"fetchCompletedShipments",
			"fetchDraftShipments",
			"setDraftPagination",
			"setPendingQuotePagination",
		]),
		...globalMethods,
		notificationSuccess(message) {
            iziToast.success({
                theme: 'dark',
                message: `<h4 style="font-weight: 500 !important; color: #fff !important;">${message}</h4>`,
                backgroundColor: '#04783A',
                messageColor: '#ffffff',
                iconColor: '#ffffff',
                progressBarColor: '#ADEEBD',
                displayMode: 1,
                position: 'bottomCenter',
                timeout: 3000,
            });
        },
		close() {
			this.clearAllData(false);
			this.useTemplateId = null;
			this.$emit("close");
		},
		async resetForm() {
			await this.clearAllData(true);
			this.shipmentItem.cargoDetailsData[0].total_carton = 0;
			this.shipmentItem.cargoDetailsData[0].total_volume = 0;
			this.shipmentItem.cargoDetailsData[0].total_weight = 0;
			this.shipmentItem.cargoDetailsData[0].commodity_description = "";
			this.shipmentItem.cargoDetailsData[0].incoterm = 0;
		},
		clearAllData(isReset) {
			if (!isReset) {
				this.shipmentItem.userRole = null;
				this.shipmentItem.consignees = [];
				this.shipmentItem.shippers = [];
				this.shipmentItem.selectedShipperIds = [];
				this.shipmentItem.cargoDetailsData = [];
				this.shipmentItem.containerItems = [];
				this.shipmentItem.requestBookingToken = null;
			}
			this.shipmentItem.selectedConsigneeIds = [];
			this.shipmentItem.type = null;
			this.shipmentItem.request_consolidations = false;
			this.shipmentItem.mode = null;
			this.shipmentItem.location_from = 0;
			this.shipmentItem.location_to = 0;
			this.shipmentItem.same_as_port_of_discharge = false;
			this.shipmentItem.give_me_best_location = false;
			this.shipmentItem.port_of_discharge = 0;
			this.shipmentItem.warehouse_delivery_location = false;
			this.shipmentItem.warehouse_pick_up_location = null;
			this.shipmentItem.contact_person_name = null;
			this.shipmentItem.contact_person_mobile_num = null;
			this.shipmentItem.contact_person_email = null;
			this.shipmentItem.earliest_shipment_date = null;
			this.shipmentItem.latest_arrival_date = null;
			this.shipmentItem.preferred_carrier = [];
			this.shipmentItem.customer_reference_number = null;
			this.shipmentItem.orderItems = [];
			this.shipmentItem.additional_instructions_and_notes = "";
			this.shipmentItem.additional_services = [];
			this.shipmentItem.import_name_id = 0;
			this.shipmentItem.forwarders = [];
			this.shipmentItem.is_review = false;
			this.shipmentItem.manualWarehouse = false;
			this.shipmentItem.manualContact = false;
		},
		onResize() {
			if (window.innerWidth < 1023) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
		},
		forceRerender() {
			this.componentKey += 1;
		},
		cancelRole() {
			this.shipmentItem.userRole = this.prevRole;
			this.showConfirmRoleModal = false;
		},
		handleConfirmRole() {
			this.prevRole = this.shipmentItem.userRole;
			this.showConfirmRoleModal = false;
			//empty purchase order items
			this.shipmentItem.orderItems = [];
		},
		submittedRequest(item) {
			if (item) {
				this.showSubmittedRequestDialog = true;
			} else {
				this.showSubmittedRequestDialog = false;
			}
		},
		bookingPaylaod() {
			const userRole =
				this.reference == "booking-request-form"
					? "consignee"
					: this.shipmentItem.userRole;

			const orders = [];
			const isManualOrder = this.shipmentItem.orderItems.filter(
				(order) => order.layout == "manual"
			);

			this.shipmentItem.orderItems.forEach((order) => {
				// Products data
				const orderProducts = [];
				if (isManualOrder.length == 0) {
					order?.products.forEach((product) => {
						if (product.product_id || product.quickAddProduct) {
							orderProducts.push({
								id: product.product_id,
								is_shipment: true,
								product_id: product.product_id,
								amount: _.toNumber(product.amount),
								carton: _.toNumber(product.carton),
								ship_cartons: _.toNumber(product.carton),
								unit: _.toNumber(product.unit),
								unit_price: _.toNumber(product.unit_price),
								units_per_carton: _.toNumber(product.units_per_carton),
								unship_cartons: !_.isEmpty(product.unship_cartons)
									? product.unship_cartons
									: null,
								volume: _.toNumber(product.volume),
								weight: _.toNumber(product.weight),
								classification_code: product.classification_code,
								cargo_ready_date: !_.isEmpty(order.cargo_ready_date)
									? order.cargo_ready_date
									: null,
								description: product.description,
								product_classification_description:
									product.product_classification_description,
								product_number: product.product_number,
								quickAddProduct: product.quickAddProduct,
							});
						}
					});
				}

				const orderItem = {
					id: order.id,
					purchase_order_id: order.purchase_order_id,
					quickAddOrder: order.quickAddOrder,
					cargo_ready_date: order.cargo_ready_date,
					order_number: order.order_number,
					selected_order_user_id: order.selected_order_user_id,
					products: orderProducts,
					total_cartons: order.total_cartons,
					total_volumes: order.total_volumes,
					total_weights: order.total_weights,
				};

				if (_.has(order, "consignee_id"))
					orderItem.consignee_id = order?.consignee_id;

				if (_.has(order, "shipper_id"))
					orderItem.shipper_id = order?.shipper_id;

				orders.push(orderItem);
			});

			var consigneeData = [];
			var shippersData = [];

			// Orders & Cargo detail data
			if (userRole == "shipper") {
				this.shipmentItem.consignees.forEach((consignee, key) => {
					const filterOrder = orders.filter(
						(order) => order.selected_order_user_id == consignee.consignee
					);
					const cargoDetails = this.shipmentItem.cargoDetailsData.find(
						(cargoDetail) => cargoDetail.consignee == consignee.consignee
					);
					this.shipmentItem.consignees[key]["orders"] = filterOrder;
					this.shipmentItem.consignees[key]["cargoDetails"] = cargoDetails;
				});

				this.shipmentItem.consignees.map((consignee) => {
					const consigneeRow = {
						id: consignee.consignee,
						consignee_details_info: consignee.consignee_details_info,
						notify_party_details: !_.isEmpty(consignee.notify_details_info)
							? consignee.notify_details_info
							: "",
						orders: consignee.orders,
						cargoDetails: consignee.cargoDetails,
					};
					consigneeData.push(consigneeRow);
				});

				shippersData = {
					id: this.shipmentItem.shippers[0].shipper,
					shipper_details_info: this.shipmentItem.shippers[0]
						.shipper_details_info,
					pickupLocation: {
						location:
							this.shipmentItem.shippers[0].warehouse_pick_up_location ?? null,
						name: this.shipmentItem.shippers[0].contact_person_name ?? null,
						phone:
							this.shipmentItem.shippers[0].contact_person_mobile_num ?? null,
						email: this.shipmentItem.shippers[0].contact_person_email ?? null,
					},
				};
			} else {
				this.shipmentItem.shippers.forEach((consignee, key) => {
					const filterOrder = orders.filter(
						(order) => order.selected_order_user_id == consignee.shipper
					);
					const cargoDetails = this.shipmentItem.cargoDetailsData.find(
						(cargoDetail) => cargoDetail.shipper == consignee.shipper
					);
					this.shipmentItem.shippers[key]["orders"] = filterOrder;
					this.shipmentItem.shippers[key]["cargoDetails"] = cargoDetails;
					this.shipmentItem.shippers[key]["pickupLocation"] = {
						location:
							this.shipmentItem.shippers[key].warehouse_pick_up_location ??
							null,
						name: this.shipmentItem.shippers[key].contact_person_name ?? null,
						phone:
							this.shipmentItem.shippers[key].contact_person_mobile_num ?? null,
						email: this.shipmentItem.shippers[key].contact_person_email ?? null,
					};
				});
				this.shipmentItem.shippers.map((shipper) => {
					const shipperRow = {
						id: shipper.shipper,
						shipper_details_info: shipper.shipper_details_info,
						orders: shipper.orders,
						cargoDetails: shipper.cargoDetails,
						pickupLocation: shipper.pickupLocation,
					};

					shippersData.push(shipperRow);
				});

				consigneeData = {
					id: this.shipmentItem.consignees[0].consignee,
					import_name_id: this.shipmentItem.import_name_id,
					consignee_details_info: this.shipmentItem.consignees[0]
						.consignee_details_info,
					notify_party_details: !_.isEmpty(
						this.shipmentItem.consignees[0].notify_details_info
					)
						? this.shipmentItem.consignees[0].notify_details_info
						: "",
				};
			}
			// Container Data
			let containers = [];
			containers = this.shipmentItem.containerItems.map((container, index) => {
				if (container?.checked == true) {
					return {
						size_id: container.size_id,
						how_many: container.how_many,
					};
				} else {
					delete this.shipmentItem.containerItems[index];
				}
			});
			containers = containers.filter((container) => !!container?.size_id);

			let payload = {};
			let {
				additional_instructions_and_notes,
				additional_services,
				warehouse_pick_up_location,
				contact_person_email,
				contact_person_mobile_num,
				contact_person_name,
				customer_reference_number,
				earliest_shipment_date,
				latest_arrival_date,
				location_from,
				location_to,
				mode,
				type,
				give_me_best_location,
				port_of_discharge,
				preferred_carrier,
				request_consolidations,
				same_as_port_of_discharge,
				shipment_ids,
				manualWarehouse,
				manualContact,
				warehouse_delivery_location,
				requestBookingToken,
			} = this.shipmentItem;

			let warehousePayload = null;
			if (warehouse_delivery_location) {
				if (!manualWarehouse && !manualContact) {
					warehousePayload = {
						warehouse_id: warehouse_pick_up_location,
						contact_id: contact_person_name,
					};
				} else if (!manualWarehouse && manualContact) {
					warehousePayload = {
						warehouse_id: warehouse_pick_up_location,
						email: contact_person_email,
						phone: contact_person_mobile_num,
						name: contact_person_name,
					};
				} else if (manualWarehouse) {
					warehousePayload = {
						location: warehouse_pick_up_location,
						email: contact_person_email,
						phone: contact_person_mobile_num,
						name: contact_person_name,
					};
				}
			}
			payload = {
				additional_instructions_and_notes,
				containers,
				additional_services,
				consignees: consigneeData,
				shippers: shippersData,
				warehouseDeliveryLocation: warehousePayload,
				customer_reference_number,
				earliest_shipment_date,
				latest_arrival_date,
				location_from,
				location_to,
				mode,
				type,
				give_me_best_location,
				port_of_discharge,
				preferred_carrier,
				request_consolidations,
				same_as_port_of_discharge,
				userRole: userRole,
				forwarders: this.shipmentItem.forwarders.forwarders,
				is_draft: this.shipmentItem?.forwarders?.is_draft
					? Boolean(this.shipmentItem?.forwarders?.is_draft)
					: false,
				schedule_id: new Date().getTime(),
				isManualOrder: isManualOrder.length > 0 ? true : false,
				request_consolidated_shipment: shipment_ids,
				requestBookingToken,
			};
			return payload;
		},
		async handleSubmit(forwarders, is_review) {
			this.submitRequestModalLoading = true;
			this.submitLoading = false;
			this.shipmentItem.forwarders = forwarders;
			this.shipmentItem.is_review = is_review;

			let payload = this.bookingPaylaod();
			console.log("payload");
			console.log(payload);

			await this.$store
				.dispatch("booking/createNewBookingShipment", payload)
				.then(async (res) => {
					console.log("booking response :: ", res);
					this.submitRequestLoading = false;
					if (
						typeof res.data !== "undefined" &&
						typeof res.data !== "undefined" &&
						typeof res?.data?.id !== "undefined"
					) {
						this.submitShipmentId = res.data.id;
						if (this.reference == "booking-request-form") {
							this.$router.push(`/booking-request-submitted`);
						} else {
							this.requestConsolidation = res.data.consolidation_status;
							this.showSubmittedRequestDialog = false;
							if(this.isDraftRequest) {
								this.notificationSuccess(`Booking request #${res.data.shifl_ref} has been saved to Draft`);
								this.isDraftRequest = false;
							} else {
								this.showBookingRequestSubmittedModal = true;
							}
							if (this.reference == "request-booking-shipment") {
								let pathData =
									typeof this.$router.history.current.query.tab !== "undefined"
										? this.$router.history.current.query.tab
										: null;

								if (this.$router.history.current.name == "POs") {
									await this.$store.dispatch("po/fetchPoAllNew", {
										page: this.getAllPoPage,
										state: pathData,
									});
								} else {
									await this.$store.dispatch("salesOrders/fetchPoAllNew", {
										page: this.getAllPoPage,
										state: pathData,
									});
								}
							}
							this.close();
						}
					} else {
						if (Object.keys(res.data).length > 0 && res.data !== undefined) {
							if (!res?.data?.isShiflFdExist) {
								this.notificationError("Shifl Forwader office user not exist.");
							} else if (!res?.data?.isShiflFdEmailExist) {
								this.notificationError(
									"Shifl office user has not valid email."
								);
							}
						} else {
							this.notificationError("SOMETHING WENT WRONG!");
						}
					}
				})
				.catch((e) => {
					this.submitLoading = false;
					this.submitRequestLoading = false;
					this.notificationError(
						"An unexpected error occured. Please try again"
					);
					console.log(e);
				});
		},
		saveAsDraft() {
			//add loading
			this.submitLoading = true;
			this.isDraftRequest = true;
			//add is draft parameters
			this.handleSubmit({
				forwarders: [],
				is_review: false,
				is_draft: 1,
			});
		},
		notificationsError(message) {
			this.notificationError(message);
		},
		async fetchBookingTemplate() {
			await this.$store.dispatch(
				"bookingTemplate/fetchBookingTemplate",
				this.userDetails?.default_customer?.id
			);
		},
		saveAsTemplate(item) {
			let payload = this.bookingPaylaod();
			let templatePayload = {
				created_by: this.userDetails?.default_customer?.id,
				data: payload,
				...item,
			};
			this.$store
				.dispatch("bookingTemplate/createBookingTemplate", templatePayload)
				.then((res) => {
					if (res.status == 200) {
						if (res.data.is_template_exist) {
							this.templateData = item;
							this.existTemplateId = res.data.template_id;
							this.existTemplateNameDialog = true;
						} else {
							this.notificationMessage(
								`Booking template '${item.template_name}' has been saved`
							);
							this.useTemplateId = res.data.template_id;
							this.componentKey += 1;
							this.fetchBookingTemplate();
							this.bookingRequestTemplateDialog = false;
						}
					}
				})
				.catch((e) => {
					this.notificationError(
						"An unexpected error occured. Please try again"
					);
					console.log(e);
				});
		},
		changeTemplateName() {
			this.existTemplateNameDialog = false;
			this.bookingRequestTemplateDialog = true;
		},
		confirmReplaceTemplate(id) {
			this.replaceTemplateName(id);
		},
		useTemplate(item) {
			this.useTemplateId = item;
			this.keepEditingTemplateId = null;
			this.forceRerender();
			this.confirmReplaceTemplateDialog = false;
		},
		replaceTemplateName(templateId) {
			let payload = this.bookingPaylaod();
			let templatePayload = {
				created_by: this.userDetails?.default_customer?.id,
				data: payload,
				templateId: templateId,
				...this.templateData,
			};
			this.$store
				.dispatch("bookingTemplate/updateBookingTemplate", templatePayload)
				.then((res) => {
					if (res.status == 200) {
						if (res.data.is_template_exist) {
							this.existTemplateId = res.data.template_id;
							this.existTemplateNameDialog = true;
						} else {
							this.notificationMessage(
								`Template '${this.templateData.template_name}' has been updated`
							);
							this.fetchBookingTemplate();
							this.templateData = null;
							this.existTemplateNameDialog = false;
							this.bookingRequestTemplateDialog = false;
						}
					}
				})
				.catch((e) => {
					this.notificationError(
						"An unexpected error occured. Please try again"
					);
					console.log(e);
				});
		},
		saveAsCopy(templateId) {
			let payload = this.bookingPaylaod();
			let templatePayload = {
				created_by: this.userDetails?.default_customer?.id,
				data: payload,
				save_as_copy: true,
				templateId: templateId,
				...this.templateData,
			};
			this.$store
				.dispatch("bookingTemplate/createBookingTemplate", templatePayload)
				.then((res) => {
					if (res.status == 200) {
						if (res.data.is_template_exist) {
							this.existTemplateId = res.data.template_id;
							this.existTemplateNameDialog = true;
						} else {
							this.notificationMessage(
								`Booking template '${this.templateData.template_name}' has been saved Copied`
							);
							this.fetchBookingTemplate();
							this.templateData = null;
							this.existTemplateNameDialog = false;
							this.bookingRequestTemplateDialog = false;
						}
					}
				})
				.catch((e) => {
					this.notificationError(
						"An unexpected error occured. Please try again"
					);
					console.log(e);
				});
		},
		updateBookingTemplateRequest(item) {
			this.templateData = {
				template_name: item.name,
				template_description: item.template_description,
				vendor_id: item.vendor_id,
			};
			this.replaceTemplateName(item.id);
		},
		replaceTemplateDialog(itemData) {
			this.replaceTemplateData = itemData;
			this.confirmReplaceTemplateDialog = true;
		},
		keepEditingTemplate(id) {
			this.keepEditingTemplateId = id;
			this.forceRerender();
			this.confirmReplaceTemplateDialog = false;
		},
		missingEmailRequest(item, userRole) {
			const { name, id, emails } = item;
			this.missingEmailsData = {
				userRole: userRole,
				name: name,
				id: id,
			};
			if ((!emails || emails, length == 0)) {
				this.missingEmailAddressDialog = true;
			}
		},
		async submitMissingEmail(id) {
			let payload = {
				role: this.shipmentItem.userRole,
				id: id,
				email: this.missingEmailAddress,
			};
			await this.$store
				.dispatch("booking/updateUserEmails", payload)
				.then(async (res) => {
					if (res.status == 200) {
						this.notificationMessage(`Email has been successfully updated`);
						if (this.shipmentItem.userRole == "consignee") {
							await this.$store.dispatch("booking/getShipperOptions", {
								page: 1,
							});
						} else {
							await this.$store.dispatch("booking/getConsigneeOptions", {
								page: 1,
							});
						}
						this.missingEmailAddressDialog = false;
					}
				})
				.catch((e) => {
					this.notificationError(
						"An unexpected error occured. Please try again"
					);
					console.log(e);
				});
		},
		showConfirmRoleModalDialog(newRole) {
			if (
				this.prevRole !== "" &&
				this.prevRole !== newRole &&
				this.shipmentItem.orderItems.length > 0
			) {
				this.showConfirmRoleModal = true;
			} else {
				//always empty when the previous role is different
				if (this.prevRole !== "" && this.prevRole !== newRole) {
					this.shipmentItem.orderItems = [];
				}
				this.prevRole = newRole;
			}
		},
		saveAsDefaultTemplate() {
			let payload = this.bookingPaylaod();
			this.$store
				.dispatch("bookingTemplate/saveBookingLinkTemplateData", payload)
				.then(async (res) => {
					if (res.status == 200) {
						this.notificationMessage(`Booking template has been saved`);
						window.location.reload();
					}
				});
		},
	},
};
</script>
<style lang="scss">
@import "../BookingShipment/scss/BookingShipmentDialogV2.scss";
</style>
