<!-- @format -->

<template>
	<div>
		<v-dialog
			v-model="dialog"
			width="700px"
			@click:outside="close"
			:content-class="`view-document-dialog ${getClassNameDoc} ${additionalClass ? 'dialog-expanded' : ''}`"
			scrollable
			transition="slide-x-reverse-transition"
		>
			<v-card class="view-document-card">
				<v-card-title class="view-document-title">
					<div class="headline">
						<p class="file-name mb-1">
							<span>{{ documentData.name }}</span>
							<img
								@click="openUpdateDialog(documentData)"
								src="@/assets/icons/edit-blue.svg"
								width="16px"
								height="16px"
								class="ml-2"
							/>
						</p>
						<p class="file-details mb-0" v-if="documentData.updated_by_name">
							{{
								documentData.updated_by_name
									? documentData.updated_by_name.company_name
									: "N/A"
							}}, {{ formatDate(documentData.updated_at) }}
						</p>
						<p class="file-details mb-0 text-capitalize">
							{{ documentData.type }}<span v-if="isFrom !== 'logout'">, </span>
							<span v-if="isFrom !== 'logout'">
								<span
									v-if="
										documentData.supplier_ids &&
										documentData.supplier_ids.length === 1 &&
										documentData.supplier_ids[0] !== null
									"
								>
									<span
										v-for="(supplier, index) in documentData.supplier_ids"
										:key="index"
									>
										{{ supplier.company_name }}
									</span>
								</span>
								<span v-else>Multiple</span>
							</span>							
						</p>
					</div>
					<div class="file-details"></div>

					<div class="header-actions">
						<button
							class="btn-white btn-edit mr-2"
							@click="expandDialog()"
							style="min-width: 40px !important; padding: 8px 10px !important;"
							v-if="getClassNameDoc === 'excel-type-doc'"
							:title="!additionalClass ? 'Expand Modal' : 'Collapse Modal'">

							<img src="@/assets/icons/expand-full-icon.svg"
								width="18px" height="18px"
								v-if="!additionalClass" />

							<img src="@/assets/icons/close-expand-icon.svg"
								width="18px" height="18px"
								v-else />
						</button>

						<button
							class="btn-blue btn-edit"
							@click="download(documentData.url)"
							style="min-width: 40px !important; padding: 8px 10px !important;"
							title="Download Document"
							v-if="isFrom !== 'logout'">
							<img src="@/assets/images/download-white.svg"
								width="16px"
								height="16px" />
						</button>

						<!-- <button
							class="btn-white btn-edit ml-2"
							@click="sendDocument(documentData)">
							Send
						</button> -->

						<button icon dark class="btn-close ml-3" @click="close" title="Close Modal">
							<v-icon color="#1A6D9E">mdi-close</v-icon>
						</button>
					</div>
				</v-card-title>
				<v-card-text class="view-document-content">
					<!-- <pdf :src="pdf"></pdf> -->

					<div v-if="isFetching" class="d-flex justify-center" style="width: 100%; padding-top: 50px;">
						<v-progress-circular
							:size="35"
							:color="getClassNameDoc === 'excel-type-doc' ? '#1A6D9E' : '#ffffff'"
							indeterminate
							v-if="isFetching">
						</v-progress-circular>
					</div>

					<div v-if="!isFetching" style="width: 100%; height: 100%; overflow: auto;">
						<div class="forthis" v-for="file in fileList" :key="file.name" style="width: 100%; height: 100%;">
							<object :data="getFileURL(file)" frameborder="0" width="100%" height="100%" 
								v-if="documentFileType === 'pdf'"></object>

							<img style="width: 100%; height: 100%;" :src="getFileURL(file)" v-if="isImageType"> 

							<div v-if="getClassNameDoc === 'excel-type-doc'" 
								class="sheet-js-table-wrapper" 
								style="height: 100%;">
								<excel-viewer 
									ref="excelViewer"
									height="100%"
									:first-row-num="firstRowNum"
									:min-col-counts="1" />
							</div>
						</div>
					</div>					    
				</v-card-text>
			</v-card>
		</v-dialog>

		<UpdateDocumentsDialog
			:shipment_id="shipment_id"
			:editItem.sync="editItem"
			:dialogData.sync="dialogUpdateDocs"
			@close="dialogUpdateDocs = false"
			@fetchDocuments="fetchShipmentDocumentsAfter"
		/>
	</div>
</template>

<script>
// import pdf from "vue-pdf";
import UpdateDocumentsDialog from "../Documents/UpdateDocumentsDialog.vue";
import moment from "moment";
const baseUrl = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import { types, excelAllowedTypes, imagesAllowedTypes } from "../../../constants/mimeTypes";
import globalMethods from '../../../utils/globalMethods';

export default {
	name: "ViewDocumentsDialog",
	props: ["dialogData", "documentData", "isFrom"],
	components: { 
		UpdateDocumentsDialog,
	},
	data: () => ({
		sendDocumentDialog: false,
		selectedDocument: [],
		editItem: {
			id: 0,
			name: "",
			type: "",
			supplier_ids: [],
		},
		dialogUpdateDocs: false,
		shipment_id: "",
		fileList: [],
		filenameobj: '',
		isFetching: 0,
		request: null,
		excel: "",
		firstRowNum: 1,
		additionalClass: false
	}),
	computed: {
		dialog: {
			get() {
				return this.dialogData;
			},
			set(value) {
				this.$emit("update:dialogData", value);
			},
		},
		pdf() {
			let url = process.env.VUE_APP_BASE_URL.slice(0, -3);
			let imageUrl = `${url}storage/`;

			return `${imageUrl}${this.documentData.url}`;
		},
		getBetaUrl() {
			let betaUrl = this.$store.getters["page/getBetaUrl"];
			betaUrl = betaUrl.replace("api", "");
			return betaUrl;
		},
		documentFileType() {
			let fileType = "";
			if (this.documentData !== null && this.documentData !== "") {
				if (this.documentData.path !== "") {
					fileType = this.documentData.path.split('.').pop();
				}
			}
			return fileType !== "" ? fileType.toLowerCase() : ""
		},
		getClassNameDoc() {
			if (this.documentFileType !== null && this.documentFileType !== "") {
				if (excelAllowedTypes.includes(this.documentFileType)) {
					return "excel-type-doc"
				} else {
					return ""
				}
			} else return ""
		},
		isImageType() {
			if (this.documentFileType !== null && this.documentFileType !== "") {
				if (imagesAllowedTypes.includes(this.documentFileType)) {
					return true
				} else {
					return false
				}
			} else return false
		}
	},
	watch: {
        dialog(c) {
            if (c) {
				document.body.classList.add("modal-open");
                if (this.documentData !== null) {
                    this.previewDoc(this.documentData);

					if (this.getClassNameDoc === "excel-type-doc") {									
						this.expandDialog();
					}
                }
            } else {
				document.body.classList.remove("modal-open");
			}
        }
    },
	methods: {
		...globalMethods,
		close() {
			this.$emit("close");
			this.cancel();
			this.excel = "";
			this.additionalClass = false;
		},
		formatDate(date) {
			return moment(date).format(" h:mm A DD/MM/YY");
		},
		download(url) {
			window.location.href = `${
				this.getBetaUrl
			}download?link=${encodeURIComponent(url)}`;
		},
		sendDocument(item) {
			this.sendDocumentDialog = true;
			this.selectedDocument.push(item);
		},
		openUpdateDialog(item) {
			this.shipment_id = item.shipment_id;
			this.$store.dispatch("documents/setEditId", item.id);
			this.editItem = {
				id: item.id,
				name: item.name,
				supplier_ids: item.supplier_ids[0],
				shipment_id: item.shipment_id,
				type: item.type,
			};
			this.$store.dispatch("documents/setEditDocument", item);
			this.dialogUpdateDocs = true;
		},
		async fetchShipmentDocumentsAfter(payload) {
			this.$store.dispatch("fetchShipmentDocuments", payload);
			this.close();
		},
		getFileURL(file) {
			return URL.createObjectURL(file) + "#view=Fit&toolbar=0";
		},
		previewDoc(document) {
            if (document !== null) {
                this.fileList = [];

				this.filenameobj = document.name
				this.isFetching = 1;
				let c = [];

				// cancel
				this.cancel();
				let axiosSource = axios.CancelToken.source();
				this.request = { cancel: axiosSource.cancel };

				let data = {
					"path": `public/${document.path}`,
					"original_name": document.name
				}

				let url = this.isFrom !== "logout" ? `${baseUrl}/v2/shipment/do-document` : `${baseUrl}/no-login-view-document` 

				let passedData = {
					method: "post",
					// url: `${baseUrl}/v2/shipment/do-document`,
					url,
					responseType: 'blob',
					data,
					cancelToken: axiosSource.token,
				}

				axios(passedData).then((response) => {
					this.fileList = [];
					this.isFetching = 0;

					window.URL = window.URL || window.webkitURL;
					var json = (response.data);

					let fileType = types[this.documentFileType.toLowerCase()]

					c[0] = new File([json], this.filenameobj , { lastModified: new Date().getTime(), type: fileType })
					this.fileList = c;
					URL.revokeObjectURL(c);
					this.request = null;
					
					if (this.getClassNameDoc === "excel-type-doc") {
						let fileReader = new FileReader()
						fileReader.onload = (e) => {
							this.$refs.excelViewer[0].openExcelData(e.target.result)
						};
						fileReader.readAsBinaryString(json);
					} 
				}).catch(() => {
					this.isFetching = 0;
				});
            }
        },
		errorHandler() {
			this.notificationCustom("An error has occured. Please try again later.")
		},
		cancel() {
			if (this.request) this.request.cancel();
		},
		expandDialog() {
			this.additionalClass = !this.additionalClass
		},
	},
};
</script>

<style lang="scss">
@import "@/assets/scss/pages_scss/shipment/viewDocumentDialog.scss";
</style>
