<!-- @format -->

<template>
	<div class="shipment-scheduling-options-wrapper shipment-details-card" v-if="schedulingData.length > 0">
		<h3>
			{{ !isChosen ? "Scheduling Options" : "Chosen Quote" }}
		</h3>
		<div
			class="shipment-schedule-detail"
			v-for="(itemData, index) in schedulingData"
			:key="index"
		>
			<v-row class="schedule-detail" v-if="itemData.haveSellRate">
				<v-col cols="12">
					<h3 class="d-flex gap-2 align-center">
						{{ itemData.carrier_name ? itemData.carrier_name : "N/A" }}
						<span
							class="status-label"
							v-if="
								itemData.cheapest &&
									!isConfirmed(itemData) &&
									schedulingData.length > 1
							"
							>Cheapest</span
						>
						<span
							class="status-label"
							v-if="
								itemData.fastest &&
									!isConfirmed(itemData) &&
									schedulingData.length > 1
							"
							>Fastest</span
						>
						<span class="selected-status" v-if="isConfirmed(itemData)">
							<img
								src="../../../assets/icons/checkMark-green.svg"
								width="12px"
								height="12px"
							/>
							Selected</span
						>
					</h3>
					<v-btn
						class="btn-blue"
						@click="confirmSchedule(itemData)"
						v-if="!isConfirmed(itemData)"
					>
						Choose This
					</v-btn>
				</v-col>
			</v-row>
			<v-row class="shipment-general-info" v-if="itemData.haveSellRate">
				<v-col sm="12" md="4" class="general-info">
					<div>
						<p>From & ETD</p>
						<p>{{ itemData.location_from }}, {{ itemData.etd }}</p>
					</div>
					<div>
						<p>To & ETA</p>
						<p>{{ itemData.location_to }}, {{ itemData.eta }}</p>
					</div>
					<div v-if="itemData.legs && itemData.legs.length > 0">
						<div v-for="(leg, i) in itemData.legs" :key="i">
							<p class="mb-0">To & ETA</p>
							<p class="mb-0">
								{{ leg.location_to_name }}, {{ leg.eta_readable }}
							</p>
						</div>
					</div>
					<div>
						<p>Transit Time</p>
						<p>{{ itemData.transit_time }} days</p>
					</div>
					<div v-if="itemData.container_free_days">
						<p>Container free days</p>
						<p>
							{{
								itemData.container_free_days
									? itemData.container_free_days + ` days (Business days)`
									: "N/A"
							}}
						</p>
					</div>
				</v-col>
				<v-col sm="12" md="8" class="schedule-info">
					<v-data-table
						:headers="scheduleHeader"
						:items="schedulesSellRateData(itemData.sell_rates)"
						hide-default-footer
						class="schedule-table"
						mobile-breakpoint="0"
					>
						<template v-slot:body="{ items }">
							<tbody v-if="items.length > 0">
								<tr v-for="(item, index) in items" :key="index">
									<td class="text-left item-name">
										{{ item.service_name }}
										<span v-if="item.qty">({{ item.qty }}x)</span>
									</td>
									<td class="text-right">
										<p class="mb-0 schedule-label" v-if="item.label">
											{{ item.label }}
										</p>
										<p class="mb-0 schedule-label" v-if="item.valid_to">
											Valid to {{ item.valid_to }}
										</p>
										<span v-if="item.total" class="schedule-amount"
											>${{ item.total ? item.total : "N/A" }}
										</span>
										<span class="schedule-status" v-else>Pending</span>
									</td>
								</tr>
								<tr class="total-amount">
									<td class="text-right">Total</td>
									<td class="text-right">${{ itemData.total_amount }}</td>
								</tr>
							</tbody>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</div>
		<ConfirmDialog
			:dialogData.sync="BookingConfirmDialog"
			:carrier="
				currentSelectedSchedule !== null
					? currentSelectedSchedule.carrier_name
					: ''
			"
			@confirm="handleChoose"
			:loading.sync="loading"
		/>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import ConfirmDialog from "../SchedulingOptions/ConfirmDialog.vue";
import globalMethods from "../../../utils/globalMethods";

export default {
	name: "ShipmentSchedulingOptionsV2",
	props: ["getDetails"],
	components: {
		ConfirmDialog,
	},
	data: () => ({
		scheduleHeader: [
			{
				text: "Item",
				align: "start",
				sortable: false,
				value: "schduleItem",
				width: "75%",
				fixed: true,
			},
			{
				text: "Total",
				value: "total",
				align: "right",
				sortable: false,
				width: "25%",
				fixed: true,
			},
		],
		loading: false,
		BookingConfirmDialog: false,
		currentSelectedSchedule: null,
		isChosen: false,
	}),
	computed: {
		schedulingData() {
			return this.getDetails.scheduling_options.filter((o) => {
				return o.haveSellRate == true;
			});
		},
	},
	methods: {
		...mapActions({
			sendConfirmSchedule: "sendConfirmSchedule",
			fetchNewShipmentDetails: "shipmentDetailsV2/fetchNewShipmentDetails",
			fetchShipmentTrackingDetails:
				"shipmentDetailsV2/fetchShipmentTrackingDetails",
		}),
		...globalMethods,
		schedulesSellRateData(sell_rates) {
			return sell_rates.map((item) => {
				return {
					service_name: item?.service_name,
					total: item?.total,
					valid_to: item?.valid_to,
					qty: item?.qty,
				};
			});
		},
		confirmSchedule(item) {
			this.BookingConfirmDialog = true;
			this.currentSelectedSchedule = item;
		},
		async handleChoose() {
			let { shipment_id, mbl_num } = this.getDetails;
			this.loading = true;

			try {
				if (shipment_id !== null && this.currentSelectedSchedule.id !== null) {
					let passedData = {
						id: shipment_id,
						sid: this.currentSelectedSchedule.id,
					};
					await this.sendConfirmSchedule(passedData);
					this.loading = false;
					this.BookingConfirmDialog = false;
					this.notificationMessage("Booking was successfully Confirmed ");
					let payload = {
						shipment_id: shipment_id,
						mbl_num: mbl_num,
					};
				
					await this.fetchNewShipmentDetails(shipment_id);
					await this.fetchShipmentTrackingDetails(payload);
				}
			} catch (e) {
				this.loading = false;
				this.notificationError("SOMETHING WENT WRONG");
			}
		},
		isConfirmed(item) {
			this.isChosen = item.is_confirmed == 1;
			return this.isChosen;
		},
	},
};
</script>

<style lang="scss">
@import "../../../assets/scss/pages_scss/shipment/shipmentDetails/ShipmentSchedulingOptions.scss";
</style>
