<template>
    <v-dialog v-model="dialog" max-width="680px" content-class="add-statement-dialog" v-resize="onResize" :retain-focus="false">
        <v-card class="add-supplier-card">            
            <v-card-title style="padding: 16px 18px !important;">
                <span class="headline">Report Schedule</span>

                <button icon dark class="btn-close" @click="close">
                    <v-icon>mdi-close</v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" action="#" @submit.prevent="">
                    <v-row class="ach-statement-emails-wrapper">
                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <label class="text-item-label"></label>
                            <div class="report-schedule-label-wrapper">
                                <v-switch
                                    v-model="editedItem.for_ach_daily_email"
                                    inset
                                    :ripple="false"
                                    hide-details="auto"
                                    class="ach-toggle-receive"
                                    @change="updateToggleReport('statementsDaily')">
                                </v-switch>
                                <p class="report-schedule-title">Receive ACH Statement Daily</p>
                            </div>
                            <p class="receive-statement-info">
                                We will send a copy of the statements in the emails
                            </p>
                        </v-col>      

                        <v-col cols="12" sm="12" md="12" v-if="editedItem.for_ach_daily_email" class="px-4 pt-4 pb-0">
                            <label class="text-item-label">Recipients</label>
                            <div class="tags-email-wrapper statementsDaily">
                                <vue-tags-input
                                    hide-details="auto"
                                    :tags="statementDailyEmails"
                                    :rules="editedItem.for_ach_daily_email ? statementDailyArrayNotEmptyRules : []"
                                    :add-on-blur=true
                                    :add-on-key="[13, ',']"
                                    v-model="statementDailyEmailAddress"
                                    placeholder="e.g. name@email.com"
                                    @before-adding-tag="(e) => { checkEmailBeforeAdding(e, 'statementsDaily') }"
                                    @tags-changed="newTags => {
                                        this.statementDailyEmails = newTags
                                        this.statementsDailyTagsInput.touched = true
                                        this.statementsDailyTagsInput.hasError = (this.statementDailyEmails.length > 0) ? false : true
                                    }"
                                />
                                <!-- :rules="statementDailyArrayNotEmptyRules" -->
                            </div>

                            <div style="min-height: 12px;" class="mt-1 mb-1">
                                <div v-if="statementDailyErrorMessage === ''">
                                    <p class="my-0" style="color: #9CA5B4; font-size: 12px;">
                                        Separate multiple email addresses with comma
                                    </p>
                                </div>

                                <div v-else class="v-text-field__details">
                                    <span class="v-messages theme--light error--text">{{ statementDailyErrorMessage }}</span>
                                </div>
                            </div>                   
                        </v-col>
                    </v-row>

                    <v-row class="ach-statement-emails-wrapper">
                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <label class="text-item-label"></label>
                            <div class="report-schedule-label-wrapper">
                                <v-switch
                                    v-model="editedItem.for_ach_email_statement"
                                    inset
                                    :ripple="false"
                                    hide-details="auto"
                                    class="ach-toggle-receive"
                                    @change="updateToggleReport('statements')">
                                </v-switch>
                                <p class="report-schedule-title">Receive ACH Statement Monthly</p>
                            </div>
                            <p class="receive-statement-info">
                                We will send a copy of the statements in the emails
                            </p>
                        </v-col>      

                        <v-col cols="12" sm="12" md="12" v-if="editedItem.for_ach_email_statement" class="px-4 pt-4 pb-0">
                            <label class="text-item-label">Recipients</label>
                            <div class="tags-email-wrapper statements">
                                <vue-tags-input
                                    hide-details="auto"
                                    :rules="editedItem.for_ach_email_statement ? statementArrayNotEmptyRules : []"
                                    :tags="statementEmails"
                                    :add-on-blur=true
                                    :add-on-key="[13, ',']"
                                    v-model="statementEmailAddress"
                                    placeholder="e.g. name@email.com"
                                    @before-adding-tag="(e) => { checkEmailBeforeAdding(e, 'statements') }"
                                    @tags-changed="newTags => {
                                        this.statementEmails = newTags
                                        this.statementsTagsInput.touched = true
                                        this.statementsTagsInput.hasError = (this.statementEmails.length > 0) ? false : true
                                    }"
                                />
                                <!-- :rules="statementArrayNotEmptyRules" -->
                            </div>

                            <div style="min-height: 12px;" class="mt-1 mb-1">
                                <div v-if="statementErrorMessage === ''">
                                    <p class="my-0" style="color: #9CA5B4; font-size: 12px;">
                                        Separate multiple email addresses with comma
                                    </p>
                                </div>

                                <div v-else class="v-text-field__details">
                                    <span class="v-messages theme--light error--text">{{ statementErrorMessage }}</span>
                                </div>
                            </div>                   
                        </v-col>
                    </v-row>

                    <v-row class="ach-reminders-emails-wrapper pb-4">
                        <v-col cols="12" sm="12" md="12" class="pb-0">
                            <label class="text-item-label"></label>
                            <div class="report-schedule-label-wrapper">
                                <v-switch
                                    v-model="editedItem.for_ach_email_reminder"
                                    inset
                                    :ripple="false"
                                    hide-details="auto"
                                    class="ach-toggle-receive"
                                    @change="updateToggleReport('reminders')">
                                </v-switch>
                                <p class="report-schedule-title">Receive ACH Reminder</p>
                            </div>
                            <p class="receive-statement-info">
                                We will send a copy of the reminders in the emails
                            </p>
                        </v-col>      

                        <v-col cols="12" sm="12" md="12" v-if="editedItem.for_ach_email_reminder" class="px-4 pt-4 pb-0">
                            <label class="text-item-label">Recipients</label>
                            <div class="tags-email-wrapper reminders">
                                <vue-tags-input
                                    hide-details="auto"
                                    :rules="editedItem.for_ach_email_reminder ? remindersArrayNotEmptyRules : []"
                                    :tags="remindersEmails"
                                    :add-on-blur=true
                                    :add-on-key="[13, ',']"
                                    v-model="remindersEmailAddress"
                                    placeholder="e.g. name@email.com"
                                    @before-adding-tag="(e) => { checkEmailBeforeAdding(e, 'reminders') }"
                                    @tags-changed="newTags => {
                                        this.remindersEmails = newTags
                                        this.remindersTagsInput.touched = true
                                        this.remindersTagsInput.hasError = (this.remindersEmails.length > 0) ? false : true
                                    }"
                                />
                                <!-- :rules="remindersArrayNotEmptyRules" -->
                            </div>

                            <div style="min-height: 12px;" class="mt-1 mb-1">
                                <div v-if="remindersErrorMessage === ''">
                                    <p class="my-0" style="color: #9CA5B4; font-size: 12px;">
                                        Separate multiple email addresses with comma
                                    </p>
                                </div>

                                <div v-else class="v-text-field__details">
                                    <span class="v-messages theme--light error--text">{{ remindersErrorMessage }}</span>
                                </div>
                            </div>                   
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-btn class="btn-blue" text @click="save" :disabled="isLoading">
                    {{ isLoading ? 'Saving...' : 'Save' }}
                </v-btn>

                <v-btn class="btn-white" text @click="close" :disabled="isLoading">
                    Cancel
                </v-btn>
            </v-card-actions>            
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueTagsInput from '@johmun/vue-tags-input';
import jQuery from 'jquery';
import globalMethods from '../../utils/globalMethods';

export default {
    name: 'StatementDialog',
    props: ['editedItemData','editedIndexData', 'dialogData', 'defaultItemData'],
    components: { VueTagsInput },
    data: () => ({
        valid: true,
        value: [],
        isMobile: false,
        tagsValidation: [
            {
                classes: 't-new-tag-input-text-error',
                rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
                disableAdd: true
            }
        ],
        documentProto: document,
        isLoading: false,
        // statements data
        statementArrayNotEmptyRules: [
            (v) => !!v || "Email is required",
            () => this.statementEmailsFiltered.length > 0 || "Make sure to supply at least 1 email." 
        ],
        statementErrorMessage: "",        
        statementEmailAddress: "",
        statementEmails: [],
        statementsTagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        // daily statements
        statementDailyArrayNotEmptyRules: [
            (v) => !!v || "Email is required",
            () => this.statementDailyEmailsFiltered.length > 0 || "Make sure to supply at least 1 email." 
        ],
        statementDailyEmails: [],
        statementDailyEmailAddress: "",
        statementDailyErrorMessage: "",
        statementsDailyTagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        // reminders data
        remindersArrayNotEmptyRules: [
            (v) => !!v || "Email is required",
            () => this.remindersEmailsFiltered.length > 0 || "Make sure to supply at least 1 email." 
        ],
        remindersErrorMessage: "",        
        remindersEmailAddress: "",
        remindersEmails: [],
        remindersTagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
    }),
    computed: {
        ...mapGetters({
            getUser: 'getUser',
        }),
        dialog: {
            get () {
                return this.dialogData
            },
            set (value) {
                if (!value) {
                    this.$emit('update:dialogData', false)
                } else {
                    this.$emit('update:dialogData', true)
                }
            }
        },
        editedItem: {
            get () {
                return this.editedItemData
            },
            set (value) {
                console.log(value);
            }
        },
        defaultItem: {
            get () {
                return this.defaultItemData
            },
            set (value) {
                console.log(value);
            }
        },
        statementEmailsFiltered: {
            get() {
                let statementsValidEmailAddress = []

                if (this.editedItem.ach_statement_recipient !== null && 
                    this.editedItem.ach_statement_recipient.length > 0) {                    
                    this.editedItem.ach_statement_recipient.map(wea => {
                        if (wea?.email) {
                            statementsValidEmailAddress.push({
                                text: wea.email,
                                tiClasses: ["ti-valid"],
                            })
                        } else if (wea !== null) {
                            statementsValidEmailAddress.push({ text: wea, tiClasses: ["ti-valid"] })
                        }
                    })
                }

                return statementsValidEmailAddress
            },
            set(value) {
                this.statementEmails = value
            }            
        },
        remindersEmailsFiltered: {
            get() {
                let remindersValidEmailAddress = []

                if (this.editedItem.ach_reminder_recipient !== null && 
                    this.editedItem.ach_reminder_recipient.length > 0) {                    
                    this.editedItem.ach_reminder_recipient.map(wea => {
                        if (wea?.email) {
                            remindersValidEmailAddress.push({
                                text: wea.email,
                                tiClasses: ["ti-valid"],
                            })
                        } else if (wea !== null) {
                            remindersValidEmailAddress.push({ text: wea, tiClasses: ["ti-valid"] })
                        }
                    })
                }

                return remindersValidEmailAddress
            },
            set(value) {
                this.statementEmails = value
            }                
        },
        statementDailyEmailsFiltered: {
            get() {
                let statementsValidEmailAddress = []

                if (this.editedItem.ach_daily_recipient !== null && 
                    this.editedItem.ach_daily_recipient.length > 0) {                    
                    this.editedItem.ach_daily_recipient.map(wea => {
                        if (wea?.email) {
                            statementsValidEmailAddress.push({
                                text: wea.email,
                                tiClasses: ["ti-valid"],
                            })
                        } else if (wea !== null) {
                            statementsValidEmailAddress.push({ text: wea, tiClasses: ["ti-valid"] })
                        }
                    })
                }

                return statementsValidEmailAddress
            },
            set(value) {
                this.statementDailyEmails = value
            }            
        },
        achMonthlyStatementValue() {
            return this.editedItem.for_ach_email_statement === 1 || this.editedItem.for_ach_email_statement ? true : false;
        },
        achRemindersValue() {
            return this.editedItem.for_ach_email_reminder === 1 || this.editedItem.for_ach_email_reminder ? true : false;
        },
        achDailyStatementValue() {
            return this.editedItem.for_ach_daily_email === 1 || this.editedItem.for_ach_daily_email ? true : false;
        },
    },
    methods: {
        ...mapActions({
            fetchAchStatements: "statements/fetchAchStatements",
            updateReportSchedule: "statements/updateReportSchedule",
            fetchReportSchedule: "statements/fetchReportSchedule"
        }),
        ...globalMethods,
        generateErrorMessage() {
            this.statementsTagsInput.hasError = 
                (this.statementEmails.length === 0 && this.achMonthlyStatementValue) ? true : false;
            if (this.statementsTagsInput.hasError)
                jQuery('.tags-email-wrapper.statements .ti-input').addClass('ti-new-tag-input-error');
            else
                jQuery('.tags-email-wrapper.statements .ti-input').removeClass('ti-new-tag-input-error');


            this.remindersTagsInput.hasError = 
                (this.remindersEmails.length === 0 && this.achRemindersValue) ? true : false;
            if (this.remindersTagsInput.hasError)
                jQuery('.tags-email-wrapper.reminders .ti-input').addClass('ti-new-tag-input-error');
            else
                jQuery('.tags-email-wrapper.reminders .ti-input').removeClass('ti-new-tag-input-error');


            this.statementsDailyTagsInput.hasError = 
                (this.statementDailyEmails.length === 0 && this.achDailyStatementValue) ? true : false;
            if (this.statementsDailyTagsInput.hasError)
                jQuery('.tags-email-wrapper.statementsDaily .ti-input').addClass('ti-new-tag-input-error');
            else
                jQuery('.tags-email-wrapper.statementsDaily .ti-input').removeClass('ti-new-tag-input-error');


            // statement errors
            if (this.statementErrorMessage !== "" || (this.statementEmails.length === 0 && this.achMonthlyStatementValue)) {
				jQuery('.tags-email-wrapper.statements .ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
				this.statementErrorMessage = 
                    this.statementEmails.length === 0 && this.statementEmailAddress === "" && this.achMonthlyStatementValue
					? "Please provide at least 1 valid email address" 
					: (this.statementEmailAddress !== "" 
						? "Please make sure to enter a valid email address" 
						: this.statementErrorMessage)
			} else {
				this.statementErrorMessage = "";
				jQuery('.tags-email-wrapper.statements .ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
			}

            // reminders errors
            if (this.remindersErrorMessage !== "" || (this.remindersEmails.length === 0 && this.achRemindersValue)) {
				jQuery('.tags-email-wrapper.reminders .ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
				this.remindersErrorMessage = 
                    this.remindersEmails.length === 0 && this.remindersEmailAddress === "" && this.achRemindersValue
					? "Please provide at least 1 valid email address" 
					: (this.remindersEmailAddress !== "" 
						? "Please make sure to enter a valid email address" 
						: this.remindersErrorMessage)
			} else {
				this.remindersErrorMessage = "";
				jQuery('.tags-email-wrapper.reminders .ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
			}

            // daily statement errors
            if (this.statementDailyErrorMessage !== "" || (this.statementDailyEmails.length === 0 && this.achDailyStatementValue)) {
				jQuery('.tags-email-wrapper.statementsDaily .ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
				this.statementDailyErrorMessage = 
                    this.statementDailyEmails.length === 0 && this.statementDailyEmailAddress === "" && this.achDailyStatementValue
					? "Please provide at least 1 valid email address" 
					: (this.statementDailyEmailAddress !== "" 
						? "Please make sure to enter a valid email address" 
						: this.statementDailyErrorMessage)
			} else {
				this.statementDailyErrorMessage = "";
				jQuery('.tags-email-wrapper.statementsDaily .ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
			}
        },
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true
            } else {
                this.isMobile = false
            }
        },
        close() {
            this.$refs.form.resetValidation();
            this.$emit('close');
            this.statementErrorMessage = "";
            this.remindersErrorMessage = "";
            this.statementDailyErrorMessage = "";
        },
        async save() {
            if (!this.statementsTagsInput.touched)
                this.statementsTagsInput.touched = true;

            if (!this.remindersTagsInput.touched)
                this.remindersTagsInput.touched = true;

            if (!this.statementsDailyTagsInput.touched)
                this.statementsDailyTagsInput.touched = true;

            this.$refs.form.validate()    
            this.generateErrorMessage();

            setTimeout(async () => {
                if (this.$refs.form.validate()) {
                    if (!this.statementsTagsInput.hasError && !this.remindersTagsInput.hasError && 
                        !this.statementsDailyTagsInput.hasError && this.statementErrorMessage === "" && 
                        this.remindersErrorMessage === "" && this.statementDailyErrorMessage === "") {
                        this.isLoading = true;

                        try {
                            jQuery('.ti-new-tag-input').trigger(
                                jQuery.Event( 'keyup', { keyCode: 13, which: 13 } )
                            )
                            
                            let cid = (typeof this.getUser=='string') ? JSON.parse(this.getUser).default_customer_id : '';

                            let finalStatementEmails = (this.statementEmails.length > 0) ? this.statementEmails.map(o => {
                                return o.text
                            }) : []

                            let finalReminderEmails = (this.remindersEmails.length > 0) ? this.remindersEmails.map(o => {
                                return o.text
                            }) : []

                            let finalDailyStatementEmails = (this.statementDailyEmails.length > 0) ? this.statementDailyEmails.map(o => {
                                return o.text
                            }) : []

                            let data = {
                                ach_statement_recipient: finalStatementEmails,
                                ach_reminder_recipient: finalReminderEmails,
                                ach_daily_recipient: finalDailyStatementEmails,
                                for_ach_email_statement: this.editedItem.for_ach_email_statement ? 1 : 0,
                                for_ach_email_reminder: this.editedItem.for_ach_email_reminder ? 1 : 0,
                                for_ach_daily_email: this.editedItem.for_ach_daily_email ? 1 : 0,
                                id: cid
                            }

                            await this.updateReportSchedule(data);
                            this.isLoading = false;
                            this.notificationMessage('Report Schedule has been updated.');
                            this.close();
                            await this.fetchReportSchedule(cid);
                        } catch (e) {
                            this.notificationError(e)
                            this.isLoading = false;
                        }
                    }                    
                } 
            }, 200)    
        },
        setToDefault() {
            this.$emit('setToDefault')
            this.statementsTagsInput.touched = false;
            this.statementEmails = [];
            this.remindersTagsInput.touched = false;
            this.remindersEmails = [];
            this.statementsDailyTagsInput.touched = false;
            this.statementDailyEmails = [];
        },
        updateToggleReport(isFor) {
            this.$refs.form.resetValidation();
            
            if (isFor === "statements") {
                this.statementsTagsInput.hasError = false;
                this.statementsTagsInput.touched = false;
                jQuery('.tags-email-wrapper.statements .ti-input').removeClass('ti-new-tag-input-error');
            } else if (isFor === "statementsDaily") {
                this.statementsDailyTagsInput.hasError = false;
                this.statementsDailyTagsInput.touched = false;
                jQuery('.tags-email-wrapper.statementsDaily .ti-input').removeClass('ti-new-tag-input-error');
            } else {
                this.remindersTagsInput.hasError = false;
                this.remindersTagsInput.touched = false;
                jQuery('.tags-email-wrapper.reminders .ti-input').removeClass('ti-new-tag-input-error');
            }
        },
        checkEmailBeforeAdding(item, type) {
			if ((/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(item.tag.text)) {
                item.addTag();

                jQuery(`tags-email-wrapper.${type} .ti-input`).removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
                
                if (type === "statements") this.statementErrorMessage = "";
                else if (type === "statementsDaily") this.statementDailyErrorMessage = "";
                else this.remindersErrorMessage = "";
            } else {
                jQuery(`.tags-email-wrapper.${type} .ti-input`).addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
                let message = "Please make sure to enter a valid email address";

                if (type === "statements") this.statementErrorMessage = message;
                else if (type === "statementsDaily")  this.statementDailyErrorMessage = message;               
                else this.remindersErrorMessage = message;
            }
		},
    },
    watch: {
        statementsTagsInput(value) {
            if (value.hasError)
                jQuery('.tags-email-wrapper.statements .ti-input').addClass('ti-new-tag-input-error');
            else
                jQuery('.tags-email-wrapper.statements .ti-input').removeClass('ti-new-tag-input-error');
        },
        remindersTagsInput(value) {
            if (value.hasError)
                jQuery('.tags-email-wrapper.reminders .ti-input').addClass('ti-new-tag-input-error');
            else
                jQuery('.tags-email-wrapper.reminders .ti-input').removeClass('ti-new-tag-input-error');
        },
        statementsDailyTagsInput(value) {
            if (value.hasError)
                jQuery('.tags-email-wrapper.statementsDaily .ti-input').addClass('ti-new-tag-input-error');
            else
                jQuery('.tags-email-wrapper.statementsDaily .ti-input').removeClass('ti-new-tag-input-error');
        },
        dialog(value, oldValue) {
            this.statementsTagsInput.hasError = false;
            this.statementsTagsInput.touched = false;
            this.remindersTagsInput.hasError = false;
            this.remindersTagsInput.touched = false;
            this.statementsDailyTagsInput.hasError = false;
            this.statementsDailyTagsInput.touched = false;

            jQuery('.tags-email-wrapper.statements .ti-input').removeClass('ti-new-tag-input-error');
            jQuery('.tags-email-wrapper.statementsDaily .ti-input').removeClass('ti-new-tag-input-error');
            jQuery('.tags-email-wrapper.reminders .ti-input').removeClass('ti-new-tag-input-error');
            if (typeof el!=='undefined') {
                let el = document.getElementsByClassName('ti-input')[0]
                el.classList.remove('ti-new-tag-input-error')
            }           
            
            if (!value) {
                this.statementEmails = [];
                this.statementEmailAddress = '';

                this.remindersEmails = [];
                this.remindersEmailAddress = '';

                this.statementDailyEmails = [];
                this.statementDailyEmailAddress = '';

            } else if(value && !oldValue) {
                if (this.editedIndex==-1) {
                    this.statementEmails = [];
                    this.remindersEmails = [];
                    this.statementDailyEmails = [];
                } else {
                    let statementsValidEmailAddress = [];
                    let remindersValidEmailAddress = [];
                    let statementsDailyValidEA = [];

                    if (this.editedItem.ach_statement_recipient !== null && 
                        this.editedItem.ach_statement_recipient.length > 0) {
                        
                        this.editedItem.ach_statement_recipient.map(wea => {
                            if (wea?.email) {
								statementsValidEmailAddress.push({
									text: wea.email,
									tiClasses: ["ti-valid"],
								})
							} else if (wea !== null) {
								statementsValidEmailAddress.push({ text: wea, tiClasses: ["ti-valid"] })
							}
                        })
                    }

                    if (this.editedItem.ach_reminder_recipient !== null && 
                        this.editedItem.ach_reminder_recipient.length > 0) {
                        
                        this.editedItem.ach_reminder_recipient.map(wea => {
                            if (wea?.email) {
								remindersValidEmailAddress.push({
									text: wea.email,
									tiClasses: ["ti-valid"],
								})
							} else if (wea !== null) {
								remindersValidEmailAddress.push({ text: wea, tiClasses: ["ti-valid"] })
							}
                        })
                    }

                    if (this.editedItem.ach_daily_recipient !== null && 
                        this.editedItem.ach_daily_recipient.length > 0) {
                        
                        this.editedItem.ach_daily_recipient.map(wea => {
                            if (wea?.email) {
								statementsDailyValidEA.push({
									text: wea.email,
									tiClasses: ["ti-valid"],
								})
							} else if (wea !== null) {
								statementsDailyValidEA.push({ text: wea, tiClasses: ["ti-valid"] })
							}
                        })
                    }

                    this.statementEmails = statementsValidEmailAddress;
                    this.remindersEmails = remindersValidEmailAddress;
                    this.statementDailyEmails = statementsDailyValidEA;
                }                
            }
        },
        statementEmailAddress() {
			this.statementErrorMessage = "";
			jQuery('.tags-email-wrapper.statements .ti-input').removeClass('ti-new-tag-input-error');
			jQuery('.tags-email-wrapper.statements .ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
		},
        remindersEmailAddress() {
			this.remindersErrorMessage = "";
			jQuery('.tags-email-wrapper.reminders .ti-input').removeClass('ti-new-tag-input-error');
			jQuery('.tags-email-wrapper.reminders .ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
		},
        statementDailyEmailAddress() {
            this.statementDailyErrorMessage = "";
            jQuery('.tags-email-wrapper.statementsDaily .ti-input').removeClass('ti-new-tag-input-error');
			jQuery('.tags-email-wrapper.statementsDaily .ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
        },
        'editedItem.for_ach_daily_email': {
            deep: true,
            handler(value) {
                if (!value) {
                    this.statementsDailyTagsInput.touched = false;
                    this.statementsDailyTagsInput.hasError = false;
                    this.statementDailyErrorMessage = "";
                }
            }
        },
        'editedItem.for_ach_email_statement': {
            deep: true,
            handler(value) {
                if (!value) {
                    this.statementsTagsInput.touched = false;
                    this.statementsTagsInput.hasError = false;
                    this.statementErrorMessage = "";
                }
            }
        },
        'editedItem.for_ach_email_reminder': {
            deep: true,
            handler(value) {
                if (!value) {
                    this.remindersTagsInput.touched = false;
                    this.remindersTagsInput.hasError = false;
                    this.remindersErrorMessage = "";
                }
            }
        }
    },
    updated() {
        if (this.editedIndexData === -1) {
            if (typeof this.$refs.form !== 'undefined') {
                if (typeof this.$refs.form.resetValidation() !== 'undefined') {
                    this.$refs.form.resetValidation()
                }
            }
        }
    }
}
</script>

<style lang="scss">
@import '../../assets/scss/pages_scss/dialog/globalDialog.scss';
@import '../../assets/scss/pages_scss/supplier/supplierAddDialog.scss';

.v-autocomplete__content.v-menu__content {
    border-radius: 4px !important;
}

.add-statement-dialog {
    .report-schedule-label-wrapper {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 20px 0 0 4px;

        // justify-content: flex-start;
        // align-items: center;
        // padding: 20px 8px 16px;    

        &.toggled {
            // flex-direction: row-reverse;
            // justify-content: space-between;
            padding: 16px 0 0 4px;
        }

        .v-input.ach-toggle-receive {
            // v-toggle-switch UI from vue
            &.v-input--switch--inset {
                margin-top: 0 !important;
                padding-top: 0 !important;
                padding-bottom: 2px !important;

                .v-input--selection-controls__input {
                    height: 26px;
                    width: 70px;
                    margin-right: 0;
                }

                .v-input--switch__track {
                    width: 65px !important;
                    height: 30px !important;
                    color: $shifl-primary-b20 !important;
                    border: 1px solid $shifl-neutral-n20 !important;
                    opacity: 1 !important;

                    &:after {
                        content: "Off";
                        font-size: 14px;
                        position: absolute;
                        right: 12px;
                        top: 3px;
                        color: $shifl-neutral-n50;
                        font-family: 'Inter-Medium', sans-serif !important;
                    }
                }

                .v-input--switch__thumb {
                    background-color: $shifl-neutral-n50;
                    top: calc(50% - 9px);
                }

                &.v-input--is-label-active.v-input--is-dirty {
                    .v-input--switch__track {
                        color: $shifl-success-g80 !important;

                        &:after {
                            content: "On";
                            color: #fff;
                            position: absolute;
                            left: 12px;
                        }
                    }

                    .v-input--switch__thumb {
                        background-color: #fff !important;
                        transform: translate(36px, 0) !important;
                    }
                }
            }
        }

        p {
            margin-bottom: 0;
            color: $shifl-neutral-n90;

            &.report-schedule-title {
                font-size: 16px;
                font-family: "Inter-SemiBold", sans-serif;
            }
        }
    }

    .receive-statement-info {
        margin-bottom: 0;
        margin-left: 4px;
        color: $shifl-neutral-n90;
        font-size: 14px;
    }

    .tags-email-wrapper {
        width: 100%;

        .vue-tags-input {
            max-width: 100%;
            border-radius: 4px !important;

            .ti-tag {
                border-radius: 4px !important;
                font-family: 'Inter-Medium', sans-serif;
                padding: 3px 8px !important;
                line-height: 1;
                height: 26px;
                background-color: $shifl-neutral-n10 !important;
                border: 1px solid #E1ECF0;
                color: $shifl-neutral-n60 !important;
                margin: 2px 4px 2px 2px;
                min-width: 75px;
                justify-content: space-between;
                align-items: center;
            }
            
            .ti-new-tag-input {
                flex: 1 1 auto;
                line-height: 20px;
                padding: 6px 0 6px;
                max-width: 100%;
                min-width: 0px;
                width: 100%;
            }
            
            .ti-input {
                border: 1px solid $shifl-neutral-n40 !important;
                border-radius: 4px;
                min-height: 40px;
                font-size: 14px;

                .ti-tags {
                    span {
                        color: $shifl-neutral-n90 !important;
                        font-size: 12px;
                    }

                    .ti-icon-close {
                        margin-left: 4px;
                        font-size: 16px;
                    }
                }
            }                    
            
            .ti-new-tag-input::placeholder,
            .ti-input::placeholder{
                color: $shifl-neutral-n40 !important;
            }
            
            .ti-new-tag-input-error {
                border: 1px solid #B11B1B !important;
            }
            
            .t-new-tag-input-text-error {
                color: #B11B1B !important;
            }
            
            .ti-new-tag-input-wrapper {
                padding: 0 4px;
                height: 26px;

                input {
                    font-size: 14px !important;
                }
            }

            &.ti-disabled {
                background-color: $shifl-neutral-n20 !important;
                
                .ti-input {                            
                    border: 1px solid $shifl-neutral-n20 !important;
                }

                .ti-tags {
                    span {
                        color: $shifl-neutral-n40 !important;
                    }
                    
                    .ti-icon-close {
                        color: $shifl-neutral-n40 !important;
                        cursor: auto;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .add-statement-dialog {
        margin: 0 !important;
        height: 100% !important;
        max-height: 100% !important;
        background-color: #fff;

        .v-card.add-supplier-card {
            box-shadow: none !important;
        }
    }
}
</style>
