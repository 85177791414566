<!-- @format -->

<template>
	<div class="logged-out-upload-wrapper" v-resize="onResize">
		<div class="logged-out-upload-content-mobile" v-if="isMobile">
			<div class="content-for-mobile-logout">
				<div class="logged-out-top-content-mobile">
					<img src="@/assets/images/logo.svg" width="120px" height="38px" alt="Shifl Logo">
				</div>

				<div class="logged-out-top-content-mobile">
					<p class="mb-0 text--default text-right" style="font-size: 16px;">Want to join us
						<a href="/signup" class="font-semi-bold text--blue" style="text-decoration: none;">Sign Up</a>
					</p>
				</div>
			</div>

			<div class="logged-out-mobile-wrapper mt-4">
				<div class="shipment-details-wrapper">
					<v-expansion-panels>
						<v-expansion-panel>
							<v-expansion-panel-header>
								<div class="upload-header">
									<p class="mb-0 upload-header-title">Shipment 
										{{ (getShipmentDetails && getShipmentDetails.shifl_ref) || "" }}
									</p>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="upload-body">
									<div class="upload-content-sub mbl-content">
										<p class="upload-subtitle font-semi-bold mb-0">MBL</p>
										<p class="upload-data mb-0">
											{{ (getShipmentDetails && getShipmentDetails.mbl) || "N/A" }}
										</p>
									</div>

									<div class="upload-content-sub location-date-content">
										<p class="upload-subtitle font-semi-bold mb-0">From & ETD</p>
										<p class="upload-data mb-0">
											{{ getShipmentDetails.from || "N/A" }}, 
											<span class="text--blue">{{ convertDateAction(getShipmentDetails.etd || "") }}</span>
										</p>
									</div>

									<div class="upload-content-sub location-date-content">
										<p class="upload-subtitle font-semi-bold mb-0">To & ETA</p>
										<p class="upload-data mb-0">
											{{ getShipmentDetails.to || "N/A" }}, 
											<span class="text--blue">{{ convertDateAction(getShipmentDetails.eta || "") }}</span>
										</p>
									</div>

									<div class="upload-content-sub mode-content">
										<p class="upload-subtitle font-semi-bold mb-0">Mode & Type</p>
										<p class="upload-data mb-0 d-flex align-center">
											<mode-icon v-if="shipmentMode" :iconName="shipmentMode.toLowerCase()" />
											{{ modeName() }},

											<span class="ml-1 text-uppercase d-flex align-center">
												{{ typeName() }}
											</span>
										</p>
									</div>

									<div class="upload-content-sub carrier-content">
										<p class="upload-subtitle font-semi-bold mb-0">Carrier</p>
										<p class="upload-data mb-0">
											<span>{{ getShipmentDetails.carrier ? getShipmentDetails.carrier : "N/A" }}</span>
										</p>
									</div>

									<div class="upload-content-sub vessel-content">
										<p class="upload-subtitle font-semi-bold mb-0">Vessel & Voyage</p>
										<p class="upload-data mb-0">
											<span>{{ getVessel() }}, {{ getVoyage() }}</span>
										</p>
									</div>

									<div class="upload-content-sub terminal-content">
										<p class="upload-subtitle font-semi-bold mb-0">Terminal</p>
										<p class="upload-data mb-0">
											{{ getShipmentDetails.terminal ? getShipmentDetails.terminal : "N/A" }}
										</p>
									</div>

									<div class="upload-content-sub lfd-content">
										<p class="upload-subtitle font-semi-bold mb-0">LFD</p>
										<p class="upload-data mb-0">
											<span>{{ convertDateAction(getShipmentDetails.lfd || "") }}</span>
										</p>
									</div>

									<div class="upload-content-sub container-content">
										<p class="upload-subtitle font-semi-bold mb-0">Container #</p>
										<p class="upload-data mb-0">
											{{ 
												getShipmentDetails.container && getShipmentDetails.container.length > 0 
												? getShipmentDetails.container.join(", ") 
												: "N/A" 
											}}
										</p>
									</div>

									<div class="upload-content-sub volume-content">
										<p class="upload-subtitle font-semi-bold mb-0">Total Volume</p>
										<p class="upload-data mb-0">
											{{ getShipmentDetails.total_volume || "0" }} 
											{{ getShipmentDetails.total_volume ? "CBM" : "" }}
										</p>
									</div>

									<div class="upload-content-sub weight-content">
										<p class="upload-subtitle font-semi-bold mb-0">Total Weight</p>
										<p class="upload-data mb-0">
											{{ getShipmentDetails.total_weight || "0" }} 
											{{ getShipmentDetails.total_weight ? "KG" : "" }}
										</p>
									</div>

									<div class="upload-content-sub carton-content">
										<p class="upload-subtitle font-semi-bold mb-0">Total Carton</p>
										<p class="upload-data mb-0">
											{{ getShipmentDetails.total_carton || "0" }}
										</p>
									</div>

									<div class="upload-content-sub status-content">
										<p class="upload-subtitle font-semi-bold mb-0">Status</p>
										<p class="upload-data mb-0">
											{{ getShipmentDetails.status ? getShipmentDetails.status : "N/A" }}
										</p>
									</div>

									<div class="upload-content-sub pos-content">
										<p class="upload-subtitle font-semi-bold mb-0">PO's</p>
										<p class="upload-data mb-0">
											{{ 
												getShipmentDetails.pos && getShipmentDetails.pos.length > 0 
												? getShipmentDetails.pos.join(", ") 
												: "N/A" 
											}}
										</p>
									</div>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
			</div>
		</div>

		<div class="logged-out-upload-content">
			<div class="logged-out-upload-left">
				<div class="logged-out-top-content" v-if="!isMobile">
					<img src="@/assets/images/logo.svg" width="150px" height="38px" alt="Shifl Logo" class="mb-3">
				</div>

				<div class="upload-content-wrapper-parent" v-if="!isUploadedSuccessfully && !isUploadedSuccessfullyFromUrl">
					<div class="upload-header">
						<p class="mb-0 upload-header-title">{{ formTitle }}</p>
					</div>

					<div class="upload-body" v-if="!callRequestLoading && getRequestDetails !== null">
						<div class="upload-request-from-wrapper">
							<div class="d-flex align-center">
								<img src="@/assets/icons/message-icon.svg" class="mr-2" alt="message" width="18px" height="18px">
								<p class="font-semi-bold mb-0 text--default">Request from {{ companyName || "" }}</p>
							</div>
							<div class="pt-1 text--default" style="padding-left: 26px; font-size: 14px;">
								{{ getRequestDetails ? getRequestDetails.notes : "" }}
							</div>
						</div>

						<div class="upload-request-file-inputs mt-5">
							<div class="for-commercial-invoice" v-if="documentTypeExist('commercial_invoice')">
								<UploadComponent 
									:uploadDocTitle="'Upload Commercial Invoice'"
									:uploadDocType="'commercial_invoice'"
									:refName="'upload_reference_commercial_invoice'"
									ref="upload_reference_commercial_invoice"
									@inputChanged="(e) => inputChanged(e, 'upload_reference_commercial_invoice')"
									@addDocuments="addDocuments('upload_reference_commercial_invoice')"
									:documentLists="documentFiles.documentFilesInvoice"
									:isMobile="isMobile"
									:docTitle="'Commercial Invoice'"
									:dialogMessage="dialogMessage"
									@removeFile="(index) => removeFile(index, 'documentFilesInvoice')"
									:type.sync="type"
									:fieldErrors="fieldErrors.invoice"
									:editedUploadIndex="editedUploadIndex"
									:filePath="filePath"
									:fromLoggedOut="true"
								/>
							</div>

							<div class="for-packing-list" v-if="documentTypeExist('packing_list')">
								<UploadComponent 
									:uploadDocTitle="'Upload Packing List'"
									:uploadDocType="'packing_list'"
									:refName="'upload_reference_packing_list'"
									ref="upload_reference_packing_list"
									@inputChanged="(e) => inputChanged(e, 'upload_reference_packing_list')"
									@addDocuments="addDocuments('upload_reference_packing_list')"
									:documentLists="documentFiles.documentFilesPacking"
									:isMobile="isMobile"
									:docTitle="'Packing List'"
									:dialogMessage="dialogMessage"
									@removeFile="(index) => removeFile(index, 'documentFilesPacking')"
									:type.sync="type"
									:fieldErrors="fieldErrors.packing"
									:editedUploadIndex="editedUploadIndex"
									:filePath="filePath"
									:fromLoggedOut="true"
								/>
							</div>

							<div class="for-commercial-packing-list" v-if="documentTypeExist('commercial_and_packing')">
								<UploadComponent 
									:uploadDocTitle="'Upload Commercial Invoice & Packing List'"
									:uploadDocType="'commercial_and_packing'"
									:refName="'upload_reference_commercial_and_packing'"
									ref="upload_reference_commercial_and_packing"
									@inputChanged="(e) => inputChanged(e, 'upload_reference_commercial_and_packing')"
									@addDocuments="addDocuments('upload_reference_commercial_and_packing')"
									:documentLists="documentFiles.documentBothTypes"
									:isMobile="isMobile"
									:docTitle="'Packing List'"
									:dialogMessage="dialogMessage"
									@removeFile="(index) => removeFile(index, 'documentBothTypes')"
									:type.sync="type"
									:fieldErrors="fieldErrors.packing"
									:editedUploadIndex="editedUploadIndex"
									:filePath="filePath"
									:fromLoggedOut="true"
								/>
							</div>

							<div class="for-others">
								<UploadComponent 
									:uploadDocTitle="'Other Documents'"
									:uploadDocType="'other'"
									:refName="'upload_reference_other'"
									ref="upload_reference_other"
									@inputChanged="(e) => inputChanged(e, 'upload_reference_other')"
									@addDocuments="addDocuments('upload_reference_other')"
									:documentLists="documentFiles.documentFilesOthers"
									:isMobile="isMobile"
									:docTitle="''"
									:dialogMessage="dialogMessage"
									@removeFile="(index) => removeFile(index, 'documentFilesOthers')"
									:type.sync="type"
									:fieldErrors="fieldErrors.others"
									:editedUploadIndex="editedUploadIndex"
									:filePath="filePath"
									:fromLoggedOut="true"
									:getSelectedDocumentUpload="getRequestDetails"
								/>
							</div>
						</div>
					</div>

					<div class="upload-body d-flex align-center justify-center" v-else-if="callRequestLoading">
						<v-progress-circular :size="30" color="#0171a1" indeterminate></v-progress-circular>
					</div>

					<div class="upload-footer px-6" v-if="!callRequestLoading">
						<button class="btn-blue" @click="confirmUploadDocument" 
							:disabled="isUploadLoading || concatDocumentTypes.length === 0">
							{{ isUploadLoading ? "Uploading..." : "Upload" }}
						</button>
					</div>
				</div>

				<div class="upload-content-wrapper-parent" v-if="isUploadedSuccessfully || isUploadedSuccessfullyFromUrl">
					<div class="upload-header">
						<p class="mb-0 upload-header-title">{{ formTitle }}</p>
					</div>

					<div class="upload-body">
						<div class="upload-success-from-wrapper">
							<div class="d-flex align-center">
								<img src="@/assets/icons/check-outline-green.svg" class="mr-2" width="48px" height="48px">
							</div>
							<div class="mt-3 pl-1 text--default font-semi-bold" style="font-size: 20px;">
								Document Uploaded Successfully
							</div>

							<p class="mb-0 pl-1 mt-2 text--default">
								Your document has been successfully uploaded and securely received. Thank you for responding to file submission.
							</p>
						</div>

						<div class="upload-request-file-inputs mt-8">
							<div class="document-tab-wrapper">
								<v-data-table
									:headers="headers"
									:items="documentLists"
									item-key="id"
									:items-per-page="25"
									class="elevation-1 documents-table"
									hide-default-footer 
									v-if="!isMobile">

									<template v-slot:[`item.name`]="{ item }">
										<div class="document-name-info">
											<p>{{ item.name }}</p>
											<!-- <p class="updated-by-name-date">
												<span>{{ dateFormat(item.updated_at) }}</span>
											</p> -->
										</div>
									</template>

									<template v-slot:[`item.type`]="{ item }">
										<div class="document-type-info">
											<p class="text-capitalize">
												{{ 
													typeof item.type !== 'undefined' ? 
													(item.type === 'Hbl' || item.type === "hbl" ? 'OBL Document' : item.type)
													: '--' 
												}}
											</p>
										</div>
										<!-- <div class="supplier-name-logout-wrapper">
											<p class="supplier-name-logout mb-0" v-if="item.type !== 'Delivery Order' && item.type !== 'Other'">
												Supplier: {{ item.supplier_name }}
											</p>
										</div> -->
									</template>

									<template v-slot:[`item.actions`]="{ item }">
										<div class="actions">
											<button class="btn-white" @click="viewDocument(item)" :disabled="checkFileExtension(item)">
												<v-icon>mdi-eye</v-icon>
											</button>
										</div>
									</template>
								</v-data-table>

								<v-data-table
									:headers="headersMobile"
									:items="documentLists"
									item-key="id"
									:items-per-page="25"
									class="elevation-1 documents-table"
									hide-default-footer 
									mobile-breakpoint="0"
									v-if="isMobile">

									<template v-slot:item="props">
										<tr>
											<td>
												<div class="document-name-info d-flex justify-space-between align-start">
													<div class="document-info-content">
														<div class="document-name font-medium mb-1">
															{{ props.item.name }}
														</div> 
														<div class="document-name text-capitalize dc-darkgrey" style="font-size: 12px;">
															{{ 
																typeof props.item.type !== 'undefined' ? 
																(props.item.type === 'Hbl' || props.item.type === "hbl" ? 'OBL Document' : props.item.type) 
																: '--' 
															}}
														</div> 
													</div>

													<div class="actions">
														<button class="btn-white" @click="viewDocument(props.item)" :disabled="checkFileExtension(props.item)">
															<v-icon>mdi-eye</v-icon>
														</button>
													</div>
												</div>
											</td>
										</tr>
									</template>
								</v-data-table>
							</div>
						</div>
					</div>

					<div class="upload-footer px-6 d-flex justify-start align-center">
						<button class="btn-blue mr-2" @click="closeUpload">
							Close Tab
						</button>
					</div>
				</div>
			</div>

			<div class="logged-out-upload-right" v-if="!isMobile">
				<div class="logged-out-top-content pr-5">
					<p class="mb-0 text--default text-right" style="font-size: 16px;">Want to join 
						<span class="font-semi-bold">Shifl’s</span> supply chain platform? 
						<a href="/signup" class="font-semi-bold text--blue" style="text-decoration: none;">Sign Up</a>
					</p>
				</div>
				<div class="upload-content-wrapper-parent" v-if="!callDetailsLoading && getShipmentDetails !== null">
					<div class="upload-header">
						<p class="mb-0 upload-header-title">Shipment {{ getShipmentDetails.shifl_ref }}</p>
					</div>

					<div class="upload-body">
						<div class="upload-content-sub mbl-content">
							<p class="upload-subtitle font-semi-bold mb-0">MBL</p>
							<p class="upload-data mb-0">{{ getShipmentDetails.mbl || "N/A" }}</p>
						</div>

						<div class="upload-content-sub location-date-content">
							<p class="upload-subtitle font-semi-bold mb-0">From & ETD</p>
							<p class="upload-data mb-0">
								{{ getShipmentDetails.from || "N/A" }}, 
								<span class="text--blue">{{ convertDateAction(getShipmentDetails.etd || "") }}</span>
							</p>
						</div>

						<div class="upload-content-sub location-date-content">
							<p class="upload-subtitle font-semi-bold mb-0">To & ETA</p>
							<p class="upload-data mb-0">
								{{ getShipmentDetails.to || "N/A" }}, 
								<span class="text--blue">{{ convertDateAction(getShipmentDetails.eta || "") }}</span>
							</p>
						</div>

						<div class="upload-content-sub mode-content">
							<p class="upload-subtitle font-semi-bold mb-0">Mode & Type</p>
							<p class="upload-data mb-0 d-flex align-center">
								<mode-icon v-if="shipmentMode" :iconName="shipmentMode.toLowerCase()" />
								{{ modeName() }},

								<span class="ml-1 text-uppercase d-flex align-center">
									{{ typeName() }}
								</span>
							</p>
						</div>

						<div class="upload-content-sub carrier-content">
							<p class="upload-subtitle font-semi-bold mb-0">Carrier</p>
							<p class="upload-data mb-0">
								<span>{{ getShipmentDetails.carrier ? getShipmentDetails.carrier : "N/A" }}</span>
							</p>
						</div>

						<div class="upload-content-sub vessel-content">
							<p class="upload-subtitle font-semi-bold mb-0">Vessel & Voyage</p>
							<p class="upload-data mb-0">
								<span>{{ getVessel() }}, {{ getVoyage() }}</span>
							</p>
						</div>

						<div class="upload-content-sub terminal-content">
							<p class="upload-subtitle font-semi-bold mb-0">Terminal</p>
							<p class="upload-data mb-0">
								{{ getShipmentDetails.terminal ? getShipmentDetails.terminal : "N/A" }}
							</p>
						</div>

						<div class="upload-content-sub lfd-content">
							<p class="upload-subtitle font-semi-bold mb-0">LFD</p>
							<p class="upload-data mb-0">
								<span>{{ convertDateAction(getShipmentDetails.lfd || "") }}</span>
							</p>
						</div>

						<div class="upload-content-sub container-content">
							<p class="upload-subtitle font-semi-bold mb-0">Container #</p>
							<p class="upload-data mb-0">
								{{ 
									getShipmentDetails.container && getShipmentDetails.container.length > 0 
									? getShipmentDetails.container.join(", ") 
									: "N/A" 
								}}
							</p>
						</div>

						<div class="upload-content-sub volume-content">
							<p class="upload-subtitle font-semi-bold mb-0">Total Volume</p>
							<p class="upload-data mb-0">
								{{ getShipmentDetails.total_volume || "0" }} 
								{{ getShipmentDetails.total_volume ? "CBM" : "" }}
							</p>
						</div>

						<div class="upload-content-sub weight-content">
							<p class="upload-subtitle font-semi-bold mb-0">Total Weight</p>
							<p class="upload-data mb-0">
								{{ getShipmentDetails.total_weight || "0" }} 
								{{ getShipmentDetails.total_weight ? "KG" : "" }}
							</p>
						</div>

						<div class="upload-content-sub carton-content">
							<p class="upload-subtitle font-semi-bold mb-0">Total Carton</p>
							<p class="upload-data mb-0">
								{{ getShipmentDetails.total_carton || "0" }}
							</p>
						</div>

						<div class="upload-content-sub status-content">
							<p class="upload-subtitle font-semi-bold mb-0">Status</p>
							<p class="upload-data mb-0">
								{{ getShipmentDetails.status ? getShipmentDetails.status : "N/A" }}
							</p>
						</div>

						<div class="upload-content-sub pos-content">
							<p class="upload-subtitle font-semi-bold mb-0">PO's</p>
							<p class="upload-data mb-0">
								{{ 
									getShipmentDetails.pos && getShipmentDetails.pos.length > 0 
									? getShipmentDetails.pos.join(", ") 
									: "N/A" 
								}}
							</p>
						</div>
					</div>
				</div>
				<div class="upload-content-wrapper-parent d-flex align-center justify-center" 
					style="padding-bottom: 45px;" v-else-if="callDetailsLoading">
					<v-progress-circular :size="30" color="#0171a1" indeterminate></v-progress-circular>
				</div>
			</div>
		</div>

		<ViewDocumentsDialog
			:dialogData.sync="viewDocumentDialog"
			:documentData="documentData"
			@close="closeViewDocumentDialog"
			:isFrom="'logout'" />

		<!-- if request has been cancelled -->
		<ConfirmDialog :dialogData.sync="requestCancelledConfirmationDialog" :className="'request-small-dialog'">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Request has been cancelled </h2>
			</template>

			<template v-slot:dialog_content>
				<p> This request has already been cancelled. </p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="requestCancelInfoClose('requestCancelledConfirmationDialog')">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>

		<!-- if request has been fulfilled -->
		<ConfirmDialog :dialogData.sync="requestFulfilledConfirmationDialog" :className="'request-small-dialog'">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/info-blue.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Request has been fulfilled </h2>
			</template>

			<template v-slot:dialog_content>
				<p> This request has already been fulfilled. </p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="requestCancelInfoClose('requestFulfilledConfirmationDialog')">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>

<script>
import UploadComponent from '../../components/ShipmentComponents/Documents/UploadComponent.vue';
import { allTypesAllowed } from "../../constants/mimeTypes";
import axios from 'axios';
const baseURL = process.env.VUE_APP_BASE_URL;
import globalMethods from '../../utils/globalMethods';
import ModeIcon from "../../components/Icons/ModeIcon.vue";
import ViewDocumentsDialog from '../../components/ShipmentComponents/Documents/ViewDocumentsDialog.vue';
import ConfirmDialog from '../../components/Dialog/GlobalDialog/ConfirmDialog.vue';
import moment from "moment";

export default {
	name: "UploadPage",
	props: [],
	components: { 
		UploadComponent,
		ModeIcon,
		ViewDocumentsDialog,
		ConfirmDialog
	},
	data: () => ({
		dialogMessage: false,
		type: "",
		fieldErrors: {
			invoice: false,
			packing: false,
			others: false
		},
		files: {
			documentFilesInvoice: [],
			documentFilesPacking: [],
			documentFilesOthers: [],
			documentFilesDefault: [],
			documentBothTypes: []
		},
		documentFiles: {
			documentFilesInvoice: [],
			documentFilesPacking: [],
			documentFilesOthers: [],
			documentFilesDefault: [],
			documentBothTypes: []
		},
		isMobile: false,
		editedUploadIndex: -1,
		isFetchingDocumentReupload: false,
		isReuploading: false,
		isCommercialInvoiceTypeExist: false,
		isPackingListTypeExist: false,
		isCommercialAndPackingTypeExist: false,
		isOtherDocumentTypeExists: false,
		isUploadedSuccessfully: false,
		headers: [
            {
                // text: 'Name & Updated at',
				text: 'Name',
                align: 'start',
                sortable: true,
                value: 'name',
                width: '35%',
                fixed: true
            },
            {
                text: 'Type & Tag',
                align: 'start',
                sortable: false,
                value: 'type',
                width: '35%',
                fixed: true
            },
            { 
                text: '', 
                value: 'actions',
                sortable: false,
                align: 'end',
                fixed: true,
                width: '10%'
            }
        ],
		headersMobile: [
            {
                text: 'Name, Type & Tag',
                align: 'start',
                sortable: true,
                value: 'name',
                width: '45%',
                fixed: true
            }
        ],
		viewDocumentDialog: false,
		documentData: "",
		noLoggedinDetails: {
			requestDetails: null,
			shipmentDetails: null
		},
		companyName: "",
		sCompanyName: "",
		shipmentMode: "",
		shipmentType: "",
		callRequestLoading: false,
		callDetailsLoading: false,
		request_id: "",
		shipment_id: "",
		isUploadLoading: false,
		supplierCustomerId: "",
		panel: null,
		vessel: "",
		voyage: "",
		documentLists: [],
		requestCancelledConfirmationDialog: false,
		requestFulfilledConfirmationDialog: false
	}),
	computed: {
		formTitle() {
			return this.editedUploadIndex === -1 ? "Upload Documents" : "Reupload Documents"
		},
		filePath() {
			return ""
		},
		getRequestDetails() {
			return this.noLoggedinDetails.requestDetails;
		},
		getShipmentDetails() {
			return this.noLoggedinDetails.shipmentDetails;
		},
		getDocumentTypes() {
			let arr = []

			if (this.getRequestDetails !== null && this.getRequestDetails.document_types !== undefined) {
				if (this.getRequestDetails.document_types.length > 0) {
					arr = JSON.parse(this.getRequestDetails.document_types)

					if (arr.length > 1) {
						if (arr.includes("Commercial Invoice & Packing List")) {
							if (arr.includes("Commercial Invoice") && arr.includes("Packing List")) {
								arr = arr.filter(v => {
									return v !== "Commercial Invoice & Packing List"
								})
							}
						}
					} 				
					if (arr.length > 0) {
						arr = arr.map(v => {
							return {
								name: v,
								id: v !== "Commercial Invoice & Packing List" 
									? v.replace(/ /g,"_").toLowerCase() : "commercial_and_packing"
							}
						})
					}
				}
			}
			return arr
		},
		concatDocumentTypes() {
			let arr = []

			let { documentFilesDefault, documentFilesInvoice, documentFilesOthers, documentFilesPacking, documentBothTypes } = this.documentFiles

			arr = [].concat(
				documentFilesDefault, 
				documentFilesInvoice, 
				documentFilesOthers, 
				documentFilesPacking,
				documentBothTypes
			);

			return arr
		},
		isUploadedSuccessfullyFromUrl() {
			let isUploaded = false;
			if (this.$route.query.uploadedSuccess !== undefined) {
				isUploaded = true;
			}
			return isUploaded
		},
	},	
    watch: {
		getRequestDetails(v) {
			v.isFromRequest = true;
		}
	},
	async mounted() {
		let q = this.$route.query;

		if (Object.keys(q).length === 0) { // check if params are empty, redirect to login
			this.closeUpload();
		} else {
			const url = new URL(window.location.origin + this.$route.query.redirectTo);
			const requestId = url.searchParams.get("rid");
			this.companyName = url.searchParams.get("companyName");
			this.sCompanyName = url.searchParams.get("sCompanyName");
			this.supplierCustomerId = url.searchParams.get("scustomer");
			this.supplier_id = url.searchParams.get("supplier_id");
			let shipmentId = url.pathname !== "" ? url.pathname.split('/').pop() : "";
			this.callDetailsLoading = true;
			this.callRequestLoading = true;
			this.request_id = requestId;
			this.shipment_id = shipmentId;
			await this.callShipmentDetailsWithoutLogginIn(shipmentId); // call shipment details
			await this.callRequestDetailsWithoutLoggingIn(requestId);
			// check the request if it's already fulfilled or cancelled - then show corresponding dialog
			if (this.getRequestDetails !== null) {
				if (this.getRequestDetails.is_cancelled === 1) { // request is already cancelled					
					this.requestCancelledConfirmationDialog = true;
				} else if (this.getRequestDetails.is_fulfilled === 1) { // request is already fulfilled					
					this.requestFulfilledConfirmationDialog = true;
				}
			}			
		}
	},
	methods: {
		...globalMethods,
		onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
		viewDocument(item) {
			this.viewDocumentDialog = true;
			this.documentData = item;
		},
		closeViewDocumentDialog() {
			this.viewDocumentDialog = false;
			this.documentData = "";
		},
        addDocuments(item) {
			if (item === "upload_documents_reference") {
				this.$refs.upload_documents_reference.click();
			} else {
				this.$refs[item].$refs[item].click();
			}
		},
		inputChanged(e, item) {
			// let files = this.$refs.upload_documents_reference.files;

			let files = null;
			if (item === "upload_documents_reference") {
				files = this.$refs.upload_documents_reference.files;
			} else {
				files = e.target.files
			}

			if (!files.length) {
				return false;
			}

			for (let i = 0; i < files.length; i++) {
				let typeName = "documentFilesDefault";
				let type = ""

				if (item === "upload_reference_commercial_invoice") {
					typeName = "documentFilesInvoice"
					type = "Commercial Invoice"
				} else if (item === "upload_reference_packing_list") {
					typeName = "documentFilesPacking"
					type = "Packing List"
				} else if (item === "upload_reference_other") {
					typeName = "documentFilesOthers"
					type = "Other"
				} else if (item === "upload_reference_commercial_and_packing") {
					typeName = "documentBothTypes"
					type = "Commercial Invoice & Packing List"
				}

				this.documentFiles[typeName].push({
					file: files[i],
					supplier_id: [],
					type,
					name: files[i].name,
					typeError: false,
					supplierError: false,
					nameError: false,
					fileError: false,
					is_document_new: true
				})
				this.files[typeName].push(files[i])

				if (this.editedUploadIndex > -1) { // if reupload
					// remove the first item to replace
					this.documentFiles[typeName].splice(0, 1);
					this.files[typeName].splice(0, 1)
				}
			}
		},
		removeFile(index, isFor) {
			this.documentFiles[isFor].splice(index, 1);
			this.files[isFor].splice(index, 1);
		},
		documentTypeExist(docType) {
			let exists = false

			if (this.getDocumentTypes.length > 0) {
				let arr = []
				arr = this.getDocumentTypes.map(v => { return v.id })
				
				if (arr.length > 0) {
					if (arr.includes(docType)) {
						exists = true
					}
				}
			}

			return exists
		},
		close() {
			this.$emit("close");
			this.type = "";
			this.selected_tags = [];
			this.files = {
				documentFilesInvoice: [],
				documentFilesPacking: [],
				documentFilesOthers: [],
				documentFilesDefault: [],
				documentBothTypes: []
			};
			this.documentFiles = {
				documentFilesInvoice: [],
				documentFilesPacking: [],
				documentFilesOthers: [],
				documentFilesDefault: [],
				documentBothTypes: []
			};

			this.fieldErrors = {
				invoice: false,
				packing: false,
				others: false
			};

			this.isCommercialInvoiceTypeExist = false;
			this.isPackingListTypeExist = false;
			this.isCommercialAndPackingTypeExist = false;
			this.isOtherDocumentTypeExists = false;
			
			if (typeof this.$refs.upload_documents_reference !== "undefined") {
				this.$refs.upload_documents_reference.value = "";
			}
			
			if (typeof this.$refs.upload_reference_other !== "undefined") {
				this.$refs.upload_reference_other.$refs.upload_reference_other.value = "";
			}

			if (typeof this.$refs.upload_reference_commercial_invoice !== "undefined") {
				this.$refs.upload_reference_commercial_invoice.$refs.upload_reference_commercial_invoice.value = "";
			}

			if (typeof this.$refs.upload_reference_packing_list !== "undefined") {
				this.$refs.upload_reference_packing_list.$refs.upload_reference_packing_list.value = "";
			}

			if (typeof this.$refs.upload_reference_commercial_and_packing !== "undefined") {
				this.$refs.upload_reference_commercial_and_packing.$refs.upload_reference_commercial_and_packing.value = "";
			}
		},
		checkFileExtension(file) {
            if (file.path !== null) {
                var parts = file.path.split('.');
                let fileType = parts[parts.length - 1];

                if (!allTypesAllowed.includes(fileType.toLowerCase())) { // if file type is in above
                    return true
                } else return false
                
            } else return false
        },
		async callRequestDetailsWithoutLoggingIn(id) {
			if (id !== "" && id !== null) {
				await axios.get(`${baseURL}/no-login-request-details/${id}`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data.data !== "undefined") {
							this.noLoggedinDetails.requestDetails = res.data.data;
						}
					}
				}).catch(() => {
					this.notificationError("An error occurred.")
				})
				this.callRequestLoading = false;
			}
		},
		async callShipmentDetailsWithoutLogginIn(id) {
			if (id !== "" && id !== null) {
				await axios.get(`${baseURL}/no-login-shipment-details/${id}`)
				.then((res) => {
					if (res.status === 200) {
						if (typeof res.data.data !== "undefined") {
							this.noLoggedinDetails.shipmentDetails = res.data.data;
						}
					}
				}).catch(() => {
					this.notificationError("An error occurred.")
				})
			}
			this.callDetailsLoading = false;
		},
		// other methods for getting data
		ucFirst(str) {
			let pieces = str.split(" ");
			for (let i = 0; i < pieces.length; i++) {
				let j = pieces[i].charAt(0).toUpperCase();
				pieces[i] = j + pieces[i].substr(1);
			}
			return pieces.join(" ");
		},
		modeName() {
			this.shipmentMode = this.getShipmentDetails.mode !== null && this.getShipmentDetails.mode !== "" 
				? this.getShipmentDetails.mode.toLowerCase() : ""

			return this.getShipmentDetails.mode !== null && this.getShipmentDetails.mode !== "" 
				? this.getShipmentDetails.mode : "N/A"
		},
		typeName() {
			return this.getShipmentDetails.type !== null && this.getShipmentDetails.type !== "" 
				? this.getShipmentDetails.type : "N/A"
		},
		getVessel() {
			this.vessel = this.getShipmentDetails.vessel !== null && 
				this.getShipmentDetails.vessel !== "" 
					? this.getShipmentDetails.vessel : "";

			return this.vessel ? this.vessel : "N/A";
		},
		getVoyage() {
			this.voyage = this.getShipmentDetails !== null &&
				typeof this.getShipmentDetails.voyage !== "undefined" &&
					this.getShipmentDetails.voyage !== null &&
					this.getShipmentDetails.voyage !== ""
					? this.getShipmentDetails.voyage
					: "";
			return this.voyage ? this.voyage : "N/A";
		},
		checkDocumentType(type) {
			if (type !== null && type !== "") {
				if (type === "Invoice and Packing List" || type === "invoice and packing list" || 
					type === "commercial invoice & packing list" || type === "Commercial Invoice & Packing List" ||
					type === "Invoice & Packing List") {
					return "Invoice & Packing List"

				} else if (type.toLowerCase() === "commercial invoice") {
					return "Commercial Invoice"

				} else if (type.toLowerCase() === "packing list") {
					return "Packing List"

				} else if (type.toLowerCase() === "other") {
					return "Other Documents"

				} else if (type === "OBL Document" || type === "hbl") {
					return "Hbl Copy"

				} else if (type.toLowerCase() === "other commercial docs" || type.toLowerCase() === "other commercial documents") {
					return "Other Commercial Documents"
				}
			} else {
				return ""
			}
		},
		async confirmUploadDocument() {
			let { shipment_id } = this;
			let finalDocuments = []
			
			let { documentFilesDefault, documentFilesInvoice, documentFilesOthers, documentFilesPacking, documentBothTypes } = this.documentFiles

			finalDocuments = [].concat(
				documentFilesDefault, 
				documentFilesInvoice, 
				documentFilesOthers, 
				documentFilesPacking,
				documentBothTypes
			);

			if (finalDocuments.length > 0) {
				let uploaded_by = { 
					customer_admin: "", 
					user_id: "", 
					customer: this.sCompanyName, 
					customer_id: this.supplierCustomerId !== "0" ? this.supplierCustomerId : ""
				}

				let finalData = {
					documents: finalDocuments,
					shipment_id,
					uploaded_by: JSON.stringify(uploaded_by),
					isFromRequest: true,
					request_id: this.request_id
				};
				
				try {
					finalData.documents.map((v) => {
						if (v.type.toLowerCase() === "delivery order" || v.type.toLowerCase() === "other") {
							v.supplier_id = [];
						}

						// if (v.type === "OBL Document") {
						// 	v.type = "hbl";
						// }
					});
				
					this.isUploadLoading = true;
					await this.uploadShipmentDocuments(finalData);
				} catch (e) {
					if (typeof e == "object") {
						let getKeys = Object.keys(e);
						// this.documentFiles.map((df, k) => {
						finalDocuments.map((df, k) => {
							finalDocuments[k].typeError = false;
							finalDocuments[k].supplierError = false;
							finalDocuments[k].nameError = false;
							finalDocuments[k].fileError = false;
						});

						getKeys.map((gk) => {
							let getKey = e[gk][0].split(".")[2].split(" ")[0];
							if (getKey === "supplier_id") {
								finalDocuments[
									parseInt(e[gk][0].split(".")[1])
								].supplierError = true;
							}

							if (getKey === "type") {
								finalDocuments[
									parseInt(e[gk][0].split(".")[1])
								].typeError = true;
							}

							if (getKey === "name") {
								finalDocuments[
									parseInt(e[gk][0].split(".")[1])
								].nameError = true;
							}

							if (getKey === "file") {
								finalDocuments[
									parseInt(e[gk][0].split(".")[1])
								].fileError = true;
							}
						});
					} else {
						//errorMessageDisplay = e
					}
				}
			}	
		},
		async uploadShipmentDocuments(params) {
			let { documents, request_id, shipment_id, uploaded_by } = params

			let fd = new FormData()
			fd.append('shipment_id', parseInt(shipment_id))
			fd.append('request_id', parseInt(request_id))
			fd.append('uploaded_by', uploaded_by)

			documents.map((finalDocument, key) => {
				let checkInputs = ['supplier_id', 'type', 'file', 'name']
				checkInputs.map(ci => {
					if ((ci === 'name' || ci === 'file' || ci === 'type') && typeof finalDocument[ci] !== 'undefined') {
						if (ci === 'type') {
							// if type is Commercial Invoice & Packing List, change to invoice and packing list
							if (finalDocument[ci].toLowerCase() === "commercial invoice & packing list") {
								finalDocument[ci] = "invoice and packing list"
							}

							fd.append(`document_infos[${key}][${ci}]`, finalDocument[ci].toLowerCase())
							fd.append(`document_infos[${key}][document_type]`, this.checkDocumentType(finalDocument[ci]))
						} else
							fd.append(`document_infos[${key}][${ci}]`, finalDocument[ci])
					}
					
					if (ci === 'supplier_id' && typeof finalDocument[ci]!=='undefined') {
						fd.append(`document_infos[${key}][supplier_id][0]`, this.supplier_id)
					}
				})
			})

			await axios.post(`${baseURL}/no-login-upload-multiple-documents`, fd)
			.then(async (res) => {
				if (res.status === 200) {
					this.isUploadedSuccessfully = true;
					this.isUploadLoading = false;
					this.documentLists = res.data.data;
					let q = this.$route.query
					
					await this.callRequestDetailsWithoutLoggingIn(request_id);

					if (this.getRequestDetails !== null) {
						let doc_types = JSON.parse(this.getRequestDetails.document_types);
						if (doc_types.length === 0) {
							this.$router.push({ 
								query: { ...q, uploadedSuccess: 1 }
							}).catch(()=>{});
						}
					}
				}
			}).catch(() => {
				this.isUploadLoading = false;
				this.notificationError("An error occurred.");
				this.documentLists = [];
			})
		},
		closeUpload() {
			this.close();
			this.$router.push("/login")
		},
		requestCancelInfoClose(dataItem) {
			this[dataItem] = false;
			this.$router.push("/login");
		},
		convertDateAction(date) {
			return date !== null && date !== "" ? moment(date).format("MMM DD, YYYY") : "N/A"
		}
	},
	updated() {},
};
</script>

<style lang="scss">
@import "../../assets/scss/pages_scss/shipment/uploadLoggedOut.scss";
</style>
