export let editorFields = [
    // {
    //     field: "Daily Statement",
    //     label: "A",
    //     type: "string",
    //     width: "180px",
    // },
    // {
    //     field: "Entry No",
    //     label: "B",
    //     type: "string",
    //     width: "120px",
    // },
    // {
    //     field: "Reference",
    //     label: "C",
    //     type: "string",
    //     width: "120px",
    // },
    // {
    //     field: "POs",
    //     label: "D",
    //     type: "string",
    //     width: "120px",
    // },
    // {
    //     field: "Container No",
    //     label: "E",
    //     type: "string",
    //     width: "180px",
    // },
    // {
    //     field: "Amount",
    //     label: "F",
    //     type: "string",
    //     width: "120px",
    // },

    {
        text: "",
        align: "center",
        value: "Index",
        sortable: false,
        width: "2%", 
        fixed: false
    },
    {
        text: "A",
        align: "start",
        value: "Daily Statement",
        sortable: false,
        width: "10%", 
        fixed: false
    },
    {
        text: "B",
        align: "start",
        value: "Entry No",
        sortable: false,
        width: "10%", 
        fixed: false
    },
    {
        text: "C",
        align: "start",
        value: "Reference",
        sortable: false,
        width: "10%", 
        fixed: false
    },
    {
        text: "D",
        align: "start",
        value: "POs",
        sortable: false,
        width: "10%", 
        fixed: false
    },
    {
        text: "E",
        align: "start",
        value: "Container No",
        sortable: false,
        width: "10%", 
        fixed: false
    },
    {
        text: "F",
        align: "start",
        value: "Amount",
        sortable: false,
        width: "10%", 
        fixed: false
    },
]