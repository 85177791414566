const item = {
    is_notify_party: false,
    special_instructions: '',
    inco_term: "",
    inco_term_other: "",
    showOtherMenus: false,
    contact: '',
    total_cartons: 0,
    total_volume: 0,
    total_weight: 0,
    commodity_other_infos: '',
    contact_number: '',
    port_of_departure: 0,
    poManual: {
        po_number: '',
        cargoReadyDate: '',
        cargoReady: '',
        volume: 0,
        cartons: 0,
        weights: 0,
        suppliersOptions: [],
        supplier_id: 0
    },
    port_of_destination: 0,
    port_of_discharge: 0,
    purchaseOrders: [],
    consignee: 0,
    shipper: 0,
    shipper_details_info: '',
    commodities_details_info: '',
    consignee_details_info: '',
    booking_for: 0,
    mbl_num: '',
    vessel: '',
    role: 0,
    marks: '',
    consignees_details_info: '',
    voyage_number: '',
    details_info: '',
    commodities_info: '',
    eta: '',
    etd: '',
    etaDate: '',
    etdDate: '',
    mode: '',
    type: '',
    anotherType: '',
    notify_details_info: '',
    carrier_id: '',
    location_from: '',
    location_to: '',
    payment_terms: '',
    discharge: '',
    booking_num: '',
    terminal: '',
    customer_reference_number: ''
}

export default item