var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"booking-request-template-wrapper"},[_c('v-dialog',{attrs:{"width":"480px","content-class":'booking-request-template',"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Save Booking Request as Template")]),_c('button',{staticClass:"btn-close",attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('v-card-text',{staticClass:"card-content"},[_c('div',{staticClass:"d-flex align-start pa-2 info-text"},[_c('img',{attrs:{"src":require("@/assets/icons/info-blue.svg"),"alt":"info-blue","width":"18px"}}),_c('p',{staticClass:"mb-0 info-desc"},[_vm._v(" Save your filled-out details as a reusable booking template. (Except for orders and containers) ")])]),_c('div',{staticClass:"input-field-wrapper mt-4"},[_c('p',{staticClass:"input-field-label mb-1  d-flex align-center"},[_vm._v(" Template name ")]),_c('v-text-field',{attrs:{"height":40,"placeholder":"Enter Template name","width":"200px","type":"text","dense":"","outlined":"","hide-details":"auto"},model:{value:(_vm.template_name),callback:function ($$v) {_vm.template_name=$$v},expression:"template_name"}})],1),_c('div',{staticClass:"input-field-wrapper  vendor-checkbox"},[_c('v-checkbox',{staticClass:"checkbox-wrapper pt-0 shifl-ui-checkbox",class:_vm.template_to_vendor ? 'front-info' : '',attrs:{"hide-details":""},model:{value:(_vm.template_to_vendor),callback:function ($$v) {_vm.template_to_vendor=$$v},expression:"template_to_vendor"}},[_c('template',{slot:"label"},[_c('span',{staticClass:"d-flex align-center"},[_vm._v(" Associate Template to Vendor ")]),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
									text:
										'By Associating it with a vendor this will be the default Booking template for that vendor',
									position: 'top',
								}),expression:"{\n\t\t\t\t\t\t\t\t\ttext:\n\t\t\t\t\t\t\t\t\t\t'By Associating it with a vendor this will be the default Booking template for that vendor',\n\t\t\t\t\t\t\t\t\tposition: 'top',\n\t\t\t\t\t\t\t\t}"}],staticClass:"ml-2"},[_c('GenericIcon',{attrs:{"color":'#4A4A4A',"iconName":'info-icon'}})],1)])],2),(_vm.template_to_vendor)?_c('div',{staticClass:"child-input-field pt-2 pl-2"},[_c('div',{staticClass:"input-field-wrapper"},[_c('span',{staticClass:"input-field-label"},[_vm._v("Vendors ")]),_c('v-autocomplete',{staticClass:"template-to-vendor",attrs:{"height":40,"color":"#002F44","items":_vm.filteredShipperOptions,"item-text":"name","item-value":"id","dense":"","outlined":"","hide-details":"auto","placeholder":"Select vendor","spellcheck":"false","multiple":"","menu-props":{
									contentClass: 'vendor-name-wrapper shifl-ui-checkbox',
									bottom: true,
									offsetY: true,
								},"small-chips":"","clearable":"","deletable-chips":""},model:{value:(_vm.vendors),callback:function ($$v) {_vm.vendors=$$v},expression:"vendors"}})],1)]):_vm._e()],1),_c('div',{staticClass:"input-field-wrapper"},[_c('span',{staticClass:"input-field-label"},[_vm._v("Description "),_c('small',[_vm._v("(Optional)")])]),_c('v-textarea',{staticClass:"text-fields",attrs:{"outlined":"","placeholder":"Enter description","hide-details":"auto","autocomplete":"off"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)]),_c('v-card-actions',[_c('div',{staticClass:"d-flex footer"},[_c('v-btn',{staticClass:"save-btn btn-blue mr-2",attrs:{"text":"","disabled":_vm.getCreateBookingTemplateLoading ||
								_vm.template_name == null ||
								_vm.template_name == ''},on:{"click":function($event){return _vm.submitBookingTemplateRequest()}}},[_vm._v(" Save as Template ")]),_c('v-btn',{staticClass:"btn-white",attrs:{"text":"","disabled":_vm.getCreateBookingTemplateLoading},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Cancel ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }