<!-- @format -->

<template>
	<div
		class="shipment-supplier-wrapper-v2 shipment-details-card"
		v-if="getDetails.shippers.length > 0"
	>
		<h3>
			Shipper
			<span class="user-role ml-1" v-if="getDetails.userRole == 'shipper'"
				>Your Role
			</span>
		</h3>
		<div v-if="getDetails.userRole !== 'shipper'">
			<div
				class="shippers-consignees-details"
				v-for="(shipper, index) in getDetails.shippers"
				:key="index"
			>
				<v-menu
					content-class="info-details-menu"
					:close-on-content-click="false"
					:nudge-width="320"
					:nudge-bottom="40"
					offset-x
				>
					<template
						v-slot:activator="{ on, attrs }"
						v-if="shipper.supplier_details.display_name || shipper.company_name"
					>
						<div class="company-name">
							<p class="mb-0">
								<span
									v-if="getDetails.shippers.length > 1"
									class="shipper-consignee-index"
									>{{ index > 9 ? index + 1 : `0${index + 1}` }}</span
								>
								<span
									class="company-details"
									v-on="on"
									v-bind="attrs"
									@click="showShipperDetail(shipper.supplier_details)"
								>
									<span class="mb-0 cp-name">
										{{
											shipper.supplier_details.display_name
												? shipper.supplier_details.display_name
												: shipper.company_name
										}}
									</span>
								</span>
							</p>
							<span v-on="on" v-bind="attrs"
								><img src="../../../assets/icons/alert-info-gray.svg"
							/></span>
						</div>
					</template>
					<v-card v-if="!isMobile" class="info-menu-details-wrapper">
						<v-card-text>
							<p class="info-title">
								Shipper’s Details
							</p>
							<div class="general-info">
								<p class="mb-0 info-label">Address</p>
								<p class="mb-0 info-value">
									{{ checkValue(shipper.supplier_details.address) }}
								</p>
							</div>
							<div class="general-info">
								<p class="mb-0 info-label">Contact Name</p>
								<p class="mb-0 info-value">
									{{ checkValue(shipper.supplier_details.display_name) }}
								</p>
							</div>
							<div class="general-info">
								<p class="mb-0 info-label">Contact Tel</p>
								<p class="mb-0 info-value">
									{{ checkValue(shipper.supplier_details.phone) }}
								</p>
							</div>
							<div class="general-info" v-if="shipper.supplier_details.emails">
								<p class="mb-0 info-label">Contact Email</p>
								<p
									class="mb-0 info-value"
									v-for="(email, index) in shipper.supplier_details.emails"
									:key="index"
								>
									<a class="mailto" :href="`mailto:${email}`">{{ email }}</a>
								</p>
							</div>
						</v-card-text>
					</v-card>
				</v-menu>

				<div class="orders-details">
					<div class="order-numbers" v-if="shipper.orders.length > 0">
						<v-chip v-for="(items, index) in shipper.orders" :key="index">
							Po #{{ items }}
						</v-chip>
					</div>
					<div class="order-numbers" else>
						<v-chip v-for="(items, index) in shipper.manualOrders" :key="index">
							Po #{{ items }}
						</v-chip>
					</div>
					<div class="cargo-details-wrapper">
						<v-row class="cargo-info">
							<v-col
								md="2"
								sm="3"
								:cols="isMobile ? 5 : ''"
								class="cargo-dt-info"
								v-if="shipper.cargoDetails.total_cartoon"
							>
								<div class="general-info">
									<p class="mb-0 info-label">Total Cartons</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.cargoDetails.total_cartoon) }}
									</p>
								</div>
							</v-col>
							<v-col
								md="2"
								sm="3"
								:cols="isMobile ? 4 : ''"
								class="cargo-dt-info"
								v-if="shipper.cargoDetails.total_volume"
							>
								<div class="general-info">
									<p class="mb-0 info-label">Volume</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.cargoDetails.total_volume) }}
									</p>
								</div>
							</v-col>
							<v-col
								md="2"
								sm="3"
								:cols="isMobile ? 3 : ''"
								class="cargo-dt-info"
								v-if="shipper.cargoDetails.total_weight"
							>
								<div class="general-info">
									<p class="mb-0 info-label">Weight</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.cargoDetails.total_weight) }}
									</p>
								</div>
							</v-col>
							<v-col
								md="3"
								sm="3"
								:cols="isMobile ? 3 : ''"
								class="cargo-dt-info"
								v-if="getDetails.booking_confirmed == 1 && shipper.ams_num"
							>
								<div class="general-info">
									<p class="mb-0 info-label">AMS</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.ams_num) }}
									</p>
								</div>
							</v-col>
							<v-col
								md="3"
								sm="3"
								:cols="isMobile ? 3 : ''"
								class="cargo-dt-info"
								v-if="getDetails.booking_confirmed == 1 && shipper.hbl_num"
							>
								<div class="general-info">
									<p class="mb-0 info-label">HBL</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.hbl_num) }}
									</p>
								</div>
							</v-col>
							<v-col
								md="2"
								sm="3"
								:cols="isMobile ? 4 : ''"
								class="cargo-dt-info"
								v-if="shipper.cargoDetails.incoterm_id"
							>
								<div class="general-info">
									<p class="mb-0 info-label">Terms</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.cargoDetails.incoterm_id) }}
									</p>
								</div>
							</v-col>
							<v-col
								md="2"
								sm="3"
								:cols="isMobile ? 4 : ''"
								class="cargo-dt-info"
								v-if="getDetails.booking_confirmed == 1 && shipper.bl_status"
							>
								<div class="general-info">
									<p class="mb-0 info-label">BL Status</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.bl_status) }}
									</p>
								</div>
							</v-col>
							<v-col
								md="8"
								sm="3"
								:cols="isMobile ? 8 : ''"
								class="cargo-dt-info"
								v-if="shipper.cargoDetails.product_description"
							>
								<div class="general-info">
									<p class="mb-0 info-label">Product Description</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.cargoDetails.product_description) }}
									</p>
								</div>
							</v-col>
						</v-row>
					</div>

					<v-divider
						v-if="
							shipper.pickupLocation.location !== null ||
								shipper.pickupLocation.name !== null ||
								shipper.pickupLocation.phone !== null ||
								shipper.pickupLocation.email !== null
						"
					/>

					<div
						class="pickup-location"
						v-if="
							shipper.pickupLocation.location !== null ||
								shipper.pickupLocation.name !== null ||
								shipper.pickupLocation.phone !== null ||
								shipper.pickupLocation.email !== null
						"
					>
						<v-row class="cargo-info">
							<v-col md="3" :cols="isMobile ? 12 : ''" class="pick-up-info">
								<div class="general-info">
									<p class="mb-0 info-label">Pickup Address at Origin</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.pickupLocation.location) }}
									</p>
								</div>
							</v-col>
							<v-col md="3" :cols="isMobile ? 7 : ''" class="pick-up-info">
								<div class="general-info">
									<p class="mb-0 info-label">Contact Person Name</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.pickupLocation.name) }}
									</p>
								</div>
							</v-col>
							<v-col md="3" :cols="isMobile ? 5 : ''" class="pick-up-info">
								<div class="general-info">
									<p class="mb-0 info-label">Contact Tel</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.pickupLocation.phone) }}
									</p>
								</div>
							</v-col>
							<v-col md="3" :cols="isMobile ? 12 : ''" class="pick-up-info">
								<div class="general-info">
									<p class="mb-0 info-label">Contact Email</p>
									<p class="mb-0 info-value">
										{{ checkValue(shipper.pickupLocation.email) }}
									</p>
								</div>
							</v-col>
						</v-row>
					</div>
				</div>
			</div>
			<v-dialog
				v-model="shipperDetailDialog"
				width="auto"
				content-class="shipper-consignee-detail-dialog"
			>
				<v-card class="info-menu-details-wrapper rounded-0">
					<v-card-text>
						<p class="info-title">
							Shipper’s Details
						</p>
						<div class="general-info">
							<p class="mb-0 info-label">Address</p>
							<p class="mb-0 info-value">
								{{ checkValue(shipperDetails.address) }}
							</p>
						</div>
						<div class="general-info">
							<p class="mb-0 info-label">Contact Name</p>
							<p class="mb-0 info-value">
								{{ checkValue(shipperDetails.display_name) }}
							</p>
						</div>
						<div class="general-info">
							<p class="mb-0 info-label">Contact Tel</p>
							<p class="mb-0 info-value">
								{{ checkValue(shipperDetails.phone) }}
							</p>
						</div>
						<div class="general-info" v-if="shipperDetails.emails">
							<p class="mb-0 info-label">Contact Email</p>

							<p
								class="mb-0 info-value"
								v-for="(email, index) in shipperDetails.emails"
								:key="index"
							>
								<a class="mailto" :href="`mailto:${email}`">{{ email }}</a>
							</p>
						</div>

						<div class="close-btn mt-2">
							<v-btn class="btn-white" @click="shipperDetailDialog = false">
								close
							</v-btn>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
		<div v-else>
			<div
				class="shippers-consignees-details"
				v-for="(shipper, index) in getDetails.shippers"
				:key="index"
			>
				<h4 class="cm-cp-name">
					{{ shipper.company_name }}
				</h4>

				<div class="shippers-consignee-info-details px-3 pt-3">
					<p class="mb-0 info-label">
						Details:
						<span class="mb-0 info-value">{{
							shipper.shipper_details_info
								? shipper.shipper_details_info
								: "N/A"
						}}</span>
					</p>
				</div>
				<div
					class="pickup-location"
					v-if="
						shipper.pickupLocation.location !== null ||
							shipper.pickupLocation.name !== null ||
							shipper.pickupLocation.phone !== null ||
							shipper.pickupLocation.email !== null
					"
				>
					<v-row class="cargo-info">
						<v-col
							md="3"
							sm="4"
							:cols="isMobile ? 12 : ''"
							class="pick-up-info"
						>
							<div class="general-info">
								<p class="mb-0 info-label">Pickup Address at Origin</p>
								<p class="mb-0 info-value">
									{{ checkValue(shipper.pickupLocation.location) }}
								</p>
							</div>
						</v-col>
						<v-col md="3" sm="4" :cols="isMobile ? 7 : ''" class="pick-up-info">
							<div class="general-info">
								<p class="mb-0 info-label">Contact Person Name</p>
								<p class="mb-0 info-value">
									{{ checkValue(shipper.pickupLocation.name) }}
								</p>
							</div>
						</v-col>
						<v-col md="3" sm="4" :cols="isMobile ? 5 : ''" class="pick-up-info">
							<div class="general-info">
								<p class="mb-0 info-label">Contact Tel</p>
								<p class="mb-0 info-value">
									{{ checkValue(shipper.pickupLocation.phone) }}
								</p>
							</div>
						</v-col>
						<v-col
							md="3"
							sm="4"
							:cols="isMobile ? 12 : ''"
							class="pick-up-info"
						>
							<div class="general-info">
								<p class="mb-0 info-label">Contact Email</p>
								<p class="mb-0 info-value">
									{{ checkValue(shipper.pickupLocation.email) }}
								</p>
							</div>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: ["getDetails", "isMobile"],
	name: "ShipmentShipper",
	data: () => ({
		shipperDetailDialog: false,
		shipperDetails: {},
	}),
	methods: {
		checkValue(value) {
			return value == "" || value == null || value == "null" ? "N/A" : value;
		},
		showShipperDetail(item) {
			if (this.isMobile) {
				this.shipperDetails = item;
				this.shipperDetailDialog = true;
			}
		},
	},
};
</script>
<style lang="scss">
@import "../../../assets/scss/pages_scss/shipment/shipmentDetails/ShipmentShippersConsignees.scss";
</style>
