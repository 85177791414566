const sidebarItems = {
	booking: {
		data: require("./json/sidebarItems.json"),
	},
	shipmentBooking: {
		data: [
			{
				icon: "general",
				label: "General Information",
				width: 20,
				selected: true,
				id: "generalInformationSection",
				reference: "generalInformationSection",
				referenceMobile: "generalInformationSectionMobile",
				height: 20,
			},
			{
				icon: "po-icon",
				label: "Orders & Cargo",
				width: 20,
				selected: false,
				id: "purchaseOrderSection",
				reference: "purchaseOrderSection",
				referenceMobile: "purchaseOrderSectionMobile",
				height: 20,
			},
			{
				icon: "container-icon",
				label: "Containers & Others",
				width: 20,
				selected: false,
				reference: "containerSection",
				referenceMobile: "containerSectionMobile",
				height: 20,
			},
		],
	},
};

export default sidebarItems;
