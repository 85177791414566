var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],attrs:{"max-width":"815px","content-class":"periodic-statement-dialog","retain-focus":false,"scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"periodic-supplier-card"},[_c('v-card-title',[(!_vm.isMobile)?_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]):_vm._e(),(_vm.isMobile)?_c('span',{staticClass:"headline"},[_vm._v(" Periodic Monthly "),_c('br'),_vm._v(" Statements (PMS) Dates "),_c('br'),_vm._v(" For "+_vm._s(_vm.currentYear)+" ")]):_vm._e(),_c('button',{staticClass:"btn-close",attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.getPeriodicStatementsLoading)?_c('div',{staticClass:"preloader d-flex justify-center align-center mt-5 pt-5"},[_c('v-progress-circular',{attrs:{"size":30,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e(),(!_vm.getPeriodicStatementsLoading)?_c('v-data-table',{staticClass:"periodic-table-inner-content elevation-1",attrs:{"headers":_vm.headers,"mobile-breakpoint":"0","items":_vm.periodicStatements,"hide-default-footer":"","fixed-header":"","items-per-page":15},scopedSlots:_vm._u([{key:"item.entry_month",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"month_year",staticStyle:{"color":"#4B586C"}},[_vm._v(" "+_vm._s(_vm.getEntryMonthValue(item))+" ")])]}},{key:"item.month",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"month_year",staticStyle:{"color":"#4B586C"}},[_vm._v(" "+_vm._s(_vm.formatDate(item, item.month, 'month'))+" ")])]}},{key:"item.preliminary_statement",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"full-date"},[_vm._v(" "+_vm._s(_vm.formatDate(item, item.preliminary_statement, 'full_date'))+" ")])]}},{key:"item.final_statement",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"full-date"},[_vm._v(" "+_vm._s(_vm.formatDate(item, item.final_statement, 'full_date'))+" ")])]}}],null,true)}):_vm._e(),(!_vm.getPeriodicStatementsLoading)?_c('div',{staticClass:"notes-section mt-2"},[_c('div',{staticClass:"notes-title-section d-flex align-center"},[_c('p',{staticClass:"mb-0 ml-1"},[_vm._v("Notes")])]),_c('div',{staticClass:"notes-content-section"},[_c('ol',[_c('li',[_vm._v("Preliminary statement date is the 11th workday of the month")]),_c('li',[_vm._v("Final Statement date is the 15th workday of the month")])])])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }