<!-- @format -->

<template>
	<v-container fluid>
		<div class="shipment-details-wrapper">
			<div class="preloader" v-if="detailsLoading">
				<v-progress-circular :size="40" color="#0171a1" indeterminate>
				</v-progress-circular>
			</div>

			<v-row v-if="!detailsLoading">
				<v-col class="first-col" sm="12" md="8">
					<div class="details-breadcrumbs"
						:class="getShipmentDetails.is_tracking_shipment === 1 ? 'tracking' : ''">

						<div class="navigation-back">
							<router-link to="/shipments" class="shipment-link">
								Shipments
							</router-link>

							<span class="right-chevron">
								<img src="../assets/images/right_chevron.svg" alt="" srcset="" width="7px" />
							</span>

							<p class="shipment-ref"
								v-if="
									getShipmentDetails.shifl_ref !== 'undefined' &&
									getShipmentDetails.shifl_ref !== null
								">
								{{ getShipmentDetails.shifl_ref }}
							</p>
						</div>

						<!-- <div class="button-delete-tracking-shipment" v-if="getShipmentDetails.is_tracking_shipment === 1">
							<button class="btn-white btn-delete">
								<img src="../assets/icons/delete-po.svg" width="16px" height="16px" class="mr-1">
								Delete Tracking Shipment
							</button>
						</div> -->
					</div>

					<div id="top-header" v-resize="onResize">
						<div class="reference-status">
							<div :class="`${isMobile ? 'reference-container-mobile' : ''}`">
								<h2 v-if="
										getShipmentDetails.shifl_ref !== 'undefined' &&
										getShipmentDetails.shifl_ref !== null
									">
									Ref #{{ getShipmentDetails.shifl_ref }}

									<!-- <span class="is-shipment-tracking"
										v-if="
											getFilteredShipmentDetails.external_shipment == 1 &&
											!getMilestonesLoading &&
											getMilestonesAttributes !== null &&
											!isMobile
										">
										Tracking
									</span>

									<span style="display: none;" class="is-not-tracking-shipment-details"
										v-if="
											getFilteredShipmentDetails.external_shipment == 1 &&
											!getMilestonesLoading &&
											getMilestonesAttributes == null &&
											!isMobile
										">
										Not Tracking (temp hide)
									</span> -->

									<div class="d-flex t-shipment-status-wrapper" v-if="!isMobile">
										<div style="margin-right: 6px;" :class="getShipmentStatusClass(getShipmentDetails, 'tracking')" 
											v-if="getShipmentDetails.tracking_status !== ''" class="font-medium">
											{{ getShipmentDetails.tracking_status }}
										</div>

										<div style="margin-right: 2px !important;" class="font-medium" 
											v-if="shipment_status==='In transit - hold'">
											{{ "In Transit - Pending Release"}}
										</div>
										<div style="margin-right: 2px !important;" class="font-medium Partial-released" 
											v-else-if="shipment_status==='Partially discharged - released'">
											<span style="color: #4A4A4A;" class="font-medium pr-1">{{ "Partial Discharged - " }}</span>
											<span style="color: #16B442;" class="font-medium">{{ "Released" }}</span>
										</div>
										<div style="margin-right: 2px; !important" class="font-medium Partial-hold" 
											v-else-if="shipment_status==='Partially discharged - hold'">
											<span style="color: #4A4A4A;" class="font-medium pr-1">{{ "Partial Discharged - " }}</span>
											<span style="color: #F93131;" class="font-medium">{{ "Hold" }}</span>
										</div>
										<div style="margin-right: 2px !important;" class="font-medium" 
											v-else :class="getShipmentStatusClass(getShipmentDetails,'default')">
											{{ ucFirst(shipment_status.toLowerCase()) }}
										</div>
									</div>
								</h2>

								<div v-if="isMobile" class="d-flex flex-row" style="position: relative;">
									<div v-if="getFilteredShipmentDetails.external_shipment == 1" :class="`edit-shipment-btn-wrapper ${(isMobile) ? 'mobile' : ''}`">
										<button v-if="!getShipmentDetailsLoading" @click.stop="showEditShipmentDialog" class="btn-blue edit-shipment-btn d-flex">
											<kenetic-icon iconName="edit" />	
											<span>Edit</span>
										</button>
									</div>
									<v-menu
										offset-y
										v-if="getShipmentDetails.is_tracking_shipment == 1"
										bottom
										left
										content-class="delete-shipment-options-container">
										<template v-slot:activator="{ on, attrs }">
											<button
												v-bind="attrs"
												v-on="on"
												class="three-dots-container">
												<custom-icon
													iconName="three-dots"
													color="#0171A1"
													width="11"
													height="3"/>
											</button>
										</template>
										<v-list class="delete-shipment-options-container-list">
											<v-list-item @click.stop="showDeleteShipmentDialog">
												<v-list-item-title>
													Delete Shipment
												</v-list-item-title>
											</v-list-item>
										</v-list>

									</v-menu>
								</div>
							</div>

							<div style="position: relative;">
								<button
									class="btn btn-status-white shipment-status"
									v-if="shipment_status !== null && 1==2"
								>
									{{ shipment_status }}
								</button>

								<!-- <div v-if="!isMobile" :class="`edit-shipment-btn-wrapper ${(isMobile) ? 'mobile' : ''}`">
									<v-btn v-if="!getShipmentDetailsLoading && getFilteredShipmentDetails.is_tracking_shipment == 1" @click.stop="showEditShipmentDialog" class="btn-blue edit-shipment-btn d-flex">
										<kenetic-icon iconName="edit" />	
										<span>Edit Shipment</span>
									</v-btn>
								</div> -->
								<div v-if="!isMobile" :class="`edit-shipment-btn-wrapper ${(isMobile) ? 'mobile' : ''}`">
									<v-btn v-if="!getShipmentDetailsLoading && getFilteredShipmentDetails.is_draft == 1" @click.stop="EditBookingShipmentDialog" class="btn-blue edit-shipment-btn d-flex">
										<kenetic-icon iconName="edit" />	
										<span>Edit Draft Shipment</span>
									</v-btn>
								</div>
								<div v-if="getShipmentDetails.cancelled == 0">
									<v-menu
										offset-y
										bottom
										left
										content-class="pending-dropdown-container"
									>
										<template v-slot:activator="{ on, attrs }">
											<button v-bind="attrs" v-on="on" class="three-dots-container">
												<custom-icon
													iconName="three-dots"
													color="#0171A1"
													width="11"
													height="3"
												/>
											</button>
										</template>
										<v-list>
											<v-list-item>
												<template v-if="getShipmentDetails.booking_confirmed == 1 && getShipmentDetails.mark_as_completed == 0">
													<v-list-item-title class="k-fw-sm k-font-inter-regular k-red" style="cursor: pointer;" @click="cancelBooking(1)">
														Cancel Request
													</v-list-item-title>
												</template>
												<template v-else>
													<v-list-item-title class="k-fw-sm k-font-inter-regular k-red" style="cursor: pointer;" @click="cancelBooking(0)">
														Cancel Request
													</v-list-item-title>
												</template>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>
								<!-- <span
									class="is-shipment-tracking is-shipment-tracking-mobile"
									v-if="
										getFilteredShipmentDetails.external_shipment == 1 &&
											!getMilestonesLoading &&
											getMilestonesAttributes !== null &&
											isMobile
									"
								>
									Tracking
								</span>
								<span
									style="display: none;"
									v-if="
										getFilteredShipmentDetails.external_shipment == 1 &&
											!getMilestonesLoading &&
											getMilestonesAttributes === null &&
											isMobile
									"
									class="is-not-tracking-shipment-details is-not-tracking-shipment-details-mobile"
								>
									Not Tracking (temp hide)
								</span> -->
							
								<!-- tracking_status -->
								<div class="d-flex t-shipment-status-wrapper mobile-status-multiple" v-if="isMobile">
									<div style="margin-right: 6px;" :class="getShipmentStatusClass(getShipmentDetails, 'tracking')" 
										v-if="getShipmentDetails.tracking_status !== ''" class="font-medium">
										{{ getShipmentDetails.tracking_status }}
									</div>

									<div style="margin-right: 2px !important;" class="font-medium" 
										v-if="shipment_status==='In transit - hold'">
										{{ "In Transit - Pending Release"}}
									</div>
									<div style="margin-right: 2px !important;" class="font-medium Partial-released" 
										v-else-if="shipment_status==='Partially discharged - released'">
										<span style="color: #4A4A4A;" class="font-medium pr-1">{{ "Partial Discharged - " }}</span>
										<span style="color: #16B442;" class="font-medium">{{ "Released" }}</span>
									</div>
									<div style="margin-right: 2px; !important" class="font-medium Partial-hold" 
										v-else-if="shipment_status==='Partially discharged - hold'">
										<span style="color: #4A4A4A;" class="font-medium pr-1">{{ "Partial Discharged - " }}</span>
										<span style="color: #F93131;" class="font-medium">{{ "Hold" }}</span>
									</div>
									<div style="margin-right: 2px !important;" class="font-medium" 
										v-else :class="getShipmentStatusClass(getShipmentDetails,'default')">
										{{ ucFirst(shipment_status.toLowerCase()) }}
									</div>
								</div>

								<!-- <v-menu
									bottom
									left
									nudge-left
									nudge-bottom
									offset-y
									v-if="
										getShipmentDetails.is_tracking_shipment == 1 && !isMobile
									"
									content-class="delete-shipment-options-container"
								>
									<template v-slot:activator="{ on, attrs }">
										<button
											v-bind="attrs"
											v-on="on"
											class="three-dots-container"
										>
											<custom-icon
												iconName="three-dots"
												color="#0171A1"
												width="11"
												height="3"
											/>
										</button>
									</template>
									<v-list class="delete-shipment-options-container-list">
										<v-list-item @click.stop="showDeleteShipmentDialog">
											<v-list-item-title class="delete-shipment-item-title">
												Delete Shipment
											</v-list-item-title>
										</v-list-item>
										<v-list-item v-if="1==2" @click.stop="showAddShipmentDialog">
											<v-list-item-title>
												Create Shipment
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu> -->
							</div>
						</div>

						<div :class="`d-flex shipment-tracking-label ${(isMobile) ? 'mobile' : ''}`">
							<div :class="`${(isMobile) ? 'mobile mb-2' : ''}`">
								{{ getShipmentDetails.customer !== 'undefined' && getShipmentDetails.customer !== null && getShipmentDetails.customer.company_name !== 'undefined' ? getShipmentDetails.customer.company_name : ''
								}}
							</div>
							<div class="updated-at-content">
								<div class="mr-2 updated-title">Updated At</div>
								<div>{{ formatDate(getShipmentDetails.updated_at) }}</div>
							</div>
						</div>

						<div v-if="1 == 2"
							:class="
								`${isMobile ? 'is-not-tracking-shipment-details-ttc-mobile' : ''} is-not-tracking-shipment-details-ttc`">
							<div>
								<div> Type: </div>
								<div> N/A </div>
							</div>
							<div>
								<div> Terminal: </div>
								<div> N/A </div>
							</div>
							<div>
								<div> Carrier: </div>
								<div v-if="getShipmentDetails.is_tracking_shipment === 1">
									{{ getMilestonesAttributes.shipping_line_short_name !==
											"undefined" &&
										getMilestonesAttributes.shipping_line_short_name !== ""
											? getMilestonesAttributes.shipping_line_short_name
											: "N/A"
									}}
								</div>
								<div v-if="getShipmentDetails.is_tracking_shipment === 0">
									{{ getShipmentDetails.carrier !== "undefined" &&
										getShipmentDetails.carrier !== "" &&
										getShipmentDetails.carrier !== null
											? typeof getShipmentDetails.carrier === "String"
												? getShipmentDetails.carrier
												: getShipmentDetails.carrier.name
											: "N/A"
									}}
								</div>
							</div>
						</div>

						<div class="place-wrapper"
							style="height:30px"
							v-if="getShipmentDetails.is_tracking_shipment === 0 && getShipmentDetails.shipment_status !='Pending Approval'"
						>
							<div class="place-content">
								<p class="heading"
									style="margin-right: 25px"
									v-if="shipment_type !== '' && shipment_type !== null">
									<span class="info-title">Type: </span>

									<img
										v-if="shipment_type == 'LCL'"
										src="../assets/images/small-container.svg" />

									<img
										v-if="shipment_type == 'Air' || shipment_type == 'AIR'"
										src="../assets/images/airplane-shipment.svg" />

									<img
										v-if="shipment_type == 'FCL'"
										src="../assets/images/big-container.svg" />

									{{ shipment_type }}
								</p>
								<div
									class="carrier-wrapper"
									v-if="
										getShipmentDetails.carrier !== 'undefined' &&
										getShipmentDetails.carrier !== '' &&
										getShipmentDetails.carrier !== null &&
										1 == 2">
									<p class="heading">
										<span class="info-title">Carrier: </span>
										{{ typeof getShipmentDetails.carrier === "String"
												? getShipmentDetails.carrier
												: getShipmentDetails.carrier.name
										}}
									</p>
								</div>
							</div>
							<p
								class="heading"
								v-if="!isMobile && location_to_with_details !== null"
							>
								<span v-if="getShipmentDetails.shipment_status !='Pending Approval'" style="color: #4A4A4A !important;">
									{{ location_to_with_details }}
								</span>
							</p>
						</div>

						<div class="place-wrapper" style="height:30px"
							v-if="
								getShipmentDetails.is_tracking_shipment === 1 &&
								getMilestonesAttributes !== null
							">
							<div class="place-content">
								<p class="heading"
									style="margin-right: 25px"
									v-if="shipment_type !== '' && shipment_type !== null">
									<span class="info-title">Type: </span>

									<img
										v-if="shipment_type == 'LCL'"
										src="../assets/images/small-container.svg" />

									<img
										v-if="shipment_type == 'Air' || shipment_type == 'AIR'"
										src="../assets/images/airplane-shipment.svg" />

									<img
										v-if="shipment_type == 'FCL'"
										src="../assets/images/big-container.svg" />

									{{ shipment_type }}
								</p>

								<div class="carrier-wrapper"
									v-if="
										getMilestonesAttributes.shipping_line_short_name !==
										'undefined' &&
										getMilestonesAttributes.shipping_line_short_name !== ''
									">
									<p class="heading">
										<span class="info-title">Carrier: </span>
										{{ getMilestonesAttributes.shipping_line_short_name }}
									</p>
								</div>
							</div>
							<p
								class="heading"
								v-if="
									!isMobile &&
										getMilestonesAttributes.port_of_discharge_name !== null &&
										(getMilestonesAttributes.pod_eta_at !== null || getMilestonesAttributes.pod_ata_at!==null )
								"
							>
								<span style="color: #4A4A4A !important;">
									{{
										getTrackingDetailsLocationWithDetails(
											getMilestonesAttributes
										)
									}}
								</span>
							</p>
						</div>

						<div class="mobile-status"
							v-if="
								isMobile &&
								getMilestonesAttributes !== null &&
								getMilestonesAttributes.port_of_discharge_name !== null &&
								(getMilestonesAttributes.pod_eta_at !== null || getMilestonesAttributes.pod_ata_at !== null )
							">
							<p class="heading">
								<span style="color: #4A4A4A !important;">
									{{ getTrackingDetailsLocationWithDetails(getMilestonesAttributes)}}
								</span>
							</p>
						</div>
					</div>

					<div class="to-dos"
						v-if="
							!isMobile &&
							(oblRequired.length > 0 || commercialDocuments.length > 0) &&
							(oblRequired.includes(false) ||
							commercialDocuments.includes(false))
						">
						<v-list two-line>
							<h3 class="to-dos-title">To-Dos</h3>
							<v-list-item v-show="oblRequired.includes(false)">
								<v-list-item-avatar class="my-0">
									<v-img src="../assets/images/red-document.svg"></v-img>
								</v-list-item-avatar>
								<v-list-item-content class="pa-1 mt-1">
									<p class="mb-1">OBL Required</p>
									<ul>
										<li class="supplier-list"
											v-for="(supplier,
											i) in getShipmentDetails.shipment_suppliers"
											:key="i"
											v-show="supplier.obl_filled === false">
											<!-- {{ supplier.name }} -->
											{{ getSupplierDisplayName(supplier.supplier_id) }}
										</li>
									</ul>
								</v-list-item-content>
							</v-list-item>

							<v-list-item v-show="commercialDocuments.includes(false)" class="pr-0">
								<v-list-item-avatar class="my-0">
									<v-img src="../assets/images/red-document.svg"></v-img>
								</v-list-item-avatar>

								<v-list-item-content class="pa-1 mt-2">
									<h5 class="mb-1">Commercial Documents Required
										<span class="status-requested" v-if="isCurrentSelectedCustomer && checkDocumentRequested(getAllRequestDocuments)">
											Requested
										</span>
									</h5>
									<ul>
										<li
											class="supplier-list"
											v-for="(supplier,
											i) in getShipmentDetails.shipment_suppliers"
											:key="i"
											v-show="supplier.commercial_documents_filled === false">
											<!-- {{ supplier.name }} -->
											{{ getSupplierDisplayName(supplier.supplier_id) }}
											{{ supplier.hbl_num ? `(HBL# ${supplier.hbl_num})` : "" }}
										</li>
									</ul>
								</v-list-item-content>
								<v-btn
									class="btn btn-blue mr-2"
									width="80px"
									@click="openUploadDialog">
									Upload
								</v-btn>
								<v-btn
									class="btn btn-white"
									width="88px"
									@click="openRequestDialog"
									v-if="isCurrentSelectedCustomer && !checkDocumentRequested(getAllRequestDocuments)">
									Request
								</v-btn>
							</v-list-item>

							<!-- <v-list-item>
								<v-list-item-avatar class="my-0">
									<v-img src="../assets/images/red-freight.svg"></v-img>
								</v-list-item-avatar>

								<v-list-item-content class="pa-1">
									<h5 class="mb-1">Payment Required</h5>
									<v-list-item-subtitle></v-list-item-subtitle>
								</v-list-item-content>
								<v-btn class="btn btn-blue" width="150px">
									Make Payment
								</v-btn>
							</v-list-item> -->
						</v-list>
					</div>

					<div
						class="to-dos mt-1"
						v-if="
							isMobile &&
								(oblRequired.length > 0 || commercialDocuments.length > 0) &&
								(oblRequired.includes(false) ||
									commercialDocuments.includes(false))
						"
					>
						<v-list two-line>
							<h3 class="to-dos-title">To-Dos</h3>
							<v-list-item v-show="oblRequired.includes(false)">
								<v-list-item-avatar class="my-0">
									<v-img src="../assets/images/red-document.svg"></v-img>
								</v-list-item-avatar>

								<v-list-item-content class="pa-1">
									<p class="mb-1 mt-1">OBL Required</p>
									<ul>
										<li
											class="supplier-list"
											v-for="(supplier,
											i) in getShipmentDetails.shipment_suppliers"
											:key="i"
											v-show="supplier.obl_filled === false"
										>
											{{ supplier.name }}
										</li>
									</ul>
								</v-list-item-content>
							</v-list-item>

							<v-list-item v-show="commercialDocuments.includes(false)">
								<v-list-item-avatar class="my-0">
									<v-img src="../assets/images/red-document.svg"></v-img>
								</v-list-item-avatar>

								<v-list-item-content class="pa-1">
									<h5 class="mb-1 mt-1">Commercial Documents Required</h5>
									<ul>
										<li
											class="supplier-list"
											v-for="(supplier,
											i) in getShipmentDetails.shipment_suppliers"
											:key="i"
											v-show="supplier.commercial_documents_filled === false"
										>
											{{ supplier.name }}
											{{ supplier.hbl_num ? `(HBL# ${supplier.hbl_num})` : "" }}
										</li>
									</ul>
								</v-list-item-content>
							</v-list-item>
							<v-btn
								class="btn btn-blue list-btn mt-1"
								width="150px"
								@click="openUploadDialog"
							>
								Upload Documents
							</v-btn>

							<!-- <v-list-item>
								<v-list-item-avatar class="my-0">
									<v-img src="../assets/images/red-freight.svg"></v-img>
								</v-list-item-avatar>

								<v-list-item-content class="pa-1">
									<h5 class="mb-1">Payment Required</h5>
									<v-list-item-subtitle
										class="list-subtitle"
									></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
							<v-btn class="btn btn-blue list-btn" width="150px"
								>Make Payment</v-btn -->
						</v-list>
					</div>

					<div
						class="documents-wrapper"
						style="background:white; margin: 16px 0 0; border: 1px solid #F3F4F7; position:relative; border-radius: 4px;"
					>
						<v-tabs
							mobile-breakpoint="500"
							:class="`customTab ${isMobile ? 'customTab-mobile' : ''}`"
							height="50px"
							v-model="currentTab"
						>
							<v-tab
								v-for="(n, i) in tabs"
								:key="n"
								v-on:click="setMainTab(i)"
								:class="n"
							>
								{{ n }}
							</v-tab>
						</v-tabs>

						<UploadDocumentsDialog
							:dialogData.sync="dialogUploadDocs"
							:dialogMessage.sync="dialogMessage"
							@fetchDocuments="fetchShipmentDocumentsAfter"
							@close="closeUploadDialog"
							:shipment_id="shipment_id"
							:isCurrentSelectedCustomer="isCurrentSelectedCustomer"
            				:selectedDocumentRequest="selectedDocumentRequest"
							:getDetails="getShipmentDetails"
							:editedUploadIndex="editedUploadIndex"
							:isFrom="'to-dos'"
							@fetchRequestDocuments="fetchRequestDocuments"
						/>

						<div v-if="currentTab == 'Shipment Info' || currentTab == 0">
							<ShipmentInfo
								:getDetails="getShipmentDetails"
								:isMobile="isMobile"
								:shipment_status="shipment_status"
								:getFilteredShipmentDetails="getFilteredShipmentDetails"
								:reloadAllShipments="reloadAllShipments"
							/>
						</div>
						<div v-if="currentTab == 'Products' || currentTab == 1">
							<ShipmentProducts :id="getShipmentDetails.id" />
						</div>
						<div v-if="currentTab == 'Documents' || currentTab == 2">
							<ShipmentDocuments
								:shipmentsLoading.sync="shipmentDocumentsLoading"
								@handleDocumentsChange="handleDocumentsChange"
								:getDetails="getShipmentDetails"
								:id="shipment_id"
								:isCurrentSelectedCustomer="isCurrentSelectedCustomer"
							/>
						</div>
						<div v-if="currentTab == 'Bills' || currentTab == 4">
							<ShipmentBills :isMobile="isMobile" />
						</div>
						<div v-if="currentTab == 'Notes' || currentTab == 5">
							<ShipmentNotes :id="shipment_id" />
						</div>
						<div v-if="isMobile && (currentTab == 'Milestones' || currentTab == 3)">
							<Map
								v-show="this.getShipmentDetails.ais_link !== ''"
								:getDetails="getShipmentDetails"
							/>

							<Milestones
								:loading="getMilestonesNewLoading"
								:getDetails="getShipmentDetails"
								:status="shipment_status"
								:departedLocation="getShipmentDetails.location_from_name"
								:arrivedLocation="getShipmentDetails.location_to_name"
							/>
						</div>
					</div>
				</v-col>

				<v-col class="second-col" sm="12" md="4" v-if="!isMobile">
					<Map
						v-show="this.getShipmentDetails.ais_link !== ''"
						:getDetails="getShipmentDetails"
					/>

					<v-card class="pa-5" outlined tile :class="this.getShipmentDetails.ais_link !== '' ? 'has-ais' : ''">
						<div class="milestone-content-wrapper">
							<h2 class="milestone-name">Milestones</h2>

							<Milestones
								:loading="getMilestonesNewLoading"
								:getDetails="getShipmentDetails"
								:status="shipment_status"
								:departedLocation="getShipmentDetails.location_from_name"
								:arrivedLocation="getShipmentDetails.location_to_name"
							/>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</div>

		<create-shipment-dialog
            reference="formEditShipment"
            v-if="editShipmentDialogView"
            className="edit-shipment-dialog-wrapper"
            :show="editShipmentDialogView"
            :isMobile="isMobile"
            :item="getShipmentDetails"
            :rules="editShipmentRules"
            :windowWidth="windowWidth"
            :addShipmentDialogModalView.sync="addShipmentDialogModalView"
            @close="handleCloseEditShipmentDialog">

            <template v-slot:title>
                <div id="headline-custom-wrapper">
                    <span class="headline-custom">{{ "Edit Shipment" }}</span>
                    <!-- <span class="headline-custom-track">Track Shipment</span> -->
                </div>
            </template>

            <template v-slot:sidebar="{ mainContent }">
                <div class="d-flex flex-column first-column sidebar-item-main-wrapper">
                    <div class="d-flex align-center sidebar-items-wrapper" v-bind:key="`si-${key}`" v-for="(sidebarItem, key) in mainContent.sidebarItems">
                        <a @click.stop="mainContent.selectPage(sidebarItem)" :class="`d-flex sidebar-item align-center ${sidebarItem.selected ? 'selected' : ''}`">
                            <kenetic-icon :paddingTop="`${sidebarItem.icon==='general' ? 6: 0}`" :color="`${(sidebarItem.selected) ? '#0171A1' : '#4A4A4A'}`" v-if="sidebarItem.icon!==''" :iconName="sidebarItem.icon" :width="sidebarItem.width" :height="sidebarItem.height" />
                            <div style="margin-left: 13px;" class="sidebar-label">
                                {{ sidebarItem.label }}
                            </div>
                        </a>
                    </div>
                </div>
            </template>

            <template v-slot:actions="{ footer }">
                <div class="d-flex footer">
                    <v-btn :disabled="getEditShipmentLoading" @click.stop="footer.updateShipment" class="save-btn btn-blue" text>
                        <span >{{ "Save" }}</span>
                    </v-btn>
                    <v-btn class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue" text @click="footer.close">
                        <span>{{ "Cancel" }}</span>
                    </v-btn>
                </div>
            </template>
        </create-shipment-dialog>

		<delete-shipment-dialog
			:show="deleteShipmentDialogShow"
			title="Shipment"
			:deleting="getTrackingShipmentDeleteLoading"
			@close="closeDeleteShipmentDialog"
			@deleteShipment="deleteShipment"
			:ref_num="getShipmentDetails.shifl_ref"
			:id="getShipmentDetails.id">
			<template v-slot:message="{ ref_num }">
				<p>
					Do you want to delete the shipment ‘Ref#{{ ref_num }}’? Deleted
					shipment cannot be retrieved later.
				</p>
			</template>
		</delete-shipment-dialog>

		<booking-shipment-dialog
            :reference="`${getEditingDraftShipment ? 'formEditShipment' : 'formBookingShipment'}`"
            v-if="bookingShipmentDialogView"
            className="edit-shipment-dialog-wrapper"
            :show.sync="bookingShipmentDialogView"
            :bookingShipmentDialogView.sync="bookingShipmentDialogView"
            :isMobile="isMobile"
            :submitRequestModalView.sync="submitRequestModalView"
            :bookingRequestSubmittedModalView.sync="bookingRequestSubmittedModalView"
            :item="editedItem"
            :rules="editShipmentRules"
            :windowWidth="windowWidth"
            :isEdit.sync="isEdit"
            :addShipmentDialogModalView.sync="addShipmentDialogModalView"
            @close="handleCloseBookingShipmentDialog"
			@reloadShipments="callShipmentsAPI"
        >
            <template v-slot:title>
                <div id="headline-custom-wrapper">
                    <span v-if="1==2" class="headline-custom">{{ "Add Shipment" }}</span>
                    <span class="headline-custom-track booking">{{ isEdit ? "Edit Shipment" : "Create Booking Request" }}</span>
                </div>
            </template>
            <template v-slot:sidebar="{ mainContent }">
                <div style="padding-top: 16px !important;" class="d-flex flex-column first-column sidebar-item-main-wrapper">
                    <div :class="`d-flex align-center sidebar-items-wrapper ${sidebarItem.selected ? 'selected' : ''}`" v-bind:key="`si-${key}`" v-for="(sidebarItem, key) in mainContent.sidebarItems">
                        <a @click.stop="mainContent.selectPage(sidebarItem)" :class="`d-flex sidebar-item align-center ${sidebarItem.selected ? 'selected' : ''}`">
                            <kenetic-icon :paddingTop="`${sidebarItem.icon==='general' ? 6: 0}`" :color="`${(sidebarItem.selected) ? '#0171A1' : '#4A4A4A'}`" v-if="sidebarItem.icon!==''" :iconName="sidebarItem.icon" :width="sidebarItem.width" :height="sidebarItem.height" />
                            <div style="margin-left: 13px;" class="sidebar-label">
                                {{
                                    sidebarItem.label
                                }}
                            </div>
                        </a>
                    </div>
                </div>
            </template>
            <template v-slot:actions="{ footer }">
                <div class="d-flex footer">
                    <v-btn v-if="!getEditingShipment" style="margin-right: 8px;" :disabled="footer.createLoading" @click.stop="footer.createShipment" class="save-btn btn-blue" text >
                        <span >{{ "Submit Request" }}</span>
                    </v-btn>
                    <v-btn v-if="getEditingShipment" style="margin-right: 8px;" :disabled="footer.createLoading" @click.stop="footer.updateShipment" class="save-btn btn-blue" text >
                        <span >{{ "Save" }}</span>
                    </v-btn>
                    <v-btn v-if="!getEditingShipment" :disabled="footer.submitLoading" style="margin-right: 8px;" class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue" text @click="footer.saveAsDraft">
                        <span style="color: #0171A1;">{{ "Save as Draft" }}</span>
                    </v-btn>
                    <v-btn class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue" text @click="footer.close">
                        <span>{{ "Cancel" }}</span>
                    </v-btn>
                </div>
            </template>
        </booking-shipment-dialog>

		<ConfirmDialog :dialogData.sync="cancelBookingRequestDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Cancel Booking Request</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Are you sure you want to cancel the booking request?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text @click="submitCancelBooking()" :disabled="getCancelShipmentLoading">
					<span v-if="getCancelShipmentLoading">Cancel Request Processing...</span>
					<span v-else>Cancel Request</span>
				</v-btn>
				<v-btn class="btn-white" text @click="cancelBookingRequestDialog = false">
					<span>Close</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="openCancelRequestForm">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Request Cancellation</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Do you want to request cancellation for this Shipment?
				</p>
                <div class="form-label mt-3">
                    <span class="cancel-reason-label">REASON FOR CANCELLATION</span>
                </div>
                <v-textarea
                    :rows="4"
                    outlined
                    placeholder="Type reason for cancellation"
                    hide-details="auto"
                    class="text-fields"
                    required
                    v-model="cancel_reason"
                >
                </v-textarea>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text @click="submitCancelBooking()" :disabled="getCancelShipmentLoading">
					<span v-if="getCancelShipmentLoading">Cancel Request Processing...</span>
					<span v-else>Cancel Request</span>
				</v-btn>
				<v-btn class="btn-white" text @click="openCancelRequestForm = false" :disabled="getCancelShipmentLoading">
					Close
				</v-btn>
			</template>
		</ConfirmDialog>

		<RequestDocumentDialog
			:dialogData.sync="dialogRequest"
			@close="closeRequestDocumentDialog"
            :getShipmentDetails="getShipmentDetails"
			:isFrom="'to-dos'"
			@fetchRequestDocuments="fetchRequestDocuments"
		/>

		<ConfirmDialog :dialogData.sync="cancelRequestDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Cancel Document Request </h2>
			</template>

			<template v-slot:dialog_content>
				<p> 
					Are you sure you want to cancel all document requests?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="confirmCancelRequest" :disabled="getRequestsLoading">
					<span v-if="!getRequestsLoading">Yes, Cancel</span>
                    <span v-if="getRequestsLoading">Canceling...</span>
				</v-btn>

				<v-btn class="btn-white" @click="closeRequest" :disabled="getRequestsLoading">
					Close
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="openSwitchAccountDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Switch Account </h2>
			</template>

			<template v-slot:dialog_content>
				<div class="switch-account-modal-wrapper">
					<p> 
						This document request is for a different user account. 
						You need to switch to the relevant account below.
					</p>

					<div class="switch-account-modal-details d-flex justify-start align-center">
						<div class="switch-company-logo">
							<img src="@/assets/icons/import-name-logo.svg" alt="" width="20px" height="20px">
						</div>
						<div class="switch-company-details">
							<div class="switch-company-content" v-if="accountVendorDetails !== null">
								<div class="switch-company-name">
									{{ accountVendorDetails.company_name || "" }}
								</div>
								<div class="switch-company-address">
									{{ accountVendorDetails.address || "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="confirmSwitch" :disabled="isSwitchingAccountLoading">
					{{ isSwitchingAccountLoading ? "Switching..." : "Switch Account" }}
				</v-btn>

				<v-btn class="btn-white" @click="cancelSwitch" :disabled="isSwitchingAccountLoading">
					Cancel
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="openLogoutAccountDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Request is associated with different account </h2>
			</template>

			<template v-slot:dialog_content>
				<div class="switch-account-modal-wrapper">
					<p> 
						This document request is associated with
						<span class="font-semi-bold">
							{{ supplierCompanyName ? `${supplierCompanyName}.` : "a different account." }}
						</span>
						If you are associated with the company, please logout from here and login with the associated account.
					</p>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="confirmLogout" :disabled="isLogoutLoading">
					{{ isLogoutLoading ? "Confirming..." : "Logout" }}
				</v-btn>

				<v-btn class="btn-white" @click="cancelLogout" :disabled="isLogoutLoading">
					Cancel
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="requestCancelledConfirmationDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/alert.svg" alt="alert" 
						v-if="isRequestFor !== '' && isRequestFor === 'cancelled'">
					<img src="@/assets/icons/info-blue.svg" alt="alert" 
						v-if="isRequestFor !== '' && isRequestFor === 'fulfilled'">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Request has been {{ isRequestFor !== "" ? isRequestFor : "" }} </h2>
			</template>

			<template v-slot:dialog_content>
				<p> This request has already been {{ isRequestFor !== "" ? isRequestFor : "" }}. </p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="requestCancelInfoClose">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="notAuthorizedToViewDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Customer not authorized </h2>
			</template>

			<template v-slot:dialog_content>
				<p> <span class="font-semi-bold">{{ parseUserDetails.default_customer.company_name || '' }}</span> 
					is not authorized to view this shipment. If you are associated with the company, 
					please switch to the associated account of this shipment.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="closeNotAuthorized">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="vendorNotConnectedToShiflDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Vendor not connected to Shifl </h2>
			</template>

			<template v-slot:dialog_content>
				<p> 
					Vendor for this Shipment is not connected to Shifl. 
					Please logout from here and we will redirect you to the Uploading Page.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="confirmLogout" :disabled="isLogoutLoading">
					{{ isLogoutLoading ? "Confirming..." : "Logout" }}
				</v-btn>
			</template>
		</ConfirmDialog>
	</v-container>
</template>

<script>
import Milestones from "@/components/ShipmentComponents/Milestones/Milestones.vue";
import ShipmentInfo from "@/components/ShipmentInfo.vue";
import ShipmentDocuments from "@/components/ShipmentDocuments.vue";
import ShipmentBills from "@/components/ShipmentBill.vue";
import UploadDocumentsDialog from "../components/ShipmentComponents/Documents/UploadDocumentsDialog.vue";
import RequestDocumentDialog from "../components/ShipmentComponents/Documents/RequestDocumentDialog.vue";
import Map from "@/components/Map.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import CustomIcon from "@/components/Icons/CustomIcon";
import DeleteShipmentDialog from "@/components/Dialog/DeleteShipmentDialog";
import ShipmentProducts from "@/components/ShipmentProducts.vue";
import ShipmentNotes from "@/components/ShipmentNotes.vue";
import globalMethods from "../utils/globalMethods";
import CreateShipmentDialog from "@/components/Dialog/FormShipmentDialog/CreateShipmentDialog";
import KeneticIcon from "@/components/Icons/KeneticIcon";
import BookingShipmentDialog from '@/components/Dialog/FormShipmentDialog/BookingShipmentDialog'
import ConfirmDialog from "@/components/Dialog/GlobalDialog/ConfirmDialog.vue";

const baseURL = process.env.VUE_APP_BASE_URL;
import axios from "axios";

export default {
	components: {
		CreateShipmentDialog,
		KeneticIcon,
		Milestones,
		ShipmentInfo,
		ShipmentDocuments,
		Map,
		CustomIcon,
		DeleteShipmentDialog,
		UploadDocumentsDialog,
		ShipmentNotes,
		ShipmentProducts,
		ShipmentBills,
		BookingShipmentDialog,
		ConfirmDialog,
		RequestDocumentDialog
	},
	data: () => ({
		windowWidth: 0,
		tabs: [
			"Shipment Info",
			"Products",
			"Documents",
			"Milestones",
			"Bills",
			"Notes",
		],
		tabs2: ["Shipments"],
		currentTab: "Shipment Info",
		currentTabMileStone: "Shipments",
		isMobile: false,
		addShipmentDialogModalView: false,
		editShipmentRules: {
			mbl_num: [(v) => !!v || "MBL number is required."],
			etd: [(v) => !!v || "ETD is required."],
			eta: [(v) => !!v || "ETA is required."],
			contact_number: [(v) => !!v || "Contact Number is required."],
		},
		deleteShipmentDialogShow: false,
		shipment_id: null,
		deletingShipment: false,
		title: "",
		detailsLoading: true,
		location_to_with_details: null,
		shipment_status: null,
		term_id: null,
		createShipmentDialogView: false,
		editShipmentDialogView: false,
		isEditShipment: false,
		deleteShipmentTooltipShow: false,
		shipmentDocumentsLoading: true,
		items: [
			{
				text: "Shipments",
				disabled: false,
				href: "/shipments",
			},
			{
				text: "#",
				disabled: true,
				href: "breadcrumbs_link_1",
			},
		],
		fetchMilestoneLoading: true,
		mbl_num: "",
		shipment_mode: "",
		shipment_type: "",
		isComponentLoading: false,
		dialogUploadDocs: false,
		dialogMessage: false,
		//
		trackingStatusMap: [
			{
				class: "manual-tracking",
				value: "Manual Tracking",
			},
			{
				class: "auto-tracking",
				value: "Auto Tracking",
			},
			{
				class: "auto-tracked",
				value: "Auto Tracked",
			},
			{
				class: "manually-tracked",
				value: "Manually Tracked",
			},
			{
				class: "not-tracking",
				value: "Not Tracking",
			},
			{
				class: "past-last-free-day",
				value: "Past Last Free Day",
			},
			{
				class: "discharged-released",
				value: "Discharged - released",
			},
			{
				class: "in-transit-hold",
				value: "In transit - hold",
			},
			{
				class: "in-transit-released",
				value: "In transit - released",
			},
			{
				class: "discharged-hold",
				value: "Discharged - Hold",
			},
			{
				class: "partially-discharged",
				value: "Partially discharged",
			},
		],
		bookingShipmentDialogView: false,
		submitRequestModalView: false,
		bookingRequestSubmittedModalView: false,
		isEdit: false,
		editedItem: {
			Departure: "",
			Arrival: "",
			Suppliers: "",
			PO: "",
			Status: "",
			id: "",
			mode: "",
			container_num_list: "",
		},
		cancelBookingRequestDialog: false,
		openCancelRequestForm: false,
		cancel_reason: "",
		shipmentType: "",
		viewBookingShipmentDialog: false,
		shipmentData: null,
		dialogRequest: false,
		hasRequests: false,
		cancelRequestDialog: false,
        selectedDocument: [],
        getRequestsLoading: false,
        selectedDocumentRequest: null,
		editedUploadIndex: -1,
		openSwitchAccountDialog: false,
		accountVendorDetails: null,
		isSwitchingAccountLoading: false,
		openLogoutAccountDialog: false,
		isLogoutLoading: false,
		supplierCompanyName: "",
		requestCancelledConfirmationDialog: false,
		isRequestFor: "",
		isForViewShipmentData: null,
		notAuthorizedToViewDialog: false,
		vendorNotConnectedToShiflDialog: false,
		isNotConnected: false
	}),
	methods: {
		...mapActions({
			fetchShipmentDetails: "fetchShipmentDetails",
			fetchMilestones: "fetchMilestones",
			setMilestonesNewLoading: "setMilestonesNewLoading",
			setMilestonesOtherEvents: "setMilestonesOtherEvents",
			setShipmentLoading: "setShipmentLoading",
			fetchShipmentDocuments: "fetchShipmentDocuments",
			clearShipmentDocuments: "clearShipmentDocuments",
			fetchShipmentSuppliers: "fetchShipmentSuppliers",
			fetchTerms: "fetchTerms",
			fetchContainers: "fetchContainers",
			fetchScheduleOptions: "fetchScheduleOptions",
			cancelShipment: "cancelShipment",
			fetchShipments: "fetchShipments",
			fetchPendingShipments: "fetchPendingShipments",
			fetchExpiredShipments: "fetchExpiredShipments",
			fetchSnoozeShipments: "fetchSnoozeShipments",
			fetchDraftShipments: "fetchDraftShipments",
			fetchArchivedBookings: "fetchArchivedBookings",
			fetchPendingQuoteShipments: "fetchPendingQuoteShipments",
			fetchConsolidationRequests: "fetchConsolidationRequests",
			fetchCompletedShipments: "fetchCompletedShipments",
			fetchRequestDocumentsAPI: "documents/fetchRequestDocumentsAPI",
			updateCustomerPreference: "updateCustomerPreference",
			logout: "logout",
		}),
		...globalMethods,
		ucFirst(str) {
			let pieces = str.split(" ");
			for (let i = 0; i < pieces.length; i++) {
				let j = pieces[i].charAt(0).toUpperCase();
				pieces[i] = j + pieces[i].substr(1);
			}
			return pieces.join(" ");
		},
		handleCloseCreateShipmentDialog() {
			this.createShipmentDialogView = false;
		},
		handleCloseEditShipmentDialog() {
			this.isEditShipment = false;
			this.editShipmentDialogView = false;
			this.createShipmentDialogView = false;
		},
		formatDate(value) {
			// let setDate = moment.utc(value).local().format('h:mm:ssA, MMM DD, YYYY')
			let setDate = moment
				.utc(value)
				.local()
				.format("h:mmA, MMM DD, YYYY");
			return setDate === "Invalid date" ? "N/A" : setDate;
		},
		async handleDocumentsChange(page) {
			try {
				this.shipmentDocumentsLoading = true;
				await this.fetchShipmentDocuments({
					shipment_id: this.shipment_id,
					page,
				});
				this.shipmentDocumentsLoading = false;
			} catch (e) {
				console.log(e);
			}
		},
		toggleDeleteShipmentTooltip() {
			this.deleteShipmentTooltipShow = !this.deleteShipmentTooltipShow;
		},
		async deleteShipment(id) {
			try {
				await this.$store.dispatch("trackingShipment/delete", id);
				this.closeDeleteShipmentDialog();
				this.setShipmentLoading(true);
				this.$router.push("/shipments");
				setTimeout(() => {
					this.notificationGlobal({
						className: "product-toast shipment-delete-toast",
						title: "",
						icon: "trash-can",
						message: "Shipment has been deleted.",
						position: "bottomCenter",
						timeout: 5000,
						is_success: true,
					});
				}, 2000);
			} catch (e) {
				this.notificationError("An error occured while deleting shipment.");
			}
		},
		closeDeleteShipmentDialog() {
			this.deleteShipmentDialogShow = false;
		},
		showAddShipmentDialog() {
			this.isEditShipment = false;
			this.createShipmentDialogView = true;
			this.editShipmentDialogView = false;
		},
		showEditShipmentDialog() {
			this.isEditShipment = true;
			this.createShipmentDialogView = false;
			this.editShipmentDialogView = true;
		},
		showDeleteShipmentDialog() {
			this.deleteShipmentDialogShow = true;
			this.deleteShipmentTooltipShow = false;
		},
		openUploadDialog() {
			this.dialogUploadDocs = true;
			this.dialogMessage = true;

			this.selectedDocumentRequest = this.getAllRequestDocuments
		},
		closeUploadDialog() {
			this.dialogUploadDocs = false;
		},
		openRequestDialog() {
			this.dialogRequest = true;
		},
		closeRequestDocumentDialog() {
			this.dialogRequest = false;
		},
		setMainTab(value) {
			this.currentTab = value;
		},
		setMilestoneTab(value) {
			this.currentTabMileStone = value;
		},
		onResize() {
			this.windowWidth = window.innerWidth;
			if (window.innerWidth < 960) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
		},
		async loadShipmentMetaData() {
			this.setMilestonesOtherEvents([]);
			this.setMilestonesNewLoading(true);

			//synchronous call for fetching terms and fetching containers
			if (
				typeof this.getShipmentTerms !== "undefined" &&
				this.getShipmentTerms.length === 0
			)
				this.fetchTerms();

			if (
				typeof this.getShipmentContainerSizes !== "undefined" &&
				this.getShipmentContainerSizes.length === 0
			)
				this.fetchContainers();

			try {
				await this.fetchShipmentDetails(this.shipment_id);
				this.fetchRequestDocuments(this.shipment_id);

				this.fetchMilestones(this.getShipmentDetails.mbl_num)
					.then(() => {
						this.fetchMilestoneLoading = false;
					})
					.catch((e) => {
						this.fetchMilestoneLoading = false;
						console.log(e);
					});

				this.mbl_num = this.getShipmentDetails.mbl_num;
				this.detailsLoading = false;
				this.title = this.getShipmentDetails.shifl_ref;

				let etaDetails =
					this.getShipmentDetails.eta !== null
						? ", ETA " +
						  moment.utc(this.getShipmentDetails.eta).format("MMM DD, YYYY")
						: "";
				let to_name =
					this.getShipmentDetails.location_to_name !== null &&
					this.getShipmentDetails.location_to_name !== ""
						? this.getShipmentDetails.location_to_name
						: "";

				this.location_to_with_details =
					to_name !== "" ? `${to_name}${etaDetails}` : "";

				// get shipment mode
				this.getShipmentDetails.schedules_group_bookings =
					typeof this.getShipmentDetails.schedules_group_bookings !==
						"undefined" &&
					this.getShipmentDetails.schedules_group_bookings !== "" &&
					this.getShipmentDetails.schedules_group_bookings !== null &&
					JSON.parse(this.getShipmentDetails.schedules_group_bookings.length) >
						0
						? JSON.parse(this.getShipmentDetails.schedules_group_bookings)
						: [];

				if (
					this.getShipmentDetails.schedules_group_bookings !== "undefined" &&
					this.getShipmentDetails.schedules_group_bookings !== ""
				) {
					this.shipment_mode =
						typeof _.find(
							this.getShipmentDetails.schedules_group_bookings,
							(e) => e.is_confirmed == 1
						) !== "undefined"
							? _.find(
									this.getShipmentDetails.schedules_group_bookings,
									(e) => e.is_confirmed == 1
							  ).mode
							: null;

					this.shipment_type =
						typeof _.find(
							this.getShipmentDetails.schedules_group_bookings,
							(e) => e.is_confirmed == 1
						) !== "undefined"
							? _.find(
									this.getShipmentDetails.schedules_group_bookings,
									(e) => e.is_confirmed == 1
							  ).type
							: this.getShipmentDetails.selected_schedule_type != undefined &&
							  this.getShipmentDetails.selected_schedule_type != ""
							? this.getShipmentDetails.selected_schedule_type
							: "";
				}

				// new shipment status - show status_v2 if not empty, otherwise show shipment_status
				this.shipment_status =
					this.getShipmentDetails.status_v2 !== "" &&
					this.getShipmentDetails.status_v2 !== null
						? this.getShipmentDetails.status_v2
						: this.getShipmentDetails.shipment_status;
			} catch (e) {
				console.log(e);
				this.detailsLoading = false;
			}

			//set current page
			this.$store.dispatch("page/setPage", "shipments");
		},
		getTrackingDetailsLocationWithDetails(details) {
			if (details !== null) {
				let location_to =
					details.port_of_discharge_name !== null
						? details.port_of_discharge_name
						: null;
				//let eta = details.pod_eta_at !== null ? details.pod_eta_at : null;
				let eta =
					details.pod_eta_at !== null ? details.pod_eta_at : details.pod_ata_at;
				let etaDetails =
					eta !== null ? ", ETA " + moment.utc(eta).format("MMM DD, YYYY") : "";
				let finalLocationWithInfo =
					location_to !== null ? `${location_to}${etaDetails}` : "";

				return finalLocationWithInfo;
			}
		},
		async fetchShipmentDocumentsAfter(payload) {
			this.clearShipmentDocuments();
			this.shipmentDocumentsLoading = true;
			await this.fetchShipmentDocuments(payload);
			this.selected = [];
			this.shipmentDocumentsLoading = false;
		},
		getShipmentStatusClass(item, type) {
			let { tracking_status } = item;

			let setValue =
				type === "tracking" ? tracking_status : this.shipment_status;
			let setClass = _.find(
				this.trackingStatusMap,
				(e) => e.value === setValue
			);
			setClass =
				typeof setClass !== "undefined" ? setClass.class : this.shipment_status;

			if (
				setClass === "not-tracking" &&
				this.shipment_status === "Past last free day"
			)
				setClass = "not-tracking-past";
			return setClass;
		},
		EditBookingShipmentDialog() {
			this.$store.dispatch("page/setEditingDraftShipment", true);
			// this.viewBookingShipmentDialog = true;
			// this.shipmentData = this.getShipmentDetails
			this.editedItem = Object.assign({}, this.getShipmentDetails);
			this.isEdit = true;
			this.bookingShipmentDialogView = true;
		},
		handleCloseBookingShipmentDialog() {
			this.bookingShipmentDialogView = false;
			this.$store.dispatch("page/setEditingDraftShipment", false);
		},
		async callShipmentsAPI() {
			// await this.fetchShipmentDetails(this.shipment_id)
			// await this.fetchScheduleOptions(this.shipment_id)
			// await this.fetchShipmentSuppliers(this.shipment_id)
			this.$router.go();
		},
		cancelBooking(isShipment) {
			if (isShipment === 1) {
				this.shipmentType = "shipment";
				this.openCancelRequestForm = true;
			} else {
				this.shipmentType = "booking";
				this.cancelBookingRequestDialog = true;
			}
		},
		submitCancelBooking() {
			if (this.shipment_id && this.shipment_id !== 0) {
				if (this.shipmentType == "shipment" && this.cancel_reason === "") {
					this.notificationErrorCustom(
						"Please fill the reason for cancellation field."
					);
				} else {
					let payloadObject = {
						shipmentId: this.shipment_id,
						cancel_reason: this.cancel_reason,
						type: this.shipmentType,
					};
					this.cancelShipment(payloadObject)
						.then((response) => {
							this.cancelBookingRequestDialog = false;
							this.openCancelRequestForm = false;
							this.notificationErrorCustom(response.data.message);
							this.reloadAllShipments();
						})
						.catch((e) => {
							console.log(e);
							this.cancelBookingRequestDialog = false;
							this.openCancelRequestForm = false;
							this.notificationErrorCustom("SOMETHING WENT WRONG!");
						});
				}
			}
		},
		reloadAllShipments() {
			this.fetchShipments(1);
			this.fetchPendingShipments(1);
			this.fetchExpiredShipments(1);
			this.fetchSnoozeShipments(1);
			this.fetchPendingQuoteShipments(1);
			this.fetchConsolidationRequests(1);
			this.fetchCompletedShipments(1);
			this.fetchArchivedBookings(0);
		},
		getSupplierDisplayName(id) {
            if (id) {
                let findDisplayName = _.find(this.getShipmentDetails.suppliers, (e) => (e.id == id))

                if (typeof findDisplayName !== 'undefined') {
                    if (findDisplayName.display_name !== 'undefined') {
                        return findDisplayName.display_name !== null ? findDisplayName.display_name : findDisplayName.company_name
                    }
                } else {
                    return 'N/A';
                }
            }
        },
		async fetchRequestDocuments(id) {
            let cid = (typeof this.getUser=='string') 
                ? JSON.parse(this.getUser).default_customer_id : this.getUser.default_customer_id
            let url = this.isCurrentSelectedCustomer 
                ? `${baseURL}/get-customer-requests/${id}` : `${baseURL}/get-supplier-requests/${id}/customer/${cid}`

            await this.fetchRequestDocumentsAPI(url)
            this.saveDataToState()
        },
		saveDataToState() {            
            if (this.getAllRequestDocuments !== undefined) {
                if (this.getAllRequestDocuments.length > 0) {
                    this.selectedDocument = this.getAllRequestDocuments
                } else {
                    this.selectedDocument = []
                }
            }
        },
		async cancelRequest() {
            this.cancelRequestDialog = true;
        },
        async confirmCancelRequest() {
            var searchParams = new URLSearchParams();
            let { id } = this
            this.getRequestsLoading = true

            if (this.getAllRequestDocuments.length !== 0) {
                if (this.getAllRequestDocuments.length === 1) {
                    searchParams.append(`ids[]`, this.getAllRequestDocuments[0].id)
                } else if (this.getAllRequestDocuments.length > 1) {
                    for(var ser = 0; ser < this.getAllRequestDocuments.length; ser++){
                        searchParams.append(`ids[]`, this.getAllRequestDocuments[ser].id)
                    }
                }
            }
            
            await axios.get(`${baseURL}/cancel-requests`, {
                params: searchParams
            })
            .then((res) => {
                if (res.status === 200) {
                    this.notificationMessage('Documents requested has been cancelled.');
                    this.fetchRequestDocuments(id);
                    this.closeRequest();
                    this.getRequestsLoading = false;
                }
            }).catch(e => {
                this.notificationError(e.message)
                this.getRequestsLoading = false
            }) 
        },
        closeRequest() {
            this.selectedDocument = [];
            this.cancelRequestDialog = false;
            this.selectedSuppliers = [];
        },
		confirmSwitch() {
			this.isSwitchingAccountLoading = true;
			let customer_id = this.accountVendorDetails.id;
			let q = this.$route.query

			let userDetails = JSON.parse(localStorage.getItem("shifl.user_details"))
            if (customer_id !== userDetails.default_customer_id) {
                if (typeof userDetails === "object") {
                    localStorage.setItem(
                        "shifl.user_details",
                        JSON.stringify({...userDetails, default_customer_id: customer_id, })
                    );
                }
                this.updateCustomerPreference(customer_id)
				.then((response) => {
					this.isSwitchingAccountLoading = false;
					if (typeof response.status !== "undefined") {
						if (response.status == "ok") {
							this.$router.push({ 
								query: { ...q, setDocumentTab: 1 } 
							}).catch(()=>{});

							let currentUrl = window.location.href;
							window.location.href = currentUrl;							
						}
					}
				})
				.catch((e) => {
					this.isSwitchingAccountLoading = false;
					console.log(e);
				});    
            }			
			// this.accountVendorDetails = null;
		},
		cancelSwitch() {
			this.openSwitchAccountDialog = false;
			this.accountVendorDetails = null;
			this.$router.push("/shipments")
		},
		async confirmLogout() {
			this.isLogoutLoading = true;
			const fullPath = this.$route.fullPath;
			await this.logout();
			this.isLogoutLoading = false;
			localStorage.setItem('documentRedirectPath', fullPath);
			let q = this.$route.query

			if (!this.isNotConnected) {
				this.$router.push({ 
					query: { ...q, isFromLoggedIn: 1, setDocumentTab: 1 } 
				}).catch(()=>{});
			} else {
				this.$router.push({ 
					query: { ...q, toUploadingPage: 1 } 
				}).catch(()=>{});
			}
			
			this.supplierCompanyName = "";
		},
		cancelLogout() {
			this.openLogoutAccountDialog = false;
			this.supplierCompanyName = "";
			this.$router.push('/shipments');
		},
		checkDocumentRequested(requestLists) {
			let shipmentSuppliers = this.getShipmentDetails.shipment_suppliers
			let hasRequested = false;

			if (requestLists.length > 0) {
				if (shipmentSuppliers !== null && shipmentSuppliers.length > 0) {
					shipmentSuppliers.map(v => {
						if (!v.commercial_documents_filled) {							
							requestLists.map(req => {
								if (req.supplier_id === v.supplier_id) {
									let doc_types = req.document_types;

									if (doc_types.length === 1 && doc_types[0] !== "Other Documents") {
										hasRequested = true;
									} else if (doc_types.length > 0) {
										if (doc_types.includes("Commercial Invoice") || 
											doc_types.includes("Packing List") || 
											doc_types.includes("Commercial Invoice & Packing List")) {
											hasRequested = true;
										}
									}
								}
							})
						}
					})
				}				
			}
			return hasRequested
		},
		async checkForCancelDocument(id) {
            await axios.get(`${baseURL}/check-for-cancel/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    if (res.data.status) {
                        this.requestCancelledConfirmationDialog = true;
						this.isRequestFor = res.data.message;
                    }
                }
            }).catch(e => {
                this.notificationError(e.message)
            })
        },
		requestCancelInfoClose() {
			this.requestCancelledConfirmationDialog = false;
			this.isRequestFor = "";
			// let q = this.$route.query

			// remove query params in url
			this.$router.push({ query: '' }).catch(()=>{});
		},
		async checkForAuthorization(id) {
			await axios.get(`${baseURL}/check-for-authorization/${id}`)
            .then((res) => {
                if (res.status === 200) {
					this.isForViewShipmentData = res.data
                }
            }).catch(e => {
                this.notificationError(e.message)
            })
		},
		closeNotAuthorized() {
			this.notAuthorizedToViewDialog = false;
			this.isForViewShipmentData = null;
			this.$router.push("/shipments")
		}
	},
	async mounted() {
		this.windowWidth = window.innerWidth;
		this.shipment_id = this.$route.params.id;
		//synchronous call for loading shipment meta and suppliers
		this.loadShipmentMetaData();
		this.fetchShipmentSuppliers(this.shipment_id);

		let paramsData = this.$router.currentRoute
		let isForViewShipment = parseInt(paramsData.query.is_view)
		let customersAPI = this.parseUserDetails.customers_api
		let currentSelectedCustomer = this.parseUserDetails.default_customer_id
		let isFrom = paramsData.query.from


		if (isFrom === "mail") {
			if (typeof paramsData.query.customer !== "undefined") {
				let type = paramsData.query.type
				let vendorCustomerId = parseInt(paramsData.query.scustomer)
				let vendorName = paramsData.query.sCompanyName
				let request_id = paramsData.query.rid

				if (type === "Vendor" || type === "BO" || type === "Other") {
					let findVendor = _.find(customersAPI, e => (e.id == vendorCustomerId))

					if (vendorCustomerId !== 0) {
						if (currentSelectedCustomer !== vendorCustomerId) {
							if (findVendor !== undefined) {
								this.openSwitchAccountDialog = true;
								this.accountVendorDetails = findVendor;
							} else {
								this.openLogoutAccountDialog = true;
								this.supplierCompanyName = vendorName !== null ? vendorName : "";
							}
						} else {
							this.setMainTab(2); // set current tab to documents
							// check if document is cancelled, then show cancelled dialog
							await this.checkForCancelDocument(request_id)
						}
					} else {
						// scustomer is not connected or not a Customer of Shifl 
						this.vendorNotConnectedToShiflDialog = true;
						this.isNotConnected = true;
					}
				}
			} else {
				if (typeof isForViewShipment !== "undefined" && isForViewShipment) {
					await this.checkForAuthorization(this.shipment_id);

					if (this.isForViewShipmentData !== null) {
						if (this.isForViewShipmentData.authorised) {
							this.setMainTab(2); // set current tab to documents
						} else { // show not authorized to view the shipment							
							this.notAuthorizedToViewDialog = true;
						}
					}
				}
			}
		}
	},
	async updated() {
		if (this.shipment_id !== this.$route.params.id) {
			this.shipment_id = this.$route.params.id;
			this.loadShipmentMetaData();
		}
	},
	created() {},
	computed: {
		...mapGetters({
            getAllRequestDocuments: "documents/getAllRequestDocuments",
            getAllRequestDocumentsLoading: "documents/getAllRequestDocumentsLoading",
			getShipmentDocumentsPage: "getShipmentDocumentsPage",
			getShipmentDocumentsLoading: "getShipmentDocumentsLoading",
			getShipmentDetailsLoading: "getShipmentDetailsLoading",
			getShipmentDetails: "getShipmentDetails",
			getMilestonesNewLoading: "getMilestonesNewLoading",
			getMilestonesLoading: "getMilestonesLoading",
			getMilestonesAttributes: "getMilestonesAttributes",
			getShipmentContainerSizes: "getShipmentContainerSizes",
			getShipmentTerms: "getShipmentTerms",
			getEditShipmentLoading: "getEditShipmentLoading",
			getCancelShipmentLoading: "getCancelShipmentLoading",
			getUser: "getUser"
		}),
		minDate() {
			return moment().format("YYYY-MM-DD");
		},
		oblRequired() {
			return this.getShipmentDetails.shipment_suppliers?.map((item) => {
				return item.obl_filled === false ? false : true;
			});
		},
		commercialDocuments() {
			return this.getShipmentDetails.shipment_suppliers?.map((item) => {
				return item.commercial_documents_filled === false ? false : true;
			});
		},
		getTrackingShipmentDeleteLoading() {
			return this.$store.getters["trackingShipment/getDeleteLoading"];
		},
		getFilteredShipmentDetails() {
			let shipmentDetails = this.$store.getters.getShipmentDetails;
			shipmentDetails.external_shipment = 0;
			shipmentDetails.external_shipment_tracking = 0;
			if (!Array.isArray(shipmentDetails.po_list)) {
				shipmentDetails.po_list = [];
			}
			if (shipmentDetails.is_tracking_shipment == 1) {
				shipmentDetails.external_shipment = 1;
			}

			if (
				typeof shipmentDetails.terminal_fortynine !== "undefined" &&
				shipmentDetails.terminal_fortynine !== null &&
				shipmentDetails.terminal_fortynine.attributes !== "undefined"
			) {
				let getAttributes = shipmentDetails.terminal_fortynine.attributes;
				let newAttributes = {};
				if (getAttributes !== null && getAttributes !== "") {
					try {
						newAttributes = JSON.parse(getAttributes);
					} catch (e) {
						console.log(e);
					}
				}

				if (
					typeof newAttributes.created_at !== "undefined" &&
					shipmentDetails.is_tracking_shipment == 1
				) {
					shipmentDetails.external_shipment_tracking = 1;
				}
			}

			return shipmentDetails;
		},
		getEditingDraftShipment() {
			return this.$store.getters["page/getEditingDraftShipment"];
		},
		getEditingShipment() {
			return this.$store.getters["page/getEditingShipment"];
		},
		isCurrentSelectedCustomer() {
            let user = (typeof this.getUser=='string') ? JSON.parse(this.getUser) : this.getUser

            if (typeof this.getShipmentDetails !== "undefined" && this.getShipmentDetails !== null) {
                if (this.getShipmentDetails.customer_id !== null) {
                    if (user !== null && user.default_customer_id === this.getShipmentDetails.customer_id) {
                        return true
                    } else return false
                } else return false
            } else return false
        },
		parseUserDetails() {
			let userDetailsFromStorage = localStorage.getItem("shifl.user_details");
			let getUserFromAPI = (typeof this.getUser=='string') ? JSON.parse(this.getUser) : this.getUser

			return userDetailsFromStorage !== null ? JSON.parse(userDetailsFromStorage) : getUserFromAPI;
		}
	},
	watch: {
		title() {
			this.items[1].text = this.title;
		},
	},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import "../assets/scss/pages_scss/shipment/shipmentDetails.scss";
@import "../assets/scss/buttons.scss";

.switch-account-modal-wrapper {
	.switch-account-modal-details {
		padding: 5px 8px;
		border: 1px solid #F3F4F7;
		border-radius: 4px;
		margin-top: 12px;

		.switch-company-logo {
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #E7E9EE;
			border-radius: 50px;
			padding: 16px;
			margin-right: 8px;
			background-color: #F3F4F7;
		}

		.switch-company-details {
			line-height: 20px;

			.switch-company-name {
				font-family: "Inter-SemiBold", sans-serif;
				color: #253041 !important;
				font-size: 14px;
			}

			.switch-company-address {
				margin-bottom: 0;
				color: #69758C;
				font-size: 12px;
			}
		}
	}	
}
</style>
