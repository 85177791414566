<!-- @format -->

<template>
	<div class="make-payment-dialog">
		<!-- <v-dialog v-model="dialogAdd" max-width="960px" content-class="make-payment-dialog" :retain-focus="false"
			@click:outside="close"> -->
		<v-card fill-height v-if="getMakePaymentData.isMobile == undefined || getMakePaymentData.isMobile == 'undefined'">
			loading
		</v-card>
		<v-card class="elevation-0" v-else>
			<v-form ref="form" v-model="valid" action="#" @submit.prevent="">
				<v-card-text>
					<div class="make-payment-wrapper">
						<v-col v-if="getMakePaymentData.isMobile" class="d-flex justify-start  align-center mt-0"
							style="border-bottom: 2px solid #EBF2F5;height: 62px;padding: 0;">
							<button icon class="shipment-logo pr-4 ml-3 mt-1" @click="gotoPreviousScreen">
								<img style="width: 12px;height: 14px;" src="@/assets/icons/back-blue.svg" alt="" />
							</button>
							<span class="headline"
								style="font-size: 20px !important;color: #121B2B !important;font-weight: 600;">Make
								Payment</span>
							<!-- <button icon dark class="btn-close" @click="close">
									<v-icon>mdi-close</v-icon>
								</button> -->
						</v-col>
						<div class="make-payment-info">
							<div class="invoice-details">
								<div :class="getMakePaymentData.isMobile ? '' : 'd-flex justify-between'">
									<h2 class="invoice-title"
										:style="getMakePaymentData.isMobile ? 'font-size:16px !important;line-height:24px' : ''">
										{{ getMakePaymentData.isMobile ? (getMakePaymentData.editedItemData.length === 1 ||
											typeof getMakePaymentData.editedItemData === 'object' ?
											'Invoice' : 'Invoice items') : 'Invoice items' }}
										<span v-if="Array.isArray(getMakePaymentData.editedItemData)"
											class="count-invoice">({{
												getMakePaymentData.editedItemData.length }} Invoices)</span>
									</h2>
									<v-spacer></v-spacer>
									<div>

									
									<div class="total-amount-header">
										<p class="mb-0 mr-2">Amount To Pay</p>
										<!-- <p class="input-amount-total mb-0">{{ currencyNumberFormat(computedTotal) }}</p> -->
										<div class="amount-wrapper">
											<span>$</span>
											<input style="width:152px;min-height:20px !important"
												@input="handleUpdateTotalAmount" class="input-amount-total" outlined
												hide-details v-model="totalAMountValue" 
											/>
										</div>
									</div>
									<p class="req-message">* Applied to earliest invoices first</p>
								</div>
								</div>

								<!-- <v-row class="row-inline ma-0" v-if="!Array.isArray(getMakePaymentData.editedItemData) &&
										!isNumeric(getMakePaymentData.editedItemData)
										">
										<v-col cols="12" sm="12" class="card-section">
											<div class="card-name">
												<p class="card-title">Reference</p>
												<span>{{
													getMakePaymentData.editedItemData.shipment_reference_number
												}}</span>
											</div>

											<div class="card-name">
												<p class="card-title">Date</p>
												<span>{{
													getMakePaymentData.editedItemData.date
													? getMakePaymentData.editedItemData.date
													: getMakePaymentData.editedItemData.invoice_date
												}}</span>
											</div>

											<div class="card-name">
												<p class="card-title">Due on</p>
												<span>{{ getMakePaymentData.editedItemData.due_date }}</span>
											</div>
										</v-col>
									</v-row> -->

								<!-- <v-row class="row-inline ma-0" v-if="Array.isArray(getMakePaymentData.editedItemData)">
										<v-col cols="12" sm="12" class="multiple-invoice">
											<div class="card-name flex-wrap mb-2">
												<span class="pr-2 chips"
													v-for="(multiplePaymentInvoice, i) in getMakePaymentData.editedItemData" :key="i">
													<v-chip class="invoice">{{ multiplePaymentInvoice.invoice_number }}
														<v-icon>mdi-open-in-new </v-icon>
													</v-chip>
												</span>
											</div>
										</v-col>
									</v-row> -->

								<v-row class="row-inline ma-0" v-if="isNumeric(getMakePaymentData.editedItemData)">
									<v-col cols="12" sm="12">
										<div class="card-name mb-2">
											<p class="card-title multiple">Balance Due</p>
											<span class="amount ml-2">{{
												currencyNumberFormat(twoDecimalFormat(getMakePaymentData.editedItemData))
											}}</span>
										</div>
									</v-col>
								</v-row>

								<v-row class="ma-0 flex-column invoice-items-wrapper">
									<v-col class="px-0" :class="getMakePaymentData.isMobile ? '' : 'pt-3'">
										<div class="invoice-table-details-wrapper">
											<v-simple-table dense class="invoice-details-wrapper">
												<template v-slot:default>

													<thead>
														<tr v-if="!getMakePaymentData.isMobile">
															<th class="text-start text-header">
																Particulars
															</th>
															<th class="text-mid text-header">
																Open
															</th>
															<th class="text-end text-header">
																Payment Amount
															</th>
														</tr>
														<tr v-else>
															<th :style="getMakePaymentData.isMobile ? 'color:#4B586C !important;padding-left:16px':''" style="background-color: #F9FAFB;"
																class="text-start text-header">
																Particulars
															</th>
															<th :style="getMakePaymentData.isMobile ? 'color:#4B586C !important;padding-right:10px':''" style="background-color: #F9FAFB;" colspan="50%"
																class="text-mid text-header">
																Open
															</th>
														</tr>
													</thead>
													<tbody>

														<template
															v-if="Array.isArray(getMakePaymentData.editedItemData) && !getMakePaymentData.isMobile">

															<tr v-for="(multiplePaymentInvoice, i) in getMakePaymentData.editedItemData"
																:key="i">
																<td>
																	<div class="d-flex align-center">
																		<input
																			:id="'onsite-checkbox-' + i"
																			class="empty-checkbox-invoice-payment my-checkbox"
																			@change="handleCheckboxChange(multiplePaymentInvoice.selectCheckBox, i)"
																			type="checkbox"
																			v-model="multiplePaymentInvoice.selectCheckBox"
																		/>
																		<label :for="'onsite-checkbox-' + i" class="checkbox-label-invoice-payment mb-1"></label>
																		<span style="margin-left: 12px;" class="d-flex">Invoice #{{
																			multiplePaymentInvoice.invoice_number }}
																			<span
																				v-if="multiplePaymentInvoice.is_duty_invoice && multiplePaymentInvoice.total_duty_amount == multiplePaymentInvoice.total_amount">-
																				Duty</span>

																			<!-- <v-icon
																				style="font-size: 14px; margin-left:3px; color: #69758C;">
																				mdi-open-in-new
																			</v-icon> -->
																			<img style="cursor:pointer" @click="download(multiplePaymentInvoice)" src="@/assets/icons/link-black.svg" alt="" class="ml-1">

																		</span>
																		<span
																			v-if="feeSettingCheck.is_fee_for_overdue == 1 && countDaysOfOverDue(multiplePaymentInvoice) > feeSettingCheck.overdue_days"
																			class="processing-fee px-2 ml-2 d-flex align-center"
																			style="display:inline;color:red;background-color: #FEE2E2 !important;border-radius: 24px;height:24px;">
																			{{ countDaysOfOverDue(multiplePaymentInvoice) }} Days Overdue
																		</span>
																	</div>

																	<!-- <span
																		v-if="multiplePaymentInvoice.is_duty_invoice"
																		class="processing-fee"
																		style="text-align: left;">Includes {{
																			currencyNumberFormat(multiplePaymentInvoice.total_duty_amount)
																		}} of
																		duties <span
																			v-if="feeSettingCheck.is_fee_for_overdue == 1 && countDaysOfOverDue(multiplePaymentInvoice) > feeSettingCheck.overdue_days"
																			class="processing-fee px-2 py-1"
																			style="display:inline;color:red;background-color: #FEE2E2 !important;border-radius: 24px;">
																			{{ feeSettingCheck.overdue_days }} Days Overdue
																		</span>
																	</span> -->

																	<div>
																	<span
																	v-if="multiplePaymentInvoice.is_duty_invoice && multiplePaymentInvoice.total_duty_amount != multiplePaymentInvoice.total_amount"
																		class="processing-fee"
																		style="text-align: left;margin-left: 28px;">Includes
																		{{ 																			
																		currencyNumberFormat(multiplePaymentInvoice.total_duty_amount)
																		}} of
																		duties 
																	</span>
																	</div>
																</td>
																<td 
																	style="text-align: right;"
																	:style="multiplePaymentInvoice.is_duty_invoice && multiplePaymentInvoice.total_duty_amount != multiplePaymentInvoice.total_amount ? 'vertical-align: top;':''" 
																>
																	<div>
																		{{
																			currencyNumberFormat(multiplePaymentInvoice.balance)
																		}}
																	</div>
																</td>
																<td 
																	style="text-align: right;"
																	:style="multiplePaymentInvoice.is_duty_invoice && multiplePaymentInvoice.total_duty_amount != multiplePaymentInvoice.total_amount ? 'vertical-align: top;':''" 
																>
																<div class="amount-wrapper">
																	<span>$</span>
																	<input
																		@input="recalculateProcessingFee(multiplePaymentInvoice.amountNew,i)"
																		class="input-amount"
																		v-model="multiplePaymentInvoice.amountNew" />
																	</div>
																</td>
															</tr>
														</template>
														<template
															v-else-if="typeof getMakePaymentData.editedItemData === 'object' && !getMakePaymentData.isMobile">
															<tr>
																<td>
																	<div class="d-flex align-center">
																		<input
																			:id="'onsite-checkbox-'"
																			class="empty-checkbox-invoice-payment my-checkbox"
																		 	type="checkbox"
																			v-model="getMakePaymentData.editedItemData.selectCheckBox"
																			@change="handleCheckboxChange(getMakePaymentData.editedItemData.selectCheckBox)"
																		/>
																		<label :for="'onsite-checkbox-'" class="checkbox-label-invoice-payment mb-1"></label>
																		<span style="margin-left: 12px;" class="d-flex">Invoice #{{
																			getMakePaymentData.editedItemData.invoice_number
																		}}
																			<span
																				v-if="getMakePaymentData.editedItemData.is_duty_invoice && getMakePaymentData.editedItemData.total_duty_amount == getMakePaymentData.editedItemData.total_amount">
																				- Duty
																			</span>
																			<!-- <v-icon
																				style="font-size: 14px; color: #69758C;">mdi-open-in-new
																			</v-icon> -->
																			<img style="cursor:pointer" @click="download(getMakePaymentData.editedItemData)" src="@/assets/icons/link-black.svg" alt="" class="ml-1">

																		</span>
																		<span
																			v-if="feeSettingCheck.is_fee_for_overdue == 1 && countDaysOfOverDue(getMakePaymentData.editedItemData) > feeSettingCheck.overdue_days"
																			class="processing-fee px-2 ml-2 d-flex align-center"
																			style="display:inline;color:red;background-color: #FEE2E2 !important;border-radius: 24px;height:24px;">
																			{{ countDaysOfOverDue(getMakePaymentData.editedItemData) }} Days Overdue
																		</span>
																	</div>
																
																	<div>
																	<span
																		v-if="getMakePaymentData.editedItemData.is_duty_invoice && getMakePaymentData.editedItemData.total_duty_amount != getMakePaymentData.editedItemData.total_amount"
																		class="processing-fee"
																		style="text-align: left;margin-left: 28px;">Includes
																		{{ currencyNumberFormat(getMakePaymentData.editedItemData.total_duty_amount)
																		}} of
																		duties 
																	</span>
																	
																	</div>

																</td>
																<td 
																	:style="getMakePaymentData.editedItemData.is_duty_invoice && getMakePaymentData.editedItemData.total_duty_amount != getMakePaymentData.editedItemData.total_amount ? 'vertical-align: top;':''" 
																	style="text-align: right;"
																>
																	{{
																		currencyNumberFormat(getMakePaymentData.editedItemData.balance)
																	}}
																</td>
																<td 
																	style="text-align: right;"
																	:style="getMakePaymentData.editedItemData.is_duty_invoice && getMakePaymentData.editedItemData.total_duty_amount != getMakePaymentData.editedItemData.total_amount ? 'vertical-align: top;':''"
																>
																	<div class="amount-wrapper">
																		<span>$</span>
																		<input @input="recalculateProcessingFee(getMakePaymentData.editedItemData.amountNew,-1)"
																		class="input-amount"

																		v-model="getMakePaymentData.editedItemData.amountNew" />
																	</div>
																</td>
															</tr>
														</template>

														<template
															v-if="Array.isArray(getMakePaymentData.editedItemData) && getMakePaymentData.isMobile">

															<tr v-for="(multiplePaymentInvoice, i) in getMakePaymentData.editedItemData"
																:key="i">
																<td colspan="3" style="width:100%;background-color: white">
															<tr style="background-color: white">
																<td colspan="3"
																	style="padding-left: 0 !important;padding-right: 0 !important;border-bottom: 0 !important;">
																	<div class=""
																		style="width: 100%;display: flex;justify-content: space-between;">
																		<div>
																			<div class="d-flex align-center">
																				<input 
																					type="checkbox"
																					:id="'onsite-checkbox-' + i"
																					class="empty-checkbox-invoice-payment my-checkbox"
																					@change="handleCheckboxChange(multiplePaymentInvoice.selectCheckBox, i)"
																					v-model="multiplePaymentInvoice.selectCheckBox" 
																				/>
																				<label :for="'onsite-checkbox-' + i" class="checkbox-label-invoice-payment"></label>
																				<span
																					style="margin-left: 5px;margin-right: 5px;" class="d-flex">Invoice
																					#{{
																						multiplePaymentInvoice.invoice_number
																					}}<span
																						v-if="multiplePaymentInvoice.is_duty_invoice && multiplePaymentInvoice.total_duty_amount == multiplePaymentInvoice.total_amount">
																						-
																						Duty</span>
																					<!-- <v-icon
																						style="font-size: 14px; margin-left:3px; color: #69758C;">
																						mdi-open-in-new
																					</v-icon> -->
																					<img style="cursor:pointer" @click="download(multiplePaymentInvoice)" src="@/assets/icons/link-black.svg" alt="" class="ml-1">
																				</span>
																				<span
																					v-if="feeSettingCheck.is_fee_for_overdue == 1 && countDaysOfOverDue(multiplePaymentInvoice) > feeSettingCheck.overdue_days"
																					class="processing-fee px-2 py-1"
																					style="display:inline;color:red;background-color: #FEE2E2 !important;border-radius: 24px;">
																					{{ countDaysOfOverDue(multiplePaymentInvoice) }} Days
																					Overdue
																				</span>
																			</div>
																			<span
																				v-if="multiplePaymentInvoice.is_duty_invoice && multiplePaymentInvoice.total_duty_amount != multiplePaymentInvoice.total_amount"
																				class="processing-fee"
																				style="text-align: left;margin-left: 22px;">Includes
																				{{
																					currencyNumberFormat(multiplePaymentInvoice.total_duty_amount)
																				}} of
																				duties

																			</span>

																		</div>
																		<div
																			style="text-align: right;padding-right: 0 !important;width: max-content;margin-top: 3px;">
																			<div>
																				{{
																					currencyNumberFormat(multiplePaymentInvoice.balance)
																				}}
																			</div>
																		</div>
																	</div>

																</td>
															</tr>
															<tr style="background-color: white">
																<td
																	style="width: 70%;text-align: left;padding-left: 0 !important;;border-bottom: 0 !important;">
																	Payment
																</td>
																<td
																	style="text-align: right;width: 60% !important;padding-right: 0 !important;;border-bottom: 0 !important;"
																>
																<div class="amount-wrapper">
																	<span>$</span>
																	<input
																		style="width: 144px;border-color: #E7E9EE;"
																		@input="recalculateProcessingFee(multiplePaymentInvoice.amountNew,i)"
																		class="input-amount"
																		v-model="multiplePaymentInvoice.amountNew" />
																</div>
																</td>
															</tr>
															</td>
															</tr>
														</template>
														<template
															v-else-if="typeof getMakePaymentData.editedItemData === 'object' && getMakePaymentData.isMobile">
															<tr>
																<td colspan="3" style="width:100%">
															<tr style="background-color: white">
																<td colspan="3"
																	style="padding-left: 0 !important;padding-right: 0 !important;border-bottom: 0 !important;">
																	<div class=""
																		style="width: 100%;display: flex;justify-content: space-between;">
																		<div>
																			<div class="d-flex align-center">
																				<input
																					:id="'onsite-checkbox-'"
																					class="empty-checkbox-invoice-payment my-checkbox"
																					type="checkbox"
																					v-model="getMakePaymentData.editedItemData.selectCheckBox"
																					@change="handleCheckboxChange(getMakePaymentData.editedItemData.selectCheckBox)"
																				/>
																				<label :for="'onsite-checkbox-'" class="checkbox-label-invoice-payment"></label>
																				<span style="margin-left: 5px" class="d-flex">
																					Invoice #{{
																						getMakePaymentData.editedItemData.invoice_number
																					}}
																					<span
																						v-if="getMakePaymentData.editedItemData.is_duty_invoice && getMakePaymentData.editedItemData.total_duty_amount == getMakePaymentData.editedItemData.total_amount">
																						- Duty
																					</span>
																					<!-- <v-icon
																						style="font-size: 14px; color: #69758C;">mdi-open-in-new
																					</v-icon> -->
																					<img style="cursor:pointer" @click="download(getMakePaymentData.editedItemData)" src="@/assets/icons/link-black.svg" alt="" class="ml-1">
																					<span
																						v-if="feeSettingCheck.is_fee_for_overdue == 1 && countDaysOfOverDue(getMakePaymentData.editedItemData) > feeSettingCheck.overdue_days"
																						class="processing-fee ml-2 px-2 py-1"
																						style="display:inline;color:red;background-color: #FEE2E2 !important;border-radius: 24px;">
																						{{ countDaysOfOverDue(getMakePaymentData.editedItemData) }}
																						Days Overdue
																					</span>
																				</span>
																			</div>
																			<span
																				v-if="getMakePaymentData.editedItemData.is_duty_invoice && getMakePaymentData.editedItemData.total_duty_amount != getMakePaymentData.editedItemData.total_amount"
																				class="processing-fee"
																				style="text-align: left;margin-left: 22px;display: block;">Includes
																				of {{
																					currencyNumberFormat(getMakePaymentData.editedItemData.total_duty_amount)
																				}}
																				duties
																			</span>
																		</div>
																		<div
																			style="text-align: right;padding-right: 0 !important;width: max-content;margin-top: 3px;">
																			{{
																				currencyNumberFormat(getMakePaymentData.editedItemData.balance)
																			}}
																		</div>
																	</div>
																</td>

															</tr>
															<tr>
																<td colspan="2"
																	style="width: 70%;text-align: left;padding-left: 0 !important;border-bottom: 0 !important;">
																	Payment
																</td>
																<td colspan="0"
																	style="text-align: right;width: 60% !important;padding-right: 0 !important;border-bottom: 0 !important;"
																>
																<div class="amount-wrapper">
																	<span>$</span>
																	<input style="width: 144px;border-color: #E7E9EE;" @input="recalculateProcessingFee(getMakePaymentData.editedItemData.amountNew,-1)"
																		class="input-amount"
																		v-model="getMakePaymentData.editedItemData.amountNew" />
																</div>
																</td>
															</tr>
															</td>
															</tr>
														</template>
														<tr v-if="!getMakePaymentData.isMobile">
															<td style="text-align: left;" class="subtotal">Subtotal</td>
															<td></td>
															<td style="text-align: right;" class="subtotal">
																{{ currencyNumberFormat(computedTotal) }}
															</td>

														</tr>

														<!-- <tr>
															<td style="text-align: left;" class="totaldue">Due</td>
															<td></td>
															<td style="text-align: right;" class="totaldue">
																{{ currencyNumberFormat(countTotalDue()) }}
															</td>
														</tr> -->




														<!-- <tr v-if="countDuties() > 0">
																<td>
																	<div class="duty-fees">
																		<v-container fluid class="pa-0">
																			<v-checkbox class="ma-0 pa-0" label="Duties"
																				:value="includingDutyFees"
																				v-model="includingDutyFees"
																				hide-details></v-checkbox>
																		</v-container>
																	</div>
																</td>
																<td></td>
																<td style="text-align: right;" :class="!includingDutyFees
																	? 'text-decoration-line-through'
																	: ''
																	">
																	{{ currencyNumberFormat(countDuties()) }}
																</td>
															</tr> -->
														<tr v-if="(countDuties() > 0 || checkProcessingFeeShow) && paymentMethodsSelected !== 'ACH'">
															<td  style="width: 55%;" class="processing-fee-tooltip">
																Processing Fee
																<!-- <v-tooltip right>
																	<template v-slot:activator="{ on, attrs }">
																		<v-icon color="grey-lighten-1" v-bind="attrs"
																			v-on="on">
																			mdi-information-outline
																		</v-icon>
																	</template>
																	3.2% Processing Fee Will Be Charged For CC
																	payments
																</v-tooltip> --><br>
																<span class="processing-fee">3.2% of {{currencyNumberFormat(countProcessingFee())}}</span>
															</td>
															<td style="width: 20%;"></td>
															<td style="text-align: right;width: 25%;">
																<p style="font-weight: bold;margin-bottom: 0;">
																	{{
																		paymentMethodsSelected !== "ACH"
																		? currencyNumberFormat(
																			countProcessingFee()
																		)
																		: currencyNumberFormat(0)
																	}}


																</p>
																<button
																	v-if="!getMakePaymentData.isMobile && paymentMethodsSelected != 'ACH'"
																	@click="showProcessingFeeDiv = !showProcessingFeeDiv"
																	class="caption" style="color:#1A6D9E">
																	<v-icon v-if="!showProcessingFeeDiv"
																		style="font-size:20px;color:#1A6D9E">mdi-chevron-down</v-icon>
																	<v-icon v-else
																		style="font-size:20px;color:#1A6D9E">mdi-chevron-up</v-icon>
																	Show Details
																</button>
															</td>
														</tr>
														<template
															v-if="showProcessingFeeDiv && Array.isArray(getMakePaymentData.editedItemData) && !getMakePaymentData.isMobile">
															<tr 
																:style="!fee.selectCheckBox ? 'display:none' : ''"
																v-for="(fee,) in invoiceProcessingFee"
																:key="fee.invoice_number">
																<td style="width:55%;border-bottom:none !important">
																	Invoice # {{ fee.invoice_number }}
																</td>

																<td v-if="fee.amountNew < fee.total_duty_amount"
																	style="width:20%;border-bottom:none !important;text-align:end">
																	{{ currencyNumberFormat(fee.amountNew) }} x 3.2%
																</td>
																<td v-else-if="feeSettingCheck.is_fee_for_overdue == 1 && countDaysOfOverDue(fee) > feeSettingCheck.overdue_days"
																	style="width:20%;border-bottom:none !important;text-align:end">
																	{{ currencyNumberFormat(fee.amountNew) }} x 3.2%
																</td>
																<td v-else
																	style="width:20%;border-bottom:none !important;text-align:end">
																	{{ currencyNumberFormat(fee.total_duty_amount) }} x 3.2%
																</td>
																<td
																	style="width:25%;border-bottom:none !important;text-align:end">
																	{{ currencyNumberFormat(percentage(3.2,
																		fee.total_duty_amount, fee.amountNew, fee.balance, fee)
																	) }}
																</td>
															</tr>
														</template>
														<template
															v-if="showProcessingFeeDiv && typeof getMakePaymentData.editedItemData === 'object' && !getMakePaymentData.isMobile">
															<tr style="height:52px"
																:style="!invoiceProcessingFee.selectCheckBox ? 'display:none' : ''">
																<td style="width:55%;border-bottom:none !important">
																	Invoice # {{ invoiceProcessingFee.invoice_number }}
																</td>
																<td v-if="invoiceProcessingFee.amountNew < invoiceProcessingFee.total_duty_amount"
																	style="width:20%;border-bottom:none !important;text-align:end">
																	{{ currencyNumberFormat(invoiceProcessingFee.amountNew) }}
																	x 3.2%
																</td>
																<td v-else-if="feeSettingCheck.is_fee_for_overdue == 1 && countDaysOfOverDue(invoiceProcessingFee) > feeSettingCheck.overdue_days"
																	style="width:20%;border-bottom:none !important;text-align:end">
																	{{ currencyNumberFormat(invoiceProcessingFee.amountNew) }}
																	x 3.2%
																</td>
																<td v-else
																	style="width:20%;border-bottom:none !important;text-align:end">
																	{{
																		currencyNumberFormat(invoiceProcessingFee.total_duty_amount)
																	}} x 3.2%
																</td>
																<td
																	style="width:25%;border-bottom:none !important;text-align:center">
																	{{ currencyNumberFormat(percentage(3.2,
																		invoiceProcessingFee.total_duty_amount, invoiceProcessingFee.amountNew, invoiceProcessingFee.balance, invoiceProcessingFee)
																	) }}
																</td>
															</tr>
														</template>

														<tr v-if="!getMakePaymentData.isMobile">
															<td :style="getMakePaymentData.isMobile ? 'height:40px;min-height:40px;padding:6px 8px;margin-bottom:6px':'height: 60px;'" class="total-payment">
																Total Payment
															</td>
															<td></td>
															<td style="text-align: right;" class="subtotal">
																<span v-if="!Array.isArray(getMakePaymentData.editedItemData) &&
																	!isNumeric(getMakePaymentData.editedItemData)
																	">
																	{{
																		currencyNumberFormat(
																			totalPaymentAmount(getMakePaymentData.editedItemData)
																		)
																	}}</span>
																<span class="amount ml-2"
																	v-if="Array.isArray(getMakePaymentData.editedItemData)">
																	{{
																		currencyNumberFormat(
																			totalPaymentAmount(multiplePaymentData)
																		)
																	}}
																</span>
																<span class="amount ml-2"
																	v-if="isNumeric(getMakePaymentData.editedItemData)">{{
																		currencyNumberFormat(
																			totalPaymentAmount(getMakePaymentData.editedItemData)
																		)
																	}}</span>
															</td>

														</tr>

													</tbody>
												</template>
											</v-simple-table>
										</div>
										<v-overlay :value="overlay" :absolute="absolute">
											<div v-if="showProcessingFeeDiv && getMakePaymentData.isMobile"
												style="position: fixed;bottom: 0;height: 380px;left: 0;width: 100%;background-color: white;">
												<div style="position: relative;height: 100%;">
													<h3 class="py-3 px-4" style="color: #253041;font-size: 20px;line-height: 30px;">
														Processing Fees
													</h3>
													<v-divider color="#EBF2F5"></v-divider>
													<div style="height: 208px;overflow-y: scroll;" class="pt-3 px-4">
														<div class="d-flex" v-for="(fee, index) in invoiceProcessingFee"
															:key="fee.invoice_number + index">
															<p class="" style="width: 48%;color: #4A4A4A;">
																Invoice # {{ fee.invoice_number }}
															</p>
															<p v-if="fee.amountNew < fee.total_duty_amount"
																style="width: 32%;color: #4A4A4A;">
																{{ currencyNumberFormat(fee.amountNew) }} x 3.2%
															</p>
															<p v-else-if="feeSettingCheck.is_fee_for_overdue == 1 && countDaysOfOverDue(fee) > feeSettingCheck.overdue_days"
																style="width: 32%;color: #4A4A4A;">
																{{ currencyNumberFormat(fee.amountNew) }} x 3.2%
															</p>
															<p v-else style="width: 32%;color: #4A4A4A;">
																{{ currencyNumberFormat(fee.total_duty_amount) }} x 3.2%
															</p>
															<p style="width: 20%;text-align: end;color: #4A4A4A;">
																{{ currencyNumberFormat(percentage(3.2,
																	fee.total_duty_amount, fee.amountNew, fee.balance, fee)
																) }}
															</p>
														</div>
													</div>
													<v-divider color="#EBF2F5"></v-divider>

													<div class="d-flex justify-between py-3 px-4" style="height: 15%;">
														<div>
															<p class="mb-0" style="color: #253041;font-weight: 600;">Total
																Fees</p>
															<p class="mb-0" style="color: #4B586C;">3.2% of {{currencyNumberFormat(countProcessingFee())}} </p>
														</div>
														<v-spacer></v-spacer>
														<div style="color: #253041;font-weight: 600;">
															{{
																paymentMethodsSelected !== "ACH"
																? currencyNumberFormat(
																	countProcessingFee()
																)
																: currencyNumberFormat(0)
															}}

														</div>
													</div>
													<div class="py-3 px-4" style="width: 100%; text-align: center;">
													<button
														@click="showProcessingFeeDiv = false, absolute = false, overlay = false"
														class="btn-white" style="height: 40px;width: 100%;">Cancel</button>
													</div>
												</div>

											</div>
										</v-overlay>
										<v-overlay :value="overlaySuccess">
										<div
											style="position: fixed;top: 35%;height: 286px;left: 4%;width: 92%;background-color: white;border-radius:4px"
												v-if="successDialog"
												class="px-6 py-6"
											>
											<v-card light class="">
												<div class="mb-6">
													<img style="width:25px;height:22px" src="@/assets/icons/checkMark-green.svg" alt="">
												</div>

												<h6 style="font-size:20px;line-height:30px;font-weight:600;color:#253041">
													Payment Successful
												</h6>
												<p class="mb-6 mt-2" style="font-size:14px;color:#253041;line-height:20px">Your payment has been successfully processed. 
													You can keep track of your latest payment updates in the billing history.</p>
												<div class="d-flex">
													<v-btn
													style="padding:10px 16px !important"
												    class="btn-blue mr-2"
													color="primary"
													text
													@click="successDialog = false,absoluteSuccess = false, overlaySuccess = false"
												>
												Understood
												</v-btn>
												<v-btn
												    style="padding:10px 16px !important"
												    class="btn-white"
													color="primary"
													text
													@click="successDialog = false,absoluteSuccess = false, overlaySuccess = false"
												>
												View Billing History
												</v-btn>
											</div>
											</v-card>
											</div>
										</v-overlay>
										<v-overlay :value="overlayDeclined">
										<div
											style="position: fixed;top: 35%;height: 302px;left: 4%;width: 92%;background-color: white;border-radius:4px"
												v-if="declinedDialog"
												class="px-6 py-6"
											>
											<v-card light class="">
												<div class="mb-3">
													<img style="width:40px;height:40px" src="@/assets/icons/icon-delete-red.svg" alt="">
												</div>

												<h6 style="font-size:20px;line-height:30px;font-weight:600;color:#253041">
													Payment Declined
												</h6>
												<p class="mb-6 mt-2" style="font-size:14px;color:#253041;line-height:20px">
													We couldn’t process your payment. Please ensure you have sufficient
													balance/credit limit/permission to make the payment and try again. Or, contact our <button @click="overlayDeclined = false;declinedDialog =false;openFeedback = true;" style="color:#1A6D9E">Support Team.</button>.
												</p>
												<div class="d-flex">
													<v-btn
													style="padding:10px 16px !important"
												    class="btn-blue mr-2"
													color="primary"
													text
													@click="declinedDialog = false,absoluteDeclined = false, overlayDeclined = false"
												>
												Retry
												</v-btn>
												<v-btn
												    style="padding:10px 16px !important"
												    class="btn-white"
													color="primary"
													text
													@click="declinedDialog = false,absoluteDeclined = false, overlayDeclined = false"
												>
												Cancel
												</v-btn>
											</div>
											</v-card>
											</div>
										</v-overlay>
										<v-overlay :value="overlayAddPayment">
										<div
											style="position: fixed;top: 35%;height: 322px;left: 4%;width: 92%;background-color: white;border-radius:4px"
												v-if="AddPaymentDialog"
												class="px-6 py-6"
											>
											<v-card light class="">
												<div class="mb-4">
													<img style="width:48px;height:48px" src="@/assets/icons/icon-delete.svg" alt="">
												</div>

												<h6 style="font-size:20px;line-height:30px;font-weight:600;color:#253041">
													Add Payment Method
												</h6>
												<p class="mb-6 mt-2" style="font-size:14px;color:#253041;line-height:20px">
													You need to add payment method in order to make any payment through our platform. 
													Any information related to payment method will only be used for transaction purpose only.
												</p>
												<div class="d-flex">
													<v-btn
													style="padding:10px 16px !important"
												    class="btn-blue mr-2"
													color="primary"
													text
													@click="AddPaymentDialog = false,absoluteAddPayment = false, overlayAddPayment = false,AddpaymentMethodsDialog=true,overlayAddPaymentMethods=true"
												>
												Add Payment Method
												</v-btn>
												<v-btn
												    style="padding:10px 16px !important"
												    class="btn-white"
													color="primary"
													text
													@click="AddPaymentDialog = false,absoluteAddPayment = false, overlayAddPayment = false"
												>
												Cancel
												</v-btn>
											</div>
											</v-card>
											</div>
										</v-overlay>
										<v-overlay :value="overlayPaymentMethodAdded">
										<div
											style="position: fixed;top: 35%;height: 266px;left: 4%;width: 92%;background-color: white;border-radius:4px"
												v-if="paymentMethodAddedDialog"
												class="px-6 py-6"
											>
											<v-card light class="">
												<div class="mb-6">
													<img style="width:25px;height:22px" src="@/assets/icons/checkMark-green.svg" alt="">
												</div>

												<h6 style="font-size:20px;line-height:30px;font-weight:600;color:#253041">
													Payment Method Added
												</h6>
												<p class="mb-6 mt-2" style="font-size:14px;color:#253041;line-height:20px">
													You have added your payment method successfully. Now you can easily process all of your payment.
												</p>
												<div class="d-flex">
													<v-btn
													style="padding:10px 16px !important"
												    class="btn-blue mr-2"
													color="primary"
													text
													@click="gotoPreviousScreenPayment"
												>
												Back To Billing
												</v-btn>
											</div>
											</v-card>
											</div>
										</v-overlay>
										<v-overlay :value="overlayAddPaymentMethods">
										<div
											style="position: fixed;top: 35%;height: 386px;left: 4%;width: 92%;background-color: white;border-radius:4px"
												v-if="AddpaymentMethodsDialog"
												class="px-6 py-6"
											>
											<v-card light class="">
												
												<h6 style="font-size:20px;line-height:30px;font-weight:600;color:#253041">
													Payment Methods
												</h6>
												<p class="mb-2 mt-2" style="font-size:14px;color:#253041;line-height:20px">
													Streamline your transactions and enjoy seamless payments by adding a payment method.
												</p>
												<div  style="height:128px;margin-bottom: 64px;">
													<v-radio-group
													style="margin-top: 0 !important;"
														hide-details v-model="paymentMethodsSelected"
														@change="paymentMethodSelectFunc"
													>
														<v-radio 
															value="CC"
															class="pb-5">
															<template v-slot:label>
																<div class="d-flex mt-4">
																	<div class="pr-4">
																		<img style="width:56px;height:40px" src="@/assets/icons/creditCard.svg" alt="">
																	</div>
																	<div>
																		<h6 class="" style="color:#253041;font-size: 14px;font-weight: 600;">
																			Credit Card
																		</h6>
																		<p class="mb-0" style="color:#253041;font-size: 12px;font-weight: 400;line-height: 18px;">
																			Fast & Safe Transaction from anywhere
																		</p>
																	</div>
																</div>
															</template>
														</v-radio>
														<v-radio 
															value="ACH"
															class=""
														>
															<template v-slot:label>
																<div class="d-flex ">
																	<div class="pl-1 pr-6">
																		<img style="width:46px;height:49px" src="@/assets/icons/achnew.svg" alt="">
																	</div>
																	<div>
																		<h6 class="" style="color:#253041;font-size: 14px;font-weight: 600;">
																			ACH
																		</h6>
																		<p class="mb-0" style="color:#253041;font-size: 12px;font-weight: 400;line-height: 18px;">
																			Connect your bank & pay with your debit card
																		</p>
																	</div>
																</div>
															</template>
														</v-radio>
													</v-radio-group>
												</div>
												<div class="d-flex">
													<v-btn
													style="padding:10px 16px !important"
												    class="btn-blue mr-2"
													color="primary"
													text
													@click="openPaymentInputDialog"
												>
												Continue
												</v-btn>
												<v-btn
												    style="padding:10px 16px !important"
												    class="btn-white"
													color="primary"
													text
													@click="AddpaymentMethodsDialog = false,absoluteAddPaymentMethods = false, overlayAddPaymentMethods = false"
												>
												Cancel
												</v-btn>
											</div>
											</v-card>
											</div>
										</v-overlay>
										<v-overlay :value="overlayAddPaymentInputMethods">
										<div
											style="position: fixed;top: 15%;height: 584px;left: 4%;width: 92%;background-color: white;border-radius:4px"
												v-if="AddpaymentInputMethodDialog"
												class=""
											>
											<v-card light class="px-1 pb-6">
											<v-form ref="addPaymentInputform" action="#" @submit.prevent="">
												<div style="" class="d-flex pt-4 px-5">
													<h6 style="font-size:20px;line-height:30px;font-weight:600;color:#253041">
														Add Payment Method
													</h6>
													<v-spacer></v-spacer>
													<button icon dark class="btn-close" @click="closePaymentInputDialog">
														<v-icon>mdi-close</v-icon>
													</button>
												</div>
												<v-card-text>
													<div class="">
														<VCreditCard
															:noCard="this.showCardImage"
															:trans="translations"
															:yearDigits="4"
															@change="creditInfoChanged"
															:paymentData="this.paymentData"
															:cardEdit="this.cardEdit"
														/>

														<v-checkbox
															@change="defaultPaymentMethod($event)"
															class="payment-checkbox px-5"
															label="Make it my default payment method"
															hide-details="auto"
														>
														</v-checkbox>
													</div>
												</v-card-text>
												<div class="d-flex px-5">
													<button
														class="btn-blue mr-2"
														@click="savePaymentMethod"
														:disabled="addMethodButtonDisabled || buttonTextAdding"
													>
														<span>
															<span v-if="!buttonTextAdding">Add Method</span>
															<span v-if="buttonTextAdding">Adding...</span>
														</span>
													</button>
													<button class="btn-white" @click="closePaymentInputDialog">Cancel</button>
												</div>
												</v-form>
											</v-card>
										</div>
										</v-overlay>

										<!-- <div class="total-payments pb-0">
												<p class="mb-0">Total Payment</p>
												<p class="mb-0">
													<span v-if="!Array.isArray(getMakePaymentData.editedItemData) &&
															!isNumeric(getMakePaymentData.editedItemData)
															">
														{{
															currencyNumberFormat(
																totalPaymentAmount(getMakePaymentData.editedItemData)
															)
														}}</span>
													<span class="amount ml-2" v-if="Array.isArray(getMakePaymentData.editedItemData)">
														{{
															currencyNumberFormat(
																totalPaymentAmount(multiplePaymentData)
															)
														}}
													</span>
													<span class="amount ml-2" v-if="isNumeric(getMakePaymentData.editedItemData)">{{
														currencyNumberFormat(
															totalPaymentAmount(getMakePaymentData.editedItemData)
														)
													}}</span>
												</p>
											</div> -->
									</v-col>
								</v-row>
							</div>

							<div class="payment-details">
								<v-row  fill-height>
									<v-col class="d-flex justify-space-between align-center payment-title"
										v-if="!getMakePaymentData.isMobile">
										<span class="headline">Make Payment</span>
										<!-- <button icon dark class="btn-close" @click="close">
												<v-icon>mdi-close</v-icon>
											</button> -->
									</v-col>

									<v-col :style="getMakePaymentData.isMobile ? 'padding: 24px 16px 0 16px !important;' : ''"
										cols="12" class="payment-info-details">
										<div class="card-name mb-4">
											<p :style="getMakePaymentData.isMobile ? 'color:#6D858F !important' : ''">Payment
												Method</p>
											<v-radio-group 
												:style="getMakePaymentData.isMobile ? 'height:36px' : ''" 
												hide-details v-model="paymentMethodsSelected" 
												row
												:class="getMakePaymentData.isMobile ? 'mb-4':''"
												class="mt-0"
												@change="paymentMethodSelectFunc"
											>
												<v-radio value="CC" label="Credit Card"
													class="v-radio-payment-method-label tab-pm-cc"></v-radio>
												<v-radio label="ACH Account" value="ACH"
													class="v-radio-payment-method-label tab-pm-ach"></v-radio>
											</v-radio-group>
											<div v-if="paymentMethodsSelected !== 'ACH'">
												<p :style="getMakePaymentData.isMobile ? 'color:#4B586C;font-size:14px' : ''"
													class="card-title payment-method-select-title">
													Card
												</p>
												<v-select ref="paymentMethodSelect" v-model="card_id" class="text-fields select-items" :items="items"
													item-text="number_masked" item-value="id"
													placeholder="Select Payment Method" height="45px" dense outlined
													hide-details="auto" prepend-inner="button"
													append-icon="mdi-chevron-down"
													:menu-props="{ bottom: true, offsetY: true }"
													@change="paymentMethodSelectFunc">
													<template v-slot:selection="{ item }">
														<img width="28" height="20" :src="getImgUrl(item.card_type)"
															class="pm-selection-cc-img" />{{ item.number_masked }}
													</template>
													<template v-slot:item="{ item }">
														<img width="28" height="20" :src="getImgUrl(item.card_type)"
															class="pm-selection-cc-img" />{{ item.number_masked }}
													</template>
													<template v-slot:append-item>
														<v-divider class="mb-2"></v-divider>
														<v-list-item>
															<v-list-item-content>
																<v-list-item-title>
																	<a @click="handleManagePayment"
																		class="d-flex align-center text-decoration-none">
																		<img src="../../../assets/icons/plus.svg"
																			class="mr-2" width="12px" height="12px"
																			alt="" />
																		<span>New Payment Method</span>
																	</a>
																</v-list-item-title>
															</v-list-item-content>
														</v-list-item>
													</template>
												</v-select>
											</div>
											<div v-else>
												<p class="card-title payment-method-select-title">
													Select Account
												</p>
												<v-select ref="paymentMethodSelect" v-model="ACH_id" class="text-fields select-items"
													:items="ACHItems" item-text="name" item-value="id"
													placeholder="Select ACH Payment Method" height="45px" dense outlined
													hide-details="auto" prepend-inner="button"
													:menu-props="{ bottom: true, offsetY: true }"
													@change="paymentMethodSelectFunc">
													<template v-slot:selection="{ item }">
														{{ item.name + " (" + item.routing + ")" }}
													</template>
													<template v-slot:item="{ item }">
														<div class="ach-pm-selection mt-4">
															<p class="account-holder-name mb-1">
																{{ item.name }}
															</p>
															<p class="account-details">
																Account:
																{{ item.account }}
															</p>
														</div>
													</template>
													<template v-slot:append-item>
														<v-divider class="mb-2"></v-divider>
														<v-list-item>
															<v-list-item-content>
																<v-list-item-title>
																	<a @click="handleManagePayment"
																		class="d-flex align-center text-decoration-none">
																		<img src="../../../assets/icons/plus.svg"
																			class="mr-2" width="12px" height="12px"
																			alt="" />
																		<span>New Payment Method</span>
																	</a>
																</v-list-item-title>
															</v-list-item-content>
														</v-list-item>
													</template>
												</v-select>
											</div>
											<div v-if="feeSettingCheck.is_fee_for_overdue == 1" class="courtesy">
												<img src="../../../assets/icons/InfoFilled.svg" alt="" width="20px"
													height="20px" class="mr-3" />
												<span class="courtesy-text">As a courtesy of Shifl, No processing fee is
													applied when you pay
													within the terms (except for the Duties).</span>
											</div>
										</div>
									</v-col>
									<v-col v-show="getErrorMessage == true" cols="12" sm="12">
										<label>
											<div class="red lighten-5 caption red--text d-flex align-center pa-2">
												<div class="d-flex">
													<img src="../../../assets/icons/alert.svg" alt="" width="16px"
														height="16px" class="mr-3" />
												</div>
												<span>Sorry! We could not complete the payment process.
													Please try again or try a different payment
													method.</span>
											</div>
										</label>
									</v-col>
									<v-col v-if="getMakePaymentData.isMobile" class="subtotal-total-count-invoice">
										<div class="sub-total-main">
											<h6 style="text-align: left;" class="subtotal">Subtotal</h6>
											<p style="text-align: right;" class="subtotal mb-0">
												{{ currencyNumberFormat(computedTotal) }}
											</p>
										</div>
										<div class="processing-fee-main" v-if="countDuties() > 0 && paymentMethodsSelected !== 'ACH'">
											<div class="processing-fee-tooltip">
												<p class="mb-0">Processing Fee</p>
												<p class="mb-0" style="font-weight: 600;">
													{{
														paymentMethodsSelected !== "ACH"
														? currencyNumberFormat(
															countProcessingFee()
														)
														: currencyNumberFormat(0)
													}}


												</p>
												<!-- <v-tooltip right>
													<template v-slot:activator="{ on, attrs }">
														<v-icon color="grey-lighten-1" v-bind="attrs"
															v-on="on">
															mdi-information-outline
														</v-icon>
													</template>
													3.2% Processing Fee Will Be Charged For CC
													payments
												</v-tooltip> -->
												
											</div>
											<div class="action-btn">
												<span class="processing-fee">3.2% of {{currencyNumberFormat(countProcessingFee())}}
												</span>
												<button v-if="paymentMethodsSelected != 'ACH'"
													@click="showProcessingFeeDiv = !showProcessingFeeDiv; overlay = true"
													class="caption" style="color:#1A6D9E">
													<v-icon v-if="!showProcessingFeeDiv"
														style="font-size:20px;color:#1A6D9E">mdi-chevron-down</v-icon>
													<v-icon v-else
														style="font-size:20px;color:#1A6D9E">mdi-chevron-up</v-icon>
													Show Details
												</button>
											</div>
										</div>
										
										<div class="total-mount-main">
											<div>
												<h6 class="total-payment">Total Payment</h6>
											</div>
											<div>
												<span v-if="!Array.isArray(getMakePaymentData.editedItemData) &&
													!isNumeric(getMakePaymentData.editedItemData)"
												>
													{{
														currencyNumberFormat(
															totalPaymentAmount(getMakePaymentData.editedItemData)
														)
													}}
												</span>
												<span class="amount ml-2"
													v-if="Array.isArray(getMakePaymentData.editedItemData)">
													{{
														currencyNumberFormat(
															totalPaymentAmount(multiplePaymentData)
														)
													}}
												</span>
												<span class="amount ml-2"
													v-if="isNumeric(getMakePaymentData.editedItemData)">{{
														currencyNumberFormat(
															totalPaymentAmount(getMakePaymentData.editedItemData)
														)
													}}
												</span>
											</div>
										</div>
									</v-col>
									<v-col class="d-flex align-end justify-space-between action-button">
										<div class="payments-button d-flex align-center">
											<button :style="!getMakePaymentData.isMobile ? '' : 'width: 100% !important;'"
												class="btn-blue mr-2" @click="save(getMakePaymentData.editedItemData)" v-if="!Array.isArray(getMakePaymentData.editedItemData) &&
													!isNumeric(getMakePaymentData.editedItemData)
													" :disabled="getPaymentLoading === true ||
		makePaymentSingleDisable ||
		btnDisabled
		">
												<span v-if="!getPaymentLoading">Make Payment</span>
												<span v-if="getPaymentLoading">Processing...</span>
											</button>

											<button class="btn-blue mr-2"
												:style="!getMakePaymentData.isMobile ? '' : 'width: 100% !important;'"
												@click="multipleInvoicesPayment(getMakePaymentData.editedItemData)"
												v-if="Array.isArray(getMakePaymentData.editedItemData)" :disabled="getPaymentLoading === true ||
													makePaymentSingleDisable ||
													btnDisabled
													">
												<span v-if="!getPaymentLoading">Make Payment</span>
												<span v-if="getPaymentLoading">Processing...</span>
											</button>

											<button :style="!getMakePaymentData.isMobile ? '' : 'width: 100% !important;'"
												class="btn-blue mr-2"
												@click="clearAllduePayment(getMakePaymentData.editedItemData)"
												v-if="isNumeric(getMakePaymentData.editedItemData)" :disabled="getPaymentLoading === true ||
													makePaymentSingleDisable ||
													btnDisabled
													">
												<span v-if="!getPaymentLoading">Make Payment</span>
												<span v-if="getPaymentLoading">Processing...</span>
											</button>

											<button v-if="!getMakePaymentData.isMobile" class="btn-white" @click="close"
												:disabled="getPaymentLoading === true">
												Cancel
											</button>
										</div>
									</v-col>
								</v-row>
							</div>
						</div>
					</div>
				</v-card-text>
			</v-form>
			
		</v-card>
		<ConfirmDialog :dialogData.sync="paymentSuccessfullDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/checkMark-green.svg" alt="alert">
				</div>
			</template>
			
			<template v-slot:dialog_title>
				<h2> Payment Successful </h2>
			</template>

			<template v-slot:dialog_content>
				<p class=""> 
					<span>Your payment has been successfully processed. 
					You can keep track of your latest payment updates in the </span> 
					<button
						@click="paymentSuccessfullFunc"
						text 
						style="color:#1A6D9E;font-size:14px;text-transform: lowercase;height: 20px"
					>
						payment history
					</button>.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="paymentSuccessfullFunc" text>
                    Understood
				</v-btn>

				<v-btn class="btn-white" :disabled="getInvoiceRecepitLoadingStatus" text @click="downloadReceipt">
					Download Receipt
				</v-btn>
			</template>
		</ConfirmDialog>
		<ConfirmDialog :dialogData.sync="paymentDeclinedDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete-red.svg" alt="alert">
				</div>
			</template>
			
			<template v-slot:dialog_title>
				<h2> Payment Declined </h2>
			</template>

			<template v-slot:dialog_content>
				<p> 
					We couldn’t process your payment. Please ensure you have 
					sufficient balance/credit limit/permission to make the payment and try again.
					 Or, contact our <button @click="openFeedback = true;paymentDeclinedDialog = false" style="color:#1A6D9E">Support Team.</button> 
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="paymentRetryFunc(getMakePaymentData.editedItemData)" text>
                    Retry
				</v-btn>

				<v-btn class="btn-white" text @click="paymentDeclinedDialog = false">
					Cancel
				</v-btn>
			</template>
		</ConfirmDialog>
		<ConfirmDialog :dialogData.sync="AddpaymentDesktopDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert">
				</div>
			</template>
			
			<template v-slot:dialog_title>
				<h2> Add Payment Method </h2>
			</template>

			<template v-slot:dialog_content>
				<p> 
					You need to add payment method in order to make any payment through our 
					platform. Any information related to payment method will only be used for transaction purpose only.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="AddpaymentMethodsDesktopDialog = true;AddpaymentDesktopDialog = false" text>
                    Add Payment Method
				</v-btn>

				<v-btn class="btn-white" text @click="AddpaymentDesktopDialog = false">
					Cancel
				</v-btn>
			</template>

		</ConfirmDialog>
		<ConfirmDialog :dialogData.sync="AddpaymentMethodsDesktopDialog">
			<template v-slot:dialog_title>
				<h2> Payment Methods </h2>
			</template>

			<template v-slot:dialog_content>
				<p> 
					Streamline your transactions and enjoy seamless payments by adding a payment method.
				</p>
				<div>
					<v-radio-group
						hide-details v-model="paymentMethodsSelected"
						@change="paymentMethodSelectFunc"
					>
						<v-radio 
							value="CC"
							class="pb-5">
							<template v-slot:label>
								<div class="d-flex">
									<div class="pr-4 pl-2">
										<img style="width:56px;height:40px" src="@/assets/icons/creditCard.svg" alt="">
									</div>
									<div>
										<h6 class="" style="color:#253041;font-size: 14px;font-weight: 600;">
											Credit Card
										</h6>
										<p class="mb-0" style="color:#253041;font-size: 12px;font-weight: 400;line-height: 18px;">
											Fast & Safe Transaction from anywhere
										</p>
									</div>
								</div>
							</template>
						</v-radio>
						<v-radio 
							value="ACH"
							class=""
						>
							<template v-slot:label>
								<div class="d-flex ">
									<div class="pl-3 pr-6">
										<img style="width:46px;height:49px" src="@/assets/icons/achnew.svg" alt="">
									</div>
									<div>
										<h6 class="mt-1" style="color:#253041;font-size: 14px;font-weight: 600;">
											ACH
										</h6>
										<p class="mb-0" style="color:#253041;font-size: 12px;font-weight: 400;line-height: 18px;">
											Connect your bank & pay with your debit card
										</p>
									</div>
								</div>
							</template>
						</v-radio>
					</v-radio-group>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn @click="paymentMethodDialog = true;AddpaymentMethodsDesktopDialog = false" class="btn-blue" text>
                    Add Payment Method
				</v-btn>

				<v-btn class="btn-white" text @click="AddpaymentMethodsDesktopDialog = false">
					Cancel
				</v-btn>
			</template>

		</ConfirmDialog>
		<AddPaymentDialog
			:dialog.sync="paymentMethodDialog"
			:addMethodButtonDisabled="addMethodButtonDisabled"
			:buttonTextAdding="buttonTextAdding"
			:editedIndex.sync="editedIndex"
			:editedItemData.sync="editedItem"
			@close="closeAddPaymentDialogDesktop"
			@savePaymentMethod="savePaymentMethod"
			@isButtonDisabled="isButtonDisabled"
			:key="componentKey"
		/>
		<VerificationFailedDialog
			:failedDialog="failedDialog"
			@openPaymentMethodDialog="openPaymentMethodDialog"
			@close="closeFailedDialog"
		/>
		<FeedbackDialog
			:openFeedback="openFeedback"
			@closeDialog="openFeedback = false" />
		<!-- </v-dialog> -->
		<v-snackbar v-model="snackbarFlag" :timeout="snackbarTimeout">
			{{ snackbarMessage }}
		</v-snackbar>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../../../utils/globalMethods";
import ConfirmDialog from '../../Dialog/GlobalDialog/ConfirmDialog.vue'
import VerificationFailedDialog from "../../Dialog/VerificationFailedDialog.vue";
import VCreditCard from "../../VCreditCard/CreditCard.vue";
import AddPaymentDialog from "../../BillingComponents/Dialog/AddPaymentDialog.vue";
import FeedbackDialog from "@/components/FeedbackDialog.vue";
import _ from "lodash"

const translations = {
	name: {
		label: "Name on Card",
		placeholder: "Enter name",
	},
	card: {
		label: "Card Number",
		placeholder: "xxxx xxxx xxxx xxxx",
	},
	expiration: {
		label: "Expiry",
	},
	security: {
		label: "CVC",
		placeholder: "Enter CVC code",
	},
};

export default {
	name: "MakePaymentDialog",
	// props: [
	// 	"dialog",
	// 	"editedItemData",
	// 	"getcards",
	// 	"paymentDialog",
	// 	"default_customer_id",
	// 	"isMobile",
	// 	"fromComponent",
	// ],
	components: {
		ConfirmDialog,
		VCreditCard,
		VerificationFailedDialog,
		AddPaymentDialog,
		FeedbackDialog
	},
	mounted() {
		//set current page
		this.$store.dispatch("page/setPage", "billing");
		this.$refs.form && this.$refs.form.reset();
		this.paymentMethodSelectFunc();
		if (this.getMakePaymentData.isMobile === undefined || this.getMakePaymentData.isMobile === 'undefined') {
			this.$router.push({ name: "Billing" })
		}
		this.totalAMountValue = parseFloat(this.computedTotal).toFixed(2)
		this.computedTotalCopy = parseFloat(this.computedTotal).toFixed(2)
		this.editedItemDataCopy = _.cloneDeep(this.getMakePaymentData.editedItemData)
	},
	data: () => ({
		//Add Payment input dialog Data for mobile ui 
		// start
		value: "1234432112344321",
		label: "Credit Card",
		focus: false,
		translations,
		showCardImage: true,
		cardEdit: false,
		paymentData: {},
		creditCardDetails: [],

		// Managment Payment File
		buttonTextAdding: false,
		addMethodButtonDisabled: false,
		paymentMethodDialog: false,
		editedIndex: -1,
		componentKey: 0,
		editedItem: {
			card_number: "",
			expiration: "",
			cvc: "",
			name_on_card: "",
			country: "",
			default: false,
		},
		// endd

		// Desktop Dialog
		// start
		paymentSuccessfullDialog:false,
		paymentDeclinedDialog:false,
		isPaymentRetryFromSingleInvoice:false,
		AddpaymentDesktopDialog:false,
		AddpaymentMethodsDesktopDialog: false,
		// end

		failedDialog: false,

		overlaySuccess:false,
		overlayDeclined:false,
		overlayAddPayment:false,
		overlayAddPaymentMethods: false,
		overlayPaymentMethodAdded:false,
		overlayAddPaymentInputMethods:false,

		absoluteSuccess:true,
		absoluteDeclined:true,
		absoluteAddPayment:true,
		absolutePaymentMethodAdded:true,
		absoluteAddPaymentMethods: true,
		absoluteAddPaymentInputMethods:true,

		successDialog:false,
		declinedDialog:false,
		AddPaymentDialog:false,
		paymentMethodAddedDialog:false,
		AddpaymentMethodsDialog: false,
		AddpaymentInputMethodDialog:false,

		card_id: 0,
		ACH_id: 0,
		valid: true,
		getErrorMessage: false,
		snackbarTimeout: 5000,
		snackbarFlag: false,
		snackbarMessage: "Paid invoices are excluded from the selection.",
		paymentMethodsSelected: "CC",
		makePaymentSingleDisable: true,
		makePaymentMultipleDisable: true,
		makePaymentClearAllDisable: true,
		includingDutyFees: true,
		btnDisabled: false,
		prefix: '$',
		showProcessingFeeDiv: false,
		absolute: true,
		overlay: false,
		totalAMountValue: '',
		totalAMountValueCopy: '',
		computedTotalCopy: '',
		editedItemDataCopy: [],
		openFeedback: false,
		qbo_payment_id:''
	}),
	computed: {
		...mapGetters([
			"getUser",
			"getPaymentLoading",
			"getMultiplePaymentLoading",
			"getAllInvoices",
			"getShipmentBills",
			"getMakePaymentData",
			"getFeeSettings",
			"getcards",
			"getInvoiceRecepitLoadingStatus"
		]),
		defaultCustomerId: {
			get() {
				let customer_company_id = "";
				if (typeof this.getUser === "string") {
					customer_company_id = JSON.parse(this.getUser).default_customer_id;
				} else {
					customer_company_id = this.getUser.default_customer_id;
				}
				return customer_company_id;
			},
			set() {
				return ""
			}
        },
		dialogAdd: {
			get() {
				return this.dialog;
			},
			set(value) {
				this.$emit("update:dialog", value);
			},
		},
		feeSettingCheck() {
			if (this.getFeeSettings.is_fee_for_overdue !== undefined && typeof this.getFeeSettings.is_fee_for_overdue !== 'undefined') {
				return this.getFeeSettings
			}
			return {}
		},
		items() {
			if (this.getcards.cards !== undefined) {
				const paymentCard = this.getMakePaymentData.getcards.cards.map((item) => {
					return {
						id: item.id,
						number_masked: this.ccNumberFormat(
							item.number_masked,
							"************"
						),
						card_type: item.type.toLowerCase(),
					};
				});
				return paymentCard;
			} else {
				return false;
			}
		},
		ACHItems() {
			if (this.getMakePaymentData.getcards.ACHMethods !== undefined) {
				const ACHpaymentCard = this.getMakePaymentData.getcards.ACHMethods.map((item) => {
					return {
						id: item.id,
						name: item.name,
						routing: this.achRouteFormat(item.routing, "*****"),
						account: this.achAccountFormat(item.account, "*****"),
					};
				});
				return ACHpaymentCard;
			} else {
				return false;
			}
		},
		multiplePaymentData() {
			let isMultiple = Array.isArray(this.getMakePaymentData.editedItemData);
			let multipleInvoiceData = {
				invoice_names: " ",
				total_amount: 0,
				total_balance_due: 0,
				total_duty_amount: this.countDuties(),
				processing_fee: this.countProcessingFee(),
			};

			if (isMultiple) {
				this.getMakePaymentData.editedItemData.forEach((item) => {
					multipleInvoiceData.invoice_names = `${multipleInvoiceData.invoice_names} ,${item.invoice_number}`;
					multipleInvoiceData.total_amount = this.twoDecimalFormat(
						Number(multipleInvoiceData.total_amount) + Number(item.amountNew)
					);
					multipleInvoiceData.total_balance_due = this.twoDecimalFormat(
						Number(multipleInvoiceData.total_balance_due) + Number(item.balance)
					);
				});
			}
			return multipleInvoiceData;
		},
		snackbarFlagAction() {
			return Array.isArray(this.getMakePaymentData.editedItemData);
		},
		invoiceProcessingFee() {
			if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				return this.getMakePaymentData.editedItemData?.filter(val => {
					if (val.is_duty_invoice) {
						return val
					} else {
						if (val.total_duty_amount > 0) {
							return val
						}else if(this.feeSettingCheck.is_fee_for_overdue == 1 && (this.countDaysOfOverDue(val) > this.feeSettingCheck.overdue_days)){
							return val
						}
					}
				})
			} else {
				if(this.getMakePaymentData.editedItemData.is_duty_invoice == true || this.getMakePaymentData.editedItemData.total_duty_amount > 0){
					return this.getMakePaymentData.editedItemData
				}else if(this.feeSettingCheck.is_fee_for_overdue == 1 && (this.countDaysOfOverDue(this.getMakePaymentData.editedItemData) > this.feeSettingCheck.overdue_days)){
					return this.getMakePaymentData.editedItemData
				}else{
					return null
				}

			}

		},
		checkProcessingFeeShow(){
			if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				return this.invoiceProcessingFee?.length > 0 ? 1 : 0
			}else {
				return typeof this.invoiceProcessingFee === 'object' && this.isNotEmptyProcessingFee(this.invoiceProcessingFee) ? 1 : 0
			}
			
		},
		computedSubTotal() {
			let subTotalAmount = 0;

			// Iterate over all items in the array and calculate the subtotal
			if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				this.getMakePaymentData.editedItemData.forEach((multiplePaymentInvoice) => {
					// Your existing countSubTotal logic with the modified function
					subTotalAmount += this.financial(this.countSubTotal(multiplePaymentInvoice));
				});
			} else {
				subTotalAmount += this.financial(this.countSubTotal(this.getMakePaymentData.editedItemData));
			}

			return subTotalAmount;
		},
		computedTotal() {
			let totalAmount = 0
			if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				this.getMakePaymentData.editedItemData.forEach((multiplePaymentInvoice) => {
					// Your existing countSubTotal logic with the modified function
					totalAmount += this.financial(this.countAmountTotal(multiplePaymentInvoice));
				});
			} else {
				totalAmount += this.financial(this.countAmountTotal(this.getMakePaymentData.editedItemData));
			}
			return this.financial(totalAmount);
		}
	},
	watch: {

		snackbarFlag: function () {
			this.snackbarFlag;
		},
		snackbarFlagAction(newValue) {
			this.snackbarFlag = newValue;
		},

		paymentMethodsSelected: function () {
			if (
				this.paymentMethodsSelected == null ||
				this.paymentMethodsSelected == "" ||
				this.paymentMethodsSelected == undefined
			) {
				this.paymentMethodsSelected = "CC";
				this.card_id = 0;
				this.ACH_id = 0;
			} else {
				this.paymentMethodsSelected;
			}
		},
		paymentMethodsSelectedAction(newValue) {
			this.paymentMethodsSelected = newValue;
		},
	},
	methods: {
		...mapActions({
			makePayment: "makePayment",
			fetchInvoices: "fetchInvoices",
			addPaymentMethod: "addPaymentMethod",
			fetchPaymentMethods: "fetchPaymentMethods",
			downloadInvoice: "downloadInvoice",
			downloadRecepitApi: "downloadRecepitApi"
		}),
		...globalMethods,
		isNotEmptyProcessingFee(obj) {
			if (obj === null || obj === undefined) {
				return false
			}
			if('invoice_number' in obj) {
				return true
			}
			return false
		},
		async download(item) {
			console.log("download",item)
			if (item?.id) {
				try {
					await this.downloadInvoice(item);
				}catch(e){
					this.notificationError(e)
				}
			} 
			// else if (Array.isArray(this.selected)) {
			// 	this.selected.forEach(async (id) => {
			// 		await this.downloadInvoice(id);
			// 	});
			// }
		},
		//Todo:(Usman): Add Payment Method for mobile ui (same as AddPaymentDialog file..)
		// start
		creditInfoChanged(values) {
			this.addMethodButtonDisabled = true;
			const name = values.name;
			const cardNumber = values.cardNumber.replace(/ /g, ""); //removed space
			const expiration = values.expiration.replace("/", ""); //remove slash
			const security = values.security;

			if (
				name != "" &&
				cardNumber != "" &&
				expiration != "" &&
				security != "" &&
				cardNumber.length >= 12 &&
				cardNumber.length <= 19 &&
				expiration.length == 6 &&
				(security.length == 3 || security.length == 4)
			) {
				this.addMethodButtonDisabled = false;
				const expirationData = values.expiration.split("/");
				this.creditCardDetails["cardHolder_first_name"] = name;
				this.creditCardDetails["number"] = cardNumber;
				this.creditCardDetails["expiration_month"] = expirationData[0];
				this.creditCardDetails["expiration_year"] = expirationData[1];
				this.creditCardDetails["cv_data"] = security;
			}
			values.name = "";
			values.cardNumber = "";
			values.expiration = "";
			values.security = "";
		},
		defaultPaymentMethod(event) {
			this.creditCardDetails["is_default"] = event ? 1 : 0;
		},
		async savePaymentMethod(payload_from) {
			this.buttonTextAdding = true;
			let payload = {}
			if(this.getMakePaymentData.isMobile){
				payload = {
					cardHolder_first_name: this.creditCardDetails.cardHolder_first_name,
					number: this.creditCardDetails.number,
					expiration_month: this.creditCardDetails.expiration_month,
					expiration_year: this.creditCardDetails.expiration_year,
					cv_data: this.creditCardDetails.cv_data,
					default_customer_id: this.defaultCustomerId,
					is_default: this.creditCardDetails.is_default
					? this.creditCardDetails.is_default
					: 0,
				};
			}else{
				payload = {
					cardHolder_first_name: payload_from.cardHolder_first_name,
					number: payload_from.number,
					expiration_month: payload_from.expiration_month,
					expiration_year: payload_from.expiration_year,
					cv_data: payload_from.cv_data,
					default_customer_id: this.defaultCustomerId,
					is_default: payload_from.is_default
					? payload_from.is_default
					: 0,
				}
			}
			const response = await this.addPaymentMethod(payload);
			if (response.data.code !== 200) {
				this.failedDialog = true;
			} else {
				this.fetchPaymentMethods(this.defaultCustomerId);
				setTimeout(() => {
					this.notificationMessage(
						"Payment method have been added successfully"
					);
				}, 1000);
				if(this.getMakePaymentData.isMobile){
				this.overlayPaymentMethodAdded = true;
				this.paymentMethodAddedDialog = true;
				}
				this.close();
			}
			this.buttonTextAdding = false;
		},
		closePaymentInputDialog(){
			this.$refs.addPaymentInputform.resetValidation();
			this.AddpaymentInputMethodDialog = false,
			this.overlayAddPaymentInputMethods = false
		},
		openPaymentMethodDialog(){
			this.componentKey += 1;
			if(this.getMakePaymentData.isMobile){
			this.AddpaymentInputMethodDialog = false;
			this.overlayAddPaymentInputMethods = false;
			this.overlayAddPaymentMethods = true;
			this.AddpaymentMethodsDialog = true;
			}else{
				this.paymentMethodDialog = true
			}
			this.failedDialog = false;
		},
		closeFailedDialog() {
			this.failedDialog = false;
		},
		// endd
		closeAddPaymentDialogDesktop() {
			this.paymentMethodDialog = false;
	
		},
		isButtonDisabled(flag) {
			this.addMethodButtonDisabled = flag;
		},
		openPaymentInputDialog(){
			this.addMethodButtonDisabled = true;
			this.AddpaymentInputMethodDialog = true;
			this.absoluteAddPaymentInputMethods = true;
			this.overlayAddPaymentInputMethods = true;
			this.AddpaymentMethodsDialog = false;
			this.absoluteAddPaymentMethods = false;
			this.overlayAddPaymentMethods = false;
		},
		gotoPreviousScreen() {
			this.$router.push({ name: 'Billing' })
		},
		gotoPreviousScreenPayment() {
			this.paymentMethodAddedDialog = false;
			this.overlayPaymentMethodAdded = false;
			this.$router.push({ name: 'Billing' })
		},
		recalculateProcessingFee(event,index) {
			if(event == '') {
				if (Array.isArray(this.getMakePaymentData.editedItemData)) {
					this.getMakePaymentData.editedItemData[index].amountNew = 0
					this.getMakePaymentData.editedItemData[index].selectCheckBox = false
				}else{
					this.getMakePaymentData.editedItemData.amountNew = 0;
					this.getMakePaymentData.editedItemData.selectCheckBox = false;
				}
			}else if(event > 0){
				if (Array.isArray(this.getMakePaymentData.editedItemData)) {
					this.getMakePaymentData.editedItemData[index].selectCheckBox = true
				}else{
					this.getMakePaymentData.editedItemData.selectCheckBox = true;
				}
			}
			this.countProcessingFee();
			this.totalAMountValue = this.computedTotal
		},
		handleCheckboxChange(isCheckBoxActive,index) {
			if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				if(!isCheckBoxActive){
					this.getMakePaymentData.editedItemData[index].amountNew = 0.00;
				}else{
					this.getMakePaymentData.editedItemData[index].amountNew = this.getMakePaymentData.editedItemData[index].balance
				}
			}else{
				if(!isCheckBoxActive){
					this.getMakePaymentData.editedItemData.amountNew = 0.00;
				}else{
					this.getMakePaymentData.editedItemData.amountNew = this.getMakePaymentData.editedItemData.balance
				}
			}
			
			this.totalAMountValue = this.computedTotal
		},
		financial(value) {
			return parseFloat(parseFloat(value).toFixed(2))
		},
		handleUpdateTotalAmount(event) {

			let value = event.target.value
			if (value === '.' || value.endsWith('.') || value.endsWith('-')) return;
			if (isNaN(value) || value == '') value = 0;
			let newValue = this.financial(value);
			let newComputedTotal = parseFloat(this.computedTotal)
			const newTotal = this.financial(newComputedTotal);

			if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				let sortedArrayDecrease = [...this.getMakePaymentData.editedItemData].sort((a, b) => new Date(b.due_date) - new Date(a.due_date));
				let difference = newTotal - newValue;
				difference = this.financial(difference)
				if (newValue < newTotal) {

					for (let i = 0; i < sortedArrayDecrease.length; i++) {
						if (sortedArrayDecrease[i].selectCheckBox) {
							let currentAmount = this.financial(sortedArrayDecrease[i].amountNew)

							if (difference <= currentAmount) {
								currentAmount = this.financial(this.financial(currentAmount) - this.financial(difference));
								sortedArrayDecrease[i].amountNew = this.financial(currentAmount);
								break;
							} else {
								difference = this.financial(this.financial(difference) - this.financial(currentAmount));
								currentAmount = 0;
								sortedArrayDecrease[i].amountNew = this.financial(currentAmount);
							}
						}
						if(sortedArrayDecrease[i].amountNew == 0 && sortedArrayDecrease[i].selectCheckBox){
							sortedArrayDecrease[i].selectCheckBox = false
						}
					}
					if(newValue == 0){
							sortedArrayDecrease[sortedArrayDecrease.length - 1].selectCheckBox = false
						}

				} else {
					let sortedArrayIncrease = [...this.getMakePaymentData.editedItemData].sort((a, b) => new Date(a.due_date) - new Date(b.due_date)); // Sort from newest to oldest
					let differenceTwo = newValue - newTotal;
					differenceTwo = this.financial(differenceTwo);

					for (let i = 0; i < sortedArrayIncrease.length; i++) {
						if((sortedArrayIncrease[i].amountNew !== sortedArrayIncrease[i].balance) && !sortedArrayIncrease[i].selectCheckBox){
							sortedArrayIncrease[i].selectCheckBox = true
						}
						if (sortedArrayIncrease[i].selectCheckBox) {

							if (sortedArrayIncrease[i].amountNew < sortedArrayIncrease[i].balance) {

								const remainingSpace = this.financial(sortedArrayIncrease[i].balance) - this.financial(sortedArrayIncrease[i].amountNew);
								
								let toAdd = Math.min(remainingSpace, differenceTwo);
								toAdd = this.financial(toAdd)

								sortedArrayIncrease[i].amountNew += this.financial(toAdd);
								differenceTwo -= this.financial(toAdd);

								if (differenceTwo <= 0) break;
							}
						}	
					}
				}
			} else {
				
				if (newValue < this.computedTotalCopy) {
					if (newValue < newTotal) {

						let remainingAmount = newTotal - newValue;
						this.getMakePaymentData.editedItemData.amountNew -= this.financial(remainingAmount)
					} else {

						let remainingAmount = newValue - newTotal;
						this.getMakePaymentData.editedItemData.amountNew += this.financial(remainingAmount)
					}
				} else {

					let remainingAmount = this.computedTotalCopy
					this.getMakePaymentData.editedItemData.amountNew = this.financial(remainingAmount);
				}
				if(newValue == 0){
					this.getMakePaymentData.editedItemData.selectCheckBox = false
				}else if(newValue > 0){
					this.getMakePaymentData.editedItemData.selectCheckBox = true
				}
			}
			if (newValue > this.computedTotalCopy) return this.totalAMountValue = this.computedTotalCopy
		},
		onResize() {
			this.mobile = window.innerWidth < 769;
		},

		close(paidAmount) {
			this.$refs.form.resetValidation();
			this.$emit("close", paidAmount);
			this.getErrorMessage = false;
			this.makePaymentSingleDisable = true;
			this.paymentMethodsSelected = "CC";
			this.ACH_id = 0;
			this.card_id = 0;
			this.includingDutyFees = true;

			if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				this.getMakePaymentData.editedItemData.map(function (item) {
					item.amountNew = item.balance;
				});
			} else {
				this.getMakePaymentData.editedItemData.amountNew = this.getMakePaymentData.editedItemData.balance;
			}
			this.$router.push({ name: 'Billing' })

		},
		handleManagePayment() {
			if(!this.getMakePaymentData.isMobile){
				this.AddpaymentDesktopDialog = true;
			//this.$router.push(`settings/?tab=manage-payment-methods`);
			//this.$store.dispatch("page/setCurrentSettingsTab", 1);
		    }else{
				this.AddPaymentDialog = true;
				this.overlayAddPayment = true;
			}
			this.$refs.paymentMethodSelect.blur()

		},
		async multipleInvoicesPayment(item) {
			let isMultipleInvoices = Array.isArray(item);
			if (isMultipleInvoices) {
				let payableAmount = 0;
				item.forEach((item) => {
					const itemAmount = parseFloat(item.balance);
					return (payableAmount += itemAmount);
				});

				// var isAmountLessThanDutyAmount = false

				let invoices = this.getMakePaymentData.editedItemData.filter(function (val) {
					if (val.selectCheckBox) return val;
				}, this).map(item => {

					let process_duty = this.includingDutyFees ? true : false;
					let duty_payment_amount = 0;
					let total_amount = 0;


					if (process_duty && item.total_duty_amount > 0) {
						total_amount = item.amountNew;
						duty_payment_amount = item.total_duty_amount;
					} else {
						process_duty = false;
						total_amount = item.amountNew - item.total_duty_amount;
					}


					if(item.is_duty_invoice && (total_amount <= duty_payment_amount)){
					    duty_payment_amount = duty_payment_amount - (duty_payment_amount - total_amount )
				    }

				 
					return {
						id: item.id,
						amount: total_amount, // Change to input amount
						process_duty: process_duty,
						duty_amount: item.total_duty_amount, // total amount of duty
						duty_payment_amount: duty_payment_amount, // payment amount for duty
					}
				});
                       
				
				let ids = item.filter((item) => {
					if (item.selectCheckBox) return item.id
				}).map(item => item.id);

				const parms = {
					card_id: this.card_id,
					ACH_id: this.ACH_id,
					default_customer_id: this.getMakePaymentData.default_customer_id,
					invoice_ids: ids,
					all: false,
					process_duty: this.includingDutyFees ? true : false,
					invoices: invoices,
					amount_to_pay: this.computedTotal
				};
				

				const response = await this.makePayment(parms);
				if (response && response.data && response.data.status === "success") {
					console.log("res multtt",response)
					this.qbo_payment_id = response.data.data.qbo_payment_id
					this.fetchInvoices();
					// setTimeout(() => {
					// 	this.$emit("paymentDialog", {
					// 		invoices: [...item],
					// 		paid: true,
					// 		isMultiple: true,
					// 		isSingle: false,
					// 		clearAll: false,
					// 	});
					// }, 1000);
					// this.close(payableAmount);
					if(this.getMakePaymentData.isMobile){
					    this.overlaySuccess= true;
					    this.successDialog= true;
					}else{
					    this.paymentSuccessfullDialog = true;
					}
				} else {
					setTimeout(() => {
						this.getErrorMessage = true;
						if(this.getMakePaymentData.isMobile){
						    this.declinedDialog =true;
						    this.overlayDeclined = true;
						}else{
							this.isPaymentRetryFromSingleInvoice = false;
							this.paymentDeclinedDialog = true;
						}
					}, 1000);
				}
			}
		},
		// async clearAllduePayment(item) {
		// 	if (this.isNumeric(item)) {
		// 		const parms = {
		// 			card_id: this.card_id,
		// 			ACH_id: this.ACH_id,
		// 			default_customer_id: this.default_customer_id,
		// 			invoice_ids: true,
		// 			all: true,
		// 			process_duty: this.includingDutyFees ? true : false,
		// 		};

		// 		const response = await this.makePayment(parms);
		// 		if (response && response.data && response.data.status === "success") {
		// 			this.fetchInvoices();
		// 			setTimeout(() => {
		// 				this.$emit("paymentDialog", {
		// 					amount: this.currencyNumberFormat(this.twoDecimalFormat(item)),
		// 					paid: true,
		// 					isMultiple: false,
		// 					isSingle: false,
		// 					clearAll: true,
		// 				});
		// 			}, 1000);
		// 			this.close(this.twoDecimalFormat(item));
		// 		} else {
		// 			setTimeout(() => {
		// 				this.getErrorMessage = true;
		// 			}, 1000);
		// 		}
		// 	}
		// },
		async save(item) {
			if (this.getMakePaymentData.editedItemData.id) {
				const invoice_ids = [this.getMakePaymentData.editedItemData.id];

				let process_duty = this.includingDutyFees ? true : false;
				let duty_payment_amount = 0;
				let total_amount = 0;

				if (process_duty && this.getMakePaymentData.editedItemData.total_duty_amount > 0) {
					total_amount = this.getMakePaymentData.editedItemData.amountNew;
					duty_payment_amount = this.getMakePaymentData.editedItemData.total_duty_amount;
				} else {
					process_duty = false;
					total_amount = this.getMakePaymentData.editedItemData.amountNew - this.getMakePaymentData.editedItemData.total_duty_amount;
				}


			
                if(this.getMakePaymentData.editedItemData.is_duty_invoice && (total_amount <= duty_payment_amount)){
					duty_payment_amount = duty_payment_amount - (duty_payment_amount - total_amount )
				}

			

				let invoices = [
					{
						id: this.getMakePaymentData.editedItemData.id,
						amount: total_amount,
						process_duty: process_duty,
						duty_amount: this.getMakePaymentData.editedItemData.total_duty_amount, // total amount of duty
						duty_payment_amount: duty_payment_amount, // payment amount for duty
					}
				];



				const parms = {
					card_id: this.card_id,
					ACH_id: this.ACH_id,
					default_customer_id: this.getMakePaymentData.default_customer_id,
					invoice_ids: invoice_ids,
					all: false,
					process_duty: process_duty,
					invoices: invoices,
					amount_to_pay: this.computedTotal
				};


				console.log(parms)
				console.log(item)

				const response = await this.makePayment(parms);
				if (response && response.data && response.data.status === "success") {
					console.log("save response",response)
					this.qbo_payment_id = response.data.data.qbo_payment_id
					this.fetchInvoices();
					setTimeout(() => {
						
						if(this.getMakePaymentData.isMobile){
							this.overlaySuccess= true;
					        this.successDialog= true;
						}else{
							this.paymentSuccessfullDialog = true;
						}
						
					}, 1000);
				
				} else {
					setTimeout(() => {
						this.getErrorMessage = true;
						if(this.getMakePaymentData.isMobile){
						    this.declinedDialog =true;
						    this.overlayDeclined = true;
						}else{
							this.isPaymentRetryFromSingleInvoice = true;
							this.paymentDeclinedDialog = true;
						}
					}, 1000);
				}
			}
		},
		paymentSuccessfullFunc(){
			this.paymentSuccessfullDialog = false;
			this.qbo_payment_id = ''
			this.gotoPreviousScreen()
		},
		async downloadReceipt(){
			console.log("download Receipt")
			try{
				let payload = {
					qbo_payment_id:this.qbo_payment_id,
					customer_id:this.defaultCustomerId
				}
				await this.downloadRecepitApi(payload)
				// this.qbo_payment_id = ''
			}catch(e){
				this.notificationError(e)
			}
		},
		paymentRetryFunc(item){
           if(this.isPaymentRetryFromSingleInvoice){
			    this.save(item)
		   }else{
			    this.multipleInvoicesPayment(item)
		   }
		},
		paymentMethodSelectFunc() {
			this.makePaymentSingleDisable = true;
			if (this.paymentMethodsSelected == "CC" && this.card_id > 0) {
				this.makePaymentSingleDisable = false;
				this.ACH_id = 0;
			}
			if (this.paymentMethodsSelected == "ACH" && this.ACH_id > 0) {
				this.makePaymentSingleDisable = false;
				this.card_id = 0;
			}
		},
		getImgUrl(pic) {
			if (pic !== undefined && pic !== null) {
				return require(`../../../assets/icons/${pic}.svg`);
			} else {
				return require("../../../assets/icons/payment-icon.svg");
			}
		},
		// countSubTotal() {
		// 	let subTotalAmount = 0;
		// 	if (
		// 		!Array.isArray(this.getMakePaymentData.editedItemData) &&
		// 		!this.isNumeric(this.getMakePaymentData.editedItemData)
		// 	) {
		// 		// subTotalAmount =
		// 		// 	parseFloat(this.getMakePaymentData.editedItemData.balance) -
		// 		// 	parseFloat(this.getMakePaymentData.editedItemData.total_duty_amount);
		// 		subTotalAmount =
		// 			parseFloat(this.getMakePaymentData.editedItemData.balance) -
		// 			0;

		// 	} else if (Array.isArray(this.getMakePaymentData.editedItemData)) {
		// 		subTotalAmount == 0 &&
		// 			this.getMakePaymentData.editedItemData.forEach((item) => {
		// 				subTotalAmount += parseFloat(item.balance);
		// 			});
		// 		// let duties = this.countDuties();

		// 		subTotalAmount = subTotalAmount - 0;
		// 	} else if (this.isNumeric(this.getMakePaymentData.editedItemData)) {
		// 		let invoices =
		// 			this.getMakePaymentData.fromComponent === "Billing"
		// 				? this.getAllInvoices
		// 				: this.getShipmentBills;
		// 		subTotalAmount === 0 &&
		// 			invoices.forEach((item) => {
		// 				item.paid === false
		// 					? (subTotalAmount += parseFloat(item.balance))
		// 					: 0;
		// 			});
		// 		// subTotalAmount = subTotalAmount - this.countDuties();
		// 		subTotalAmount = subTotalAmount - 0;

		// 	}

		// 	return subTotalAmount;
		// },
		countSubTotal(multiplePaymentInvoice) {
			let subTotalAmount = 0;

			if (!Array.isArray(multiplePaymentInvoice) && !this.isNumeric(multiplePaymentInvoice)) {
				// Case 1: When multiplePaymentInvoice is not an array and not numeric
				if (multiplePaymentInvoice.selectCheckBox) {
					subTotalAmount = this.financial(multiplePaymentInvoice.balance) - 0;
				}
			} else if (Array.isArray(multiplePaymentInvoice)) {
				// Case 2: When multiplePaymentInvoice is an array
				subTotalAmount == 0 && multiplePaymentInvoice.forEach((item) => {
					if (item.selectCheckBox) {
						subTotalAmount += this.financial(item.balance);
					}
				});
				subTotalAmount = subTotalAmount - 0;
			} else if (this.isNumeric(multiplePaymentInvoice)) {
				// Case 3: When multiplePaymentInvoice is numeric
				let invoices = this.getMakePaymentData.fromComponent === "Billing" ?
					this.getAllInvoices : this.getShipmentBills;
				subTotalAmount === 0 && invoices.forEach((item) => {
					if (item.selectCheckBox && item.paid === false) {
						subTotalAmount += this.financial(item.balance);
					}
				});
				subTotalAmount = subTotalAmount - 0;
			}
			return subTotalAmount;
		},
		// countAmountTotal() {
		// 	let amountTotalAmount = 0;
		// 	if (
		// 		!Array.isArray(this.getMakePaymentData.editedItemData) &&
		// 		!this.isNumeric(this.getMakePaymentData.editedItemData)
		// 	) {
		// 		amountTotalAmount =
		// 			parseFloat(this.getMakePaymentData.editedItemData.amount);

		// 	} else if (Array.isArray(this.getMakePaymentData.editedItemData)) {
		// 		amountTotalAmount == 0 &&
		// 			this.getMakePaymentData.editedItemData.forEach((item) => {
		// 				amountTotalAmount += parseFloat(item.amount);
		// 			});
		// 		// let duties = this.countDuties();

		// 		amountTotalAmount = amountTotalAmount - 0;
		// 	} else if (this.isNumeric(this.getMakePaymentData.editedItemData)) {
		// 		let invoices =
		// 			this.getMakePaymentData.fromComponent === "Billing"
		// 				? this.getAllInvoices
		// 				: this.getShipmentBills;
		// 		amountTotalAmount === 0 &&
		// 			invoices.forEach((item) => {
		// 				item.paid === false
		// 					? (amountTotalAmount += parseFloat(item.amount))
		// 					: 0;
		// 			});
		// 		amountTotalAmount = amountTotalAmount - 0;
		// 	}

		// 	return amountTotalAmount;
		// },
		countAmountTotal(multiplePaymentInvoice) {
			let amountTotalAmount = 0;

			if (!Array.isArray(multiplePaymentInvoice) && !this.isNumeric(multiplePaymentInvoice)) {
				// Case 1: When multiplePaymentInvoice is not an array and not numeric
				if (multiplePaymentInvoice.selectCheckBox) {
					amountTotalAmount = this.financial(multiplePaymentInvoice.amountNew);
				}
			} else if (Array.isArray(multiplePaymentInvoice)) {
				// Case 2: When multiplePaymentInvoice is an array
				amountTotalAmount == 0 && multiplePaymentInvoice.forEach((item) => {
					if (item.selectCheckBox) {
						amountTotalAmount += this.financial(item.amountNew);
					}
				});
				amountTotalAmount = amountTotalAmount - 0;
			} else if (this.isNumeric(multiplePaymentInvoice)) {
				// Case 3: When multiplePaymentInvoice is numeric
				let invoices = this.getMakePaymentData.fromComponent === "Billing" ?
					this.getAllInvoices : this.getShipmentBills;
				amountTotalAmount === 0 && invoices.forEach((item) => {
					if (item.selectCheckBox && item.paid === false) {
						amountTotalAmount += this.financial(item.amountNew);
					}
				});
				amountTotalAmount = amountTotalAmount - 0;
			}
			return amountTotalAmount;
		},

		countTotalDue() {

			let dueTotalAmount = 0;

			dueTotalAmount = this.computedSubTotal - this.computedTotal;

			return dueTotalAmount;
		},
		countDuties() {
			let totalDutyAmount = 0;
			if (
				!Array.isArray(this.getMakePaymentData.editedItemData) &&
				!this.isNumeric(this.getMakePaymentData.editedItemData)
			) {
				totalDutyAmount = this.getMakePaymentData.editedItemData.total_duty_amount;
			} else if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				totalDutyAmount == 0 &&
					this.getMakePaymentData.editedItemData.forEach((item) => {
						totalDutyAmount += parseFloat(item.total_duty_amount);
					});
			} else if (this.isNumeric(this.getMakePaymentData.editedItemData)) {
				let invoices =
					this.getMakePaymentData.fromComponent === "Billing"
						? this.getAllInvoices
						: this.getShipmentBills;
				totalDutyAmount === 0 &&
					invoices.forEach((item) => {
						item.paid === false
							? (totalDutyAmount += parseFloat(item.total_duty_amount))
							: 0;
					});
			}
			return totalDutyAmount;
		},
		countProcessingFee() {
			let totalProcessingFee = 0;
			if (!Array.isArray(this.getMakePaymentData.editedItemData) && !this.isNumeric(this.getMakePaymentData.editedItemData)) {
				// Case 1: When editedItemData is not an array and not numeric
				if (this.getMakePaymentData.editedItemData.selectCheckBox) {
					if (this.getMakePaymentData.editedItemData.is_duty_invoice && parseInt(this.getMakePaymentData.editedItemData.total_duty_amount) == parseInt(this.getMakePaymentData.editedItemData.total_amount)) {
						if (this.getMakePaymentData.editedItemData.amountNew < this.getMakePaymentData.editedItemData.balance) {
							totalProcessingFee += (3.2 / 100) * parseFloat(this.getMakePaymentData.editedItemData.amountNew)
						} else {
							totalProcessingFee = parseFloat(this.getMakePaymentData.editedItemData.processing_fee);
						}
					} else if (this.getMakePaymentData.editedItemData.is_duty_invoice && parseInt(this.getMakePaymentData.editedItemData.total_duty_amount) !== parseInt(this.getMakePaymentData.editedItemData.total_amount)) {
						if (this.feeSettingCheck.is_fee_for_overdue == 1 && this.countDaysOfOverDue(this.getMakePaymentData.editedItemData) > this.feeSettingCheck.overdue_days) {
							totalProcessingFee += (3.2 / 100) * parseFloat(this.getMakePaymentData.editedItemData.amountNew)
						} else {
							if (this.getMakePaymentData.editedItemData.amountNew < this.getMakePaymentData.editedItemData.total_duty_amount) {
								totalProcessingFee += (3.2 / 100) * parseFloat(this.getMakePaymentData.editedItemData.amountNew)
							} else {
								totalProcessingFee += (3.2 / 100) * parseFloat(this.getMakePaymentData.editedItemData.total_duty_amount);
							}
						}
					} else {
						if (this.feeSettingCheck.is_fee_for_overdue == 1 && this.countDaysOfOverDue(this.getMakePaymentData.editedItemData) > this.feeSettingCheck.overdue_days) {
							totalProcessingFee += (3.2 / 100) * parseFloat(this.getMakePaymentData.editedItemData.amountNew)
						}else{
							totalProcessingFee += 0;
						}
					}

				}
			} else if (Array.isArray(this.getMakePaymentData.editedItemData)) {
				// Case 2: When editedItemData is an array
				totalProcessingFee == 0 && this.getMakePaymentData.editedItemData.forEach((item) => {
					if (item.selectCheckBox) {
						if (item.is_duty_invoice && parseInt(item.total_duty_amount) == parseInt(item.total_amount)) {
							if (item.amountNew < item.balance) {
								totalProcessingFee += (3.2 / 100) * parseFloat(item.amountNew)

							} else {
								totalProcessingFee += parseFloat(item.processing_fee);
							}
						} else if (item.is_duty_invoice && parseInt(item.total_duty_amount) !== parseInt(item.total_amount)) {
							if (this.feeSettingCheck.is_fee_for_overdue == 1 && this.countDaysOfOverDue(item) > this.feeSettingCheck.overdue_days) {
								totalProcessingFee += (3.2 / 100) * parseFloat(item.amountNew)
							} else {
								if (item.amountNew < item.total_duty_amount) {
									totalProcessingFee += (3.2 / 100) * parseFloat(item.amountNew)

								} else {
									totalProcessingFee += (3.2 / 100) * parseFloat(item.total_duty_amount);
								}
							}

						} else {
							if (this.feeSettingCheck.is_fee_for_overdue == 1 && this.countDaysOfOverDue(item) > this.feeSettingCheck.overdue_days) {
								totalProcessingFee += (3.2 / 100) * parseFloat(item.amountNew)
							} else {
								totalProcessingFee += 0;
							}
						}

					}
				});
			} else if (this.isNumeric(this.getMakePaymentData.editedItemData)) {
				// Case 3: When editedItemData is numeric
				let invoices = this.getMakePaymentData.fromComponent === "Billing" ?
					this.getAllInvoices : this.getShipmentBills;

				totalProcessingFee === 0 && invoices.forEach((item) => {
					if (item.selectCheckBox && item.paid === false) {
						if (this.getMakePaymentData.editedItemData.is_duty_invoice && parseInt(this.getMakePaymentData.editedItemData.total_duty_amount) !== parseInt(this.getMakePaymentData.editedItemData.total_amount)) {
							if (item.amountNew < item.balance) {
								if (!totalProcessingFee) return
								else totalProcessingFee += (3.2 / 100) * parseFloat(item.amountNew)
							} else {
								totalProcessingFee += parseFloat(item.processing_fee);
							}
						} else if (this.getMakePaymentData.editedItemData.is_duty_invoice && parseInt(this.getMakePaymentData.editedItemData.total_duty_amount) !== parseInt(this.getMakePaymentData.editedItemData.total_amount)) {
							if (item.amountNew < item.total_duty_amount) {
								if (!totalProcessingFee) return
								else totalProcessingFee += (3.2 / 100) * parseFloat(item.amountNew)
							} else {
								totalProcessingFee += (3.2 / 100) * parseFloat(item.total_duty_amount);
							}
						} else {
							totalProcessingFee += 0;
						}


					}
				});
			}
			return this.financial(totalProcessingFee);
		},
		
		// countProcessingFee() {
		// 	let totalProcessingFee = 0;
		// 	if (
		// 		!Array.isArray(this.getMakePaymentData.editedItemData) &&
		// 		!this.isNumeric(this.getMakePaymentData.editedItemData)
		// 	) {
		// 		totalProcessingFee = this.getMakePaymentData.editedItemData.processing_fee;
		// 	} else if (Array.isArray(this.getMakePaymentData.editedItemData)) {
		// 		totalProcessingFee == 0 &&
		// 			this.getMakePaymentData.editedItemData.forEach((item) => {
		// 				totalProcessingFee += parseFloat(item.processing_fee);
		// 			});
		// 	} else if (this.isNumeric(this.getMakePaymentData.editedItemData)) {
		// 		let invoices =
		// 			this.getMakePaymentData.fromComponent === "Billing"
		// 				? this.getAllInvoices
		// 				: this.getShipmentBills;
		// 		totalProcessingFee === 0 &&
		// 			invoices.forEach((item) => {
		// 				item.paid === false
		// 					? (totalProcessingFee += parseFloat(item.processing_fee))
		// 					: 0;
		// 			});
		// 	}
		// 	return totalProcessingFee;
		// },
		percentage(percent, total, amountNew, balance, fee) {
			if (parseInt(total) == parseInt(balance)) {
				if (amountNew < balance) {
					return ((percent / 100) * amountNew).toFixed(2)
				} else {
					return ((percent / 100) * total).toFixed(2)
				}
			} else if (parseInt(total) !== parseInt(balance)) {
				if (this.feeSettingCheck.is_fee_for_overdue == 1 && this.countDaysOfOverDue(fee) > this.feeSettingCheck.overdue_days) {
					return ((percent / 100) * amountNew).toFixed(2)
				} else {
					if (amountNew < total) {
						return ((percent / 100) * amountNew).toFixed(2)
					} else {
						return ((percent / 100) * total).toFixed(2)
					}
				}
			}
		},
		countDaysOfOverDue(data) {
			const date1 = new Date(data.due_date);
			const date2 = new Date();
			return _.floor(_.divide(_.subtract(date2, date1), 86400000)); // 86400000 milliseconds in a day
		},
		// TODO(usman): total value for single payment is incorrect,it always same even amount change so item.processing_fee change to this.countProcessingFee() 
		totalPaymentAmount(item) {
			let totalPaymentAmount = 0;
			if (this.includingDutyFees) {
				if (!this.isNumeric(item)) {
					let processingFee =
						// this.paymentMethodsSelected !== "ACH" ? item.processing_fee : 0;
						this.paymentMethodsSelected !== "ACH" ? this.countProcessingFee() : 0;
					totalPaymentAmount =
						parseFloat(processingFee) +
						// parseFloat(item.total_duty_amount) +
						this.computedTotal;
				} else {
					let processingFee =
						this.paymentMethodsSelected !== "ACH"
							? this.countProcessingFee()
							: 0;
					totalPaymentAmount =
						parseFloat(processingFee) +
						this.countDuties() +
						this.computedTotal;
				}
			} else {
				totalPaymentAmount = this.computedTotal;
			}
			this.btnDisabled = totalPaymentAmount === 0 ? true : false;
			return totalPaymentAmount;
		},
	},
	created() {
		this.snackbarFlag = this.snackbarFlagAction;
		this.paymentMethodsSelected = this.paymentMethodsSelectedAction;
	},
};
</script>

<style lang="scss">
@import "../../../assets/scss/pages_scss/settings/makePaymentDialog.scss";
@import "../../../assets/scss/pages_scss/billing/addPaymentDialog.scss";
@import "../../../assets/scss/pages_scss/billing/vCreditCard.scss";
.empty-checkbox-invoice-payment {
  /* Apply styles to hide the default checkbox */
  display: none;
}
.checkbox-label-invoice-payment {
  /* Set the background image for the label, adjust the path accordingly */
  background-image: url('/checkbox-empty-icon-1.svg');
  /* Set the width and height of the image */
  width: 16px;
  height: 16px;
  /* You can add more styling to the label as needed */
  display: inline-block;
  cursor: pointer;
//   margin-top:1px;
}
.empty-checkbox-invoice-payment:checked + .checkbox-label-invoice-payment {
  background-image: url('/checkbox-filled-icon-1.svg');
  width: 16px;
  height: 16px;
//   margin-top:1px;
}
</style>