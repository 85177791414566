<!-- @format -->

<template>
	<div class="radio-group-components-wrapper">
		<div :class="radioClass">
			<p class="mb-0 radio-label" :class="radioGroupLabelClass">
				{{ label }}
				<span class="red--text pl-1" v-if="required">*</span>
				<span class="booking-tooltip-wrapper" v-if="tooltip">
					<v-tooltip
						attach=".booking-tooltip-wrapper"
						left
						content-class="booking-tooltip"
					>
						<template v-slot:activator="{ on, attrs }">
							<div v-on="on" v-bind="attrs">
								<GenericIcon
									:color="tooltipIconColor"
									:iconName="tooltipIcon"
								/>
							</div>
						</template>
						<div>{{ tooltip }}</div>
					</v-tooltip>
				</span>
			</p>

			<v-radio-group
				class="mt-0 radio-group-wrapper"
				v-model="groupValueItem"
				row
				hide-details="auto"
				outlined
				:column="directions"
				:rules="rules"
				required
				validate-on-blur
			>
				<v-radio
					class="radio-button"
					v-for="(n, key) in items"
					:key="key"
					:label="n.label"
					:value="n.value"
					:disabled="disabledField"
				>
					<template v-slot:label>
						<div v-if="n.icons" class="label-icons">
							{{ n.label }}
							<KeneticIcon :marginLeft="8" :iconName="n.icons.toLowerCase()" />
						</div>
					</template>
				</v-radio>
			</v-radio-group>
		</div>
	</div>
</template>

<script>
import GenericIcon from "../../../../Icons/GenericIcon.vue";
import KeneticIcon from "../../../../Icons/KeneticIcon.vue";

export default {
	name: "RadioGroups",
	props: [
		"label",
		"groupValue",
		"items",
		"required",
		"radioClass",
		"tooltip",
		"disabledField",
		"directions",
		"rules",
		"radioGroupLabelClass",
		"tooltipIcon",
		"tooltipIconColor",
	],
	components: {
		KeneticIcon,
		GenericIcon,
	},
	computed: {
		groupValueItem: {
			get() {
				return this.groupValue;
			},
			set(value) {
				this.$emit("update:groupValue", value);
			},
		},
	},
};
</script>
<style lang="scss">
@import "../scss/radioGroups.scss";
@import "../scss/Common.scss";
</style>
