var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"content-class":"notification-user-dailog","max-width":"590px","retain-focus":false},model:{value:(_vm.getEmailDailog),callback:function ($$v) {_vm.getEmailDailog=$$v},expression:"getEmailDailog"}},[_c('v-card',{staticClass:"notification-emails"},[_c('v-form',{ref:"form",attrs:{"id":"createdriver","action":"#"},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticStyle:{"caret-color":"transparent"}},[_c('span',{staticClass:"headline"},[_vm._v("Send Email to")]),_c('button',{staticClass:"btn-close",attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('v-spacer'),_c('v-card-text',{staticClass:"px-6"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"selectBox",attrs:{"cols":"12","md":"12"}},[_c('label',{staticClass:"text-item-label"},[_vm._v("Email Notification for")]),_c('p',{staticClass:"notifi-type mb-2"},[_vm._v(" "+_vm._s(_vm.getCurrentEditItem)+" ")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',{staticClass:"text-item-label"},[_vm._v("Email Recipients")]),_c('div',{staticClass:"tags-email-wrapper"},[_c('vue-tags-input',{staticClass:"DialogInputfieldd",attrs:{"hide-details":"auto","rules":_vm.arrayNotEmptyRules,"tags":_vm.options,"add-on-blur":true,"add-on-key":[13, ','],"placeholder":"Enter email address"},on:{"before-adding-tag":_vm.checkEmailBeforeAdding,"tags-changed":function (newTags) {
                                                this$1.options = newTags;
                                                this$1.tagsInput.touched = true;
                                                this$1.tagsInput.hasError =
                                                    this$1.options.length > 0 ? false : true;
                                                var el = this$1.documentProto.getElementsByClassName(
                                                    'ti-input'
                                                )[0];
                                                if (typeof el !== 'undefined') {
                                                    if (this$1.tagsInput.hasError)
                                                        { el.classList.add('ti-new-tag-input-error'); }
                                                    else { el.classList.remove('ti-new-tag-input-error'); }
                                                }
                                            }},model:{value:(_vm.emailRecip),callback:function ($$v) {_vm.emailRecip=$$v},expression:"emailRecip"}})],1),_vm._v(" "),_c('div',{staticClass:"mt-1 mb-1",staticStyle:{"min-height":"12px"}},[(_vm.errorMessage === '')?_c('div',[_c('p',{staticClass:"my-0",staticStyle:{"color":"#819FB2","font-size":"12px"}},[_vm._v(" Separate multiple email addresses with comma ")])]):_c('div',{staticClass:"v-text-field__details"},[_c('span',{staticClass:"v-messages theme--light error--text"},[_vm._v(_vm._s(_vm.errorMessage))])])])]),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"12","md":"12"}},[(!_vm.currentCustomer.email_recipient_setting.same_email_for_all)?_c('v-checkbox',{staticClass:"email-users-new mt-0",attrs:{"label":"Same emails for all notifications","hide-details":"auto"},model:{value:(_vm.sameEmailsForAll),callback:function ($$v) {_vm.sameEmailsForAll=$$v},expression:"sameEmailsForAll"}}):_vm._e()],1)],1)],1),_c('v-card-actions',{staticStyle:{"border-top":"2px solid #F3F4F7"}},[_c('div',{staticClass:"d-flex align-center justify-start"},[_c('button',{staticClass:"save-btn-new btn-blue",attrs:{"disabled":_vm.getLoading},on:{"click":_vm.save}},[_c('span',{staticClass:"d-flex align-center justify-start"},[_c('span',{staticClass:"d-flex align-center justify-start",attrs:{"color":"#fff"}},[(_vm.getLoading)?_c('v-progress-circular',{staticStyle:{"margin-right":"6px"},attrs:{"size":15,"color":"#fff","width":2,"indeterminate":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.getLoading ? "Saving..." : "Save Email")+" ")],1)])]),_c('button',{staticClass:"cancel-btn-new btn-white",attrs:{"disabled":_vm.getLoading},on:{"click":_vm.close}},[_vm._v(" Cancel ")])])])],1),_c('v-row',{staticStyle:{"margin-top":"0px"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }