<!-- @format -->

<template>
	<div class="general-information">
		<div class="booking-section-1">
			<div class="input-field-wrapper radio-group-fields">
				<RadioGroups
					v-if="reference == 'booking-request-form'"
					:label="'YOUR ROLE'"
					:groupValue.sync="bookingFormRole"
					:items="roles"
					:required="true"
					:disabledField="true"
					:radioClass="'radio-group-fields'"
					:rules="roleRules"
					:radioGroupLabelClass="'input-field-label'"
				/>
				<div class="radio-group-components-wrapper" v-else>
					<div class="radio-group-fields">
						<p class="mb-0 radio-label input-field-label">
							YOUR ROLE
							<span class="red--text pl-1">*</span>
						</p>

						<v-radio-group
							class="mt-0 radio-group-wrapper"
							v-model="shipmentItem.userRole"
							row
							hide-details="auto"
							outlined
							:rules="roleRules"
							required
						>
							<v-radio
								class="radio-button"
								v-for="(n, key) in roles"
								:key="key"
								:label="n.label"
								:value="n.value"
								:disabled="
									consolidateBookingShipment ||
										reference == 'booking-request-form'
								"
								@click="updateUserRoleValues(n)"
							>
								<template v-slot:label>
									<div v-if="n.icons" class="label-icons">
										{{ n.label }}
										<KeneticIcon
											:marginLeft="8"
											:iconName="n.icons.toLowerCase()"
										/>
									</div>
								</template>
							</v-radio>
						</v-radio-group>
					</div>
				</div>
			</div>
			<div class="multiple-input-field">
				<div
					class="consignee-section d-flex"
					v-for="(item, index) in shipmentItem.consignees"
					:key="index + 'shipper_index'"
				>
					<div class="input-field-wrapper multiple-shipper-consignee-input">
						<p class="input-field-label mb-1">
							CONSIGNEE
							<span v-if="shipmentItem.consignees.length > 1">{{
								index + 1
							}}</span
							><span class="red--text pl-1">*</span>
						</p>
						<v-select
							class="text-fields select-items carton-uom consignee-name-wrapper mb-0"
							:items="filteredConsigneeOptions"
							height="40px"
							outlined
							item-text="name"
							item-value="id"
							v-model="item.consignee"
							hide-details="auto"
							v-if="
								shipmentItem.userRole === 'consignee' &&
									filteredImportNameOptions.length == 0
							"
							disabled
						/>
						<v-autocomplete
							:height="40"
							class="shipper-consignee-input-field"
							v-model="item.consignee"
							:items="filteredConsigneeOptions"
							:filter="customFilterUser"
							item-text="name"
							item-value="id"
							dense
							outlined
							hide-details="auto"
							placeholder="Select Consignee"
							spellcheck="false"
							clearable
							:rules="consigneeRules"
							:menu-props="{
								contentClass:
									'input-field-name-wrapper shipper-consignee-selection-list',
								bottom: true,
								offsetY: true,
							}"
							:disabled="
								shipmentItem.userRole == 'consignee' ||
									(!isRoleSelected && !consolidateBookingShipment)
							"
							v-if="shipmentItem.userRole !== 'consignee'"
							@change="addCargoDetails(item.consignee)"
						>
							<template v-slot:item="{ item }">
								<div class="consignee-details-info">
									<h4 class="company-name">{{ item.name }}</h4>
									<p class="company-address">
										{{
											item.address
												? item.address
												: "No available Consignee details"
										}}
									</p>
								</div>
							</template>
							<template v-slot:no-data>
								<div
									class="no-data-found"
									v-if="searchUsers && !userSearchText"
								>
									<img
										src="@/assets/icons/empty-document-blue.svg"
										width="24px"
										height="24px"
										alt=""
									/>
									<h3 class="text-center">No Data Available</h3>
								</div>
								<div class="no-data-found" v-else>
									<img
										src="@/assets/icons/empty-document-blue.svg"
										width="24px"
										height="24px"
										alt=""
									/>
									<h3 class="text-center">No Match Found</h3>
									<p class="mb-0 text-center">
										There is no ‘ {{ userSearchText }}’ as a Consignee.
									</p>
								</div>
							</template>
							<v-list
								slot="append-item"
								class="px-4 add-new-orders-options quick-add-content"
							>
								<div class="add-new-orders-buttons">
									<p
										class="mb-0 quick-order"
										@click.stop="addNewConsignees(key)"
									>
										<img
											src="@/assets/icons/plus.svg"
											class="mr-2"
											width="12px"
											height="12px"
											alt=""
										/>
										Add as new Consignee
									</p>
								</div>
							</v-list>
						</v-autocomplete>

						<v-autocomplete
							class="text-fields select-items carton-uom import-and-consignnee"
							:items="filteredImportNameOptions"
							height="40px"
							outlined
							v-model="shipmentItem.import_name_id"
							item-text="name"
							item-value="id"
							hide-details="auto"
							:menu-props="{
								contentClass: 'consignee-import-name-wrapper',
								bottom: true,
								offsetY: true,
							}"
							v-if="
								shipmentItem.userRole === 'consignee' &&
									filteredImportNameOptions &&
									filteredImportNameOptions.length > 0
							"
							:placeholder="consigneeName"
							:class="!shipmentItem.import_name_id ? 'light-placeholder' : ''"
							:disabled="
								!isRoleSelected ||
									(!isRoleSelected && !consolidateBookingShipment)
							"
						>
							<v-list slot="prepend-item" class="consignee-details pl-4">
								<div class="import-title company-title py-2">
									<span> Company Information </span>
								</div>
								<div
									v-for="(consignee, index) in filteredConsigneeOptions"
									:key="index"
									class="py-2"
								>
									<img
										src="@/assets/images/building.svg"
										alt="building"
										class="building-image"
									/>
									<div class="company-details-wrapper">
										<div
											style="width: 100%;"
											class="d-flex first-row justify-space-between"
										>
											<span class="company-name">{{
												getConsigneeName(consignee.name)
											}}</span>
										</div>
										<div
											class="d-flex second-row"
											style="width: 100%; padding-bottom: 4px;"
										>
											<span> {{ consignee.address }}</span>
										</div>
										<div
											class="d-flex third-row"
											style="width: 100%; padding-bottom: 4px;"
										>
											<span class="text-list">Email: </span
											><span> {{ consignee.email }} </span>
										</div>
										<div
											class="d-flex fourth-row"
											style="width: 100%; padding-bottom: 4px;"
										>
											<span class="text-list">Phone Number: </span>
											<span> {{ consignee.phone }} </span>
										</div>
									</div>
								</div>
								<div class="import-title py-2">
									<span> Import name </span>
								</div>
							</v-list>
							<template v-slot:item="{ item }">
								<div
									class="d-flex flex-column import-name-details"
									style="width: 100%;"
								>
									<div
										style="width: 100%;"
										class="d-flex first-row justify-space-between"
									>
										<span class="import-name">{{ item.name }}</span>
									</div>
									<div
										style="width: 100%;"
										class="d-flex first-row justify-space-between"
									>
										<span class="import-name-address">{{
											item.importAddress
										}}</span>
									</div>
								</div>
							</template>
						</v-autocomplete>
					</div>

					<div class="input-field-wrapper multiple-shipper-consignee-input">
						<p class="input-field-label mb-1 d-flex align-center">
							CONSIGNEE’S DETAILS INFO <span class="red--text pl-1">*</span>
						</p>
						<v-text-field
							v-model="item.consignee_details_info"
							:height="40"
							placeholder="Enter Cosignee’s details"
							width="200px"
							type="text"
							dense
							outlined
							hide-details="auto"
							:rules="[(v) => !!v || '']"
							validate-on-blur
						/>
					</div>

					<div class="input-field-wrapper multiple-shipper-consignee-input">
						<div
							class="input-field-label mb-1 d-flex align-center checkbox-wrapper notify-checkbox"
						>
							<div class="notify">
								<span>Notify</span>
								<v-checkbox
									class="checkbox-wrapper shifl-ui-checkbox"
									:class="
										shipmentItem.userRole == 'shipper'
											? 'notify-party-checkbox'
											: ''
									"
									label="Notify party is different"
									v-model="item.is_notify_party"
									hide-details
								/>
							</div>
						</div>
						<div class="d-flex align-center checkbox-input">
							<v-text-field
								:disabled="!item.is_notify_party"
								v-model="item.notify_details_info"
								:height="40"
								width="200px"
								type="text"
								class="notify-details"
								:placeholder="!item.is_notify_party ? 'Same as Consignee' : ''"
								dense
								outlined
								hide-details="auto"
							/>
							<div
								class="remove-row"
								v-if="isRoleSelected && shipmentItem.userRole === 'shipper'"
							>
								<v-btn
									@click="removeConsignee(item.consignee, index)"
									:disabled="shipmentItem.consignees.length == 1"
								>
									<v-icon
										:color="shipmentItem.consignees.length > 1 ? 'red' : ''"
									>
										mdi-close
									</v-icon>
								</v-btn>
							</div>
						</div>
					</div>
				</div>

				<div
					class="add-more-field mb-4"
					v-if="
						shipmentItem.userRole &&
							shipmentItem.userRole !== 'consignee' &&
							reference !== 'booking-request-form'
					"
				>
					<v-btn @click="addMoreConsignees()" :disabled="!isRoleSelected">
						<img src="@/assets/icons/plus.svg" class="mr-2" />
						Add More Consignee
					</v-btn>
				</div>

				<div
					class="shipper-section"
					:class="shipmentItem.userRole == 'consignee' ? 'mb-4' : ''"
					v-for="(item, index) in shipmentItem.shippers"
					:key="index"
				>
					<div class="d-flex shipper-details">
						<div
							class="input-field-wrapper multiple-shipper-consignee-input mb-0"
						>
							<p class="input-field-label mb-1">
								Shipper
								<span v-if="shipmentItem.shippers.length > 1">{{
									index + 1
								}}</span
								><span class="red--text pl-1">*</span>
							</p>
							<v-autocomplete
								:height="40"
								class="shipper-consignee-input-field"
								v-model="item.shipper"
								:items="filteredShipperOptions"
								:filter="customFilterUser"
								item-text="name"
								item-value="id"
								dense
								outlined
								hide-details="auto"
								placeholder="Select Shipper"
								spellcheck="false"
								clearable
								:rules="shipperRules"
								:disabled="
									shipmentItem.userRole == 'shipper' ||
										(!isRoleSelected && !consolidateBookingShipment)
								"
								:menu-props="{
									contentClass:
										'input-field-name-wrapper shipper-consignee-selection-list',
									bottom: true,
									offsetY: true,
								}"
								@change="addCargoDetails(item.shipper)"
							>
								<template v-slot:item="{ item }">
									<div class="consignee-details-info">
										<h4 class="company-name">{{ item.name }}</h4>
										<p class="company-address mb-0">
											{{
												item.address
													? item.address
													: "No available Shipper details"
											}}
										</p>
									</div>
								</template>
								<template v-slot:no-data>
									<div
										class="no-data-found"
										v-if="searchUsers && !userSearchText"
									>
										<img
											src="@/assets/icons/empty-document-blue.svg"
											width="24px"
											height="24px"
											alt=""
										/>
										<h3 class="text-center">No Data Available</h3>
									</div>
									<div class="no-data-found" v-else>
										<img
											src="@/assets/icons/empty-document-blue.svg"
											width="24px"
											height="24px"
											alt=""
										/>
										<h3 class="text-center">No Match Found</h3>
										<p class="mb-0 text-center">
											There is no ‘ {{ userSearchText }}’ as a shipper.
										</p>
									</div>
								</template>
								<v-list
									slot="append-item"
									class="px-4 add-new-orders-options quick-add-content"
								>
									<div class="add-new-orders-buttons">
										<p class="mb-0 quick-order" @click.stop="addNewShippers()">
											<img
												src="@/assets/icons/plus.svg"
												class="mr-2"
												width="12px"
												height="12px"
												alt=""
											/>
											Add as new Shipper
										</p>
									</div>
								</v-list>
							</v-autocomplete>
						</div>

						<div
							class="input-field-wrapper multiple-shipper-consignee-input mb-0"
						>
							<p class="input-field-label mb-1 d-flex align-center">
								Shipper’s DETAILS INFO <span class="red--text pl-1">*</span>
							</p>
							<v-text-field
								v-model="item.shipper_details_info"
								placeholder="Enter Shipper’s details"
								:height="40"
								width="200px"
								type="text"
								dense
								outlined
								hide-details="auto"
								:rules="[(v) => !!v || '']"
								validate-on-blur
							/>
						</div>

						<div
							class="input-field-wrapper multiple-shipper-consignee-input checkbox-wrapper mb-0"
						>
							<p class="input-field-label mb-1 d-flex align-center">
								Pickup location
							</p>
							<div class="d-flex align-center checkbox-input">
								<v-checkbox
									class="pickup-location checkbox-wrapper shifl-ui-checkbox"
									v-model="item.pick_up_location"
									hide-details
									:disabled="
										!isRoleSelected &&
											!consolidateBookingShipment &&
											reference !== 'booking-request-form'
									"
								>
									<template slot="label">
										<span>Add Pickup Location</span>
										<span
											class="ml-2"
											v-tooltip="{
												text:
													'This is applicable when booking pickup with forwarder',
												position: 'bottom',
											}"
											><GenericIcon :color="'#253041'" :iconName="'info-icon'"
										/></span>
									</template>
								</v-checkbox>
								<div
									class="remove-row"
									v-if="isRoleSelected && shipmentItem.userRole === 'consignee'"
								>
									<v-btn
										@click="removeShipper(item.shipper, index)"
										:disabled="shipmentItem.shippers.length == 1"
									>
										<v-icon
											:color="shipmentItem.shippers.length > 1 ? 'red' : ''"
										>
											mdi-close
										</v-icon>
									</v-btn>
								</div>
							</div>
						</div>
					</div>

					<div
						class="shipper-warehouse-location mt-1"
						v-if="item.pick_up_location"
					>
						<p class="location-label">
							Add Shipper {{ index + 1 }} Pickup Location
						</p>
						<div class="d-flex picup-location-details">
							<div class="input-field-wrapper multiple-shipper-consignee-input">
								<p class="input-field-label mb-1 d-flex align-center">
									Warehouse Pickup Location
									<span class="red--text pl-1">*</span>
								</p>
								<v-text-field
									v-model="item.warehouse_pick_up_location"
									placeholder="Enter Warehouse Pickup Location"
									:height="40"
									width="200px"
									type="text"
									dense
									outlined
									hide-details="auto"
									:rules="[(v) => !!v || '']"
								/>
							</div>
							<div class="input-field-wrapper multiple-shipper-consignee-input">
								<p class="input-field-label mb-1 d-flex align-center">
									CONTACT PERSON NAME<span class="red--text pl-1">*</span>
								</p>
								<v-text-field
									v-model="item.contact_person_name"
									placeholder="Enter Personal Name"
									:height="40"
									width="200px"
									type="text"
									dense
									outlined
									hide-details="auto"
									:rules="[(v) => !!v || '']"
								/>
							</div>
							<div class="input-field-wrapper multiple-shipper-consignee-input">
								<p class="input-field-label mb-1 d-flex align-center">
									Contact Person Tel/Mobile
									<span class="red--text pl-1">*</span>
								</p>
								<vue-tel-input
									v-model="item.contact_person_mobile_num"
									mode="international"
									defaultCountry="us"
									validCharactersOnly
									:dropdownOptions="vueTelDropdownOptions"
									:inputOptions="vueTelInputOptions"
									:styleClasses="
										item.contact_person_mobile_num == null ? 'is-error' : ''
									"
								>
									<template v-slot:arrow-icon>
										<v-icon class="ml-1">mdi-chevron-down</v-icon>
									</template>
								</vue-tel-input>
							</div>
							<div class="input-field-wrapper multiple-shipper-consignee-input">
								<p class="input-field-label mb-1 d-flex align-center">
									Contact Person Email <span class="red--text pl-1">*</span>
								</p>
								<v-text-field
									v-model="item.contact_person_email"
									placeholder="Enter Email Address"
									:height="40"
									width="200px"
									type="text"
									dense
									outlined
									hide-details="auto"
									:rules="emailRules"
								/>
							</div>
						</div>
					</div>
				</div>

				<div
					class="add-more-field"
					v-if="
						shipmentItem.userRole &&
							shipmentItem.userRole !== 'shipper' &&
							reference !== 'booking-request-form'
					"
				>
					<v-btn @click="addMoreShippers()" :disabled="!isRoleSelected">
						<p class="mb-0">
							<img src="@/assets/icons/plus.svg" class="mr-2" /> Add More
							Shippers
						</p>
					</v-btn>
				</div>
			</div>
		</div>
		<div class="booking-section-2">
			<div class="d-flex flex-row shipment-form-details-wrapper">
				<div class="form-edit-shipment-form-first-column form-wrapper">
					<div
						class="input-field-wrapper radio-group-fields radio-checkbox-input-field mb-4"
					>
						<div class="d-flex align-center radio-checkbox">
							<RadioGroups
								:label="'TYPE'"
								:radioClass="'radio-group-fields'"
								:groupValue.sync="shipmentItem.type"
								:items="filteredTypes"
								:required="true"
								:radioGroupLabelClass="'input-field-label'"
								v-if="shipmentItem.mode !== 'Air'"
								:rules="[(v) => !!v || '']"
							/>
							<div
								class="consolidate-checkbox checkbox-wrapper d-flex flex-column"
								v-tooltip="{
									text:
										'This is a request to try to consolidate this booking with other bookings into one shipment',
									position: 'bottom',
								}"
								v-if="!consolidateBookingShipment"
							>
								<span class="input-field-label">Request consolidations</span>
								<v-checkbox
									class="pa-1 checkbox-wrapper shifl-ui-checkbox"
									v-model="shipmentItem.request_consolidations"
									hide-details
								>
									<template v-slot:label>
										Consolidate
										<img
											src="@/assets/icons/groups.svg"
											width="28px"
											height="28px"
											alt=""
										/>
									</template>
								</v-checkbox>
							</div>
						</div>
					</div>

					<div class="input-field-wrapper">
						<span class="input-field-label"
							>FROM
							<small class="input-field-caption">(Port of Loading)</small>
							<span class="red--text pl-1">*</span></span
						>
						<v-autocomplete
							v-model="shipmentItem.location_from"
							:rules="locationFromRules"
							:height="40"
							color="#002F44"
							:items="filteredTerminalRegions"
							item-text="text"
							item-value="value"
							dense
							outlined
							hide-details="auto"
							placeholder="Enter Location (Port, Warehouse etc.)"
							spellcheck="false"
							clearable
							validate-on-blur
						/>
					</div>

					<div
						class="input-field-wrapper checkbox-wrapper port-of-discharge-checkbox"
					>
						<v-checkbox
							class="checkbox-wrapper shifl-ui-checkbox pt-0"
							:class="
								shipmentItem.same_as_port_of_discharge ? 'front-info' : ''
							"
							v-model="shipmentItem.same_as_port_of_discharge"
							hide-details
						>
							<template slot="label">
								<span class="d-flex align-center">
									Port of Destination is not the same as Port of Discharge
								</span>
								<span
									class="ml-2"
									v-tooltip="{
										text:
											'Port of Discharge is where the container is unloaded from the vessel',
										position: 'top',
									}"
									><GenericIcon :color="'#253041'" :iconName="'info-icon'"
								/></span>
							</template>
						</v-checkbox>
						<div
							class="child-input-field"
							v-if="shipmentItem.same_as_port_of_discharge"
						>
							<v-checkbox
								class="checkbox-wrapper pt-0 shifl-ui-checkbox"
								label="Give me best option"
								v-model="shipmentItem.give_me_best_location"
								hide-details
							/>
							<p class="pl-8">
								Our team will suggest you the best way of moving cargo from port
								of discharge to destination.
							</p>
							<div class="input-field-wrapper">
								<span class="input-field-label">Port of discharge </span>
								<v-autocomplete
									v-model="shipmentItem.port_of_discharge"
									:height="40"
									color="#002F44"
									class="port-of-discharge"
									:items="filteredTerminalRegions"
									item-text="text"
									item-value="value"
									dense
									outlined
									hide-details="auto"
									placeholder="Select Location (Port, Warehouse etc.) "
									spellcheck="false"
									clearable
									:disabled="shipmentItem.give_me_best_location"
									validate-on-blur
								/>
							</div>
						</div>
					</div>

					<div class="d-flex multiple-input-field-details">
						<div class="input-field-wrapper mb-0">
							<p class="input-field-label mb-0 d-flex align-center">
								Earliest Shipment Date
								<span
									class="text-none"
									v-tooltip="{
										text:
											'This date will help the booking team find the best sailing',
										position: 'top',
									}"
									><GenericIcon :color="'#253041'" :iconName="'info-icon'"
								/></span>
							</p>
							<div class="text-field-wrapper booking-dates-wrapper">
								<v-menu
									:close-on-content-click="true"
									transition="scale-transition"
									offset-y
									min-width="auto"
								>
									<template v-slot:activator="{ on, attrs }">
										<v-text-field
											placeholder="MM-DD-YYYY"
											outlined
											v-bind="attrs"
											v-on="on"
											type="text"
											hide-details="auto"
											clear-icon
											:height="40"
											v-model="shipmentItem.earliest_shipment_date"
											append-icon="mdi-calendar"
											@input="
												(val) => updateEarliestDateInput(shipmentItem, val)
											"
										/>
									</template>
									<v-date-picker
										v-model="shipmentItem.earliest_shipment_date"
									/>
								</v-menu>
							</div>
						</div>
						<div class="input-field-wrapper booking-dates-wrapper mb-0">
							<div>
								<p class="input-field-label mb-0 d-flex align-center">
									Latest Arrival date
								</p>
								<div class="text-field-wrapper booking-dates-wrapper">
									<v-menu
										:close-on-content-click="true"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on, attrs }">
											<v-text-field
												placeholder="MM-DD-YYYY"
												outlined
												v-bind="attrs"
												v-on="on"
												type="text"
												hide-details="auto"
												clear-icon
												:height="40"
												v-model="shipmentItem.latest_arrival_date"
												append-icon="mdi-calendar"
												@input="
													(val) =>
														updateLatestArrivalDateInput(shipmentItem, val)
												"
											/>
										</template>
										<v-date-picker v-model="shipmentItem.latest_arrival_date" />
									</v-menu>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="form-edit-shipment-form-second-column form-wrapper">
					<div class="input-field-wrapper radio-group-fields">
						<RadioGroups
							:label="'MODE'"
							:radioClass="'radio-group-fields'"
							:groupValue.sync="shipmentItem.mode"
							:items="modes"
							:required="true"
							:radioGroupLabelClass="'input-field-label'"
							:rules="[(v) => !!v || '']"
							validate-on-blur
						/>
					</div>

					<div class="input-field-wrapper">
						<span class="input-field-label"
							>TO
							<small class="input-field-caption">(Port of Destination)</small>
							<span class="red--text pl-1">*</span></span
						>
						<v-autocomplete
							v-model="shipmentItem.location_to"
							:rules="locationToRules"
							:height="40"
							color="#002F44"
							:items="filteredTerminalRegions"
							item-text="text"
							item-value="value"
							dense
							outlined
							hide-details="auto"
							placeholder="Enter Location (Port, Warehouse etc.)"
							spellcheck="false"
							clearable
							validate-on-blur
						/>
					</div>

					<div class="input-field-wrapper checkbox-wrapper warehouse-checkbox">
						<v-checkbox
							class="checkbox-wrapper shifl-ui-checkbox pt-0"
							:class="shipmentItem.warehouse_delivery_location ? 'pb-3' : ''"
							label="Add Warehouse/Delivery Location"
							v-model="shipmentItem.warehouse_delivery_location"
							hide-details
						/>
						<div
							class="child-input-field"
							v-if="shipmentItem.warehouse_delivery_location"
						>
							<div class="d-flex picup-location-details">
								<div class="Warehouse-Delivery-details">
									<div
										class="input-field-wrapper multiple-shipper-consignee-input mb-2"
									>
										<p class="input-field-label mb-1 d-flex align-center">
											Warehouse Location
										</p>
										<v-text-field
											v-if="
												warehouseSavingStatus ||
													(!contactSavingStatus && warehouseSavingStatus)
											"
											v-model="shipmentItem.warehouse_pick_up_location"
											placeholder="Enter Warehouse Pickup Location"
											:height="40"
											width="200px"
											type="text"
											dense
											outlined
											hide-details="auto"
											class="contact-delivery-address"
										/>
										<v-autocomplete
											v-if="
												!warehouseSavingStatus ||
													(contactSavingStatus && !warehouseSavingStatus)
											"
											:height="40"
											class="warehouse-delivery-input-field"
											:items="WarehouseLocationItems()"
											v-model="shipmentItem.warehouse_pick_up_location"
											item-text="location_name"
											item-value="id"
											:filter="customFilterWarehouse"
											dense
											outlined
											hide-details="auto"
											placeholder="Select Warehouse"
											spellcheck="false"
											:menu-props="{
												contentClass:
													'input-field-name-wrapper warehouse-selection-list',
												bottom: true,
												offsetY: true,
											}"
											@change="
												changeWareHouseLocation(
													shipmentItem.warehouse_pick_up_location
												)
											"
										>
											<template v-slot:item="{ item }">
												<div class="warehouse-details-info">
													<h4 class="warehouse-name mb-1">
														{{ item.location_name }}
													</h4>
													<p class="warehouse-address mb-1">{{ item.type }}</p>
													<p class="warehouse-address mb-0">
														{{
															item.address
																? item.address
																: "No available Warehouse details"
														}}
													</p>
												</div>
											</template>
											<template v-slot:no-data>
												<div
													class="no-data-found"
													v-if="searchWarehouses && !warehouseSearchText"
												>
													<img
														src="@/assets/icons/empty-document-blue.svg"
														width="24px"
														height="24px"
														alt="icon"
													/>
													<h3 class="text-center">No Data Available</h3>
													<p class="mb-0 text-center">
														There is no Warehouse/Delivery Location.
													</p>
												</div>
												<div class="no-data-found" v-else>
													<img
														src="@/assets/icons/empty-document-blue.svg"
														width="24px"
														height="24px"
														alt="icon"
													/>
													<h3 class="text-center">No Match Found</h3>
													<p class="mb-0 text-center">
														There is no Warehouse/Delivery Location as ‘
														{{ warehouseSearchText }}’.
													</p>
												</div>
											</template>
											<v-list
												slot="append-item"
												class="px-4 add-new-orders-options quick-add-content"
											>
												<div class="add-new-orders-buttons mb-2">
													<p
														class="mb-0 quick-order"
														@click.stop="addDeliveryLocation()"
													>
														Add & Save Location
													</p>
													<div>
														<img
															src="@/assets/icons/invite-arrow.svg"
															class="mr-0"
															width="12px"
															height="12px"
															alt="icon"
														/>
													</div>
												</div>
												<div class="add-new-orders-buttons saving-location-btn">
													<p
														class="mb-0 quick-order add-without-saving-location-btn mt-2"
														@click="addWithoutSavingLocation()"
													>
														Add Without Saving
													</p>
													<div>
														<img
															src="@/assets/icons/invite-arrow.svg"
															class="mr-0"
															width="12px"
															height="12px"
															alt="icon"
														/>
													</div>
												</div>
											</v-list>
										</v-autocomplete>
									</div>
									<div
										class="input-field-wrapper multiple-shipper-consignee-input mb-2"
									>
										<p class="input-field-label mb-1 d-flex align-center">
											Contact Person Tel/Mobile
										</p>
										<vue-tel-input
											v-model="shipmentItem.contact_person_mobile_num"
											mode="international"
											defaultCountry="us"
											validCharactersOnly
											:autoDefaultCountry="true"
											:dropdownOptions="vueTelDropdownOptions"
											:inputOptions="vueTelInputOptions"
											class="contact-delivery-address"
											:disabled="
												!warehouseSavingStatus &&
													!contactSavingStatus &&
													!warehouseSavingStatus
											"
										>
											<template v-slot:arrow-icon>
												<v-icon class="ml-1">mdi-chevron-down</v-icon>
											</template>
										</vue-tel-input>
									</div>
								</div>
								<div class="Warehouse-Delivery-details">
									<div
										class="input-field-wrapper multiple-shipper-consignee-input mb-2"
									>
										<p class="input-field-label mb-1 d-flex align-center">
											Contact Person Name
										</p>
										<v-text-field
											v-if="
												warehouseSavingStatus ||
													(contactSavingStatus && !warehouseSavingStatus)
											"
											v-model="shipmentItem.contact_person_name"
											placeholder="Enter Personal Name"
											:height="40"
											width="200px"
											type="text"
											dense
											outlined
											hide-details="auto"
											class="contact-delivery-address"
										/>
										<v-autocomplete
											v-if="
												!warehouseSavingStatus &&
													!contactSavingStatus &&
													!warehouseSavingStatus
											"
											:height="40"
											class="warehouse-delivery-input-field"
											:items="deliveryContacts"
											v-model="shipmentItem.contact_person_name"
											item-text="contact_name"
											item-value="id"
											:filter="customFilterWarehouseContacts"
											dense
											outlined
											hide-details="auto"
											placeholder="Select Contact"
											spellcheck="false"
											:menu-props="{
												contentClass:
													'input-field-name-wrapper warehouse-selection-list',
												bottom: true,
												offsetY: true,
											}"
											@change="
												addSelectedWarehouseContact(
													shipmentItem.contact_person_name
												)
											"
										>
											<template v-slot:item="{ item }">
												<div class="warehouse-details-info">
													<h4 class="warehouse-name mb-1">
														{{ item.contact_name ? item.contact_name : "N/A" }}
													</h4>
													<p class="warehouse-address mb-1">
														{{ item.phone_number ? item.phone_number : "N/A" }}
													</p>
													<p class="warehouse-address mb-0">
														{{ item.email ? item.email : "N/A" }}
													</p>
												</div>
											</template>
											<template v-slot:no-data>
												<div
													class="no-data-found"
													v-if="
														searchWarehouseContacts &&
															!warehouseContactSearchText
													"
												>
													<img
														src="@/assets/icons/empty-document-blue.svg"
														width="24px"
														height="24px"
														alt="icon"
													/>
													<h3 class="text-center">No Data Available</h3>
													<p class="mb-0 text-center">
														There is no Warehouse/Delivery Contact.
													</p>
												</div>
												<div class="no-data-found" v-else>
													<img
														src="@/assets/icons/empty-document-blue.svg"
														width="24px"
														height="24px"
														alt="icon"
													/>
													<h3 class="text-center">No Match Found</h3>
													<p class="mb-0 text-center">
														There is no Warehouse/Delivery Contacts as ‘
														{{ warehouseContactSearchText }}’.
													</p>
												</div>
											</template>
											<v-list
												slot="append-item"
												class="px-4 add-new-orders-options quick-add-content"
											>
												<div class="add-new-orders-buttons">
													<p
														class="mb-0 quick-order ml-2"
														@click="addNewContact()"
													>
														<img
															src="@/assets/icons/plus.svg"
															class="mr-1"
															width="12px"
															height="12px"
															alt="icon"
														/>
														Add New Contact
													</p>
												</div>
											</v-list>
										</v-autocomplete>
									</div>
									<div
										class="input-field-wrapper multiple-shipper-consignee-input mb-2"
									>
										<p class="input-field-label mb-1 d-flex align-center">
											Contact Person Email
										</p>
										<v-text-field
											v-model="shipmentItem.contact_person_email"
											placeholder="Enter Email Address"
											:height="40"
											width="200px"
											type="text"
											dense
											outlined
											hide-details="auto"
											class="contact-delivery-address"
											:rules="emailRules"
											:disabled="
												!warehouseSavingStatus &&
													!contactSavingStatus &&
													!warehouseSavingStatus
											"
										/>
									</div>
								</div>
							</div>
							<p
								class="pickup-location-info-text mb-0"
								v-if="warehouseSavingStatus"
							>
								<img
									src="@/assets/icons/icon-alert.svg"
									class="mr-0"
									width="14px"
									height="14px"
									alt="icon"
								/>
								This Information will not be saved
							</p>
						</div>
					</div>

					<div class="d-flex align-center multiple-input-field-details">
						<v-col class="pa-0" md="6">
							<div class="input-field-wrapper mb-0">
								<span class="input-field-label">Preferred carrier</span>
								<v-select
									class="prefferd-carrier-input-field text-fields select-items"
									v-model="shipmentItem.preferred_carrier"
									:items="preferredCarrierOptions"
									item-text="name"
									item-value="id"
									outlined
									hide-details="auto"
									placeholder="Select preferred carrier"
									:menu-props="{
										contentClass:
											'product-lists-items preferred-carrier-wrapper contacts',
										bottom: true,
										offsetY: true,
										closeOnContentClick: false,
									}"
									spellcheck="false"
									multiple
									small-chips
									deletable-chips
									clearable
								>
									<template v-slot:selection="{ item }">
										<v-chip
											class="product-item-chip font-medium"
											close
											@click:close="removeChip(item)"
										>
											<span class="name">{{ item.name }}</span>
										</v-chip>
									</template>

									<template v-slot:prepend-item>
										<v-list-item class="search-input-field pa-0">
											<v-text-field
												v-model="searchCarrierName"
												placeholder="Search Carrier"
												hide-details="auto"
												class="text-fields select-items product-search-contact-field"
												outlined
												prepend-inner-icon="mdi-magnify"
											/>
										</v-list-item>
										<v-divider
											style="border-color: #F3F4F7 !important;"
										></v-divider>
									</template>

									<template v-slot:item="{ item, attrs, on }">
										<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
											<v-list-item-action class="mr-2 pa-0">
												<v-checkbox :input-value="active"></v-checkbox>
											</v-list-item-action>
											<v-list-item-content>
												<v-list-item-title>
													<p class="contact-name mb-0">{{ item.name }}</p>
												</v-list-item-title>
											</v-list-item-content>
										</v-list-item>
									</template>
								</v-select>
							</div>
						</v-col>
						<v-col class="pr-3 pa-0" md="6">
							<div class="input-field-wrapper mb-0">
								<p class="input-field-label mb-0 d-flex align-center">
									CUSTOM REFERENCE
									<span
										class="text-none"
										v-tooltip="{
											text: 'Your own reference number for this booking',
											position: 'left',
										}"
										><GenericIcon :color="'#253041'" :iconName="'info-icon'"
									/></span>
								</p>
								<v-text-field
									v-model="shipmentItem.customer_reference_number"
									placeholder="Enter custom reference number"
									:height="40"
									color="#002F44"
									width="200px"
									type="text"
									dense
									outlined
									hide-details="auto"
								/>
							</div>
						</v-col>
					</div>
				</div>
			</div>
		</div>
		<SupplierDialog
			v-if="showSupplierDialog"
			:editedIndexData.sync="editedIndex"
			:dialogData.sync="showSupplierDialog"
			:editedItemData.sync="supplierEditItem"
			:defaultItemData.sync="defaultSupplierItem"
			@setToDefault="setToDefaultSupplierItem"
			:customerPagination="customerPagination"
			:suppliersPagination="suppliersPagination"
			:searchVendors.sync="searchVendors"
			:searchCustomers.sync="searchCustomers"
			:suppliersData="suppliers"
			:customersData="customers"
			:fromComponent="'BookingShipmentDialog'"
			@createdUser="createdUser"
		/>
		<!-- @close="close" -->

		<AddNewDeliveryLocationDialog
			v-if="addNewDeliveryLocationDialog"
			:dialogImportName.sync="addNewDeliveryLocationDialog"
			@closelocation="closeLocationDialog"
			:defaultLocationItems="defaultLocationItems"
			:defaultCustomer="defaultCustomer"
			@warehousePayload="warehouseCreatedPayload"
		/>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import shipmentBookingItem from "../js/ShipmentBooking";
import { VueTelInput } from "vue-tel-input";
import _ from "lodash";
import RadioGroups from "./GeneralInformation/RadioGroups.vue";
import GenericIcon from "../../../Icons/GenericIcon.vue";
import SupplierDialog from "../../../SupplierComponents/SupplierDialog.vue";
import SupplierItem from "../../../Dialog/FormShipmentDialog/Structures/SupplierItem";
import "vue-tel-input/dist/vue-tel-input.css";
import AddNewDeliveryLocationDialog from "../../../SettingsComponents/Dialog/AddDeliveryLocationDialog.vue";
import KeneticIcon from "../../../Icons/KeneticIcon.vue";
import moment from "moment";

export default {
	name: "GeneralInformations",
	props: [
		"filteredConsigneeOptions",
		"filteredShipperOptions",
		"userDetails",
		"isMobile",
		"reference",
		"bookingTemplateId",
	],
	components: {
		RadioGroups,
		GenericIcon,
		VueTelInput,
		SupplierDialog,
		AddNewDeliveryLocationDialog,
		KeneticIcon,
	},
	data: () => ({
		shipmentItem: shipmentBookingItem,
		userRole: "",
		roles: [
			{
				label: "Shipper",
				value: "shipper",
				icons: "",
			},
			{
				label: "Consignee",
				value: "consignee",
				icons: "",
			},
		],
		modes: [
			{
				label: "Ocean",
				value: "Ocean",
				icons: "Ocean",
			},
			{
				label: "Air",
				value: "Air",
				icons: "Air",
			},
		],
		tooltipContent:
			"International Commercial Terms, are a set of international rules produced by the International Chamber of Commerce (ICC).",
		locationFromRules: [(v) => !!v || ""],
		locationToRules: [(v) => !!v || ""],
		roleRules: [(v) => !!v || ""],
		modeRules: [(v) => !!v || ""],
		consigneeRules: [(v) => !!v || ""],
		shipperRules: [(v) => !!v || ""],
		incoTermRules: [(v) => !!v || ""],
		emailRules: [
			(v) => !!v || "",
			(v) =>
				!v ||
				/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
				"E-mail must be valid",
		],
		vueTelDropdownOptions: {
			showDialCodeInSelection: false,
			showDialCodeInList: true,
			showFlags: true,
			showSearchBox: true,
		},
		vueTelInputOptions: {
			autocomplete: false,
			placeholder: "Type phone number",
			styleClasses: "tel-input-class",
			required: true,
		},
		isRoleSelected: false,
		userSearchText: "",
		searchUsers: true,
		editedIndex: -1,
		showSupplierDialog: false,
		supplierEditItem: SupplierItem,
		defaultSupplierItem: SupplierItem,
		searchVendors: "",
		searchCustomers: "",
		consigneeName: "",
		addNewDeliveryLocationDialog: false,
		defaultLocationItems: {
			location_name: "",
			type: "",
			country: "",
			state: "",
			city: "",
			address: "",
			zip_code: "",
			facility_operation_schedule: 0,
			special_delivery_instruction: "",
			contacts: [
				{
					contact_name: "",
					phone_number: "",
					email: "",
					role: "",
					on_site: 0,
					primary: "",
				},
			],
			facility_operation_hour: {
				type: "weekly",
				days: [],
				mon_open: "",
				mon_close: "",
				tue_open: "",
				tue_close: "",
				wed_open: "",
				wed_close: "",
				thu_open: "",
				thu_close: "",
				fri_open: "",
				fri_close: "",
				sat_open: "",
				sat_close: "",
				sun_open: "",
				sun_close: "",
			},
		},
		warehouseSearchText: "",
		searchWarehouses: true,
		deliveryContacts: [],
		warehouseContactSearchText: "",
		searchWarehouseContacts: true,
		warehouseSavingStatus: false,
		contactSavingStatus: false,
		bookingFormRole: "shipper",
		searchCarrierName: "",
		maxDisplay: 2,
	}),
	async mounted() {
		if (
			(this.bookingTemplateId !== null &&
				this.bookingTemplateId !== "" &&
				this.bookingTemplateId !== undefined &&
				this.bookingTemplateId !== 0) ||
			this.reference == "request-booking-shipment"
		) {
			this.isRoleSelected = true;
		}


		if (
			this.shipmentItem.consignees.length == 0 &&
			this.shipmentItem.shippers.length == 0 &&
			this.reference !== "booking-request-form"
		) {
			await this.addMoreConsignees();
			await this.addMoreShippers();
		}

		
		
		if (this.reference == "booking-request-form") {
			const {
				default_customer: { id, address },
			} = this.userDetails;
			this.shipmentItem.consignees.map((item) => {
				item.consignee = id;
				item.consignee_details_info = address;
			});
			const { supplier_id } = this.$store.getters["booking/getbookingLinkData"];

			const selectedShipperDetails = await this.filteredShipperOptions.find(
				(supplier) => supplier.id == supplier_id
			);

			this.shipmentItem.shippers.map((item) => {
				if (item.shipper == supplier_id) {
					item.shipper_details_info = selectedShipperDetails.address;
				}
			});
		}
		if (this.getTerminalRegions.length == 0) {
			await this.$store.dispatch("fetchTerminalRegions", {
				is_paginate: 0,
			});
		}
		await this.$store.dispatch("fetchCarriers", {
			is_paginate: 0,
		});

		await this.$store.dispatch("po/fetchImportName");

		await this.$store.dispatch("warehouse/fetchCountries", {
			is_paginate: 0,
		});
		if (this.userDetails?.default_customer?.id) {
			await this.$store
				.dispatch(
					"booking/fetchDefaultRequirements",
					this.userDetails?.default_customer?.id
				)
				.then((response) => {
					let keys = Object.keys(response);
					keys.forEach((key) => {
						let value = response[key];
						if (value === 1) {
							this.shipmentItem.additional_services.push(key);
						}
					});
				});
		}
	},
	computed: {
		...mapGetters({
			getUser: "getUser",
			getTerminalRegions: "getTerminalRegions",
			getCarriers: "getCarriers",
			getCountries: "warehouse/getCountries",
		}),
		getSearchedSuppliers() {
			return this.$store.getters["suppliers/getSearchedSuppliers"];
		},
		getCustomers() {
			return this.$store.getters["customers/getCustomers"];
		},
		getSearchedCustomer() {
			return this.$store.getters["customers/getSearchedCustomer"];
		},
		getSuppliers() {
			return this.$store.getters["suppliers/getSuppliers"];
		},
		getWarehouseLocations() {
			return this.$store.getters["settings/getDeliveryLocations"];
		},
		filteredTerminalRegions() {
			let newTerminalRegions = [];
			this.getTerminalRegions.map((item) => {
				newTerminalRegions.push({
					text: item.name,
					value: item.id,
				});
			});
			return newTerminalRegions;
		},
		preferredCarrierOptions() {
			if (!this.searchCarrierName) {
				return this.getCarriers;
			} else {
				return this.getCarriers.filter((c) => {
					return (
						c.name.toLowerCase().indexOf(this.searchCarrierName.toLowerCase()) >
						-1
					);
				});
			}
		},
		filteredTypes() {
			let types = [
				{
					label: "Air",
					options: [],
				},
				{
					label: "Ocean",
					options: [
						{
							label: "FCL",
							value: "FCL",
							icons: "FCL",
						},
						{
							label: "LCL",
							value: "LCL",
							icons: "LCL",
						},
					],
				},
			];

			let setMode = this.shipmentItem.mode;
			if (setMode === "" || setMode == "N/A" || setMode == null) {
				setMode = "Ocean";
			}

			//return options
			let getOptions = _.find(types, (e) => e.label === setMode);
			return getOptions.options;
		},
		filteredImportNameOptions() {
			let importName = [];

			importName = this.$store.getters["po/getImportName"];

			importName =
				importName &&
				importName.map(({ address, id, import_name }) => {
					return {
						name: import_name,
						id,
						importAddress: address,
					};
				});

			return importName;
		},
		suppliers() {
			let data = [];

			if (
				typeof this.getSearchedSuppliers !== "undefined" &&
				this.getSearchedSuppliers !== null
			) {
				if (
					this.searchVendors !== "" &&
					this.getSearchedSuppliers.tab === "vendors"
				) {
					if (
						typeof this.getSearchedSuppliers.data !== "undefined" &&
						this.getSearchedSuppliers.data.length !== "undefined"
					) {
						data = this.getSearchedSuppliers.data;
					}
				} else {
					if (
						typeof this.getSuppliers !== "undefined" &&
						this.getSuppliers !== null
					) {
						if (
							typeof this.getSuppliers.data !== "undefined" &&
							this.getSuppliers.data.length !== "undefined"
						) {
							data = this.getSuppliers.data;
						}
					}
				}
			} else {
				if (
					typeof this.getSuppliers !== "undefined" &&
					this.getSuppliers !== null
				) {
					if (
						typeof this.getSuppliers.data !== "undefined" &&
						this.getSuppliers.data.length !== "undefined"
					) {
						data = this.getSuppliers.data;
					}
				}
			}

			if (data.length > 0) {
				data.map((value) => {
					if (value.address === null) {
						value.address = "";
					}

					if (value.phone === null) {
						value.phone = "";
					}

					if (value.emails === null) {
						value.emails = "";
					}
				});
			}

			return data;
		},
		customers() {
			let customerData = [];

			if (
				typeof this.getSearchedCustomer !== "undefined" &&
				this.getSearchedCustomer !== null
			) {
				if (
					this.searchCustomers !== "" &&
					this.getSearchedCustomer.tab === "customers"
				) {
					if (
						typeof this.getSearchedCustomer.data !== "undefined" &&
						this.getSearchedCustomer.data.length !== "undefined"
					) {
						customerData = this.getSearchedCustomer.data;
					}
				} else {
					if (
						typeof this.getCustomers !== "undefined" &&
						this.getCustomers !== null
					) {
						if (
							typeof this.getCustomers.data !== "undefined" &&
							this.getCustomers.data.length !== "undefined"
						) {
							customerData = this.getCustomers.data;
						}
					}
				}
			} else {
				if (
					typeof this.getCustomers !== "undefined" &&
					this.getCustomers !== null
				) {
					if (
						typeof this.getCustomers.data !== "undefined" &&
						this.getCustomers.data.length !== "undefined"
					) {
						customerData = this.getCustomers.data;
					}
				}
			}

			if (customerData.length > 0) {
				customerData.map((value) => {
					value.name = value.company_name;

					if (value.address !== null) {
						value.address = value.address.replace(/,(?=[^\s])/g, ", ");
					} else {
						value.address = "";
					}

					if (value.phone == null) {
						value.phone = "";
					}

					if (value.emails == null) {
						value.emails = "";
					}
				});
			}

			return customerData;
		},
		consolidateBookingShipment() {
			return this.reference === "consolidte-booking-shipment";
		},
		defaultCustomer() {
			let getUser = this.$store.getters.getUser;
			getUser = typeof getUser === "string" && JSON.parse(getUser);
			return getUser.default_customer_id;
		},
		countries() {
			return typeof this.getCountries !== "undefined" &&
				this.getCountries !== null &&
				this.getCountries.length !== 0
				? this.getCountries
				: [];
		},
	},
	methods: {
		async updateUserRoleValues(role) {
			const userRole = role?.value;

			this.shipmentItem.selectedConsigneeIds = [];
			this.shipmentItem.selectedShipperIds = [];
			if (role !== "" || role !== null) {
				this.isRoleSelected = true;
			}

			this.$emit("showConfirmRoleModal", userRole);
			if (!this.consolidateBookingShipment) {
				this.shipmentItem.additional_services = [];
				this.shipmentItem.cargoDetailsData = [];
				if (userRole == "shipper") {
					if (this.shipmentItem.shippers.length >= 1) {
						this.shipmentItem.shippers = [];
						this.addMoreShippers();
					}
					this.shipmentItem.shippers.map((item) => {
						item.shipper = this.userDetails?.default_customer?.id;
						item.shipper_details_info = this.userDetails?.default_customer?.address;
					});
					this.shipmentItem.consignees.map((item) => {
						item.consignee_details_info = null;
						item.is_notify_party = false;
						item.notify_details_info = null;
					});
				} else {
					if (this.shipmentItem.consignees.length >= 1) {
						this.shipmentItem.consignees = [];
						this.addMoreConsignees();
					}
					this.shipmentItem.consignees.map((item) => {
						item.consignee = this.userDetails?.default_customer?.id;
						item.consignee_details_info = this.userDetails?.default_customer?.address;
					});
					this.shipmentItem.shippers.map((item) => {
						item.shipper_details_info = null;
						item.pick_up_location = false;
						item.warehouse_pick_up_location = null;
						item.contact_person_name = null;
						item.contact_person_mobile_num = null;
						item.contact_person_email = null;
					});
				}
			}
		},
		addMoreConsignees() {
			this.shipmentItem.consignees.push({
				consignee: 0,
				consignee_details_info: null,
				is_notify_party: false,
				notify_details_info: null,
			});
		},
		addMoreShippers() {
			this.shipmentItem.shippers.push({
				shipper: 0,
				shipper_details_info: null,
				pick_up_location: false,
				warehouse_pick_up_location: null,
				contact_person_name: null,
				contact_person_mobile_num: null,
				contact_person_email: null,
			});
		},
		removeConsignee(item) {
			if (item !== null && item !== 0) {
				this.removeCargoDetails(item);
			}
			let index = this.shipmentItem.consignees.findIndex(
				(element) => element.consignee == item
			);
			this.shipmentItem.consignees.splice(index, 1);
		},
		removeShipper(item) {
			if (item !== null && item !== 0) {
				this.removeCargoDetails(item);
			}
			let index = this.shipmentItem.shippers.findIndex(
				(element) => element.shipper == item
			);
			this.shipmentItem.shippers.splice(index, 1);
		},
		addCargoDetails(value) {
			if (this.shipmentItem.userRole === "shipper") {
				if (value !== "" && value !== null && value !== undefined) {
					const selectedConsigneeDtails = this.filteredConsigneeOptions.find(
						(customer) => customer.id == value
					);
					this.shipmentItem.consignees.map((item) => {
						if (item.consignee == value) {
							item.consignee_details_info = selectedConsigneeDtails?.address;
						}
					});

					this.shipmentItem.selectedConsigneeIds.push(value);
					this.shipmentItem.cargoDetailsData.push({
						consignee: value,
						total_carton: 0,
						total_volume: 0,
						total_weight: 0,
						commodity_description: "",
						incoterm: 0,
					});
					this.disableEnableOption();

					let Arr = [];
					this.shipmentItem.consignees.forEach((item) => {
						if (
							item.consignee !== "" &&
							item.consignee !== null &&
							item.consignee !== undefined
						) {
							Arr.push(item.consignee);
						}
					});

					let newItem = this.shipmentItem.selectedConsigneeIds.filter(
						(x) => !Arr.includes(x)
					);

					if (newItem.length > 0) {
						this.removeCargoDetails(newItem[0]);
					}
				} else {
					let Arr = [];
					this.shipmentItem.consignees.forEach((item) => {
						if (
							item.consignee !== "" &&
							item.consignee !== null &&
							item.consignee !== undefined
						) {
							Arr.push(item.consignee);
						}
					});
					let newItem = this.shipmentItem.selectedConsigneeIds.filter(
						(x) => !Arr.includes(x)
					);

					this.removeCargoDetails(newItem[0]);
				}
			} else {
				if (value !== "" && value !== null && value !== undefined) {
					const selectedShipperDetails = this.filteredShipperOptions.find(
						(supplier) => supplier.id == value
					);

					this.shipmentItem.shippers.map((item) => {
						if (item.shipper == value) {
							item.shipper_details_info = selectedShipperDetails?.address;
						}
					});
					this.shipmentItem.selectedShipperIds.push(value);
					this.shipmentItem.cargoDetailsData.push({
						shipper: value,
						total_carton: 0,
						total_volume: 0,
						total_weight: 0,
						commodity_description: "",
						incoterm: 0,
					});
					this.disableEnableOption();

					let Arr = [];
					this.shipmentItem.shippers.forEach((item) => {
						if (
							item.shipper !== "" &&
							item.shipper !== null &&
							item.shipper !== undefined
						) {
							Arr.push(item.shipper);
						}
					});

					let newItem = this.shipmentItem.selectedShipperIds.filter(
						(x) => !Arr.includes(x)
					);

					if (newItem.length > 0) {
						this.removeCargoDetails(newItem[0]);
					}
				} else {
					let Arr = [];
					this.shipmentItem.shippers.forEach((item) => {
						if (
							item.shipper !== "" &&
							item.shipper !== null &&
							item.shipper !== undefined
						) {
							Arr.push(item.shipper);
						}
					});
					let newItem = this.shipmentItem.selectedShipperIds.filter(
						(x) => !Arr.includes(x)
					);

					this.removeCargoDetails(newItem[0]);
				}
			}
			this.fetchOrders();
		},
		fetchOrders() {
			if (this.shipmentItem.userRole === "shipper") {
				this.fetchPurchaseOrderOptions(
					"",
					"SO",
					this.userDetails.default_customer.id,
					this.shipmentItem.selectedConsigneeIds
				);
			} else {
				this.fetchPurchaseOrderOptions(
					"",
					"PO",
					this.userDetails.default_customer.id,
					this.shipmentItem.selectedShipperIds
				);
			}
		},
		fetchPurchaseOrderOptions(qry, type, entity_id, other_id) {
			return new Promise((resolve) => {
				this.$store
					.dispatch("po/fetchPurchaseOrderOptions", {
						qry,
						type,
						entity_id,
						other_id,
					})
					.then((r) => {
						resolve(r);
					});
			});
		},
		removeCargoDetails(value) {
			if (this.shipmentItem.userRole === "shipper") {
				let index = this.shipmentItem.cargoDetailsData.findIndex(
					(item) => item.consignee === value
				);
				this.shipmentItem.selectedConsigneeIds.splice(index, 1);
				this.shipmentItem.cargoDetailsData.splice(index, 1);
				this.filteredConsigneeOptions.forEach((item) => {
					item["disabled"] = false;
				});
			} else {
				let index = this.shipmentItem.cargoDetailsData.findIndex(
					(item) => item.shipper === value
				);
				this.shipmentItem.selectedShipperIds.splice(index, 1);
				this.shipmentItem.cargoDetailsData.splice(index, 1);
				this.filteredShipperOptions.forEach((item) => {
					item["disabled"] = false;
				});
			}
			this.fetchOrders();
			this.disableEnableOption();
		},
		disableEnableOption() {
			if (this.shipmentItem.userRole === "shipper") {
				this.filteredConsigneeOptions.forEach((item) => {
					if (this.shipmentItem.selectedConsigneeIds.includes(item.id)) {
						item["disabled"] = true;
					}
				});
			} else {
				this.filteredShipperOptions.forEach((item) => {
					if (this.shipmentItem.selectedShipperIds.includes(item.id)) {
						item["disabled"] = true;
					}
				});
			}
		},
		customFilterUser(item, queryText, itemText) {
			const address = item.address ? item.address : "";
			const data =
				item.phone + address.toLowerCase() + itemText.toLocaleLowerCase();
			this.userSearchText = queryText;

			this.searchUsers = data.indexOf(queryText.toLowerCase()) > -1;
			return this.searchUsers;
		},
		addNewShippers() {
			this.supplierEditItem.isSupplier = true;
			this.showSupplierDialog = true;
		},
		createdUser({data}){
			if(this.shipmentItem.userRole == 'shipper'){
				
				this.shipmentItem.consignees.splice(Object.keys(this.shipmentItem.consignees).length-1, 0, {
					consignee: data?.id,
					consignee_details_info: data?.address,
					is_notify_party: false,
					notify_details_info: null
				});
			} else {

				this.shipmentItem.shippers.splice(Object.keys(this.shipmentItem.shippers).length-1, 0, {
					shipper: data?.id,
					shipper_details_info: data?.address,
					pick_up_location: false,
					warehouse_pick_up_location: null,
					contact_person_name: null,
					contact_person_mobile_num: null,
					contact_person_email: null,
				});
			}
				
			this.addCargoDetails(data?.id);
		},
		addNewConsignees() {
			this.supplierEditItem.isSupplier = false;
			this.showSupplierDialog = true;
		},
		setToDefaultSupplierItem() {
			this.supplierEditItem = this.defaultSupplierItem;
			this.showSupplierDialog = false;
		},
		suppliersPagination() {
			if (this.searchVendors === "") {
				return this.getSuppliers;
			} else {
				return this.getSearchedSuppliers;
			}
		},
		customerPagination() {
			if (this.searchCustomers === "") {
				return this.getCustomers;
			} else {
				return this.getSearchedCustomer;
			}
		},
		getConsigneeName(name) {
			this.consigneeName = name;
			return name;
		},
		addDeliveryLocation() {
			this.editDeliveryLocationIndex = false;
			this.addNewDeliveryLocationDialog = true;
		},
		closeLocationDialog() {
			this.addNewDeliveryLocationDialog = false;
			this.editDeliveryLocationDialog = false;
			this.editDeliveryLocationIndex = false;
			this.defaultLocationItems = {
				location_name: "",
				address: "",
				country: "",
				city: "",
				state: "",
				location_contacts: [],
			};
		},
		WarehouseLocationItems() {
			return this.getWarehouseLocations;
		},
		customFilterWarehouse(item, queryText, itemText) {
			const address = item.location_name ? item.location_name : "";
			const data =
				item.phone + address.toLowerCase() + itemText.toLocaleLowerCase();
			this.warehouseSearchText = queryText;

			this.searchWarehouses = data.indexOf(queryText.toLowerCase()) > -1;
			return this.searchWarehouses;
		},
		customFilterWarehouseContacts(item, queryText, itemText) {
			const contact = item.contact_name ? item.contact_name : "";
			const data =
				item.phone_number +
				contact.toLowerCase() +
				itemText.toLocaleLowerCase();
			this.warehouseContactSearchText = queryText;

			this.searchWarehouseContacts = data.indexOf(queryText.toLowerCase()) > -1;
			return this.searchWarehouseContacts;
		},
		addSelectedWarehouseContact(item) {
			let selectedContact = this.deliveryContacts.find((row) => row.id == item);
			this.shipmentItem.contact_person_mobile_num =
				selectedContact.phone_number;
			this.shipmentItem.contact_person_email = selectedContact.email;
		},
		changeWareHouseLocation(warehouse_id) {
			this.contactSavingStatus = false;
			this.shipmentItem.manualContact = false;
			this.shipmentItem.contact_person_mobile_num = "";
			this.shipmentItem.contact_person_email = "";
			this.shipmentItem.contact_person_name = "";
			this.deliveryContacts = this.getWarehouseLocations.find(
				(row) => row.id == warehouse_id
			)?.contacts;
		},
		addWithoutSavingLocation() {
			this.warehouseSavingStatus = true;
			this.shipmentItem.manualWarehouse = true;
			this.shipmentItem.warehouse_pick_up_location = "";
			this.shipmentItem.contact_person_mobile_num = "";
			this.shipmentItem.contact_person_email = "";
			this.shipmentItem.contact_person_name = "";
		},
		warehouseCreatedPayload() {
			this.$store.dispatch("settings/fetchDeliveryLocationsApi", {
				page: 1,
			});
		},
		addNewContact() {
			this.contactSavingStatus = true;
			this.shipmentItem.manualContact = true;
			this.shipmentItem.contact_person_mobile_num = "";
			this.shipmentItem.contact_person_email = "";
			this.shipmentItem.contact_person_name = "";
		},
		updateLatestArrivalDateInput(shipmentItem, value) {
			if (moment(value).isValid()) {
				shipmentItem.latest_arrival_date = moment(value).format("YYYY-MM-DD");
			} else {
				shipmentItem.latest_arrival_date = "";
			}
		},
		updateEarliestDateInput(shipmentItem, value) {
			if (moment(value).isValid()) {
				shipmentItem.earliest_shipment_date = moment(value).format(
					"YYYY-MM-DD"
				);
			} else {
				shipmentItem.earliest_shipment_date = "";
			}
		},
		removeChip(item) {
			let findIndex = _.findIndex(
				this.preferredCarrierOptions,
				(e) => e.id == item.id
			);

			this.preferredCarrierOptions.splice(findIndex, 1);
		},
	},
};
</script>

<style lang="scss">
@import "../SubComponents/scss/ConfirmationDetails.scss";
@import "../SubComponents/scss/Common.scss";
</style>
