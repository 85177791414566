var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"560px","content-class":"send-report-wrapper-dialog","scrollable":"","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"send-report-dialog"},[_c('v-card-text',[_c('div',{staticClass:"send-report-content mt-2"},[_c('div',{staticClass:"header-wrapper-close mb-3"},[_c('img',{attrs:{"src":require("@/assets/icons/question-blue.svg"),"alt":"alert"}})]),_c('h2',{staticClass:"send-report-title pb-3"},[_vm._v("Sending Report")]),_c('p',{staticClass:"send-report-sub mb-3"},[_vm._v("Do you want to send the "),_c('span',{staticClass:"font-semi-bold"},[_vm._v(" 'Shipment Report - "+_vm._s(_vm.editedItemData !== null ? _vm.findFrequency(_vm.editedItemData.frequency) : '')+"' ")]),_vm._v(" to the following email addresses? ")])]),_c('v-form',{ref:"form",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"send-report-content"},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('label',{staticClass:"text-item-label"},[_vm._v("EMAIL")]),_c('vue-tags-input',{attrs:{"hide-details":"auto","rules":_vm.arrayNotEmptyRules,"tags":_vm.options,"add-on-blur":true,"allow-edit-tags":"","add-on-key":[13, ','],"validation":_vm.tagsValidation,"placeholder":_vm.options.length > 0 ? '' : 'Enter emails'},on:{"tags-changed":function (newTags) {
                                        this$1.options = newTags
                                        this$1.tagsInput.touched = true
                                        this$1.tagsInput.hasError = (this$1.options.length > 0) ? false : true
                                        var el = this$1.documentProto.getElementsByClassName('ti-input')[0]
                                        if (typeof el!=='undefined') {
                                            if (this$1.tagsInput.hasError)
                                                { el.classList.add('ti-new-tag-input-error') }
                                            else
                                                { el.classList.remove('ti-new-tag-input-error') }
                                        }
                                    }},model:{value:(_vm.reportsEmailAddress),callback:function ($$v) {_vm.reportsEmailAddress=$$v},expression:"reportsEmailAddress"}}),_c('p',{staticClass:"mb-1",staticStyle:{"color":"#9CA5B4","font-size":"12px","margin-top":"5px"}},[_vm._v(" Separate multiple email addresses with comma ")]),(_vm.tagsInput.touched)?_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[((_vm.options.length > 0) && _vm.reportsEmailAddress!=='')?_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.tagsInput.errorMessage)+" ")]):_vm._e(),(_vm.options.length == 0 && _vm.reportsEmailAddress!=='')?_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.tagsInput.errorMessage)+" ")]):_vm._e(),(_vm.options.length == 0 && _vm.reportsEmailAddress=='')?_c('div',{staticClass:"v-messages__message"},[_vm._v(" Please provide at least 1 valid email address. ")]):_vm._e()])])]):_vm._e()],1)],1)],1)])],1),_c('v-card-actions',{staticClass:"send-report-btn-wrapper"},[_c('v-btn',{staticClass:"btn-blue",attrs:{"text":"","disabled":_vm.getSendReportScheduleLoading},on:{"click":_vm.sendReportCompany}},[_vm._v(" "+_vm._s(_vm.getSendReportScheduleLoading ? 'Sending...' : 'Send Now')+" ")]),_c('v-btn',{staticClass:"btn-white",attrs:{"text":"","disabled":_vm.getSendReportScheduleLoading},on:{"click":_vm.close}},[_vm._v(" Cancel ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }