<!-- @format -->

<template>
	<v-dialog
		v-model="dialog"
		max-width="560px"
		:content-class="`add-supplier-dialog ${phoneDropdownClass()}`"
		v-resize="onResize"
		:retain-focus="false"
		persistent
		scrollable>

		<v-card class="add-supplier-card">			
			<v-card-title>
				<span class="headline">{{ formTitle }}</span>

				<button icon dark class="btn-close" @click="close">
					<v-icon>mdi-close</v-icon>
				</button>
			</v-card-title>

			<v-card-text class="dialog-card-text-content">
				<v-form ref="form" v-model="valid" action="#" @submit.prevent="">
					<v-row>
						<v-col cols="12" sm="12" md="12" class="pb-0">
							<label class="text-item-label">SHIFL KEY 
                                <small class="text-capitalize" style="font-size: 10px">(Optional)</small>
                            </label>
							<div class="d-flex justify-start align-start">
                                <v-text-field
                                    :disabled="editedIndexData > -1 || getCompanyKeyLoading"
                                    placeholder="Enter Shifl Key"
                                    outlined
                                    class="text-fields shifl-key-input"
                                    hide-details="auto"
                                    :error-messages="companyKeyError"
                                    v-model="editedItem.company_key"
									:class="isKeyVerified && editedIndexData > -1 ? 'verified' : ''"
                                    :suffix="isKeyVerified ? 'Verified' : ''">
                                </v-text-field>

                                <button v-if="!isKeyVerified" class="btn-verify btn-white" :class="getButtonClass()" 
                                    type="button" @click="checkCompanyKey(editedItem.company_key)" 
									:disabled="getCompanyKeyLoading || editedIndexData > -1">
                                    <v-progress-circular
                                        :size="18"
                                        color="#1A6D9E"
                                        indeterminate
                                        v-if="getCompanyKeyLoading"
                                        class="mr-1">
                                    </v-progress-circular>
                                    <span>{{ getCompanyKeyLoading ? "Verifying" : "Verify" }}</span>
                                </button>

								<button 
									v-if="isKeyVerified && editedIndexData === -1" 
									class="btn-verify btn-white" 
									:class="companyKeyError.length > 0 ? 'has-error' : ''" 
                                    type="button" @click="removeFieldsEdit()" 
									:disabled="getCompanyKeyLoading"> <!-- :disabled="editedIndexData > -1 -->
                                    <span>Edit</span>
                                </button>
                            </div>

                            <p class="text-contact-sub-label mb-0" v-if="companyKeyError.length === 0">
                                Shifl Key is your {{ editedItem.isSupplier ? 'vendor' : 'buyer' }} company’s unique identifier.
                            </p>
						</v-col>

						<v-col cols="12" sm="12" md="12" class="pt-2 pb-0">
							<label class="text-item-label d-flex align-center mb-0" v-if="!isKeyVerified">
								{{ editedItem.isSupplier ? 'VENDOR' : 'CUSTOMER' }} NAME
							</label>
							<label class="text-item-label d-flex align-center mb-0" v-if="isKeyVerified">
								{{ editedItem.isSupplier ? 'VENDOR' : 'CUSTOMER' }} ACCOUNT NAME IN SHIFL
							</label>
							<v-text-field
								:placeholder="
									`Type ${editedItem.isSupplier ? 'Vendor' : 'Buyer'} Display Name`
								"
								outlined
								class="text-fields"
								v-model="editedItem.name"
								:rules="rules"
								hide-details="auto"
                                :disabled="isKeyVerified">
							</v-text-field>
						</v-col>
						
						<v-col cols="12" sm="12" md="12" class="pb-0">
							<label class="text-item-label d-flex align-center mb-0">
								{{ editedItem.isSupplier ? 'VENDOR' : 'CUSTOMER' }} DISPLAY NAME
								<small class="text-capitalize ml-1" style="font-size: 10px">(Optional)</small>

								<v-tooltip right content-class="tooltip-wrapper supplier tooltip-right" v-if="editedItem.isSupplier">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="d-flex align-center ml-1">
                                            <img src="@/assets/icons/alert-icon.svg" alt="" width="14px" height="14px">
                                        </span>
                                    </template>
                                    <span style="font-size:13px;">
										Alternate company name for internal system display and referencing
									</span>
                                </v-tooltip>
							</label>
							<v-text-field
								:placeholder="
									`Type ${editedItem.isSupplier ? 'Vendor' : 'Buyer'} Display Name`
								"
								outlined
								class="text-fields"
								v-model="editedItem.display_name"
								hide-details="auto">
							</v-text-field>
						</v-col>

						<v-col cols="12" sm="12" md="12" class="vue-tel-wrapper pb-0" :class="classText">
							<label class="text-item-label">Phone Number</label>
							<!-- <vue-tel-input-vuetify
								class="text-fields"
								type="number"
								outlined
								dense
								single-line
								hide-details="auto"
								:rules="numberRules"
								v-bind="telProps"
								v-model="editedItem.phone"
								:menu-props="{ bottom: true, offsetY: true }">

								<template v-slot:selection="{ item, index }">
									<div class="v-select__selection v-select__selection--comma" :key="index">
										{{ item }}
									</div>
								</template>
							</vue-tel-input-vuetify> -->

							<vue-tel-input
								v-model="editedItem.phone"
								mode="international"
								defaultCountry="US"
								:autoDefaultCountry="true"
								validCharactersOnly
								:dropdownOptions="vueTelDropdownOptions"
								:inputOptions="vueTelInputOptions"
								:styleClasses="[
									isPhoneNumberEmpty && editedItem.phone === '' ? 'is-error' : '',
									editedItem.phone !== '' && isPhoneNumberInvalid ? 'is-error' : '',
								]"
								@open="phoneDropdownClass"
								@validate="validatePhoneNumber">
								
								<template v-slot:arrow-icon>
									<v-icon class="ml-1">mdi-chevron-down</v-icon>
								</template>
							</vue-tel-input>

							<span class="error-message" style="color: #B11B1B; font-size: 12px;" v-if="isPhoneNumberEmpty">
								{{ editedItem.phone === '' ? 'Phone number is required' : ''}}
							</span>

							<span class="error-message" style="color: #B11B1B; font-size: 12px;" v-if="isPhoneNumberInvalid">
								{{ editedItem.phone === '' ? '' : 'Please provide a valid phone number'}}
							</span>
						</v-col>

						<v-col cols="12" sm="12" md="12" class="pb-0">
							<label class="text-item-label">Email</label>
							<div class="tags-email-wrapper">
								<vue-tags-input
									hide-details="auto"
									:rules="arrayNotEmptyRules"
									allow-edit-tags
									:tags="options"
									:add-on-blur="true"
									:add-on-key="[13, ',']"
									v-model="warehouseEmailAddress"
									@before-adding-tag="checkEmailBeforeAdding"
									:placeholder="
										`Type ${
											editedItem.isSupplier ? `Vendor's` : `Buyer's`
										} Email Address`									
									"
									@tags-changed="
										(newTags) => {
											this.options = newTags;
											this.tagsInput.touched = true;
											this.tagsInput.hasError =
												this.options.length > 0 ? false : true;
											let el = this.documentProto.getElementsByClassName(
												'ti-input'
											)[0];
											if (typeof el !== 'undefined') {
												if (this.tagsInput.hasError)
													el.classList.add('ti-new-tag-input-error');
												else el.classList.remove('ti-new-tag-input-error');
											}
										}
									"
								/>
							</div> <!-- :validation="tagsValidation" -->

							<div style="min-height: 12px;" class="mt-1 mb-1">
								<div v-if="errorMessage === ''">
									<p class="my-0" style="color: #819FB2; font-size: 12px;">
										Separate multiple email addresses with comma
									</p>
								</div>

								<div v-else class="v-text-field__details">
									<span class="v-messages theme--light error--text">{{ errorMessage }}</span>
								</div>
							</div>

							<!-- <span style="color: #9CA5B4; font-size: 12px; letter-spacing: 0;">
								Separate multiple email addresses with comma
							</span> -->

							<!-- <div style="line-height: 12px; height: 12px;">
								<div v-if="tagsInput.touched" class="v-text-field__details">
									<div class="v-messages theme--light error--text" role="alert">
										<div class="v-messages__wrapper">
											<div
												v-if="options.length > 0 && warehouseEmailAddress !== ''"
												class="v-messages__message"
											>
												{{ tagsInput.errorMessage }}
											</div>

											<div
												v-if="options.length == 0 && warehouseEmailAddress !== ''"
												class="v-messages__message"
											>
												{{ tagsInput.errorMessage }}
											</div>
											<div
												v-if="options.length == 0 && warehouseEmailAddress == ''"
												class="v-messages__message"
											>
												Please provide at least 1 valid email address.
											</div>
										</div>
									</div>
								</div>
							</div> -->
						</v-col>
						
						<v-col cols="12" sm="12" md="12" class="pt-1" :class="editedItem.isSupplier ? 'pb-0' : 'pb-5'">
							<label class="text-item-label">Address
								<small class="text-capitalize" style="font-size: 10px">(Optional)</small>
							</label>
							<v-textarea
								height="76px"
								class="text-fields"
								outlined
								name="input-7-4"
								:placeholder="
									`Enter ${
										editedItem.isSupplier ? `Vendor's` : `Buyer's`
									} Address`	
								"
								v-model="editedItem.address"
								hide-details="auto">								
								<!-- :rules="rules" -->
							</v-textarea>
						</v-col>

                        <v-col cols="12" sm="12" md="12" class="pb-0" v-if="editedItem.isSupplier">
							<label class="text-item-label">Associate Import Name 
								<small class="text-capitalize" style="font-size: 10px">(Optional)</small>
							</label>

							<v-autocomplete
								class="text-fields select-items select-warehouses associated-import-names"								
								:items="allImportNames"
								item-text="import_name"
								item-value="id"
								outlined
								hide-details="auto"
								placeholder="Select Import Name"
								clearable
								v-model="editedItem.import_name_id"
                                :menu-props="{ contentClass: 'associated-import-lists', bottom: true, offsetY: true, closeOnContentClick: false }">
							</v-autocomplete>
						</v-col>

                        <v-col cols="12" sm="12" md="12" class="pt-2 pb-4" v-if="editedItem.isSupplier">
                            <v-checkbox
                                class="my-0 text-center associated-checkbox"
                                hide-details
                                v-model="editedItem.show_only_import_name"
                                label="Only show this associated import name for the vendor">
                            </v-checkbox>
						</v-col>
					</v-row>
				</v-form>
			</v-card-text>

			<v-card-actions>
				<v-btn
					class="btn-blue"
					text
					@click="save"
					:disabled="disabledSaveButton || getCreateSuppliersLoading || getCreateCustomersLoading || getUpdateCustomersLoading">

					<span v-if="editedIndexData === -1">
						<span v-if="editedItem.isSupplier">
							{{ getCreateSuppliersLoading ? "Adding Vendor..." : "Add Vendor" }}
						</span>

						<span v-else>
							{{ getCreateCustomersLoading ? "Adding Buyer..." : "Add Buyer" }}
						</span>
					</span>

					<span v-if="editedIndexData > -1">
						<span v-if="editedItem.isSupplier">
							{{ getCreateSuppliersLoading ? "Saving..." : "Save Vendor" }}
						</span>

						<span v-else>
							{{ getUpdateCustomersLoading ? "Saving..." : "Save Buyer" }}
						</span>
					</span>
				</v-btn>

				<!-- <v-btn class="btn-white" text @click="saveAndAddSupplier" v-if="editedIndexData === -1">
					{{ ( getSupplierSaveAddLoading ) ? 'Saving...' : 'Save & Add Another' }}
				</v-btn> -->

				<v-btn class="btn-white" text @click="close"
					:disabled="getCreateSuppliersLoading || getCreateCustomersLoading || getUpdateCustomersLoading">
					Cancel
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { VueTelInput } from "vue-tel-input"
import "vue-tel-input/dist/vue-tel-input.css"
import VueTagsInput from "@johmun/vue-tags-input"
import jQuery from "jquery"
import globalMethods from "../../utils/globalMethods";
import _ from "lodash";
const baseURL = process.env.VUE_APP_BASE_URL;

export default {
	name: "SupplierDialog",
	props: [
		"editedItemData",
		"editedIndexData",
		"dialogData",
		"defaultItemData",
		"customerPagination",
		"suppliersPagination",
		'searchVendors',
		'searchCustomers',
		'suppliersData',
		'customersData',
		'fromComponent',
	],
	components: {
		VueTelInput,
		VueTagsInput,
	},
	data: () => ({
		associateVal: false,
		isKeyVerified: false,
		valid: true,
		options: [],
		value: [],
		isMobile: false,
		rules: [(v) => !!v || "Vendor name is required"],
		telProps: {
			mode: "international",
			defaultCountry: "US",
			placeholder: "Type Phone Number",
			label: "Type Phone Number",
			autocomplete: "off",
			maxLen: 25,
			preferredCountries: ["US", "CN"],
			disabledDialCode: false,
			inputOptions: {
				showDialCode: false,
			},
		},
		vueTelDropdownOptions: {
			showDialCodeInSelection: false,
			showDialCodeInList: true,
			showFlags: true,
			showSearchBox: true,
		},
		vueTelInputOptions: {
			autocomplete: false,
			placeholder: "Type Phone Number",
			styleClasses: "tel-input-class",
			required: true,
		},
		countryCode: null,
		numberRules: [
			(v) => !!v || "Input is required.",
			(v) => /^(?=.*[0-9])[- +()0-9]+$/.test(v) || "Letters are not allowed.",
		],
		tagsValidation: [
			{
				classes: "t-new-tag-input-text-error",
				rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
				disableAdd: true,
			},
		],
		documentProto: document,
		tagsInput: {
			touched: false,
			hasError: false,
			errorMessage: 'Please confirm the email address by pressing the "Enter" or "," key',
		},
		warehouseEmailAddress: "",
		arrayNotEmptyRules: [
			(v) => !!v || "Email is required",
			() =>
				this.optionsFiltered.length > 0 ||
				"Make sure to supply at least 1 email.",
		],
		isPhoneNumberEmpty: false,
		companyKeyError: [],
		disabledSaveButton: false,
		errorMessage: "",
		searchImportName: "",
		allImportNameLists: [],
		allImportNameListsCopy: [],
		classText: "",
		isPhoneNumberInvalid: false,
		phoneNumberDetails: null
	}),
	computed: {
		...mapGetters({
			getUser: "getUser",
			getCreateSuppliersLoading: "suppliers/getCreateSuppliersLoading",
			getSupplierSaveAddLoading: "suppliers/getSupplierSaveAddLoading",
			getCreateCustomersLoading: "customers/getCreateCustomersLoading",
			getUpdateCustomersLoading: "customers/getUpdateCustomersLoading",
			getSearchedSuppliers: "suppliers/getSearchedSuppliers",
			getSearchedCustomer: 'customers/getSearchedCustomer',
			getCompanyKeyLoading: "suppliers/getCompanyKeyLoading",
			getCompanyKeyResponse: "suppliers/getCompanyKeyResponse",
			getCustomerDetailsData: "suppliers/getCustomerDetailsData",
			getCompanyKeyError: "suppliers/getCompanyKeyError",
			getAllImportNameDropdown:'settings/getAllImportNameDropdown',
			getAllImportNameLoading: "settings/getAllImportNameLoading"
		}),
		formTitle() {
			if (this.editedItem.isSupplier) {
				return this.editedIndexData === -1 ? "Add Vendor" : "Edit Vendor"
			} else {
				return this.editedIndexData === -1 ? "Add Buyer" : "Edit Buyer"
			}
		},
		dialog: {
			get() {
				return this.dialogData
			},
			set(value) {
				if (!value) {
					this.$emit("update:dialogData", false)
				} else {
					this.$emit("update:dialogData", true)
				}
			},
		},
		editedItem: {
			get() {
				return this.editedItemData
			},
			set(value) {
				this.$emit('update:editedItemData', value)
			},
		},
		defaultItem: {
			get() {
				return this.defaultItemData
			},
			set(value) {
				this.$emit('update:defaultItemData', value)
			},
		},
		addSupplierTemplate() {
			let { name, address, phone, emails, display_name, show_only_import_name, import_name_id } = this.editedItem

			return {
				custom_customers_id: [
					typeof this.getUser == "string"
					? JSON.parse(this.getUser).customer.id
					: "",
				],
				company_name: name,
				address,
				phone,
				emails,
				display_name,
				show_only_import_name: show_only_import_name === true ? 1 : 0,
				import_name_id
			}
		},
		addCustomerTemplate() {
			let { name, address, phone, emails, display_name } = this.editedItem

			return {
				custom_customers_id: [
					typeof this.getUser == "string"
					? JSON.parse(this.getUser).customer.id
					: "",
				],
				company_name: name,
				address,
				phone,
				emails,
				display_name
			}
		},
		optionsFiltered: {
			get() {
				let validEmailAddress = []

				if (this.editedItem.emails !== null &&
					this.editedItem.emails.length > 0) {
					this.editedItem.emails.map((wea) => {
						if (wea !== null) {
							validEmailAddress.push({ text: wea, tiClasses: ["ti-valid"] })
						}
					})
				}
				return validEmailAddress
			},
			set(value) {
				this.options = value
			},
		},
		allImportNames() {
			let data = []
			if (typeof this.getAllImportNameDropdown !== "undefined" && this.getAllImportNameDropdown !== null) {
				if (this.getAllImportNameDropdown.length > 0) {
					data = this.getAllImportNameDropdown
				}
			}
			return data
		},
		defaultCustomerId: {
			get() {
				let customer_company_id = "";
				if (typeof this.getUser === "string") {
					customer_company_id = JSON.parse(this.getUser).default_customer_id;
				} else {
					customer_company_id = this.getUser.default_customer_id;
				}
				return customer_company_id;
			},
			set() {
				return ""
			}
        },
	},
	methods: {
		...mapActions({
			createSuppliers: "suppliers/createSuppliers",
			fetchSuppliers: "suppliers/fetchSuppliers",
			updateSuppliers: "suppliers/updateSuppliers",
			fetchCustomers: "customers/fetchCustomers",
			createCustomers: "customers/createCustomers",
			updateCustomers: "customers/updateCustomers",
			fetchSearchedVendors: "suppliers/fetchSearchedVendors",
			setSearchedVendorsLoading: "suppliers/setSearchedVendorsLoading",
			fetchSearchedCustomers: 'customers/fetchSearchedCustomers',
			setSearchedCustomerLoading: 'customers/setSearchedCustomerLoading',
			checkCompanyKeyExists: 'suppliers/checkCompanyKeyExists',
			fetchCustomerDetails: 'suppliers/fetchCustomerDetails',
			fetchAllImportNamesDropdown:'settings/fetchAllImportNamesDropdown',
		}),
		...globalMethods,
		countrySelected(val) {
			this.countryCode = val.dialCode
			console.log(val)
		},
		generateErrorMessage() {
			this.tagsInput.hasError = this.options.length > 0 ? false : true
			if (this.tagsInput.hasError)
				jQuery(".ti-input").addClass("ti-new-tag-input-error")
			else jQuery(".ti-input").removeClass("ti-new-tag-input-error")

			this.isPhoneNumberEmpty = this.addSupplierTemplate.phone === '' ? true : false;
			
			if (this.errorMessage !== "" || this.options.length === 0) {
				jQuery('.ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
				this.errorMessage = this.options.length === 0 && this.warehouseEmailAddress === ""
					? "Please provide at least 1 valid email address" 
					: (this.warehouseEmailAddress !== "" 
						? "Please make sure to enter a valid email address" 
						: this.errorMessage)
			} else {
				this.errorMessage = "";
				jQuery('.ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
			}

			if (this.phoneNumberDetails !== null && !this.phoneNumberDetails.valid) {
				this.isPhoneNumberInvalid = true;
			} else {
				this.isPhoneNumberInvalid = false;
			}
		},
		onResize() {
			if (window.innerWidth < 769) {
				this.isMobile = true
			} else {
				this.isMobile = false
			}
		},
		close() {
			this.$refs.form.reset();
			this.$refs.form.resetValidation();
			this.editedItem.phone = '';
			this.$emit("update:dialogData", false);
			this.isPhoneNumberEmpty = false;
			this.companyKeyError = [];
			this.disabledSaveButton = false;
			this.isKeyVerified = false;
			this.errorMessage = "";
			jQuery('.vue-tel-wrapper').removeClass('has-scrollbar');
			this.classText = "";
			this.isPhoneNumberInvalid = false;
			this.phoneNumberDetails = null;
		},
		async save() {
			if (!this.tagsInput.touched) this.tagsInput.touched = true

			this.$refs.form.validate()
			this.tagsInput.hasError = this.options.length > 0 ? false : true

			this.generateErrorMessage()

			setTimeout(async () => {
				let parms = {
					itemsPerPage: this.customerPagination.per_page,
					pageNumber: this.customerPagination.current_page,
				}

				let parmsSuppliers = {
					pageNumber: this.suppliersPagination.current_page
				}

				if (this.$refs.form.validate()) {
					if (!this.tagsInput.hasError && 
						!this.isPhoneNumberEmpty && 
						this.errorMessage === "" && 
						!this.isPhoneNumberInvalid) {

						try {
							jQuery(".ti-new-tag-input").trigger(
								jQuery.Event("keyup", { keyCode: 13, which: 13 })
							)

							if (this.editedItem.isSupplier) {
								// function if for supplier
								if (this.editedIndexData === -1) {
									let finalEmailAddress =
										this.options.length > 0
											? this.options.map((o) => {
												return o.text
											})
											: []

									let addSupplierTemplate = this.addSupplierTemplate
									addSupplierTemplate.emails = finalEmailAddress
									addSupplierTemplate.company_key = this.editedItem.company_key

									await this.createSuppliers(this.addSupplierTemplate).then(() => {
										
									})
									
									if (this.searchVendors !== '') {
										if (typeof this.getSearchedSuppliers !== 'undefined') {
											let page = typeof this.getSearchedSuppliers.current_page !== 'undefined' ?
												this.getSearchedSuppliers.current_page : 1

											await this.fetchSearchedVendorsAPI(page)
										}
									} else {
										if(this.fromComponent == 'BookingShipmentDialog'){
											this.$store.dispatch('booking/getShipperOptions', {
												page: 1
											})
										}
										await this.fetchSuppliers(parmsSuppliers)
									}
									if(this.fromComponent == 'BookingShipmentDialog'){
										this.$emit('createdUser',this.$store.getters["suppliers/getCreateSuppliers"])
									}
									this.editedIndexData = -1
									//close dialog
									this.close()
									this.notificationMessage("Vendor has been created.")
								} else {
									let finalEmailAddress =
										this.options.length > 0
											? this.options.map((o) => {
												return o.text
											})
											: []

									const {
										custom_customers_id,
										...newSupplierTemplate
									} = this.addSupplierTemplate
									newSupplierTemplate.id = this.editedItem.id
									newSupplierTemplate.emails = finalEmailAddress

									await this.updateSuppliers(newSupplierTemplate)
									console.log(custom_customers_id)
									this.notificationMessage("Vendor has been updated.")
									// close dialog
									this.close()

									if (this.searchVendors !== '') {
										if (typeof this.getSearchedSuppliers !== 'undefined') {
											let page = typeof this.getSearchedSuppliers.current_page !== 'undefined' ?
												this.getSearchedSuppliers.current_page : 1

											await this.fetchSearchedVendorsAPI(page)
										}
									} else {
										await this.fetchSuppliers(parmsSuppliers)
									}								
								}
							} else {
								// add function for creating and editing here if for customer
								if (this.editedIndexData === -1) {
									let finalEmailAddress =
										this.options.length > 0
											? this.options.map((o) => {
												return o.text
											})
											: []

									let addCustomerTemplate = this.addCustomerTemplate
									addCustomerTemplate.emails = finalEmailAddress
									addCustomerTemplate.company_key = this.editedItem.company_key
									
									await this.createCustomers(this.addCustomerTemplate);

									if (this.searchCustomers !== '') {
										if (typeof this.getSearchedCustomer !== 'undefined') {
											let page = typeof this.getSearchedCustomer.current_page !== 'undefined' ?
												this.getSearchedCustomer.current_page : 1

											await this.fetchSearchedCustomersAPI(page)
										}
									} else {
										if(this.fromComponent == 'BookingShipmentDialog'){
											this.$store.dispatch('booking/getConsigneeOptions', {
												page: 1
											})
										}
										await this.fetchCustomers(parms)
									}
									if(this.fromComponent == 'BookingShipmentDialog'){
										this.$emit('createdUser',this.$store.getters["customers/getCreateCustomers"]);
									}
									this.editedIndexData = -1
									//close dialog
									this.close();
									this.notificationMessage("Buyer has been created.")
								} else {
									let finalEmailAddress =
										this.options.length > 0
											? this.options.map((o) => {
												return o.text
											})
											: []

									const { ...newCustomerTemplate } = this.addCustomerTemplate
									newCustomerTemplate.id = this.editedItem.id
									newCustomerTemplate.emails = finalEmailAddress

									await this.updateCustomers(newCustomerTemplate)
									this.notificationMessage("Buyer has been updated.")

									// close dialog
									this.close()

									if (this.searchCustomers !== '') {
										if (typeof this.getSearchedCustomer !== 'undefined') {
											let page = typeof this.getSearchedCustomer.current_page !== 'undefined' ?
												this.getSearchedCustomer.current_page : 1

											await this.fetchSearchedCustomersAPI(page)
										}
									} else {
										await this.fetchCustomers(parms)
									}									
								}
							}
						} catch (e) {
							this.notificationError(e)
							console.log(e)
						}
					}
				}
			}, 200)
		},
		// disregard this function - backup only
		async saveAndAddSupplier() {
			if (!this.tagsInput.touched) this.tagsInput.touched = true

			this.$refs.form.validate()
			this.tagsInput.hasError = this.options.length > 0 ? false : true

			this.generateErrorMessage()

			setTimeout(async () => {
				if (this.$refs.form.validate()) {
					if (!this.tagsInput.hasError) {
						try {
							jQuery(".ti-new-tag-input").trigger(
								jQuery.Event("keyup", { keyCode: 13, which: 13 })
							)

							if (this.editedIndexData === -1) {
								let finalEmailAddress =
									this.options.length > 0
										? this.options.map((o) => {
												return o.text
											})
										: []

								this.addSupplierTemplate.customerMethod = "save-add"
								this.addSupplierTemplate.emails = finalEmailAddress
								await this.createSuppliers(this.addSupplierTemplate)

								await this.fetchSuppliers()
								this.setToDefault()

								this.notificationMessage("Vendor has been created.")
							}
						} catch (e) {
							this.notificationError(e)
							console.log(e)
						}
					}
				}
			}, 200)
		},
		async fetchSearchedVendorsAPI(page) {
			let passedData = {
				method: "get",
				url: `${baseURL}/v2/suppliers`,
				params: {
					qry: this.searchVendors,
					page
				}
			}

            try {
                passedData.tab = 'vendors'
                await this.fetchSearchedVendors(passedData)

				if (this.suppliersData.length === 0 && page !== 1) {
					passedData.params.page = page - 1
					await this.fetchSearchedVendors(passedData)
				}

				await this.fetchSuppliers(1)
            } catch(e) {
                this.notificationError(e)
                this.setSearchedVendorsLoading(false)
                console.log(e, 'Search error')
            }
		},
		async fetchSearchedCustomersAPI(page) {
			let passedData = {
				method: "get",
				url: `${baseURL}/v2/buyers`,
				params: {
					qry: this.searchCustomers,
					page
				}
			}

            try {
                passedData.tab = 'customers'
                await this.fetchSearchedCustomers(passedData)

				if (this.customersData.length === 0 && page !== 1) {
					passedData.params.page = page - 1
					await this.fetchSearchedCustomers(passedData)
				}

				let parms = {
					itemsPerPage: this.customerPagination.per_page,
					pageNumber: 1,
				}

				await this.fetchCustomers(parms)
            } catch(e) {
                this.notificationError(e)
                this.setSearchedCustomerLoading(false)
                console.log(e, 'Search error')
            }
		},
		setToDefault() {
			this.$emit("setToDefault")
			this.tagsInput.touched = false
			this.options = []
			this.companyKeyError = []
			this.disabledSaveButton = false
			this.isKeyVerified = false
		},
		async checkCompanyKey(e) {
			this.companyKeyError = [];
			this.disabledSaveButton = false;
			if (e) {
				this.resetDefaultValue();
				let payload = {
					key: e,
					tab: this.editedItem.isSupplier ? "vendor" : "buyer"
				}
				await this.checkCompanyKeyExists(payload)
				if (this.getCompanyKeyResponse) {
					await this.fetchCustomerDetails(e)
                    this.isKeyVerified = true
					const custDetailsData = this.getCustomerDetailsData
					this.editedItem.name = custDetailsData.company_name
					this.editedItem.display_name = custDetailsData.company_name
					if (custDetailsData.phone) {
						this.editedItem.phone = custDetailsData.phone
					}
					this.editedItem.address = custDetailsData.address
					if (custDetailsData.emails) {
						let newEmailLists = custDetailsData.emails.filter(v => { return v.email !== null })
						var result = newEmailLists.map(item => ({ "text": item.email,"tiClasses":["ti-valid"] }))
						this.options = result
					}
				} else {
					// this.companyKeyError = ["This key does not found..!"]
					this.companyKeyError = [`${this.getCompanyKeyError}`]
					this.disabledSaveButton = true
					this.isKeyVerified = false
				}
			}
		},
		getButtonClass() {
			let className = ''
			if (this.editedIndexData === -1) {
				if (this.companyKeyError.length > 0) {
					className = 'has-error'
				}
			} else {
				className = 'btn-disabled'
			}

			if (this.getCompanyKeyLoading) {
				className = 'is-verifying'
			}
			return className
		},		
		removeFieldsEdit() {
			this.isKeyVerified = false
			this.companyKeyError = []
			this.disabledSaveButton = false
			this.options = []
			this.tagsInput.touched = false
			this.editedItem = {
				address: "",
				company_key: "",
				emails: null,
				name: "",
				phone: "",
				warehouses: [],
				isSupplier: this.editedItemData.isSupplier,
				display_name: "",
				import_name_id: "",
				show_only_import_name: false
			}
		},
		checkEmailBeforeAdding(item) {
			if ((/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(item.tag.text)) {
                item.addTag();
                this.errorMessage = "";
                jQuery('.ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
            } else {
                this.errorMessage = "Please make sure to enter a valid email address";
                jQuery('.ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
            }
		},
		validatePhoneNumber(item) {
			if (typeof item.valid !== "undefined") {
				this.phoneNumberDetails = item
			}
		},
		resetDefaultValue() {
			this.isPhoneNumberEmpty = false;
			this.errorMessage = "";
			jQuery(".ti-input").removeClass("ti-new-tag-input-error");

			if (typeof this.$refs.form !== "undefined") {
				if (typeof this.$refs.form.resetValidation() !== "undefined") {
					this.$refs.form.resetValidation();
				}
			}
		},
		phoneDropdownClass() {
			const div = document.getElementsByClassName('dialog-card-text-content');
            let target = div[0];

			if (target !== undefined) {
				let basedHeight = this.editedItem.isSupplier ? 686 : 574;
				this.classText = (target.offsetHeight !== 0 && target.offsetHeight < basedHeight) ? "has-scrollbar" : "";
			} else {
				this.classText = "";
			}
		},
	},
	watch: {
		tagsInput(value) {
			if (value.hasError) {
				jQuery(".ti-input").addClass("ti-new-tag-input-error")
			} else {
				jQuery(".ti-input").removeClass("ti-new-tag-input-error")
			}
		},
		dialog(value, oldValue) {
			this.tagsInput.hasError = false
			this.tagsInput.touched = false

			this.allImportNameLists = _.cloneDeep(this.allImportNames)
            this.allImportNameListsCopy = _.cloneDeep(this.allImportNames)
            this.allImportNameLists = this.allImportNameLists.map((rest)=> ({class: '', ...rest}))
            this.allImportNameListsCopy = this.allImportNameListsCopy.map((rest)=> ({class: '', ...rest}))

			jQuery(".ti-input").removeClass("ti-new-tag-input-error")
			if (typeof el !== "undefined") {
				let el = document.getElementsByClassName("ti-input")[0]
				el.classList.remove("ti-new-tag-input-error")
			}

			if (!value) {
				this.options = []
				this.warehouseEmailAddress = ""
			} else if (value && !oldValue) {
				if (this.editedIndex == -1) {
					this.options = []
				} else {
					let validEmailAddress = []
					if (
						this.editedItem.emails !== null &&
						this.editedItem.emails.length > 0
					) {
						this.editedItem.emails.map((wea) => {
							if (wea?.email) {
								validEmailAddress.push({
									text: wea.email,
									tiClasses: ["ti-valid"],
								})
							} else if (wea !== null) {
								validEmailAddress.push({ text: wea, tiClasses: ["ti-valid"] })
							}
						})
					}
					this.options = validEmailAddress
				}
			}
		},
		'editedItem.company_key': {
			handler: function (value) {
				if (value === "") {
					this.companyKeyError = []
				} else {
					this.resetDefaultValue()
				}
            },
            deep: true
		},
		warehouseEmailAddress() {
			this.errorMessage = "";
			jQuery('.ti-input').removeClass('ti-new-tag-input-error');
			jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
		},
		'editedItem.name': {
			handler: function (value) {
				if (!this.isKeyVerified && this.editedIndexData === -1) {
					this.editedItem.display_name = value;
				}
            },
            deep: true
		},
		'editedItem.phone': {
			handler: function () {
				this.isPhoneNumberInvalid = false;
            },
            deep: true
		},
	},
	mounted() {
		if (this.allImportNames.length === 0) {
			this.fetchAllImportNamesDropdown(this.defaultCustomerId);
		}
	},
	updated() {
		if (this.editedIndexData === -1) {
			if (typeof this.$refs.form !== "undefined") {
				if (typeof this.$refs.form.resetValidation() !== "undefined") {
					this.$refs.form.resetValidation()
				}
			}
		} else {
			if (this.editedItem.company_key !== null && this.editedItem.company_key !== "") {
				this.isKeyVerified = true
			}
		}

		this.phoneDropdownClass();
	},
}
</script>

<style lang="scss">
@import "../../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../../assets/scss/pages_scss/supplier/supplierAddDialog.scss";

.v-autocomplete__content.v-menu__content {
	border-radius: 4px !important;
}

.v-tooltip__content.tooltip-wrapper.supplier {
	background-color: $shifl-neutral-n90 !important;
	width: 300px !important;
	max-width: 300px !important;
	padding-right: 10px !important;
	line-height: 20px !important;
}

.v-tooltip__content.tooltip-wrapper.supplier.menuable__content__active {
	opacity: 1 !important;
}

.tooltip-right::before {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -8px;
    border-width: 8px;
    border-style: solid;
    border-top: solid 8px transparent;
    border-bottom: solid 8px transparent;
    border-left: solid 8px transparent;
	border-right: solid 8px $shifl-neutral-n90 !important;
}
</style>
