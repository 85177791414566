<template>
	<div v-if="getEmailWarning" class="text-center notification-user-dailog-m">
		<v-dialog
			persistent
			v-model="getEmailWarning"
			fullscreen
			max-width="590px"
			:retain-focus="false"
		>
			<v-card class="add-supplier-card-m">
				<v-card-title style="caret-color: transparent">
					<span class="headline">Turn Off Email Notification</span>

					<button icon dark class="btn-close" @click="close">
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>
				<v-spacer></v-spacer>
				<v-card-text>
					<v-row>
						<v-icon size="52" class=" ml-3 mt-10" color="#B11B1B"
							>mdi-alert-circle-outline</v-icon
						>
						<v-col
							style="color: red"
							v-if="errorFound"
							cols="12"
							sm="12"
							md="12"
						>
							{{ errorMessage }}
						</v-col>
						<v-col class="mt-0" cols="12" md="12">
							<div style="margin-top:20px">
								<p class="heading-wn">
									Turn Off Email Notification
								</p>
								<p class="card-desc">
									Turning off this email notification may
									cause you miss critical shipment/booking
									information or updates
								</p>
							</div>
						</v-col>
					</v-row>
				</v-card-text>

				<v-card-actions>
					<div class="d-flex align-center justify-start">
						<button
							class="save-btn btn-blue"
							@click="save"
							:disabled="getLoading"
						>
							<span class="d-flex align-center justify-start">
								<span
									color="#fff"
									class="d-flex align-center justify-start"
								>
									<v-progress-circular
										:size="15"
										color="#fff"
										:width="2"
										indeterminate
										v-if="getLoading"
										style="margin-right: 6px;"
									>
									</v-progress-circular>
									{{
										getLoading
											? "Turning off..."
											: "Turn Off"
									}}
								</span>
							</span>
						</button>
						<button
							class="cancel-btn btn-white ml-2"
							@click="close"
							:disabled="getLoading"
						>
							Cancel
						</button>
					</div>
				</v-card-actions>

				<v-row style="margin-top: 0px"> </v-row>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { notificationsItems } from "../staticData";
import _ from "lodash";

export default {
	props: ["id"],
	components: {},
	data: () => {
		return {
			errorFound: 0,
			showError: false,
		};
	},
	computed: {
		...mapGetters("notificationNew", [
			"getEmailWarning",
			"getLoading",
			"getCustomer",
			"getCurrentEditItem",
		]),
	},
	methods: {
		...mapActions("notificationNew", ["updateEmailSettings"]),
		...mapMutations("notificationNew", [
			"SET_EMAIL_WARNING",
			"SET_CUSTOMER",
		]),

		close() {
			let customer = this.getCustomer;
			let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
			let findData = _.find(notificationsItems, e => (e.notificationTitle === currentTitle));

			if (findData !== undefined) {
				let emailField = `email_${findData.emailField}`;
				emailField = emailField === "email_booking_confirmation" ? "email_booking" : emailField;

				customer.email_recipient_setting[emailField] = true;
				this.SET_CUSTOMER(customer);
			}
			// switch (this.getCurrentEditItem) {
			// 	case "booking":
			// 		customer["email_booking"] = true;
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// 	case "booking_updates":
			// 		customer["email_booking_updates"] = true;
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// 	case "arrival_notice":
			// 		customer["email_arrival_notice"] = true;
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// 	default:
			// 		customer["email_delivery_order"] = true;
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// }
			this.showError = false;
			this.SET_EMAIL_WARNING(false);
			this.$emit("getNotificationData");
		},
		save() {
			let form = new FormData();
			form.append("status", false);
			form.append("customer_id", this.getCustomer.id);

			let emailField = this.getCurrentEditItem === "booking" ? "booking_confirmation" : this.getCurrentEditItem;
			form.append("type", emailField);
			this.updateEmailSettings(form);
			// switch (this.getCurrentEditItem) {
			// 	case "booking":
			// 		form.append("type", "booking");
			// 		break;
			// 	case "booking_updates":
			// 		form.append("type", "booking_updates");
			// 		break;
			// 	case "arrival_notice":
			// 		form.append("type", "arrival_notice");
			// 		break;
			// 	default:
			// 		form.append("type", "delivery_order");
			// }
			// this.updateEmailSettings(form);
		},
	},
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: #d0d5dd;
	border-width: 1px;
}
</style>

<style lang="scss">
.notification-user-dailog-m {
	height: 100vh !important;
	.email-users .v-input__slot .v-label {
		color: $shifl-neutral-n90;
		font-size: 14px;
		font-family: "Inter-Regular";
	}
}
.add-supplier-card-m {
	height: 100vh !important;
}
.notifi-type {
	font-size: 14px;
	color: $shifl-neutral-n90;
	font-family: "Inter-Medium";
}
.cancel-btn {
	padding: 10px 20px;
	border: 1px solid $shifl-primary-b90;
	color: $shifl-primary-b90 !important;
	border-radius: 4px;
	margin-left: 20px;
}
.save-btn {
	background-color: $shifl-primary-b90 !important;
	padding: 10px;
	border-radius: 4px;
	color: #fff;
}
</style>
