<template>
	<div class="email-warning-card">
		<v-dialog
			persistent
			v-model="getEmailWarning"
			max-width="490px"
			:retain-focus="false"
		>
			<v-card class="card-warn email-warn">
				<v-card-text>
					<v-row>
						<v-icon size="52" class=" ml-3 mt-10" color="#B11B1B"
							>mdi-alert-circle-outline</v-icon
						>
						<v-col cols="12" sm="12" md="12">
							<div style="margin-top:10px">
								<p class="heading-wn">
									Turn Off Email Notification
								</p>
								<p class="card-desc">
									Turning off this email notification may
									cause you miss critical shipment/booking
									information or updates
								</p>
							</div>
						</v-col>

						<div
							class=""
							style="padding-bottom: 10px; padding-left: 12px;"
						>
							<div class="d-flex align-center justify-start">
								<button
									class="save-btn btn-blue"
									@click="setEmailNotification"
									:disabled="getLoading"
								>
									<span
										class="d-flex align-center justify-start"
									>
										<span
											color="#fff"
											class="d-flex align-center justify-start"
										>
											<v-progress-circular
												:size="15"
												color="#fff"
												:width="2"
												indeterminate
												v-if="getLoading"
												style="margin-right: 6px;"
											>
											</v-progress-circular>
											{{
												getLoading
													? "Turning off..."
													: "Turn Off"
											}}
										</span>
									</span>
								</button>
								<button
									class="cancel-btn btn-white ml-2"
									@click="close"
									:disabled="getLoading"
								>
									Cancel
								</button>
							</div>
						</div>
					</v-row>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { notificationsItems } from "../staticData";
import _ from "lodash";

export default {
	data: () => {
		return {
			errorSaving: 0,
			secondTime: null,
			showError: false,
		};
	},
	computed: {
		...mapGetters("notificationNew", [
			"getLoading",
			"getEmailWarning",
			"getCustomer",
			"getCurrentEditItem",
		]),
	},

	methods: {
		...mapActions("notificationNew", ["updateEmailSettings"]),
		...mapMutations("notificationNew", [
			"SET_EMAIL_WARNING",
			"SET_CUSTOMER",
			"SET_CURRENT_EDIT_ITEM",
		]),
		close() {
			let customer = this.getCustomer;
			let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
			let findData = _.find(notificationsItems, e => (e.notificationTitle === currentTitle));

			if (findData !== undefined) {
				let emailField = `email_${findData.emailField}`;
				emailField = emailField === "email_booking_confirmation" ? "email_booking" : emailField;

				customer.email_recipient_setting[emailField] = true;
				this.SET_CUSTOMER(customer);
			}
			// switch (this.getCurrentEditItem) {
			// 	case "booking":
			// 		customer["email_booking"] = true;
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// 	case "booking_updates":
			// 		customer["email_booking_updates"] = true;
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// 	case "arrival_notice":
			// 		customer["email_arrival_notice"] = true;
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// 	default:
			// 		customer["email_delivery_order"] = true;
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// }
			this.SET_EMAIL_WARNING(false);
			this.SET_CURRENT_EDIT_ITEM("");
			this.$emit("getNotificationData");
		},

		setEmailNotification() {
			let form = new FormData();
			form.append("status", false);
			form.append("customer_id", this.getCustomer.id);

			let emailField = this.getCurrentEditItem === "booking" ? "booking_confirmation" : this.getCurrentEditItem;
			form.append("type", emailField);
			this.updateEmailSettings(form);

			// switch (this.getCurrentEditItem) {
			// 	case "booking":
			// 		form.append("type", "booking_confirmation");
			// 		break;
			// 	case "booking_updates":
			// 		form.append("type", "booking_updates");
			// 		break;
			// 	case "arrival_notice":
			// 		form.append("type", "arrival_notice");
			// 		break;
			// 	case "booking_request":
			// 		form.append("type", "booking_request");
			// 		break;
			// 	case "consolidation_request":
			// 		form.append("type", "consolidation_request");
			// 		break;
			// 	default:
			// 		form.append("type", "delivery_order");
			// }
			
		},
	},
};
</script>
<style lang="scss">
.heading-wn {
	font-size: 20px;
	font-family: "Inter-SemiBold", sans-serif !important;
	color: #253041;
}
.card-desc {
	font-size: 14px !important;
	color: #253041;
	font-family: "Inter-Regular";
}

.email-warn.v-card.v-sheet {
	overflow: hidden !important;
}
</style>