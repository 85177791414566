<!-- @format -->

<template>
	<div>
		<v-dialog
			v-model="dialog"
			width="560px"
			@click:outside="close"
			content-class="booking-request-templates-dialog"
			persistent
			scrollable
		>
			<v-card class="view-booking-card">
				<v-card-title class="view-document-title">
					<span class="headline">Booking Request Templates</span>
					<button icon dark class="btn-close" @click="close">
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>
				<v-card-text class="booking-templates">
					<div
						class="preloader d-flex w-full justify-center align-center pt-4 pb-4 template-loder"
						v-if="getFetchBookingTemplateLoading"
					>
						<v-progress-circular :size="40" color="#0171a1" indeterminate>
						</v-progress-circular>
					</div>
					<div v-else>
						<div class="input-field-wrapper search-shiiper-template">
							<v-text-field
								v-model="search"
								:height="40"
								placeholder="Search shipper or template name"
								width="200px"
								type="text"
								dense
								outlined
								hide-details="auto"
								prepend-inner-icon="mdi-magnify"
							/>
						</div>
						<div
							class="booking-templates-list"
							v-if="bookingTemplates.length > 0"
						>
							<div
								class="templates"
								v-for="(template, index) in bookingTemplates"
								:key="index"
							>
								<h4>
									<div v-html="highlightMatches(template.template_name)"></div>
									<span class="action-buttons">
										<v-menu
											offset-y
											bottom
											left
											content-class="pending-dropdown-container"
										>
											<template v-slot:activator="{ on, attrs }">
												<button
													v-bind="attrs"
													v-on="on"
													class="three-dots-container"
												>
													<img
														src="@/assets/icons/edit-blue.svg"
														width="14px"
														height="14px"
														alt="Edit"
														title="Edit"
													/>
												</button>
											</template>
											<v-list>
												<v-list-item>
													<v-list-item-title
														class="k-fw-sm k-font-inter-regular text-primary d-flex"
														@click="renameTemplate(template)"
														style="color: #0171A1;"
													>
														Rename Template
													</v-list-item-title>
												</v-list-item>
												<v-list-item>
													<v-list-item-title
														class="k-fw-sm k-font-inter-regular d-flex"
														@click="editFormData(template)"
													>
														Edit Form Data
													</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
										<button @click="confirmDeleteTemplate(template)">
											<img
												src="@/assets/icons/deleteRed.svg"
												width="14px"
												height="14px"
												alt="Delete"
												title="Delete"
											/>
										</button>
									</span>
								</h4>
								<div class="mb-0 shipper-name d-flex">
									<span class="label">Shipper:</span>
									<div class="ml-1" v-html="highlightMatches(shipperName(template.company_names))"></div>
								</div>
								<p
									class="mb-0 template-descd-inline-block text-truncate"
									style="max-width: 4000px;"
								>
									{{
										template.template_description
											? template.template_description
											: "N/A"
									}}
								</p>
							</div>
						</div>
						<div class="booking-templates-list text-center mt-6" v-else>
							<h3 class="heading-empty">You have no templates</h3>
							<p class="para mt-2">
								It looks like you don't have any templates at the moment, but
								don't worry - we'll keep show you once you create any template
							</p>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
		<BookingTemplateDialog
			v-if="bookingRequestTemplateDialog"
			:renameTemplateData="renameTemplateData"
			:showDialog="bookingRequestTemplateDialog"
			:fromBookingTemplates="true"
			@close="bookingRequestTemplateDialog = false"
			@saveAsTemplate="saveAsTemplate"
		/>

		<BookingShipmentDialogV2
			:key="componentKey"
			:show.sync="viewBookingShipmentDialog"
			:editTemplateId="editTemplateId"
			:reference="'template-booking-shipment'"
			@close="viewBookingShipmentDialog = false"
		/>

		<ConfirmDialog
			:dialogData.sync="deleteConfirmationDialog"
			v-if="deleteConfirmationDialog"
		>
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/alert.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Delete Booking Template</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Are you sure you want to
					<strong>{{ deleteTemplateData.template_name }}</strong> from booking
					request templates?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="deleteTemplate(deleteTemplateData)"
					:disabled="getDeleteBookingTemplateLoading"
				>
					Yes, Delete
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="deleteConfirmationDialog = false"
					:disabled="getDeleteBookingTemplateLoading"
				>
					Cancel
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog
			:dialogData.sync="existTemplateNameDialog"
			v-if="existTemplateNameDialog"
		>
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Template with same Name already exist</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					There is already a template with similar name
					<strong>{{ renameTemplateName }}</strong
					>, Do you want to replace?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					@click="changeTemplateName()"
					text
					:disabled="createUpdateLoading"
				>
					Change Name
				</v-btn>

				<v-btn
					class="btn-white"
					text
					@click="replaceTemplate()"
					:disabled="createUpdateLoading"
				>
					Replace
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import globalMethods from "../../../../utils/globalMethods";
import ConfirmDialog from "../../../Dialog/GlobalDialog/ConfirmDialog.vue";
import BookingShipmentDialogV2 from "../BookingShipmentDialogV2.vue";
import BookingTemplateDialog from "./BookingTemplateDialog.vue";

export default {
	name: "ViewBookingTemplatesDialog",
	props: ["dialogData"],
	components: { BookingTemplateDialog, ConfirmDialog, BookingShipmentDialogV2 },
	data: () => ({
		search: "",
		renameTemplateData: null,
		deleteTemplateData: null,
		bookingRequestTemplateDialog: false,
		userDetails: null,
		deleteConfirmationDialog: false,
		existTemplateId: null,
		existTemplateNameDialog: false,
		viewBookingShipmentDialog: false,
		editTemplateId: null,
		componentKey: 0,
		renameTemplateName: null,
		createdPayload: null
	}),
	async mounted() {
		try {
			this.userDetails = await JSON.parse(this.getUser);
		} catch (e) {
			this.userDetails = await this.getUser;
		}
	},
	computed: {
		...mapGetters({
			getUser: "getUser",
			getFetchBookingTemplateLoading:
				"bookingTemplate/getFetchBookingTemplateLoading",
			getDeleteBookingTemplateLoading:
				"bookingTemplate/getDeleteBookingTemplateLoading",
		}),
		dialog: {
			get() {
				return this.dialogData;
			},
			set(value) {
				this.$emit("update:dialogData", value);
			},
		},
		bookingTemplates() {
			let templates = this.$store.getters[
				"bookingTemplate/getBookingTemplatesList"
			];
			if (this.search.length > 0) {
				templates = templates.filter((item) => {
					return (
						item.template_name
							.toLowerCase()
							.includes(this.search.toLowerCase()) ||
						item.company_names.toLowerCase().includes(this.search.toLowerCase())
					);
				});
			} else {
				templates = this.$store.getters[
					"bookingTemplate/getBookingTemplatesList"
				];
			}
			return templates;
		},
		createUpdateLoading() {
			let create = this.$store.getters[
				"bookingTemplate/getCreateBookingTemplateLoading"
			];
			let update = this.$store.getters[
				"bookingTemplate/getUpdateBookingTemplateLoading"
			];
			return create || update;
		},
	},
	methods: {
		...globalMethods,
		close() {
			this.$emit("close");
			this.renameTemplateData = null;
			this.deleteTemplateData = null;
		},
		shipperName(name) {
			return name ? name.replace(";", ", ") : "N/A";
		},
		renameTemplate(item) {
			this.bookingRequestTemplateDialog = true;
			this.renameTemplateData = item;
			this.$emit("close");
		},
		editFormData(item) {
			this.componentKey += 1;
			this.editTemplateId = item.id;
			this.viewBookingShipmentDialog = true;
		},
		async fetchBookingTemplate() {
			await this.$store.dispatch(
				"bookingTemplate/fetchBookingTemplate",
				this.userDetails?.default_customer?.id
			);
		},
		saveAsTemplate(item) {
			this.createdPayload = null;
			let templatePayload = {
				created_by: this.userDetails?.default_customer?.id,
				templateId: item.id,
				...item,
			};
			this.createdPayload = templatePayload;
			this.$store
				.dispatch("bookingTemplate/updateBookingTemplate", templatePayload)
				.then((res) => {
					if (res.status == 200) {
						if (res.data.is_template_exist) {
							this.existTemplateId = res.data.template_id;
							this.existTemplateNameDialog = true;
							this.renameTemplateName = templatePayload.template_name;							
						} else {
							this.notificationMessage(
								`Template '${item.template_name}' has been updated`
							);
							this.bookingRequestTemplateDialog = false;
							this.renameTemplateData = null;
							this.fetchBookingTemplate();
						}
					}
				})
				.catch((e) => {
					this.notificationError(
						"An unexpected error occured. Please try again"
					);
					console.log(e);
				});
		},
		replaceTemplate() {
			let templatePayload = {
				created_by: this.userDetails?.default_customer?.id,
				templateId: this.createdPayload.id,
				...this.createdPayload,
			};
			this.$store
				.dispatch("bookingTemplate/updateBookingTemplate", templatePayload)
				.then((res) => {
					if (res.status == 200) {
						this.notificationMessage(
							`Template '${this.createdPayload.template_name}' has been updated`
						);
						this.bookingRequestTemplateDialog = false;
						this.renameTemplateData = null;
						this.fetchBookingTemplate();
					}
				})
				.catch((e) => {
					this.notificationError(
						"An unexpected error occured. Please try again"
					);
					console.log(e);
				});
		},
		confirmDeleteTemplate(template) {
			this.deleteConfirmationDialog = true;
			this.deleteTemplateData = template;
		},
		async deleteTemplate(template) {
			await this.$store
				.dispatch("bookingTemplate/deleteBookingTemplate", template.id)
				.then((res) => {
					if (res.status == 200) {
						this.notificationMessage(
							`Template '${template.template_name}' has been deleted`
						);
						this.deleteConfirmationDialog = false;
						this.deleteTemplateData = null;
						this.fetchBookingTemplate();
					}
				})
				.catch((e) => {
					this.notificationError(
						"An unexpected error occured. Please try again"
					);
					console.log(e);
				});
		},
		highlightMatches(text) {
			if (this.search.length > 0) {
				const re = new RegExp(this.search, "ig");
				return text.replace(
					re,
					(matchedText) => `<span class="highlighted-text">${matchedText}</span>`
				);
			} else {
				return text;
			}
		},
		changeTemplateName() {
			this.existTemplateNameDialog = false;
			this.bookingRequestTemplateDialog = true;
		},
	},
};
</script>

<style lang="scss">
@import "./scss/ViewBookingTemplates.scss";
</style>
