export let stateData = {
	booking: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	booking_updates: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	document_upload: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	departure: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	arrival: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	delivery_order: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	etd_change_alert: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	eta_change_alert: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	booking_request: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	consolidation_request: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	discharged_alert: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	lfd_alert: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
	unreturned_container_alert: [
		"Push Notification Turned Off",
		"Active (Anytime)",
		"Working Hours (8AM-5PM)",
		"Custom Time",
	],
};

export let notificationsItems = [
	{
		notificationTitle: "Booking Request",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "booking_request",
		refName: "booking_request",
		pushField: "booking_request",
		emailField: "booking_request",
		emailListField: "booking_request_emails",
	},
	{
		notificationTitle: "New Schedules & Updates",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "booking_updates",
		refName: "booking_updates",
		pushField: "booking_updates",
		emailField: "booking_updates",
		emailListField: "booking_and_updated_emails",
	},
	{
		notificationTitle: "Booking Confirmation",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "booking",
		refName: "booking",
		pushField: "booking_confirmation",
		emailField: "booking_confirmation",
		emailListField: "booking_confirmation_emails",
	},
	{
		notificationTitle: "Consolidation Request",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "consolidation_request",
		refName: "consolidation_request",
		pushField: "consolidation_request",
		emailField: "consolidation_request",
		emailListField: "consolidation_request_emails",
	},
	{
		notificationTitle: "Document Upload",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "document_upload",
		refName: "document_upload",
		pushField: "document_upload",
		emailField: "document_upload",
		emailListField: "document_upload_emails",
	},
	{
		notificationTitle: "Departure Notice",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "departure_notice",
		refName: "departure",
		pushField: "departure_notice",
		emailField: "departure_notice",
		emailListField: "departure_notice_emails",
	},
	{
		notificationTitle: "Arrival Notice",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "arrival_notice",
		refName: "arrival",
		pushField: "arrival_notice",
		emailField: "arrival_notice",
		emailListField: "arrival_notice_emails",
	},
	{
		notificationTitle: "Delivery Order",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "delivery_order",
		refName: "delivery_order",
		pushField: "delivery_order",
		emailField: "delivery_order",
		emailListField: "delivery_order_emails",
	},
	{
		notificationTitle: "ETD Change Alert (Shipment)",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "etd_change_alert",
		refName: "etd_change_alert",
		pushField: "etd_change_alert",
		emailField: "etd_change_alert",
		emailListField: "etd_alert_emails",
	},
	{
		notificationTitle: "ETA Change Alert (Shipment)",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "eta_change_alert",
		refName: "eta_change_alert",
		pushField: "eta_change_alert",
		emailField: "eta_change_alert",
		emailListField: "eta_alert_emails",
	},
	{
		notificationTitle: "Container Discharge Alert",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "discharged_alert",
		refName: "discharged_alert",
		pushField: "discharged_alert",
		emailField: "discharged_alert",
		emailListField: "discharge_alert_emails",
	},
	{
		notificationTitle: "LFD Alert",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "lfd_alert",
		refName: "lfd_alert",
		pushField: "lfd_alert",
		emailField: "lfd_alert",
		emailListField: "lfd_alert_emails",
	},
	{
		notificationTitle: "Unreturned Container Alert",
		notificationEmail: 0,
		notificationPush: "OFF",
		notificationPushValue: "",
		notifPushBooleanVal: false,
		emails: [],
		isFor: "unreturned_container_alert",
		refName: "unreturned_container_alert",
		pushField: "unreturned_container_alert",
		emailField: "unreturned_container_alert",
		emailListField: "unreturned_container_alert_emails",
	},
]

export const unreturnedContainerList = [
	{
		text: "0",
		value: 0
	},
	{
		text: "2",
		value: 2
	},
	{
		text: "3",
		value: 3
	},
	{
		text: "4",
		value: 4
	},
	{
		text: "5",
		value: 5
	},
	{
		text: "6",
		value: 6
	},
]