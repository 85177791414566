<!-- @format -->

<template>
	<v-dialog
		v-model="dialog"
		max-width="560px"
		content-class="upload-documents upload-doc-dialog"
		v-resize="onResize"
		@click:outside="close"
		scrollable
	>
		<v-card class="create-shipment-card upload-doc-card">
			<v-card-title>
				<span class="headline">{{ formTitle }}</span>
				<button icon dark class="btn-close" @click="close">
					<v-icon>mdi-close</v-icon>
				</button>
			</v-card-title>

			<v-card-text>
				<v-form ref="form" v-model="valid" action="#" @submit.prevent="">
					<div class="upload-documents-wrapper" v-if="isCurrentSelectedCustomer || isFrom === 'to-dos' || 
						getSelectedDocumentUpload === null">
						<!-- <div class="doc-if-empty" v-if="files == false && documentFiles.length == 0 && !dialogMessage">
							<img src="@/assets/icons/info-blue.svg" alt="" width="20px" height="20px">
							<p >Please upload Commercial Invoice and Packing List</p>
						</div> -->

						<div
							class="red lighten-5 body-2 d-flex align-center pa-2 mb-4"
							v-if="dialogMessage"
						>
							<div class="d-flex">
								<img
									src="../../../assets/icons/alert.svg"
									alt=""
									width="16px"
									height="16px"
									class="mr-3"
								/>
							</div>
							<span style="color: #253041;"
								>Please upload the Commercial Invoice and the Packing List for
								each of the supplier.</span
							>
						</div>

						<div class="upload-documents">
							<div v-if="!isFetchingDocumentReupload">
								<div
									id="upload-documents-select-box-id"
									class="upload-documents-select-box"
									@click.stop="addDocuments('upload_documents_reference')"
								>
									<span>Browse files here</span>

									<button class="btn-white" @click.stop="addDocuments('upload_documents_reference')">
										<img
											src="@/assets/icons/upload.svg"
											width="16px"
											height="16px"
										/>
										Upload
									</button>
								</div>
								<input
									ref="upload_documents_reference"
									type="file"
									id="upload_documents"
									@change="(e) => inputChanged(e, 'upload_documents_reference')"
									name="upload_documents"
									accept=""
									:multiple="editedUploadIndex === -1"
								/>
							</div>

							<div v-else class="d-flex justify-center align-center pa-6">
								<v-progress-circular
									:size="40"
									color="#1A6D9E"
									indeterminate
									v-if="isFetchingDocumentReupload">
								</v-progress-circular>
							</div>

							<div class="document-lists" v-if="documentFiles.documentFilesDefault.length > 0">
								<div
									class="lists-items"
									v-for="(documentFile, index) in documentFiles.documentFilesDefault"
									:key="`d-files-${index}`"
								>
									<div class="items">
										<img
											src="@/assets/icons/shipment-document-blue.svg"
											width="45px"
											height="45px"
											class="mr-2"
											v-if="!isMobile"
										/>
										<div>
											<div
												:class="
													`document-info ${
														(documentFile.nameError &&
														(documentFile.name === '' ||
														documentFile.name === null)) ||
														documentFile.fileError
															? 'div-has-error'
															: ''
													}`
												"
											>
												<div>
													<img
														src="@/assets/icons/shipment-document-blue.svg"
														width="45px"
														height="45px"
														class="mr-2"
														v-if="isMobile"
													/>
													<input v-model="documentFile.name" />
												</div>
												<div v-if="!isMobile">
													<div class="file-size-info">
														<span>
															[ {{ getFileSize(documentFile.file.size) }}
															<span class="separator mx-1">*</span>
															<span v-if="editedUploadIndex > -1" class="mr-1">
																{{ getReuploadDocumentExtension(documentFile) }}
															</span>
															<span v-else class="mr-1">
																{{ documentFile.file.name.split(".").pop() }}
															</span>
															]
														</span>
													</div>
													<a
														href="#"
														class="file-remove"
														@click="removeFile(index, 'documentFilesDefault')"
													>
														<img
															src="@/assets/icons/close-red.svg"
															width="16px"
															height="16px"
														/>
													</a>
												</div>
											</div>

											<div class="document-info-below">
												<div class="document-info-content first-content">
													<span class="document-supplier-title" v-if="isMobile"
														>Type</span
													>
													<div class="document-info-content">
														<div
															:class="
																documentFile.typeError &&
																(documentFile.type === '' ||
																	documentFile.type === null)
																	? 'has-error w-100'
																	: 'w-100'
															"
														>
															<v-select
																class="select-product"
																:class="type !== '' ? 'has-value' : ''"
																:items="
																	!dialogMessage
																		? documentTypes
																		: documentTypesToDos
																"
																item-text="name"
																item-value="value"
																placeholder="Select Type"
																outlined
																v-model="documentFile.type"
																hide-details="auto"
																:menu-props="{
																	contentClass:
																		'supplier-lists-contents types fixedWidth',
																	bottom: true,
																	offsetY: true,
																}"
															>
																<template v-slot:item="{ item }">
																	<div class="option-items">
																		<span>{{ item.name }}</span>

																		<img
																			src="@/assets/icons/check-blue.svg"
																			alt=""
																		/>
																	</div>
																</template>
															</v-select>
														</div>
													</div>
												</div>

												<div
													class="document-info-content first-content"
													v-if="
														documentFile.type !== '' &&
															documentFile.type.toLowerCase() !== 'other' &&
															documentFile.type.toLowerCase() !== 'delivery order' &&
															supplierOptions.length > 0
													"
												>
													<span class="document-supplier-title">Supplier</span>
													<div class="document-info-content">
														<div
															:class="
																documentFile.supplierError &&
																documentFile.supplier_id.length === 0
																	? 'has-error w-100'
																	: 'w-100'
															"
														>
															<v-select
																class="select-product"
																:class="
																	documentFile.supplier_id.length > 0
																		? 'has-value'
																		: ''
																"
																:items="supplierOptions"
																item-text="name"
																item-value="id"
																return-object
																placeholder="Select Supplier"
																outlined
																v-model="documentFile.supplier_id"
																hide-details="auto"
																:menu-props="{
																	contentClass: 'supplier-lists-contents',
																	bottom: true,
																	offsetY: true,
																}"
															>
																<!-- multiple -->

																<!-- <template v-slot:selection="{ item, index }">
																	<span v-if="index === 0 && documentFile.supplier_id.length === 1">
																		{{ item.name }}
																	</span>

																	<span v-if="index === 0 && documentFile.supplier_id.length > 1" 
																		style="color: #253041;">
																		Multiple Suppliers
																	</span>
																</template>
																<template v-slot:item="{ item, attrs, on }">
																	<v-list-item v-on="on" v-bind="attrs" #default="{ active }">
																		<v-list-item-action>
																			<v-checkbox :input-value="active"></v-checkbox>
																		</v-list-item-action>

																		<v-list-item-content>
																			<v-list-item-title>
																				<v-row no-gutters align="center">
																					<span>{{ item.name }}</span>
																				</v-row>
																			</v-list-item-title>
																		</v-list-item-content>
																	</v-list-item>
																</template> -->
															</v-select>
														</div>
													</div>
												</div>

												<div v-if="isMobile" class="document-file-size-wrapper">
													<div class="file-size-info">
														<span>
															[ {{ getFileSize(documentFile.file.size) }}
															<span class="separator mx-1">*</span>
															<span v-if="editedUploadIndex > -1" class="mr-1">
																{{ getReuploadDocumentExtension(documentFile) }}
															</span>
															<span v-else class="mr-1">
																{{ documentFile.file.name.split(".").pop() }}
															</span>
															]
														</span>
													</div>
													<a
														href="#"
														class="file-remove"
														@click="removeFile(index, 'documentFilesDefault')"
													>
														<img
															src="@/assets/icons/close-red.svg"
															width="16px"
															height="16px"
														/>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="upload-documents-wrapper" v-if="!isCurrentSelectedCustomer && isFrom !== 'to-dos' &&
						getSelectedDocumentUpload !== null">
						<div class="upload-request-from-wrapper mb-4" 
							v-if="editedUploadIndex === -1 && 
								(getSelectedDocumentUpload !== null && getSelectedDocumentUpload.isFromRequest !== undefined)">

							<div class="d-flex align-center">
								<img src="@/assets/icons/message-icon.svg" class="mr-2" alt="message" width="18px" height="18px">
								<p class="font-semi-bold mb-0">Request from  {{ getCustomerOfCurrentShipment }}</p>
							</div>
							<div class="pt-1" style="padding-left: 26px; color: #253041; font-size: 14px;"
								v-if="getSelectedDocumentUpload !== null && getSelectedDocumentUpload.notes !== null">
								{{ getSelectedDocumentUpload.notes }}
							</div>
						</div>

						<div v-if="!isFetchingDocumentReupload" :class="editedUploadIndex === -1 ? 'upload' : 'reupload'">
							<div class="for-commercial-invoice" 
								v-if="editedUploadIndex === -1 ? documentTypeExist('commercial_invoice') : isCommercialInvoiceTypeExist">
								<UploadComponent 
									:uploadDocTitle="'Upload Commercial Invoice'"
									:uploadDocType="'commercial_invoice'"
									:refName="'upload_reference_commercial_invoice'"
									ref="upload_reference_commercial_invoice"
									@inputChanged="(e) => inputChanged(e, 'upload_reference_commercial_invoice')"
									@addDocuments="addDocuments('upload_reference_commercial_invoice')"
									:documentLists="documentFiles.documentFilesInvoice"
									:isMobile="isMobile"
									:docTitle="'Commercial Invoice'"
									:dialogMessage="dialogMessage"
									@removeFile="(index) => removeFile(index, 'documentFilesInvoice')"
									:type.sync="type"
									:fieldErrors="fieldErrors.invoice"
									:editedUploadIndex="editedUploadIndex"
									:filePath="filePath"
									:fromLoggedOut="false"
								/>
							</div>
							
							<div class="for-packing-list"
								v-if="editedUploadIndex === -1 ? documentTypeExist('packing_list') : isPackingListTypeExist">
								<UploadComponent 
									:uploadDocTitle="'Upload Packing List'"
									:uploadDocType="'packing_list'"
									:refName="'upload_reference_packing_list'"
									ref="upload_reference_packing_list"
									@inputChanged="(e) => inputChanged(e, 'upload_reference_packing_list')"
									@addDocuments="addDocuments('upload_reference_packing_list')"
									:documentLists="documentFiles.documentFilesPacking"
									:isMobile="isMobile"
									:docTitle="'Packing List'"
									:dialogMessage="dialogMessage"
									@removeFile="(index) => removeFile(index, 'documentFilesPacking')"
									:type.sync="type"
									:fieldErrors="fieldErrors.packing"
									:editedUploadIndex="editedUploadIndex"
									:filePath="filePath"
									:fromLoggedOut="false"
								/>
							</div>

							<div class="for-commercial-packing-list"
								v-if="editedUploadIndex === -1 ? documentTypeExist('commercial_and_packing') : isCommercialAndPackingTypeExist">
								<UploadComponent 
									:uploadDocTitle="'Upload Commercial Invoice & Packing List'"
									:uploadDocType="'commercial_and_packing'"
									:refName="'upload_reference_commercial_and_packing'"
									ref="upload_reference_commercial_and_packing"
									@inputChanged="(e) => inputChanged(e, 'upload_reference_commercial_and_packing')"
									@addDocuments="addDocuments('upload_reference_commercial_and_packing')"
									:documentLists="documentFiles.documentBothTypes"
									:isMobile="isMobile"
									:docTitle="'Packing List'"
									:dialogMessage="dialogMessage"
									@removeFile="(index) => removeFile(index, 'documentBothTypes')"
									:type.sync="type"
									:fieldErrors="fieldErrors.packing"
									:editedUploadIndex="editedUploadIndex"
									:filePath="filePath"
									:fromLoggedOut="false"
								/>
							</div>

							<div class="for-others" 
								v-if="editedUploadIndex === -1 ? true : isOtherDocumentTypeExists">
								<UploadComponent 
									:uploadDocTitle="
										(editedUploadIndex === -1 && (getSelectedDocumentUpload !== null && getSelectedDocumentUpload.isFromRequest !== undefined)) 
										? 'Other Documents' : 'Documents'"
									:uploadDocType="'other'"
									:refName="'upload_reference_other'"
									ref="upload_reference_other"
									@inputChanged="(e) => inputChanged(e, 'upload_reference_other')"
									@addDocuments="addDocuments('upload_reference_other')"
									:documentLists="documentFiles.documentFilesOthers"
									:isMobile="isMobile"
									:docTitle="''"
									:dialogMessage="dialogMessage"
									@removeFile="(index) => removeFile(index, 'documentFilesOthers')"
									:type.sync="type"
									:fieldErrors="fieldErrors.others"
									:editedUploadIndex="editedUploadIndex"
									:filePath="filePath"
									:fromLoggedOut="false"
									:getSelectedDocumentUpload="getSelectedDocumentUpload"
								/>
							</div>
						</div>

						<div v-else class="d-flex justify-center align-center pa-6">
							<v-progress-circular
								:size="40"
								color="#1A6D9E"
								indeterminate
								v-if="isFetchingDocumentReupload">
							</v-progress-circular>
						</div>
					</div>
				</v-form>
			</v-card-text>

			<v-card-actions v-if="files.length !== 0 && !isFetchingDocumentReupload">
				<v-btn
					class="btn-blue"
					text
					@click="confirmUpload"
					:disabled="files.length == 0 || getShipmentDocumentsUploadLoading || isReuploading"
				>
					<!-- IF NOT -->
					<span v-if="editedUploadIndex === -1">
						{{ getShipmentDocumentsUploadLoading ? "Uploading..." : "Confirm Upload" }}
					</span>

					<span v-else>
						{{ isReuploading ? "Reuploading..." : "Reupload" }}
					</span>					

					<!-- IF LOADING -->
					<!-- Uploading (percentage here)% -->
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="close"
					:disabled="getShipmentDocumentsUploadLoading || isReuploading">
					<!-- v-if="!isMobile" -->
					Cancel
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import globalMethods from "../../../utils/globalMethods";
import { mapActions, mapGetters } from "vuex";
import UploadComponent from './UploadComponent.vue';

const baseUrl = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import { types } from "../../../constants/mimeTypes";

export default {
	name: "UploadDocumentsDialog",
	props: [
		"editedItemData",
		"dialogData",
		"documentsData",
		"shipment_id",
		"dialogMessage",
		"isCurrentSelectedCustomer",
		"selectedDocumentRequest",
		"getDetails",
		"editedUploadIndex",
		"isFrom"
	],
	components: {
		UploadComponent
	},
	data: () => ({
		valid: true,
		isMobile: false,
		files: {
			documentFilesInvoice: [],
			documentFilesPacking: [],
			documentFilesOthers: [],
			documentFilesDefault: [],
			documentBothTypes: []
		},
		documentFiles: {
			documentFilesInvoice: [],
			documentFilesPacking: [],
			documentFilesOthers: [],
			documentFilesDefault: [],
			documentBothTypes: []
		},
		documentTypes: [
			{
				id: 0,
				value: "commercial invoice",
				name: "Commercial Invoice",
			},
			{
				id: 1,
				value: "packing list",
				name: "Packing List",
			},
			{
				id: 2,
				value: "invoice and packing list",
				name: "Invoice and Packing List",
			},
			{
				id: 3,
				value: "hbl",
				name: "OBL Document",
			},
			{
				id: 4,
				value: "other commercial docs",
				name: "Other Commercial Docs",
			},
			{
				id: 5,
				value: "delivery order",
				name: "Delivery Order",
			},
			{
				id: 6,
				value: "other",
				name: "Other",
			},
		],
		documentTypesCopy: [
			{
				id: 0,
				value: "commercial invoice",
				name: "Commercial Invoice",
			},
			{
				id: 1,
				value: "packing list",
				name: "Packing List",
			},
			{
				id: 2,
				value: "invoice and packing list",
				name: "Invoice and Packing List",
			},
			{
				id: 3,
				value: "hbl",
				name: "OBL Document",
			},
			{
				id: 4,
				value: "other commercial docs",
				name: "Other Commercial Docs",
			},
			{
				id: 5,
				value: "delivery order",
				name: "Delivery Order",
			},
			{
				id: 6,
				value: "other",
				name: "Other",
			},
		],
		documentTypesToDos: [
			{
				id: 0,
				value: "commercial invoice",
				name: "Commercial Invoice",
			},
			{
				id: 1,
				value: "packing list",
				name: "Packing List",
			},
			{
				id: 2,
				value: "invoice and packing list",
				name: "Invoice and Packing List",
			},
			{
				id: 3,
				value: "hbl",
				name: "OBL Document",
			},
			{
				id: 4,
				value: "other commercial docs",
				name: "Other Commercial Docs",
			},
		],
		selected_tags: [],
		type: "",
		fieldErrors: {
			invoice: false,
			packing: false,
			others: false
		},
		isFetchingDocumentReupload: false,
		isReuploading: false,
		isCommercialInvoiceTypeExist: false,
		isPackingListTypeExist: false,
		isCommercialAndPackingTypeExist: false,
		isOtherDocumentTypeExists: false,
		request: null,
	}),
	computed: {
		...mapGetters([
			"getShipmentDocumentsUploadLoading",
			"getShipmentSuppliers",
			"getUser"
		]),
		// supplierOptions() {
		// 	return typeof this.getShipmentSuppliers !== "undefined"
		// 		? this.getShipmentSuppliers
		// 		: [];
		// },
		shipmentSupplierLists() {
			return typeof this.getShipmentSuppliers !== "undefined"
				? this.getShipmentSuppliers
				: [];
		},
		formTitle() {
			return this.editedUploadIndex === -1 ? "Upload Documents" : "Reupload Documents";
		},
		dialog: {
			get() {
				return this.dialogData;
			},
			set(value) {
				if (!value) {
					this.$emit("update:dialogData", false);
				} else {
					this.$emit("update:dialogData", true);
				}
			},
		},
		getSelectedDocumentUpload() {
			return this.selectedDocumentRequest !== undefined ? this.selectedDocumentRequest : null
		},
		getCustomerOfCurrentShipment() {
			if (typeof this.getDetails !== "undefined" && this.getDetails !== null) {
				return this.getDetails.customer_company_name ? this.getDetails.customer_company_name : 'N/A'
			}
			return ""
		},
		getDocumentTypes() {
			let arr = []

			if (this.getSelectedDocumentUpload !== null && this.getSelectedDocumentUpload.document_types !== undefined) {
				if (this.getSelectedDocumentUpload.document_types.length > 0) {
					arr = this.getSelectedDocumentUpload.document_types

					if (arr.length > 1) {
						if (arr.includes("Commercial Invoice & Packing List")) {
							if (arr.includes("Commercial Invoice") && arr.includes("Packing List")) {
								arr = arr.filter(v => {
									return v !== "Commercial Invoice & Packing List"
								})
							}
						}
					} 				
					if (arr.length > 0) {
						arr = arr.map(v => {
							return {
								name: v,
								id: v !== "Commercial Invoice & Packing List" 
									? v.replace(/ /g,"_").toLowerCase() : "commercial_and_packing"
							}
						})
					}
				}
			}
			return arr
		},		
		documentFileType() {
			let fileType = "";
			if (this.getSelectedDocumentUpload !== null) {
				if (this.getSelectedDocumentUpload.path !== "") {
					fileType = this.getSelectedDocumentUpload.path.split('.').pop();
				}
			}
			return fileType !== "" ? fileType.toLowerCase() : ""
		},
		parseUserDetails() {
            let customerDetails = null;

            if (typeof this.getUser !== "undefined" && this.getUser !== "") {
                customerDetails = typeof this.getUser === "string" ? JSON.parse(this.getUser) : this.getUser;
            }

            return customerDetails
        },
		shipmentId() {
			if (typeof this.getDetails !== "undefined" && this.getDetails !== null) {
				return this.getDetails.shipment_id
			} else {
				return ""
			}
		},
		filePath() {
			if (this.getSelectedDocumentUpload !== null) {
				return this.getSelectedDocumentUpload.path
			}

			return ""
		},
		supplierOptions() {
			let defaultSuppliers = this.shipmentSupplierLists

			if (this.getSelectedDocumentUpload !== null &&
				typeof this.getSelectedDocumentUpload.isFromRequest !== "undefined" &&
				this.getSelectedDocumentUpload.isFromRequest) {
				let selectedSupplier = this.getSelectedDocumentUpload.supplier_id

				defaultSuppliers = defaultSuppliers.filter(v => {
					return selectedSupplier === v.id
				})				
			}
			return defaultSuppliers
		},
	},	
    watch: {
		"documentFiles.documentFilesInvoice": {
			handler: function (value) {
                if (value.length > 0) {
                    this.fieldErrors.invoice = false;                    
                }
            },
            deep: true
		},
		"documentFiles.documentFilesPacking": {
			handler: function (value) {
                if (value.length > 0) {
                    this.fieldErrors.packing = false;                    
                }
            },
            deep: true
		},
		dialog(c) {
			if (c) {
				if (this.getSelectedDocumentUpload !== null) {
					if (this.editedUploadIndex > -1) {
						this.callFetchDocAPI()
						this.documentTypes = this.documentTypesCopy
					} else {
						// if is from request is true, only map the document types that are requested
						if (typeof this.getSelectedDocumentUpload.isFromRequest !== "undefined" &&
							this.getSelectedDocumentUpload.isFromRequest) {
							if (this.getSelectedDocumentUpload.document_types.length > 0) {
								this.documentTypes = []

								this.getSelectedDocumentUpload.document_types.map((v) => {
									if (v === "Commercial Invoice & Packing List" || v === "Invoice & Packing List") {
										v = "Invoice and Packing List"
									} else if (v === "Other Documents") {
										v = "Other"
									} else if (v === "Hbl Copy") {
										v = "OBL Document"
									}

									this.documentTypes.push({
										value: v === "OBL Document" ? "hbl" : v.toLowerCase(),
										name: v
									})
								})
							}
						} else {
							this.documentTypes = this.documentTypesCopy
						}	
					}
				} else {
					this.documentTypes = this.documentTypesCopy
				}
			}
		},
	},
	mounted() {},
	methods: {
		...globalMethods,
		...mapActions(["uploadShipmentDocuments", "fetchShipmentDetails"]),
		close() {
			this.cancel();
			this.$emit("close");
			this.type = "";
			this.selected_tags = [];
			this.files = {
				documentFilesInvoice: [],
				documentFilesPacking: [],
				documentFilesOthers: [],
				documentFilesDefault: [],
				documentBothTypes: []
			};
			this.documentFiles = {
				documentFilesInvoice: [],
				documentFilesPacking: [],
				documentFilesOthers: [],
				documentFilesDefault: [],
				documentBothTypes: []
			};

			this.fieldErrors = {
				invoice: false,
				packing: false,
				others: false
			};

			this.isCommercialInvoiceTypeExist = false;
			this.isPackingListTypeExist = false;
			this.isCommercialAndPackingTypeExist = false;
			this.isOtherDocumentTypeExists = false;

			if ((this.isCurrentSelectedCustomer || this.isFrom === "to-dos") && 
				typeof this.$refs.upload_documents_reference !== "undefined") {
				this.$refs.upload_documents_reference.value = "";
			} else if (!this.isCurrentSelectedCustomer && this.isFrom !== "to-dos") {
				if (typeof this.$refs.upload_reference_other !== "undefined") {
					this.$refs.upload_reference_other.$refs.upload_reference_other.value = "";
				}

				if (typeof this.$refs.upload_reference_commercial_invoice !== "undefined") {
					this.$refs.upload_reference_commercial_invoice.$refs.upload_reference_commercial_invoice.value = "";
				}

				if (typeof this.$refs.upload_reference_packing_list !== "undefined") {
					this.$refs.upload_reference_packing_list.$refs.upload_reference_packing_list.value = "";
				}

				if (typeof this.$refs.upload_reference_commercial_and_packing !== "undefined") {
					this.$refs.upload_reference_commercial_and_packing.$refs.upload_reference_commercial_and_packing.value = "";
				}
			}			
		},
		onResize() {
			if (window.innerWidth < 768) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
		},
		addDocuments(item) {
			// this.$refs.upload_documents_reference.click();

			if (item === "upload_documents_reference") {
				this.$refs.upload_documents_reference.click();
			} else {
				this.$refs[item].$refs[item].click();
			}
		},
		inputChanged(e, item) {
			// let files = this.$refs.upload_documents_reference.files;

			let files = null;
			if (item === "upload_documents_reference") {
				files = this.$refs.upload_documents_reference.files;
			} else {
				files = e.target.files
			}

			if (!files.length) {
				return false;
			}

			for (let i = 0; i < files.length; i++) {
				let typeName = "documentFilesDefault";
				let type = ""

				if (item === "upload_reference_commercial_invoice") {
					typeName = "documentFilesInvoice"
					type = "Commercial Invoice"
				} else if (item === "upload_reference_packing_list") {
					typeName = "documentFilesPacking"
					type = "Packing List"
				} else if (item === "upload_reference_other") {
					typeName = "documentFilesOthers"
					type = "Other"
				} else if (item === "upload_reference_commercial_and_packing") {
					typeName = "documentBothTypes"
					type = "Commercial Invoice & Packing List"
				}

				if (this.editedUploadIndex > -1) { // if reupload
					// push initial files to documentFiles 
					this.documentFiles[typeName].push({
						file: files[i],
						name: files[i].name,
						typeError: false,
						supplierError: false,
						nameError: false,
						fileError: false,
						is_document_new: true
					})
					this.files[typeName].push(files[i])

					let prev_supplier_id = this.documentFiles[typeName][0].supplier_id;
					let prev_doc_type = this.documentFiles[typeName][0].type;
					
					// remove the first item to replace
					this.documentFiles[typeName].splice(0, 1);
					this.files[typeName].splice(0, 1);

					// insert the previous value of supplier id and document type to the new document uploaded
					this.documentFiles[typeName][0].supplier_id = prev_supplier_id;
					this.documentFiles[typeName][0].type = prev_doc_type;

				} else if (this.editedUploadIndex === -1) { // if uploading
					this.documentFiles[typeName].push({
						file: files[i],
						supplier_id: [],
						type,
						name: files[i].name,
						typeError: false,
						supplierError: false,
						nameError: false,
						fileError: false,
						is_document_new: true
					})
					this.files[typeName].push(files[i])
				}
			}
		},
		getFileSize(size) {
			let base = 1024;
			let decimal = 2;

			var kiloBytes = base;
			var megaBytes = base * base;
			var gigaBytes = base * base * base;

			if (size < kiloBytes) {
				return size + " Bytes";
			} else if (size < megaBytes) {
				return (size / kiloBytes).toFixed(decimal) + " kb";
			} else if (size < gigaBytes) {
				return (size / megaBytes).toFixed(decimal) + " mb";
			}
		},
		removeFile(index, isFor) {
			this.documentFiles[isFor].splice(index, 1);
			this.files[isFor].splice(index, 1);

			if (typeof this.$refs.upload_documents_reference !== "undefined") {
				this.$refs.upload_documents_reference.value = "";
			}

			if (typeof this.$refs.upload_reference_other !== "undefined") {
				this.$refs.upload_reference_other.$refs.upload_reference_other.value = "";
			}

			if (typeof this.$refs.upload_reference_commercial_invoice !== "undefined") {
				this.$refs.upload_reference_commercial_invoice.$refs.upload_reference_commercial_invoice.value = "";
			}

			if (typeof this.$refs.upload_reference_packing_list !== "undefined") {
				this.$refs.upload_reference_packing_list.$refs.upload_reference_packing_list.value = "";
			}

			if (typeof this.$refs.upload_reference_commercial_and_packing !== "undefined") {
				this.$refs.upload_reference_commercial_and_packing.$refs.upload_reference_commercial_and_packing.value = "";
			}
		},
		documentTypeExist(docType) {
			let exists = false

			if (this.getDocumentTypes.length > 0) {
				let arr = []
				arr = this.getDocumentTypes.map(v => { return v.id })
				
				if (arr.length > 0) {
					if (arr.includes(docType)) {
						exists = true
					}
				}
			}

			return exists
		},
		checkFormErrors() {
			let { upload_reference_commercial_invoice, upload_reference_packing_list } = this.$refs
			let hasErrors = false;

			if (this.documentTypeExist('commercial_invoice')) {
				let fileInvoice = upload_reference_commercial_invoice.$refs.upload_reference_commercial_invoice.files;

				if (fileInvoice.length === 0) {
					this.fieldErrors.invoice = true
				} else {
					this.fieldErrors.invoice = false
				}
			}

			if (this.documentTypeExist('packing_list')) {
				let filePacking = upload_reference_packing_list.$refs.upload_reference_packing_list.files;

				if (filePacking.length === 0) {
					this.fieldErrors.packing = true
				} else {
					this.fieldErrors.packing = false
				}
			}

			if (!this.fieldErrors.invoice && !this.fieldErrors.packing && !this.fieldErrors.others) {
				hasErrors = false
			} else hasErrors = true

			return hasErrors
		},
		async confirmUpload() {
			let { shipment_id } = this;
			let finalDocuments = []
			
			// if (!this.checkFormErrors()) { } - for checking and making fields required when uploading from Vendor
			if (!this.isCurrentSelectedCustomer) {
				let { 
					documentFilesDefault, 
					documentFilesInvoice, 
					documentFilesOthers, 
					documentFilesPacking,
					documentBothTypes 
				} = this.documentFiles

				finalDocuments = [].concat(
					documentFilesDefault, 
					documentFilesInvoice, 
					documentFilesOthers, 
					documentFilesPacking,
					documentBothTypes
				);
			} else {
				finalDocuments = this.documentFiles.documentFilesDefault
			}

			if (finalDocuments.length > 0) {
				let uploaded_by = {
					customer_admin: this.parseUserDetails !== null ? this.parseUserDetails.name : "",
					customer: this.parseUserDetails !== null ? this.parseUserDetails.default_customer.company_name : "",
					user_id: this.parseUserDetails !== null ? this.parseUserDetails.id : "",
					customer_id: this.parseUserDetails !== null ? this.parseUserDetails.default_customer_id : ""
				}

				let finalData = {
					documents: finalDocuments,
					shipment_id,
					uploaded_by: JSON.stringify(uploaded_by),
					isFromRequest: (this.getSelectedDocumentUpload !== null && 
						this.getSelectedDocumentUpload.isFromRequest !== undefined) ?
						this.getSelectedDocumentUpload.isFromRequest : false,
					isOtherParty: false,
					isCurrentSelectedCustomer: this.isCurrentSelectedCustomer,
					supplierOptions: this.supplierOptions,
				};

				if (this.getSelectedDocumentUpload !== null && this.getSelectedDocumentUpload.isFromRequest) {
					finalData.request_id = this.getSelectedDocumentUpload !== null 
						? this.getSelectedDocumentUpload.id : ""
				}
				
				try {
					finalData.documents.map((v) => {
						if (v.type.toLowerCase() === "delivery order" || v.type.toLowerCase() === "other") {
							v.supplier_id = [];
						}

						// if (v.type === "OBL Document") {
						// 	v.type = "hbl";
						// }
					});

					if (this.editedUploadIndex === -1) {
						if (this.getSelectedDocumentUpload !== null && 
							this.getSelectedDocumentUpload.type === "Other Party") {
							finalData.isOtherParty = true;
						} else {
							finalData.isOtherParty = false;
						}

						await this.uploadShipmentDocuments(finalData);
						this.notificationMessageCustomSuccess("Documents have successfully been uploaded.");

						this.$emit("fetchDocuments", {
							shipment_id,
							page: 1,
						});
						this.fetchShipmentDetails(shipment_id);
						this.close();

						if (this.getSelectedDocumentUpload !== null) {
							let id = this.shipmentId
							this.$emit("fetchRequestDocuments", id)
						}
					} else {
						// call update API here
						let formdata = new FormData();
						formdata.append("name", finalData.documents[0].name);
						formdata.append("type", finalData.documents[0].type.toLowerCase());
						formdata.append("file", finalData.documents[0].file);
						formdata.append("uploaded_by", finalData.uploaded_by);

						if (this.isCurrentSelectedCustomer && this.editedUploadIndex > -1) {
							let typeCheck = finalData.documents[0].type;

							if (this.getSelectedDocumentUpload.type !== "Other Party") {
								if (typeCheck.toLowerCase() !== "other" && typeCheck.toLowerCase() !== "delivery order") {
									formdata.append("supplier_ids[]", finalData.documents[0].supplier_id.id);
								} else {
									formdata.delete("supplier_ids[]");
								}
							} else {
								formdata.append("supplier_ids[]", "");
							}
						}

						await this.reuploadShipmentDocument(formdata, shipment_id)
					}
				} catch (e) {
					if (typeof e == "object") {
						let getKeys = Object.keys(e);
						// this.documentFiles.map((df, k) => {
						finalDocuments.map((df, k) => {
							finalDocuments[k].typeError = false;
							finalDocuments[k].supplierError = false;
							finalDocuments[k].nameError = false;
							finalDocuments[k].fileError = false;
						});

						getKeys.map((gk) => {
							let getKey = e[gk][0].split(".")[2].split(" ")[0];
							if (getKey === "supplier_id") {
								finalDocuments[
									parseInt(e[gk][0].split(".")[1])
								].supplierError = true;
							}

							if (getKey === "type") {
								finalDocuments[
									parseInt(e[gk][0].split(".")[1])
								].typeError = true;
							}

							if (getKey === "name") {
								finalDocuments[
									parseInt(e[gk][0].split(".")[1])
								].nameError = true;
							}

							if (getKey === "file") {
								finalDocuments[
									parseInt(e[gk][0].split(".")[1])
								].fileError = true;
							}
						});
					} else {
						//errorMessageDisplay = e
					}
				}
			}			
		},
		callFetchDocAPI() {
			let filenameobj = this.getSelectedDocumentUpload.name
			let c = [];

			let data = {
				"path": `public/${this.getSelectedDocumentUpload.path}`,
				"original_name": this.getSelectedDocumentUpload.name
			}

			// cancel
			this.cancel();
			let axiosSource = axios.CancelToken.source();
			this.request = { cancel: axiosSource.cancel };

			this.isFetchingDocumentReupload = true;
			let passedData = {
				method: "post",
				url: `${baseUrl}/v2/shipment/do-document`,
				responseType: 'blob',
				data,
				cancelToken: axiosSource.token,

			}
			axios(passedData).then((response) => {
				let fileList = [];
				var json = (response.data);

				let fileType = types[this.documentFileType.toLowerCase()]
				c[0] = new File([json], filenameobj , { lastModified: new Date().getTime(), type: fileType })
				fileList = c;
				URL.revokeObjectURL(c);

				if (this.isCurrentSelectedCustomer) {
					this.documentFiles.documentFilesDefault.push({
						file: fileList[0],
						supplier_id: 
							this.getSelectedDocumentUpload.supplier_ids.length > 0 
								? (this.getSelectedDocumentUpload.supplier_ids[0] !== null ? 
									this.getSelectedDocumentUpload.supplier_ids[0] : []) : [],
						type: 
							this.getSelectedDocumentUpload.type === "Commercial Invoice & Packing List" || 
							this.getSelectedDocumentUpload.type === "Invoice And Packing List"
							? "invoice and packing list" : (
								this.getSelectedDocumentUpload.type.toLowerCase() === "other commercial documents" 
								? "other commercial docs"
								: this.getSelectedDocumentUpload.type.toLowerCase()
							),
						name: filenameobj,
						typeError: false,
						supplierError: false,
						nameError: false,
						fileError: false,
						is_document_new: false
					})
					this.files.documentFilesDefault.push(fileList[0])
				} else {
					// if current logged in is the Vendor
					let documentType = "documentFilesInvoice"

					if (this.getSelectedDocumentUpload.type === "Packing List") {
						documentType = "documentFilesPacking"
					} else if (this.getSelectedDocumentUpload.type === "Other" || 
						this.getSelectedDocumentUpload.type === "Delivery Order") {
						documentType = "documentFilesOthers"
					} else if (this.getSelectedDocumentUpload.type === "Commercial Invoice") {
						documentType = "documentFilesInvoice"
					} else {
						documentType = "documentBothTypes"
					}

					this.documentFiles[documentType].push({
						file: fileList[0],
						supplier_id: this.getSelectedDocumentUpload.supplier_ids,
						type: 
							this.getSelectedDocumentUpload.type === "Commercial Invoice & Packing List" || 
							this.getSelectedDocumentUpload.type === "Invoice And Packing List"
							? "invoice and packing list" : (
								this.getSelectedDocumentUpload.type.toLowerCase() === "other commercial documents" 
								? "other commercial docs"
								: this.getSelectedDocumentUpload.type.toLowerCase()
							),
						name: filenameobj,
						typeError: false,
						supplierError: false,
						nameError: false,
						fileError: false,
						is_document_new: false
					})
					this.files[documentType].push(fileList[0])

					if (documentType === "documentFilesInvoice") {
						this.isCommercialInvoiceTypeExist = true;
						this.isPackingListTypeExist = false;
						this.isCommercialAndPackingTypeExist = false;
						this.isOtherDocumentTypeExists = false;
					} else if (documentType === "documentFilesPacking") {
						this.isPackingListTypeExist = true;
						this.isCommercialInvoiceTypeExist = false;
						this.isCommercialAndPackingTypeExist = false;
						this.isOtherDocumentTypeExists = false;
					} else if (documentType === "documentBothTypes") {
						this.isPackingListTypeExist = false;
						this.isCommercialInvoiceTypeExist = false;
						this.isCommercialAndPackingTypeExist = true;
						this.isOtherDocumentTypeExists = false;
					} else if (documentType === "documentFilesOthers") {
						this.isPackingListTypeExist = false;
						this.isCommercialInvoiceTypeExist = false;
						this.isCommercialAndPackingTypeExist = false;
						this.isOtherDocumentTypeExists = true;
					}
				}

				this.isFetchingDocumentReupload = false;
			}).catch(() => {
				this.isFetchingDocumentReupload = false;
				// console.log(e);
			});
		},
		async reuploadShipmentDocument(data, shipment_id) {
			this.isReuploading = true;
			let passedData = {
				method: "post",
				url: `${baseUrl}/v2/shipment/document/${this.getSelectedDocumentUpload.id}`,
				data,
				// cancelToken: axiosSource.token,
			}
			await axios(passedData).then((response) => {
				if (response.status === 200) {
					this.notificationMessageCustomSuccess("Documents have successfully been updated.");
					this.isReuploading = false;

					this.$emit("fetchDocuments", {
						shipment_id,
						page: 1,
					});
					this.fetchShipmentDetails(shipment_id);
					this.close();					

					if (this.getSelectedDocumentUpload !== null) {
						let id = this.shipmentId
						this.$emit("fetchRequestDocuments", id)
					}
				}
			}).catch((e) => {
				let message = typeof e.message !== "undefined" ? e.message : "An error has occurred. Please try again later";
				this.notificationCustom(message);
				this.isReuploading = false;
			});
		},
		getReuploadDocumentExtension(document) {
			if (!document.is_document_new) {
				return this.filePath.split(".").pop()
			} else {
				return document.file.name.split(".").pop()
			}
        },
		async confirmUploadBackup() {
			let { shipment_id } = this;
			let finalDocuments = this.documentFiles;
			
			if (finalDocuments.length > 0) {
				let finalData = {
					documents: finalDocuments,
					shipment_id,
				};

				try {
					finalData.documents.map((v) => {
						if (v.type.toLowerCase() === "delivery order" || v.type.toLowerCase() === "other") {
							v.supplier_id = [];
						}

						// if (v.type === "OBL Document") {
						// 	v.type = "hbl";
						// }
					});

					await this.uploadShipmentDocuments(finalData);

					this.$emit("fetchDocuments", {
						shipment_id,
						page: 1,
					});
					this.fetchShipmentDetails(shipment_id);
					this.close();
					this.notificationMessageCustomSuccess("Documents have successfully been uploaded.");
				} catch (e) {
					if (typeof e == "object") {
						let getKeys = Object.keys(e);
						this.documentFiles.map((df, k) => {
							this.documentFiles[k].typeError = false;
							this.documentFiles[k].supplierError = false;
							this.documentFiles[k].nameError = false;
							this.documentFiles[k].fileError = false;
						});

						getKeys.map((gk) => {
							let getKey = e[gk][0].split(".")[2].split(" ")[0];
							if (getKey === "supplier_id") {
								this.documentFiles[
									parseInt(e[gk][0].split(".")[1])
								].supplierError = true;
							}

							if (getKey === "type") {
								this.documentFiles[
									parseInt(e[gk][0].split(".")[1])
								].typeError = true;
							}

							if (getKey === "name") {
								this.documentFiles[
									parseInt(e[gk][0].split(".")[1])
								].nameError = true;
							}

							if (getKey === "file") {
								this.documentFiles[
									parseInt(e[gk][0].split(".")[1])
								].fileError = true;
							}
						});
					} else {
						//errorMessageDisplay = e
					}
				}
			}
		},
		cancel() {
			if (this.request) this.request.cancel();
		},
	},
	updated() {},
};
</script>

<style lang="scss">
@import "@/assets/scss/pages_scss/dialog/globalDialog.scss";
@import "@/assets/scss/buttons.scss";
@import "@/assets/scss/pages_scss/shipment/shipmentDocumentsUpload.scss";
</style>
