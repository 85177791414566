<!-- @format -->

<template>
	<div
		:class="
			`shipment-tabs-table-wrapper-component ${
				activeShipmentTab === 0 ? 'shipment-tabs-pending-main-wrapper' : ''
			}`
		"
	>
		<!-- FOR DESKTOP -->
		<div
			class="white d-flex align-center justify-space-between"
			v-if="pendingShipmentTab === 3 && selectedShipments.length > 0"
		>
			<div class="consolidation-details">
				<div class="d-flex containers-details">
					<p class="mb-0 text-title">{{ selectedShipments.length }} Selected</p>
					<div v-if="getRequiredDetails != undefined">
						<p class="white--text mb-0">Total Volume</p>
						<span class="text-title">
							{{ getRequiredDetails.totalVolume }} CBM
						</span>
					</div>
					<div v-if="getRequiredDetails != undefined">
						<p class="white--text mb-0">Container</p>
						<span
							class="text-title mb-0"
							v-for="(containerData, index) in getRequiredDetails.capacity"
							:key="index"
						>
							{{ containerData.qty }} of {{ containerData.size }}
							<span
								v-if="
									index != Object.keys(getRequiredDetails.capacity).length - 1
								"
								>,
							</span>
						</span>
					</div>
				</div>
				<div class="d-flex consolidate-btn">
					<v-btn
						class="save-btn btn-white mr-2"
						@click="openBookingConsolidationDialog = true"
						:disabled="!consolidateBtn || selectedShipments.length <= 1"
					>
						<span>Consolidate</span>
					</v-btn>
					<!-- <v-menu
						:close-on-click="closeOnClick"
						offset-y
						bottom
						left
						content-class="pending-dropdown-container"
					>
						<template v-slot:activator="{ on, attrs }">
							<button
								v-bind="attrs"
								v-on="on"
								class="three-dots-container consolidate-three-dot"
							>
								<custom-icon
									iconName="three-dots"
									color="#1A6D9E"
									width="11"
									height="3"
								/>
							</button>
						</template>

						<v-list>
							<v-list-item @click="markBookedConsolidate()">
								<v-list-item-title class="k-fw-sm k-font-inter-regular">
									Mark Consolidated
								</v-list-item-title>
							</v-list-item>
							<v-list-item>
								<v-list-item-title
									class="k-fw-sm k-font-inter-regular"
									@click="moveToRegularBooking"
								>
									Move to Regular Booking
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu> -->
				</div>
			</div>
		</div>

		<div class="default-view" v-if="currentViewTab === 0">
			<v-data-table
				v-model="selectedShipments"
				:headers="tableHeaders"
				:items="getCurrentShipmentsData"
				mobile-breakpoint="769"
				:page="getCurrentShipmentPage"
				:items-per-page="getShipmentItemsPerPage"
				item-key="shifl_ref"
				class="pending-header pending-header-quote"
				v-bind:class="{
					'no-data-table':
						typeof getCurrentShipmentsData !== 'undefined' &&
						getCurrentShipmentsData.length === 0,
					'no-current-pagination': getCurrentPaginationCountClass(),
					'consolidated-table': pendingShipmentTab === 3,
				}"
				:id="getCurrentId"
				hide-default-footer
				style="box-shadow: none !important;"
				@page-count="pageCount = $event"
				v-if="activeShipmentTab == 0 && !isMobile"
				ref="my-table"
				fixed-header
				:show-select="showSelect"
			>
				<!-- @click:row="handleClick" -->

				<template v-slot:no-data>
					<!-- <div
						class="no-data-preloader"
						v-if="
							getAllExpiredShipmentsLoading ||
								getAllSnoozeShipmentsLoading ||
								getAllPendingQuoteShipmentsLoading ||
								getConsolidatedShipmentsLoading ||
								getAllPendingShipmentsLoading ||
								getAllDraftShipmentsLoading
						"
					>
						<v-progress-circular :size="30" color="#1A6D9E" indeterminate>
						</v-progress-circular>
					</div>
					<div
						class="no-data-wrapper"
						v-if="
							!getAllExpiredShipmentsLoading &&
								!getAllPendingShipmentsLoading &&
								!getAllPendingQuoteShipmentsLoading &&
								!getConsolidatedShipmentsLoading &&
								!getAllSnoozeShipmentsLoading &&
								getCurrentShipmentsData.length === 0
						"
					>
						<div class="no-data-icon">
							<img
								src="@/assets/icons/noShipmentData.svg"
								alt=""
								width="65px"
							/>
						</div>

						<div class="no-data-heading" v-if="search === ''">
							<p v-if="activeShipmentTab == 0">
								<span v-if="pendingShipmentTab === 0">
									No Draft Shipments
								</span>
								<span v-if="pendingShipmentTab === 1">
									No Pending Quote Shipments
								</span>
								<span v-if="pendingShipmentTab === 2">
									No Pending Shipments
								</span>
								<span v-if="pendingShipmentTab === 3">
									No Consolidated Shipments
								</span>
								<span v-if="pendingShipmentTab === 4">
									No Snoozed Shipments
								</span>
								<span v-if="pendingShipmentTab === 5">
									No Expired Shipments
								</span>
							</p>
						</div>
						<div class="no-data-heading" v-if="search !== ''">
							<p v-if="activeShipmentTab == 0">
								No matches found. Try a different keyword.
							</p>
						</div>
					</div> -->

					<div class="no-data-preloader" v-if="isShipmentStillFetching">
						<v-progress-circular :size="30" color="#1A6D9E" indeterminate>
						</v-progress-circular>
					</div>
					<div class="no-data-wrapper"
						v-if="!isShipmentStillFetching && getCurrentShipmentsData.length === 0">
						<div class="no-data-icon">
							<img src="@/assets/icons/noShipmentData.svg" alt="" width="65px" />
						</div>

						<div class="no-data-heading" v-if="search === ''">
							<p v-if="activeShipmentTab === 0">
								<span v-if="pendingShipmentTab === 0">
									No Draft Shipments
								</span>
								<span v-if="pendingShipmentTab === 1">
									No Pending Quote Shipments
								</span>
								<span v-if="pendingShipmentTab === 2">
									No Pending Shipments
								</span>
								<span v-if="pendingShipmentTab === 3">
									No Consolidated Shipments
								</span>
								<span v-if="pendingShipmentTab === 4">
									No Snoozed Shipments
								</span>
								<span v-if="pendingShipmentTab === 5">
									No Expired Shipments
								</span>
							</p>
						</div>
						<div class="no-data-heading" v-if="search !== ''">
							<p> No matches found. Try a different keyword. </p>
						</div>
					</div>
				</template>

				<template v-slot:item="props">
					<tr
						class="shifl-shipment-row"
						:data-id="props.item.id"
						:id="props.item.id"
					>
						<td v-if="pendingShipmentTab === 3">
							<v-checkbox
								:input-value="props.isSelected"
								@change="props.select($event)"
							></v-checkbox>
						</td>

						<td class="shifl-shipment-reference">
							<div class="departure-content-wrapper">
								<div class="flag-wrapper">
									<p style="margin-bottom: 0px">
										{{ props.item.shifl_ref }}
									</p>
								</div>

								<div class="d-flex reference-column gap-1">
									<p
										style="color: #69758C;"
										class="reference-number"
										v-if="
											props.item.customer_reference_number !== 'null' &&
												props.item.customer_reference_number !== null &&
												props.item.customer_reference_number !== '' && 
												props.item.customer_reference_number !== 'undefined'"
									>
										{{ props.item.customer_reference_number }}
									</p>
									<p class="mb-0" v-if="props.item.consolidation_status == 2">
										<span class="consolidatation-status">
											{{
												props.item.consolidation_status == 2
													? "Consolidated"
													: ""
											}}
										</span>
									</p>
								</div>
							</div>
						</td>

						<td class="shifl-shipment-updated-created-snoozed">
							<div class="reference" v-if="pendingCurrentTab !== 4">
								<span class="name">{{
									props.item.updated_by_name
										? props.item.updated_by_name
										: "N/A"
								}}</span>
								<div class="pending-quote-items" style="color: #69758C;">
									{{ beautify(props.item.updated_at) }}
								</div>
							</div>

							<div class="reference" v-else>
								<!-- for snoozed -->
								<div class="reference">
									<div class="snooze-items">
										{{ beautify(props.item.snooze_date_at) }}
									</div>
								</div>
							</div>
						</td>

						<td class="shifl-shipment-from-shipper">
							<div class="reference" v-if="pendingShipmentTab !== 3">
								<div class="pending-quote-items">
									{{
										props.item.location_from_name !== null
											? props.item.location_from_name
											: "N/A"
									}}
								</div>
							</div>

							<div class="reference" v-else>
								<!-- for consolidated -->
								<div class="pending-quote-items">
									{{ props.item.shippers.join(", ") }}
								</div>
							</div>
						</td>

						<td class="shifl-shipment-to-crd">
							<div class="reference" v-if="pendingShipmentTab !== 3">
								<div class="pending-quote-items">
									{{
										props.item.location_to_name !== null
											? props.item.location_to_name
											: "N/A"
									}}
								</div>
							</div>

							<div class="reference" v-else>
								<!-- for consolidated -->
								{{ getMaxDate(props.item.shipment_suppliers) }}
							</div>
						</td>

						<td class="shifl-shipment-mode-from">
							<div v-if="pendingShipmentTab !== 3">
								<div v-if="pendingShipmentTab !== 5">
									<!-- <div v-if="props.item.multiple_modes.length > 0"
										class="pending-quote-actions-wrapper k-relative justify-start">
										<div class="k-pr-5 k-flex k-items-center shipment-mode-type">
											<span v-if="props.item.multiple_modes.length > 0">
												<span class="k-flex k-mr-4" :key="k"
													v-for="(m, k) in props.item.multiple_modes">
													<img :src="getImgUrl(m, 'mode')" 
														height="21px" 
														width="21px">
												</span>
											</span>

											<img :src="getImgUrl(props.item.mode, 'mode')" v-else
												height="21px" 
												width="21px" >

											<img :src="getImgUrl(props.item.shipment_type, 'shipment_type')" 
												height="20px" 
												width="20px"
												v-if="props.item.shipment_type !== null && props.item.shipment_type !== '' && props.item.shipment_type !== 'null'">

											<span style="padding-bottom:3px;" 
												v-if="props.item.shipment_type == 'FCL' && props.item.containers_qty !== 0"> 
												({{ props.item.containers_qty }})
											</span>
										</div>
									</div> -->

									<div class="pending-quote-actions-wrapper k-relative justify-start">
										<div class="k-pr-5 k-flex k-items-center shipment-mode-type">
											<span class="k-flex k-mr-4" v-if="props.item.multiple_modes.length > 0">
												<span class="k-flex" :key="k"
													v-for="(m, k) in props.item.multiple_modes">
													<img :src="getImgUrl(m, 'mode')" 
														height="20px" 
														width="22px">
												</span>
											</span>

											<span class="k-flex k-mr-4" v-else>
												<img :src="getImgUrl(props.item.mode, 'mode')"
													height="20px" 
													width="22px" 
													v-if="props.item.mode !== null && props.item.mode !== ''">												
												<span v-else class="k-flex k-items-center justify-start">N/A</span>
											</span>

											<img :src="getImgUrl(props.item.shipment_type, 'shipment_type')" 
												height="20px" 
												width="20px"
												v-if="props.item.shipment_type !== null && props.item.shipment_type !== '' && props.item.shipment_type !== 'null'">

											<span style="padding-bottom:3px;" 
												v-if="props.item.shipment_type == 'FCL' && props.item.containers_qty !== 0"> 
												({{ props.item.containers_qty }})
											</span>
										</div>
									</div>

									<!-- <div v-else class="k-flex k-items-center justify-start">
										<span>N/A</span>
									</div> -->
								</div>

								<div v-else class="k-flex k-items-center justify-start pl-1">
									<span>N/A</span>
								</div>
							</div>

							<div class="reference" v-else>
								<!-- for consolidated -->
								<div class="pending-quote-items">
									{{
										props.item.location_from_name !== null
											? props.item.location_from_name
											: "N/A"
									}}
								</div>
							</div>
						</td>

						<td class="shifl-shipment-suppliers-to">
							<div
								class="supplier-desktop"
								:class="activeShipmentTab == 0 ? 'active-tab' : ''"
								v-if="pendingShipmentTab !== 3"
							>
								<span
									v-for="(name, index) in props.item.suppliers_names"
									:key="index"
								>
									<span>{{ name }}</span
									><span v-if="index + 1 < props.item.suppliers_names.length"
										>,
									</span>
								</span>

								<span
									v-if="
										props.item.suppliers_names == null ||
											props.item.suppliers_names.length == 0
									"
								>
									<span>N/A</span>
								</span>
							</div>

							<div class="reference" v-else>
								<!-- for consolidated -->
								<div class="pending-quote-items">
									{{
										props.item.location_to_name !== null
											? props.item.location_to_name
											: "N/A"
									}}
								</div>
							</div>
						</td>

						<td class="shifl-shipment-pos-earliest-date">
							<div v-if="pendingShipmentTab !== 3">
								<div class="po-num-desktop">
									<p>
										<span
											v-for="(name, index) in props.item.po_num"
											:key="index"
										>
											{{ name
											}}<template v-if="index + 1 < props.item.po_num.length"
												>,
											</template>
										</span>
									</p>
								</div>

								<div
									v-if="
										props.item.po_num == null || props.item.po_num.length === 0
									"
								>
									<p>N/A</p>
								</div>
							</div>

							<div class="reference" v-else>
								<!-- for consolidated -->
								{{ beautify(props.item.earliest_shipment_date) }}
							</div>
						</td>

						<td class="shifl-shipment-actions-must-arrival">
							<div v-if="pendingShipmentTab !== 3">
								<div
									class="request-schedules mr-1"
									v-if="props.item.Status === 'Expired' && 1 == 2"
								>
									<button
										class="btn-white expired"
										@click.stop="requestSchedule(props.item.id)"
										:disabled="
											loadingNewScheds &&
												currentIdRequestScheds == props.item.id
										"
									>
										<span
											class="k-tracking-normal"
											v-if="props.item.is_schedule_requested === 0"
										>
											{{
												loadingNewScheds &&
												currentIdRequestScheds == props.item.id
													? "Requesting..."
													: "Request Again"
											}}
										</span>

										<span v-if="props.item.is_schedule_requested === 1">
											{{
												loadingNewScheds &&
												currentIdRequestScheds == props.item.id
													? "Requesting..."
													: "Schedule Requested"
											}}
										</span>
									</button>
								</div>

								<div
									class="pending-quote-actions-wrapper k-relative justify-end mr-1"
									v-if="pendingShipmentTab == 0 || pendingShipmentTab == 5"
								>
									<div :class="`three-dots-wrapper-${props.index}`">
										<v-menu
											:close-on-click="closeOnClick"
											offset-y
											bottom
											left
											content-class="pending-dropdown-container"
										>
											<template v-slot:activator="{ on, attrs }">
												<button
													v-bind="attrs"
													v-on="on"
													class="three-dots-container"
												>
													<custom-icon
														iconName="three-dots"
														color="#1A6D9E"
														width="11"
														height="3"
													/>
												</button>
											</template>

											<v-list>
												<v-list-item
													@click="
														(e) => handleMarkBookedExternal(props.item, e)
													"
													v-if="
														props.item.tab_status === 'pending_quote' &&
															currentUser !== null &&
															currentUser.default_customer_id ==
																props.item.customer_id &&
															props.item.is_tracking_shipment == 0
													"
												>
													<v-list-item-title
														class="k-fw-sm k-font-inter-regular"
													>
														Mark Booked External
													</v-list-item-title>
												</v-list-item>
												<v-list-item
													@click="handleShowSnoozeDialog(props.item)"
													v-if="currentUser.default_customer_id == props.item.customer_id"
												>
													<v-list-item-title
														class="k-fw-sm k-font-inter-regular"
													>
														Snooze
													</v-list-item-title>
												</v-list-item>
												<v-list-item
													v-if="props.item.tab_status === 'pending_quote'"
												>
													<v-list-item-title
														class="k-fw-sm k-font-inter-regular"
													>
														Request External Quote
													</v-list-item-title>
												</v-list-item>
												<v-list-item>
													<v-list-item-title
														class="k-fw-sm k-font-inter-regular k-red"
														@click="cancelBooking(props.item.id)"
													>
														Cancel Request
													</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</div>
								</div>

								<div
									class="pending-quote-actions-wrapper k-relative justify-end mr-1"
									v-if="pendingShipmentTab == 1 || pendingShipmentTab == 2"
								>
									<div
										class="edit-shipment pr-0"
										v-if="
											1 == 2 &&
												currentUser !== null &&
												currentUser.default_customer_id ==
													props.item.customer_id
										"
									>
										<v-btn
											class="k-tracking-normal pending-quote-actions-btn edit"
											text
											@click.stop="(e) => editShipment(props.item, e)"
										>
											<span>Edit</span>
										</v-btn>
									</div>

									<div :class="`three-dots-wrapper-${props.index}`">
										<div class="k-relative">
											<v-menu
												:close-on-click="closeOnClick"
												offset-y
												bottom
												left
												content-class="pending-dropdown-container"
											>
												<template v-slot:activator="{ on, attrs }">
													<button
														v-bind="attrs"
														v-on="on"
														class="three-dots-container"
														:disabled="pendingShipmentTab == 2 && currentUser.default_customer_id != props.item.customer_id"
													>
														<custom-icon
															iconName="three-dots"
															color="#1A6D9E"
															width="11"
															height="3"
														/>
													</button>
												</template>
												<v-list>
													<v-list-item
														@click="
															(e) => handleMarkBookedExternal(props.item, e)
														"
														v-if="
															props.item.tab_status === 'pending_quote' &&
																currentUser !== null &&
																currentUser.default_customer_id ==
																	props.item.customer_id &&
																props.item.is_tracking_shipment == 0 &&
																pendingShipmentTab == 1
														"
													>
														<v-list-item-title
															class="k-fw-sm k-font-inter-regular"
														>
															Mark Booked External
														</v-list-item-title>
													</v-list-item>
													<v-list-item
														v-if="pendingShipmentTab == 1"
														@click="requestConsolidation(props.item.id)"
													>
														<v-list-item-title
															class="k-fw-sm k-font-inter-regular"
														>
															Request Consolidation
														</v-list-item-title>
													</v-list-item>
													<v-list-item
														@click="handleShowSnoozeDialog(props.item)"
														v-if="currentUser.default_customer_id == props.item.customer_id"
													>
														<v-list-item-title
															class="k-fw-sm k-font-inter-regular"
														>
															Snooze
														</v-list-item-title>
													</v-list-item>
													<v-list-item v-if="pendingShipmentTab == 1">
														<v-list-item-title
															class="k-fw-sm k-font-inter-regular k-red"
															@click="cancelBooking(props.item.id)"
														>
															Cancel Request
														</v-list-item-title>
													</v-list-item>
												</v-list>
											</v-menu>
										</div>
									</div>
								</div>

								<div
									class="pending-quote-actions-wrapper k-relative justify-end mr-1"
									v-if="pendingShipmentTab == 4"
								>
									<div class="pr-0">
										<v-btn
											class="pending-quote-actions-btn unsnooze"
											text
											@click.stop="handleUnSnoozeShipment(props.item)"
											v-if="props.item.snooze_date_at !== null"
										>
											<span
												class="k-tracking-normal"
												v-if="!props.item.unsnoozing"
											>
												{{ "Unsnooze" }}
											</span>
											<span
												class="k-tracking-normal"
												v-if="props.item.unsnoozing"
											>
												{{ "Unsnoozing..." }}
											</span>
										</v-btn>
									</div>
									<div :class="`three-dots-wrapper-${props.index}`">
										<v-menu
											:close-on-click="closeOnClick"
											offset-y
											bottom
											left
											content-class="pending-dropdown-container"
										>
											<template v-slot:activator="{ on, attrs }">
												<button
													v-bind="attrs"
													v-on="on"
													class="three-dots-container"
												>
													<custom-icon
														iconName="three-dots"
														color="#1A6D9E"
														width="11"
														height="3"
													/>
												</button>
											</template>
											<v-list>
												<v-list-item @click.stop="handleView(props.item)">
													<v-list-item-title
														class="k-fw-sm k-font-inter-regular"
													>
														View
													</v-list-item-title>
												</v-list-item>
												<v-list-item
													v-if="1 == 2"
													@click="handleCancel(props.item)"
												>
													<v-list-item-title
														class="k-fw-sm k-font-inter-regular k-red"
														@click="cancelBooking(props.item.id)"
													>
														Cancel Request
													</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</div>
								</div>
							</div>

							<div class="reference" v-else>
								<!-- for consolidated -->
								{{ beautify(props.item.latest_arrival_date) }}
							</div>
						</td>

						<!-- table data below are for consolidated tab -->
						<td class="shifl-shipment-cbm" v-if="pendingShipmentTab === 3">
							<div class="reference">
								{{ calculateCBM(props.item.shipment_suppliers) }}
							</div>
						</td>

						<td
							class="shifl-shipment-consignee"
							v-if="pendingShipmentTab === 3"
						>
							<div class="reference">
								{{ props.item.consignees.join(", ") }}
							</div>
						</td>

						<td
							class="shifl-shipment-consignee"
							v-if="pendingShipmentTab === 3"
						>
							<div
								class="pending-quote-actions-wrapper k-relative justify-end mr-1"
							>
								<div class="pr-0">
									<v-btn
										class="pending-quote-actions-btn view"
										text
										style="letter-spacing: 0 !important;"
									>
										Edit
									</v-btn>
								</div>
								<div class="k-relative">
									<v-menu
										offset-y
										bottom
										left
										content-class="pending-dropdown-container consolidate-dropdown"
									>
										<template v-slot:activator="{ on, attrs }">
											<button
												v-bind="attrs"
												v-on="on"
												class="three-dots-container"
											>
												<custom-icon
													iconName="three-dots"
													color="#1A6D9E"
													width="11"
													height="3"
												/>
											</button>
										</template>
										<v-list>
											<!-- <v-list-item @click="markBookedConsolidate()">
												<v-list-item-title class="k-fw-sm k-font-inter-regular">
													Mark Consolidated
												</v-list-item-title>
											</v-list-item> -->
											<v-list-item>
												<v-list-item-title
													class="k-fw-sm k-font-inter-regular"
													@click="moveToRegularBooking(props.item.id)"
												>
													Move to Regular Booking
												</v-list-item-title>
											</v-list-item>
											<v-list-item @click="handleShowSnoozeDialog(props.item)" v-if="currentUser.default_customer_id == props.item.customer_id">
												<v-list-item-title class="k-fw-sm k-font-inter-regular">
													Snooze
												</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<v-list-item-title
													class="k-fw-sm k-font-inter-regular k-red"
													@click="cancelBooking(props.item.id)"
												>
													Cancel Request
												</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-menu>
								</div>
							</div>
						</td>
					</tr>
				</template>
			</v-data-table>

			<!-- FOR MOBILE -->
			<v-data-table
				:headers="tableHeaders"
				:items="getCurrentShipmentsData"
				mobile-breakpoint="769"
				:page="getCurrentShipmentPage"
				:items-per-page="getShipmentItemsPerPage"
				item-key="name"
				class="table-mobile pending-header-mobile"
				v-bind:class="{
					'no-data-table':
						typeof getCurrentShipmentsData !== 'undefined' &&
						getCurrentShipmentsData.length === 0,
					'no-current-pagination': getCurrentPaginationCountClass(),
				}"
				:id="getCurrentId"
				hide-default-footer
				style="box-shadow: none !important"
				@page-count="pageCount = $event"
				@click:row="handleClick"
				v-if="activeShipmentTab == 0 && isMobile"
				ref="my-table"
			>
				<template v-slot:no-data>
					<div
						class="no-data-preloader"
						v-if="
							getAllExpiredShipmentsLoading ||
								getAllPendingShipmentsLoading ||
								getAllPendingQuoteShipmentsLoading ||
								getConsolidatedShipmentsLoading ||
								getAllSnoozeShipmentsLoading
						"
					>
						<v-progress-circular :size="30" color="#1A6D9E" indeterminate>
						</v-progress-circular>
					</div>
					<div v-else class="no-data-wrapper">
						<div class="no-data-icon">
							<img
								src="@/assets/icons/noShipmentData.svg"
								alt=""
								width="65px"
							/>
						</div>

						<div class="no-data-heading" v-if="search === ''">
							<p v-if="activeShipmentTab == 0">
								<span v-if="pendingShipmentTab === 0">
									No Draft Shipments
								</span>
								<span v-if="pendingShipmentTab === 1">
									No Pending Quote Shipments
								</span>
								<span v-if="pendingShipmentTab === 2">
									No Pending Shipments
								</span>
								<span v-if="pendingShipmentTab === 3">
									No Consolidated Shipments
								</span>
								<span v-if="pendingShipmentTab === 4">
									No Snoozed Shipments
								</span>
								<span v-if="pendingShipmentTab === 5">
									No Expired Shipments
								</span>
							</p>
						</div>
						<div class="no-data-heading" v-if="search !== ''">
							<p v-if="activeShipmentTab == 0">
								No matches found. Try a different keyword.
							</p>
						</div>
					</div>
				</template>

				<!-- Reference ID and Status -->
				<template v-slot:[`item.shifl_ref`]="{ item }">
					<div class="table-mobile-data mobile-reference">
						<div class="mobile-reference-content" :class="pendingShipmentTab === 4 ? 'snooze' : ''">
							<span class="k-flex mr-2">{{ item.shifl_ref }}</span>

							<div v-if="pendingShipmentTab !== 3">
								<div v-if="pendingShipmentTab !== 5">
									<div v-if="item.multiple_modes.length > 0"
										class="pending-quote-actions-wrapper k-relative justify-start">
										<div class="k-pr-5 k-flex k-items-center shipment-mode-type">
											<span
												class="k-flex k-mr-4"
												:key="k"
												v-for="(m, k) in item.multiple_modes">

												<img :src="getImgUrl(item.mode, 'mode')" 
													height="21px" 
													width="21px" >
											</span>

											<img :src="getImgUrl(item.shipment_type, 'shipment_type')" 
												height="20px" 
												width="20px"
												v-if="item.shipment_type !== null && item.shipment_type !== '' && item.shipment_type !== 'null'">

											<span style="padding-bottom:3px;" 
												v-if="item.shipment_type == 'FCL' && item.containers_qty !== 0"> 
												({{ item.containers_qty }})
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="k-flex ml-2">
							<span
								v-if="
									pendingShipmentTab == 0 ||
										pendingShipmentTab == 1 ||
										pendingShipmentTab == 3
								"
								style="color: #9CA5B4; font-family: 'Inter-Medium', sans-serif;"
								class="k-flex k-items-center k-mr-5 k-f-12"
							>
								{{ beautify(item.updated_at) }}</span
							>
							<span
								v-if="pendingShipmentTab == 4"
								class="k-flex k-items-center"
							>
								<custom-icon
									iconName="notification"
									marginTop="2"
									width="20"
									height="20"
									color="#EB9B26"
								></custom-icon>
							</span>
							<span
								v-if="pendingShipmentTab == 4"
								class="k-flex k-f-12 k-items-center k-mr-5 k-orange"
							>
								{{ "Until " + beautify(item.snooze_date_at) }}
							</span>
							<span>
								<v-menu
									:close-on-click="closeOnClick"
									offset-y
									bottom
									left
									content-class="pending-dropdown-container"
								>
									<template v-slot:activator="{ on, attrs }">
										<button
											v-bind="attrs"
											v-on="on"
											class="three-dots-container k-h-26"
										>
											<custom-icon
												iconName="three-dots"
												color="#1A6D9E"
												width="11"
												height="3"
											/>
										</button>
									</template>
									<v-list>
										<v-list-item @click="handleView(item)">
											<v-list-item-title
												class="k-tracking-normal k-fw-sm k-font-inter-regular"
											>
												View
											</v-list-item-title>
										</v-list-item>
										<v-list-item
											v-if="(pendingShipmentTab == 0 || pendingShipmentTab == 1) && (currentUser.default_customer_id == item.customer_id)"
											@click.stop="handleShowSnoozeDialog(item)"
										>
											<v-list-item-title
												class="k-fw-sm k-tracking-normal k-font-inter-regular"
											>
												Snooze
											</v-list-item-title>
										</v-list-item>
										<v-list-item
											v-if="
												pendingShipmentTab == 0 &&
													currentUser !== null &&
													currentUser.default_customer_id == item.customer_id &&
													item.is_tracking_shipment == 0
											"
										>
											<v-list-item-title class="k-fw-sm k-font-inter-regular">
												Mark Booked External
											</v-list-item-title>
										</v-list-item>
										<v-list-item
											v-if="pendingShipmentTab == 2"
											@click.stop="handleUnSnoozeShipment(item)"
										>
											<v-list-item-title
												class="k-tracking-normal k-fw-sm k-font-inter-regular"
											>
												{{ item.unsnoozing ? "Unsnoozing..." : "Unsnooze" }}
											</v-list-item-title>
										</v-list-item>
										<v-list-item
											v-if="pendingShipmentTab == 3"
											@click.stop="requestSchedule(item.id)"
										>
											<v-list-item-title
												class="k-tracking-normal k-fw-sm k-dark-blue k-font-inter-regular"
											>
												Request Again
											</v-list-item-title>
										</v-list-item>
										<v-list-item
											v-if="pendingShipmentTab == 0 || pendingShipmentTab == 1"
										>
											<v-list-item-title
												class="k-fw-sm k-font-inter-regular k-red"
											>
												Cancel Request
											</v-list-item-title>
										</v-list-item>
									</v-list>
								</v-menu>
							</span>
						</div>
					</div>
				</template>

				<!-- Location From -->
				<template v-slot:[`item.location_from_name`]="{ item }">
					<div class="k-flex k-justify-between">
						<div class="k-grey">From</div>
						<div class="k-default-color">{{ item.location_from_name }}</div>
					</div>
				</template>

				<!-- Location To -->
				<template v-slot:[`item.location_to_name`]="{ item }">
					<div class="k-flex k-justify-between">
						<div class="k-grey">To</div>
						<div class="k-default-color">{{ item.location_to_name }}</div>
					</div>
				</template>

				<!-- PENDING TAB -->
				<!-- Departure, Mode and Arrival -->
				<template v-slot:[`item.po_num`]="{ item }">
					<div class="k-flex k-justify-between">
						<div class="k-grey">POs</div>
						<div class="k-default-color">
							<p>
								<span v-for="(name, index) in item.po_num" :key="index">
									<span v-if="index === 0 || index === 1">
										{{ name }}<template v-if="index + 1 < item.po_num.length">, </template>
									</span>
								</span>

								<span v-if="item.po_num.length > 2" style="color: #1A6D9E;">
									+{{ item.po_num.length - 2 }} Other
								</span>
							</p>

							<div v-if="item.po_num == null || item.po_num.length == 0">
								<p> N/A </p>
							</div>
						</div>
					</div>
				</template>
			</v-data-table>
		</div>

		<div class="calendar-view" v-if="currentViewTab === 1">
			<CalendarView
				:isFilterEnabled="isFilterEnabled"
				@handleClick="handleClick"
				:currentCalendarType.sync="type"
				:currentDateCalendar="currentDateCalendar"
				:tab="tab"
				:search.sync="search"
				:selectedDefaultFilter.sync="selectedCalendarFilter"
                :selectedCalendarOption="selectedCalendarOption"
                :isAllSelected="isAllSelected"
                :isCalendarFilterSelectionEmpty.sync="isCalendarFilterSelectionEmpty"
				:pendingShipmentTab="pendingShipmentTab" />
				<!-- @getStatus="getStatus"
				@getStatusClass="getStatusClass" -->
			/>
		</div>

		<div class="card-view" v-if="currentViewTab === 2">
			<CardView
				:getCurrentShipmentsData="getCurrentShipmentsData"
				:getCurrentShipmentPage="getCurrentShipmentPage"
				:getShipmentLastPage="getShipmentPagination"
				:tab="tab"
				:search.sync="search"
				:pendingShipmentTab="pendingShipmentTab"
			/>
		</div>

		<div class="compact-view" v-if="currentViewTab === 3">
			<CompactView
				:isFilterEnabled="isFilterEnabled"
				@handleClick="handleClick"
				:tab="tab"
				:search.sync="search"
			/>
			<!-- @getStatus="getStatus"
			@getStatusClass="getStatusClass" -->
		</div>

		<div class="pagination-wrapper-lists" v-if="currentViewTab === 0">
			<div v-if="search === ''">
				<div
					class="pagination-wrapper pt-3 k-relative"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 1 &&
							pendingQuote !== 'undefined' &&
							search == '' &&
							pagination.pendingQuoteTab.total > 1
					"
				>
					<v-pagination
						v-model="pagination.pendingQuoteTab.current_page"
						:length="pagination.pendingQuoteTab.total"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageChangePendingQuote"
					>
					</v-pagination>
				</div>
				<div
					class="pagination-wrapper pt-3 k-relative"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 3 &&
							consolidated !== 'undefined' &&
							search == '' &&
							pagination.consolidationTab.total > 1
					"
				>
					<v-pagination
						v-model="pagination.consolidationTab.current_page"
						:length="pagination.consolidationTab.total"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageChangeConsolidationRequests"
					>
					</v-pagination>
				</div>
				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 1 &&
							pending !== 'undefined' &&
							search == '' &&
							pagination.pendingTab.total > 1
					"
				>
					<v-pagination
						v-model="pagination.pendingTab.current_page"
						:length="pagination.pendingTab.total"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageChangePending"
					>
					</v-pagination>
				</div>
				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 4 &&
							snooze !== 'undefined' &&
							search == '' &&
							pagination.snoozeTab.total > 1
					"
				>
					<v-pagination
						v-model="pagination.snoozeTab.current_page"
						:length="pagination.snoozeTab.total"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageChangeSnooze"
					>
					</v-pagination>
				</div>
				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 5 &&
							expired !== 'undefined' &&
							search == '' &&
							pagination.expiredTab.total > 1
					"
				>
					<v-pagination
						v-model="pagination.expiredTab.current_page"
						:length="pagination.expiredTab.total"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageChangeExpired"
					>
					</v-pagination>
				</div>
				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 0 &&
							draft !== 'undefined' &&
							search == '' &&
							pagination.draftTab.total > 1
					"
				>
					<v-pagination
						v-model="pagination.draftTab.current_page"
						:length="pagination.draftTab.total"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageChangeDraft"
					>
					</v-pagination>
				</div>
			</div>

			<div v-if="search !== ''">
				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 0 &&
							draft !== 'undefined' &&
							search !== '' &&
							getShipmentPagination > 1
					"
				>
					<v-pagination
						v-model="getCurrentShipmentPage"
						:length="getShipmentPagination"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageSearched"
					>
					</v-pagination>
				</div>

				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 1 &&
							pendingQuote !== 'undefined' &&
							search !== '' &&
							getShipmentPagination > 1
					"
				>
					<v-pagination
						v-model="getCurrentShipmentPage"
						:length="getShipmentPagination"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageSearched"
					>
					</v-pagination>
				</div>

				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 2 &&
							pending !== 'undefined' &&
							search !== '' &&
							getShipmentPagination > 1
					"
				>
					<v-pagination
						v-model="getCurrentShipmentPage"
						:length="getShipmentPagination"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageSearched"
					>
					</v-pagination>
				</div>

				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 3 &&
							consolidated !== 'undefined' &&
							search !== '' &&
							getShipmentPagination > 1
					"
				>
					<v-pagination
						v-model="getCurrentShipmentPage"
						:length="getShipmentPagination"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageSearched"
					>
					</v-pagination>
				</div>

				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 4 &&
							snooze !== 'undefined' &&
							search !== '' &&
							getShipmentPagination > 1
					"
				>
					<v-pagination
						v-model="getCurrentShipmentPage"
						:length="getShipmentPagination"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageSearched"
					>
					</v-pagination>
				</div>

				<div
					class="pagination-wrapper pt-3"
					v-if="
						activeShipmentTab === 0 &&
							pendingShipmentTab === 5 &&
							expired !== 'undefined' &&
							search !== '' &&
							getShipmentPagination > 1
					"
				>
					<v-pagination
						v-model="getCurrentShipmentPage"
						:length="getShipmentPagination"
						prev-icon="Previous"
						next-icon="Next"
						:total-visible="!isMobile ? '11' : '5'"
						@input="handlePageSearched"
					>
					</v-pagination>
				</div>
			</div>
		</div>

		<snooze-shipment-dialog
			:isMobile="isMobile"
			:show="showSnoozeShipmentDialog"
			@close="handleCloseSnoozeShipmentDialog"
			@reloadAllShipments="reloadAllShipments"
			:item="currentSnoozeItem"
		>
			<template v-slot:reference>
				<div class="snooze-shipment-title">
					{{ "Snooze Booking" }}
				</div>
			</template>
			<template v-slot:main-text>
				<div>
					{{
						"You can snooze a booking request and we will process your request after the snoozed period or remind you about the quote after the snoozed period."
					}}
				</div>
			</template>
			<template
				v-slot:actions="{
					actionItems: { handleClose, item, handleSnoozeShipment, date_save },
				}"
			>
				<v-btn
					:disabled="date_save == null"
					@click.stop="handleSnoozeShipment(item, date_save)"
					class="btn-blue"
					text
				>
					<span>{{
						item.snoozing ? "Snoozing Shipment..." : "Snooze Shipment"
					}}</span>
				</v-btn>
				<v-btn @click.stop="handleClose" class="btn-white" text>Cancel</v-btn>
			</template>
		</snooze-shipment-dialog>

		<ConfirmDialog :dialogData.sync="cancelBookingRequestDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Cancel Booking Request</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Are you sure you want to cancel the booking request?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="submitCancelBooking()"
					:disabled="getCancelShipmentLoading"
				>
					<span v-if="getCancelShipmentLoading"
						>Cancel Request Processing...</span
					>
					<span v-else>Cancel Request</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="cancelBookingRequestDialog = false"
					:disabled="getCancelShipmentLoading"
				>
					<span>Close</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="requestConsolidationConfirmDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>
					{{
						consolidationAction === "consolidation_request"
							? `Request Consolidation`
							: "Move To Regular Booking"
					}}
				</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					{{
						consolidationAction === "consolidation_request"
							? `Are you sure you want to request for consolidation for this booking
					request?`
							: `Are you sure you want to move this consolidation request to regular booking?`
					}}
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="
						handleRequestConsolidation(
							consolidationAction === 'consolidation_request' ? 1 : 0
						)
					"
					:disabled="getchangeConsolidationStatusLoading"
				>
					<span v-if="getchangeConsolidationStatusLoading">{{
						consolidationAction === "consolidation_request"
							? `Requesting Consolidation...`
							: `Moving To Regular Booking...`
					}}</span>
					<span v-else>{{
						consolidationAction === "consolidation_request"
							? `Request Consolidation`
							: `Move To Regular Booking`
					}}</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="requestConsolidationConfirmDialog = false"
					:disabled="getchangeConsolidationStatusLoading"
				>
					<span>Close</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<!-- Confirm booking consolidation dialog start -->
		<ConfirmDialog :dialogData.sync="openBookingConsolidationDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Confirm Booking Consolidation</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Some of the fields don't match, are you sure that you want to
					consolidate anyways?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="consolidateShipment()"
					:disabled="getConsolidatedShipmentLoading"
				>
					<span>Yes, Consolidate</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="openBookingConsolidationDialog = false"
					:disabled="getConsolidatedShipmentLoading"
				>
					<span>Cancel</span>
				</v-btn>
			</template>
		</ConfirmDialog>
		<!-- Confirm booking consolidation dialog end -->

		<!-- Mark Booked Consolidate Dialog Start -->
		<MarkBookedConsolidate
			v-if="openMarkBookedConsolidateDialog"
			:show.sync="openMarkBookedConsolidateDialog"
			@close="handleCloseConsolidateDialog"
		>
			<template v-slot:title>
				<div id="headline-custom-wrapper">
					<span class="headline-custom">Mark Booked Consolidated</span>
				</div>
			</template>
		</MarkBookedConsolidate>
		<!-- Mark Booked Consolidate Dialog end -->

		<!-- Booking Shipment Dialog -->
		<BookingShipmentDialogV2
			:show.sync="viewBookingShipmentDialog"
			:reference="'consolidte-booking-shipment'"
			:shipmentData="shipmentData"
			@close="viewBookingShipmentDialog = false"
		/>
		<!-- Booking Shipment Dialog End-->
	</div>
</template>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import "../../../../assets/scss/pages_scss/shipment/pendingTabTable.scss";
@import "../../../../assets/scss/utilities.scss";
</style>
<script>
import axios from "axios";
import _ from "lodash";
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../../../../utils/globalMethods";
import moment from "moment";
import CustomIcon from "../../../Icons/CustomIcon";
import iziToast from "izitoast";
import SnoozeShipmentDialog from "../../../Dialog/SnoozeShipmentDialog";
import ConfirmDialog from "@/components/Dialog/GlobalDialog/ConfirmDialog.vue";
import MarkBookedConsolidate from "@/components/Dialog/FormShipmentDialog/MarkBookedConsolidate";
import CalendarView from "./Views/CalendarView.vue";
import CardView from "./Views/CardView.vue";
import CompactView from "./Views/CompactView.vue";
import BookingShipmentDialogV2 from "../../../ShipmentComponents/BookingShipment/BookingShipmentDialogV2.vue";

export default {
	props: [
		"pending",
		"pendingQuote",
		"snooze",
		"expired",
		"draft",
		"activeTab",
		"isMobile",
		"search",
		"pendingCurrentTab",
		"paginationData",
		"consolidated",
		"currentViewTab",
		"isFilterEnabled",
		"currentCalendarType",
		"currentDateCalendar",
		"selectedDefaultFilter",
        "selectedCalendarOption",
        "isAllSelected",
        "isCalendarFilterSelectionEmpty"
	],
	components: {
		SnoozeShipmentDialog,
		CustomIcon,
		ConfirmDialog,
		MarkBookedConsolidate,
		CalendarView,
		CardView,
		CompactView,
		BookingShipmentDialogV2,
	},
	data: () => ({
		selectedShipments: [],
		closeOnClick: true,
		documentScrollTop: 0,
		dynamicNum: 0,
		currentPendingQuoteItem: {},
		currentPendingQuoteItemIndex: 0,
		currentExpiredItem: {},
		currentExpiredItemIndex: 0,
		currentSnoozeItem: {},
		currentSnoozeItemIndex: 0,
		currentPendingItem: {},
		currentPendingItemIndex: 0,
		pageCount: 0,
		pendingQuoteData: [],
		consolidationAction: "",
		headersSnooze: [
			{
				// text: "Ref#/Cus Ref",
				text: "Reference",
				align: "start",
				sortable: false,
				value: "shifl_ref",
				fixed: true,
				class: "pending-quote-table-header",
				// width: "15.20%"
				width: "11%",
			},
			{
				text: "Snooze Until",
				align: "start",
				sortable: false,
				value: "snooze_date_at",
				fixed: true,
				class: "pending-quote-table-header",
				// width: "15.20%"
				width: "12%",
			},
			{
				text: "From",
				value: "location_from_name",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "12%",
			},
			{
				text: "To",
				value: "location_to_name",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "12%",
			},
			{
				text: "Mode/Type",
				value: "mode",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "10%",
				align: "start",
			},
			{
				text: "Suppliers",
				value: "suppliers_names",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				// width: "15.64%"
				width: "18%",
			},
			{
				text: "PO",
				value: "po_num",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				// width: "18.36%"
				width: "18%",
			},
			{
				text: "",
				align: "center",
				sortable: false,
				value: "actions",
				// width: "14.28%",
				width: "15%",
				fixed: true,
			},
		],
		headersPendingQuote: [
			{
				// text: "Ref#/Cus Ref",
				text: "Reference",
				align: "start",
				sortable: false,
				value: "shifl_ref",
				fixed: true,
				class: "pending-quote-table-header",
				// width: "15.20%"
				width: "12%",
			},
			{
				text: "Updated By & At",
				align: "start",
				sortable: false,
				value: "updated_at",
				fixed: true,
				class: "pending-quote-table-header",
				// width: "15.20%"
				width: "18%",
			},
			{
				text: "From",
				value: "location_from_name",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "13%",
			},
			{
				text: "To",
				value: "location_to_name",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "13%",
			},
			{
				text: "Mode/Type",
				align: "start",
				value: "mode",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "10%",
			},
			{
				text: "Suppliers",
				value: "suppliers_names",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "16%",
			},
			{
				text: "PO",
				value: "po_num",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "14%",
			},
			{
				text: "",
				align: "center",
				sortable: false,
				value: "actions",
				width: "5%",
				fixed: true,
			},
		],
		headersPending: [
			{
				// text: "Ref#/Cus Ref",
				text: "Reference",
				align: "start",
				sortable: false,
				value: "shifl_ref",
				width: "10%",
				fixed: true,
			},
			{
				text: "Suppliers",
				value: "suppliers_names",
				sortable: false,
				width: "20%",
				fixed: true,
			},
			{
				text: "PO",
				value: "po_num",
				sortable: false,
				width: "15%",
				fixed: true,
			},
			{
				text: "Cargo Ready Date",
				value: "cargo_ready_date",
				sortable: false,
				width: "15%",
				fixed: true,
			},
			{
				text: "Status",
				value: "Status",
				align: "center",
				sortable: false,
				width: "15%",
				fixed: true,
			},
			{
				text: "",
				align: "center",
				sortable: false,
				value: "actions",
				width: "20%",
				fixed: true,
			},
			{
				text: "Container",
				align: " d-none",
				sortable: false,
				value: "container_num_list",
				width: "0",
				fixed: true,
			},
		],
		loadingNewScheds: false,
		currentIdRequestScheds: null,
		showSnoozeShipmentDialog: false,
		cancelBookingRequestDialog: false,
		requestConsolidationConfirmDialog: false,
		requestConsolidationBookingItemId: 0,
		cancelBookingItemId: 0,
		consolidationHeaders: [
			{
				text: "Reference",
				align: "start",
				sortable: false,
				value: "shifl_ref",
				fixed: true,
				class: "pending-quote-table-header",
				width: "7%",
			},
			{
				text: "Created By & At",
				align: "start",
				sortable: false,
				value: "updated_at",
				fixed: true,
				class: "pending-quote-table-header",
				width: "12%",
			},
			{
				text: "Shipper",
				value: "shippers",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "10%",
			},
			{
				text: "CRD",
				value: "crd_date",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "10%",
			},
			{
				text: "From",
				value: "location_from_name",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "9%",
			},
			{
				text: "To",
				value: "location_to_name",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "9%",
			},
			{
				text: "Earliest Shipment Date",
				value: "earliest_shipment_date",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "12%",
			},
			{
				text: "Must Arrival Date",
				value: "latest_arrival_date",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "10%",
			},
			{
				text: "CBM",
				value: "cbm",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "6%",
			},
			{
				text: "Consignee",
				value: "consignees",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "12%",
			},
			{
				text: "",
				value: "actions",
				sortable: false,
				fixed: true,
				class: "pending-quote-table-header",
				width: "6%",
			},
		],
		selected: [],
		openBookingConsolidationDialog: false,
		openMarkBookedConsolidateDialog: false,
		consolidateBtn: false,
		viewBookingShipmentDialog: false,
		shipmentData: null,
	}),
	computed: {
		isShipmentStillFetching() {
			let loading = false;

            if (this.pendingShipmentTab === 0)
                loading = this.getAllDraftShipmentsLoading;
            else if (this.pendingShipmentTab === 1)
                loading = this.getAllPendingQuoteShipmentsLoading;
            else if (this.pendingShipmentTab === 2)
                loading = this.getAllPendingShipmentsLoading;
            else if (this.pendingShipmentTab === 3)
                loading = this.getConsolidatedShipmentsLoading;
            else if (this.pendingShipmentTab === 4)
                loading = this.getAllSnoozeShipmentsLoading;
            else if (this.pendingShipmentTab === 5)
                loading = this.getAllExpiredShipmentsLoading;

            return loading;
		}, 
		showSelect() {
			return this.pendingShipmentTab == 3;
		},
		currentPendingQuoteItemShow() {
			if (!this.currentPendingQuoteItem.show_tooltip) {
				return false;
			} else {
				return true;
			}
		},
		tableHeaders() {
			if (this.pendingShipmentTab !== 4 && this.pendingShipmentTab !== 3) {
				if (this.isMobile) {
					if (
						this.pendingShipmentTab == 0 ||
						this.pendingShipmentTab == 1 ||
						this.pendingShipmentTab == 2 ||
						this.pendingShipmentTab == 5
					) {
						let newHeaders = [
							this.headersPendingQuote[0],
							this.headersPendingQuote[2],
							this.headersPendingQuote[3],
							this.headersPendingQuote[6],
						];
						return newHeaders;
					} else {
						return this.headersPendingQuote;
					}
				} else {
					return this.headersPendingQuote;
				}
			} else if (this.pendingShipmentTab === 3) {
				return this.consolidationHeaders;
			} else {
				if (this.isMobile) {
					let newHeaders = this.headersSnooze;
					if (newHeaders.length == 8) {
						newHeaders = _.filter(
							newHeaders,
							(e) =>
								e.value !== "mode" &&
								e.value !== "snooze_date_at" &&
								e.value !== "Suppliers"
						);
					}
					return newHeaders;
				} else {
					return this.headersSnooze;
				}
			}
			//return []
		},
		currentUser() {
			return JSON.parse(this.getUser);
		},
		...mapGetters([
			"getAllPendingShipments",
			"getAllPendingQuoteShipments",
			"getConsolidatedShipments",
			"getAllSnoozeShipmentsLoading",
			"getAllSnoozeShipments",
			"getAllExpiredShipments",
			"getShipmentLoadingStatus",
			"getAllPendingShipmentsLoading",
			"getAllPendingQuoteShipmentsLoading",
			"getConsolidatedShipmentsLoading",
			"getAllExpiredShipmentsLoading",
			"getSearchedShipments",
			"getSearchedShipmentPagination",
			"getUser",
			"getCancelShipmentLoading",
			"getchangeConsolidationStatusLoading",
			"getAllDraftShipmentsLoading"
		]),
		activeShipmentTab: {
			get() {
				return this.activeTab;
			},
			set(value) {
				this.$emit("update:activeTab", value);
			},
		},
		pendingShipmentTab: {
			get() {
				return this.pendingCurrentTab;
			},
			set(value) {
				this.$emit("update:pendingCurrentTab", value);
			},
		},
		page: {
			get() {
				return this.tablePage;
			},
			set(value) {
				this.$emit("update:tablePage", value);
			},
		},
		pagination: {
			get() {
				return this.paginationData;
			},
			set(value) {
				this.$emit("update:paginationData", value);
			},
		},
		getCurrentId() {
			if (this.pendingShipmentTab === 1 && this.pendingQuote.length === 0) {
				return "table-no-data";
			} else if (this.pendingShipmentTab === 2 && this.pending.length === 0) {
				return "table-no-data";
			} else if (this.pendingShipmentTab === 4 && this.snooze.length === 0) {
				return "table-no-data";
			} else if (this.pendingShipmentTab === 5 && this.expired.length === 0) {
				return "table-no-data";
			} else if (this.pendingShipmentTab === 4 && this.snooze.length === 0) {
				return "table-no-data";
			} else if (
				this.pendingShipmentTab === 3 &&
				this.consolidated.length === 0
			) {
				return "table-no-data";
			} else {
				return "";
			}
		},
		getCurrentShipmentsData() {
			if (typeof this.getSearchedShipments !== "undefined" && this.getSearchedShipments !== null) {
				if (this.search !== "" && this.pendingShipmentTab === 1 &&
					this.getSearchedShipments.tab === "booking_pending_quote") {
					return this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.pendingShipmentTab === 3 &&
					this.getSearchedShipments.tab === "consolidated") {
					return this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.pendingShipmentTab === 4 &&
					this.getSearchedShipments.tab === "booking_snoozed") {
					return this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.pendingShipmentTab === 5 &&
					this.getSearchedShipments.tab === "booking_expired") {
					return this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.pendingShipmentTab === 2 &&
					this.getSearchedShipments.tab === "booking_pending_approval") {
					return this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.pendingShipmentTab === 0 &&
					this.getSearchedShipments.tab === "booking_draft") {
					return this.getSearchedShipments.shipments;

				} else {
					if (this.pendingShipmentTab === 1)
						return this.pendingQuote;
					else if (this.pendingShipmentTab === 2)
						return this.pending;
					else if (this.pendingShipmentTab === 4)
						return this.snooze;
					else if (this.pendingShipmentTab === 5)
						return this.expired;
					else if (this.pendingShipmentTab === 0)
						return this.draft;
					else if (this.pendingShipmentTab === 3)
						return this.consolidated;					
				}
			} else {
				if (this.pendingShipmentTab === 1)
					return this.pendingQuote;
				else if (this.pendingShipmentTab === 2)
					return this.pending;
				else if (this.pendingShipmentTab === 4)
					return this.snooze;
				else if (this.pendingShipmentTab === 5)
					return this.expired;
				else if (this.pendingShipmentTab === 0)
					return this.draft;
				else if (this.pendingShipmentTab === 3)
					return this.consolidated;				
			}
			return "";
		},
		getCurrentShipmentPage: {
			get() {
				if (
					typeof this.getSearchedShipments !== "undefined" &&
					this.getSearchedShipments !== null
				) {
					if (
						this.search !== "" &&
						this.pendingShipmentTab === 2 &&
						this.getSearchedShipments.tab === "booking_pending_approval"
					) {
						return this.getSearchedShipmentPagination.current_page;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 1 &&
						this.getSearchedShipments.tab === "booking_pending_quote"
					) {
						return this.getSearchedShipmentPagination.current_page;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 3 &&
						this.getSearchedShipments.tab === "consolidated"
					) {
						return this.getSearchedShipmentPagination.current_page;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 4 &&
						this.getSearchedShipments.tab === "booking_snoozed"
					) {
						return this.getSearchedShipmentPagination.current_page;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 5 &&
						this.getSearchedShipments.tab === "booking_expired"
					) {
						return this.getSearchedShipmentPagination.current_page;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 0 &&
						this.getSearchedShipments.tab === "booking_draft"
					) {
						return this.getSearchedShipmentPagination.current_page;
					} else {
						if (this.pendingShipmentTab === 2) {
							return this.pagination.pendingTab.current_page;
						} else if (this.pendingShipmentTab === 1) {
							return this.pagination.pendingQuoteTab.current_page;
						} else if (this.pendingShipmentTab === 3) {
							// return this.pagination.consolidated.current_page;
							return 0;
						} else if (this.pendingShipmentTab === 4) {
							return this.pagination.snoozeTab.current_page;
						} else if (this.pendingShipmentTab === 5) {
							return this.pagination.expiredTab.current_page;
						} else {
							return this.pagination.draftTab.current_page;
						}
					}
				} else {
					if (this.pendingShipmentTab === 2) {
						return this.pagination.pendingTab.current_page;
					} else {
						if (this.pendingShipmentTab === 1) {
							return this.pagination.pendingQuoteTab.current_page;
						} else if (this.pendingShipmentTab === 3) {
							return this.pagination.snoozeTab.current_page;
						} else if (this.pendingShipmentTab === 4) {
							return this.pagination.expiredTab.current_page;
						} else if (this.pendingShipmentTab === 0) {
							return this.pagination.draftTab.current_page;
						}
					}
				}
				return "";
			},
			set(value) {
				if (
					typeof this.getSearchedShipments !== "undefined" &&
					this.getSearchedShipments !== null
				) {
					if (
						this.search !== "" &&
						this.pendingShipmentTab === 2 &&
						this.getSearchedShipments.tab === "booking_pending_approval"
					) {
						this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 5 &&
						this.getSearchedShipments.tab === "booking_expired"
					) {
						this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 1 &&
						this.getSearchedShipments.tab === "booking_pending_quote"
					) {
						this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 4 &&
						this.getSearchedShipments.tab === "booking_snoozed"
					) {
						this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 0 &&
						this.getSearchedShipments.tab === "booking_draft"
					) {
						this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
					} else if (
						this.search !== "" &&
						this.pendingShipmentTab === 3 &&
						this.getSearchedShipments.tab === "consolidated"
					) {
						this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
					} else {
						if (this.pendingShipmentTab === 0) {
							this.$emit("update:paginationData", value);
						} else {
							this.$emit("update:paginationData", value);
						}
					}
				} else {
					if (this.pendingShipmentTab === 0) {
						this.$emit("update:paginationData", value);
					} else {
						this.$emit("update:paginationData", value);
					}
				}
			},
		},
		getShipmentItemsPerPage() {
			return 20;
		},
		getShipmentPagination() {
			if (
				typeof this.getSearchedShipments !== "undefined" &&
				this.getSearchedShipments !== null
			) {
				if (
					this.search !== "" &&
					this.pendingShipmentTab === 1 &&
					this.getSearchedShipments.tab === "booking_pending_quote"
				) {
					return this.getSearchedShipmentPagination.total;
				} else if (
					this.search !== "" &&
					this.pendingShipmentTab === 2 &&
					this.getSearchedShipments.tab === "booking_pending_approval"
				) {
					return this.getSearchedShipmentPagination.total;
				} else if (
					this.search !== "" &&
					this.pendingShipmentTab === 4 &&
					this.getSearchedShipments.tab === "booking_snoozed"
				) {
					return this.getSearchedShipmentPagination.total;
				} else if (
					this.search !== "" &&
					this.pendingShipmentTab === 5 &&
					this.getSearchedShipments.tab === "booking_expired"
				) {
					return this.getSearchedShipmentPagination.total;
				} else if (
					this.search !== "" &&
					this.pendingShipmentTab === 3 &&
					this.getSearchedShipments.tab === "consolidated"
				) {
					return this.getSearchedShipmentPagination.total;
				} else if (
					this.search !== "" &&
					this.pendingShipmentTab === 0 &&
					this.getSearchedShipments.tab === "booking_draft"
				) {
					return this.getSearchedShipmentPagination.total;
				} else {
					if (this.pendingShipmentTab === 1) {
						return this.pagination.pendingQuoteTab.total;
					} else if (this.pendingShipmentTab === 2) {
						return this.pagination.pendingTab.total;
					} else if (this.pendingShipmentTab === 4) {
						return this.pagination.snoozeTab.total;
					} else if (this.pendingShipmentTab === 5) {
						return this.pagination.expiredTab.total;
					} else if (this.pendingShipmentTab === 3) {
						return this.pagination.consolidationTab.total;
					} else if (this.pendingShipmentTab === 0) {
						return this.pagination.draftTab.total;
					}
				}
				return "";
			} else {
				if (this.pendingShipmentTab === 1) {
					return this.pagination.pendingQuoteTab.total;
				} else if (this.pendingShipmentTab === 2) {
					return this.pagination.pendingTab.total;
				} else if (this.pendingShipmentTab === 4) {
					return this.pagination.snoozeTab.total;
				} else if (this.pendingShipmentTab === 5) {
					return this.pagination.expiredTab.total;
				} else if (this.pendingShipmentTab === 3) {
					return this.pagination.consolidationTab.total;
				} else if (this.pendingShipmentTab === 0) {
					return this.pagination.draftTab.total;
				}
				return "";
			}
		},
		type: {
			get() {
				return this.currentCalendarType;
			},
			set(v) {
				this.$emit("update:currentCalendarType", v);
			},
		},
		selectedCalendarFilter: {
			get() {
				return this.selectedDefaultFilter;
			},
			set(v) {
				this.$emit("update:selectedDefaultFilter", v);
			},
		},
		tab() {
			let currentTab = "booking_draft";

			if (this.pendingCurrentTab === 1) {
				currentTab = "booking_pending_quote";
			} else if (this.pendingCurrentTab === 2) {
				currentTab = "booking_pending_approval";
			} else if (this.pendingCurrentTab === 3) {
				currentTab = "consolidated";
			} else if (this.pendingCurrentTab === 4) {
				currentTab = "booking_snoozed";
			} else if (this.pendingCurrentTab === 5) {
				currentTab = "booking_expired";
			}
			return currentTab;
		},
		getConsolidatedShipmentLoading() {
			return this.$store.getters["booking/getConsolidatedShipmentLoading"];
		},
	},
	asyncComputed: {
		async getRequiredDetails() {
			if (this.selectedShipments.length !== 0) {	
				let shipments = this.selectedShipments.map((item) => item.id);
				let customerIds = this.selectedShipments.map(
					(item) => item.customer_id
				);

				let userRoles = this.selectedShipments.map((item) => item.issuer_role);

				this.consolidateBtn =
					customerIds.every((val) => val == customerIds[0]) &&
					userRoles.every((val) => val == userRoles[0]);

				const result = await axios.post(`v2/calculate-required-containers`, {
					shipment_id: shipments,
				});
				return {
					totalVolume: result?.data?.cbm.toFixed(2),
					capacity: result?.data?.data,
				};
			}
		},
	},
	watch: {
        currentViewTab(v) {
            if (v === 0 && !this.isMobile) { // shouldn't be applied to mobile
                setTimeout(() => {
                    this.checkTbodyWrapper();
                }, 100);
            }
        }
    },
	mounted() {
		//set current page
		this.$store.dispatch("page/setPage", "shipments");
		if (this.currentViewTab === 0 && !this.isMobile)  // shouldn't be applied to mobile
            this.checkTbodyWrapper();
	},
	methods: {
		...mapActions([
			"requestNewSchedule",
			"fetchPendingShipments",
			"fetchPendingQuoteShipments",
			"fetchConsolidationRequests",
			"fetchSnoozeShipments",
			"fetchExpiredShipments",
			"snoozeShipment",
			"unSnoozeShipment",
			"clearShipmentsData",
			"cancelShipment",
			"fetchDraftShipments",
			"changeConsolidationStatus",
			"fetchConsolidationRequests",
		]),
		...globalMethods,
		setMarkingBookedExternal(b) {
			this.$store.dispatch("page/setMarkingBookedExternal", b);
		},
		setEditingShipment(b) {
			this.$store.dispatch("page/setEditingShipment", b);
		},
		setEditingDraftShipment(b) {
			this.$store.dispatch("page/setEditingDraftShipment", b);
		},
		setAddingShipmentTracking(b) {
			this.$store.dispatch("page/setAddingShipmentTracking", b);
		},
		setCreatingBookingRequest(b) {
			this.$store.dispatch("page/setAddingShipmentTracking", b);
		},
		editShipment(item, e) {
			//prevent parent event
			e.preventDefault();
			if (item.is_draft == 1) this.setEditingDraftShipment(true);

			this.setEditingShipment(false);
			this.setMarkingBookedExternal(false);
			this.setAddingShipmentTracking(false);
			this.setCreatingBookingRequest(false);

			this.$emit("showEditShipment", item);
		},
		getCurrentPaginationCountClass() {
			if (this.search === "") {
				if (this.pendingShipmentTab === 0) {
					return typeof this.pagination.draftTab.total !== "undefined"
						? this.pagination.draftTab.total <= 1
						: false;
				} else if (this.pendingShipmentTab === 1) {
					return typeof this.pagination.pendingQuoteTab.total !== "undefined"
						? this.pagination.pendingQuoteTab.total <= 1
						: false;
				} else if (this.pendingShipmentTab === 2) {
					return typeof this.pagination.pendingTab.total !== "undefined"
						? this.pagination.pendingTab.total <= 1
						: false;
				} else if (this.pendingShipmentTab === 3) {
					// return typeof this.pagination.consolidatedTab.total !== "undefined"
					// 	? this.pagination.consolidatedTab.total <= 1
					// 	: false;
					return false;
				} else if (this.pendingShipmentTab === 4) {
					return typeof this.pagination.snoozeTab.total !== "undefined"
						? this.pagination.snoozeTab.total <= 1
						: false;
				} else if (this.pendingShipmentTab === 5) {
					return typeof this.pagination.expiredTab.total !== "undefined"
						? this.pagination.expiredTab.total <= 1
						: false;
				}
			} else {
				if (this.pendingShipmentTab === 1) {
					return typeof this.getShipmentPagination !== "undefined"
						? this.getShipmentPagination <= 1
						: false;
				} else if (this.pendingShipmentTab === 2) {
					return typeof this.getShipmentPagination !== "undefined"
						? this.getShipmentPagination <= 1
						: false;
				} else if (this.pendingShipmentTab === 3) {
					return typeof this.getShipmentPagination !== "undefined"
						? this.getShipmentPagination <= 1
						: false;
				} else if (this.pendingShipmentTab === 4) {
					return typeof this.getShipmentPagination !== "undefined"
						? this.getShipmentPagination <= 1
						: false;
				} else if (this.pendingShipmentTab === 5) {
					return typeof this.getShipmentPagination !== "undefined"
						? this.getShipmentPagination <= 1
						: false;
				} else if (this.pendingShipmentTab === 0) {
					return typeof this.getShipmentPagination !== "undefined"
						? this.getShipmentPagination <= 1
						: false;
				}
			}
		},
		handleMarkBookedExternal(item, e) {
			//prevent parent event
			e.preventDefault();
			this.setEditingShipment(false);
			this.setMarkingBookedExternal(true);
			this.setAddingShipmentTracking(false);
			this.setAddingShipmentTracking(false);
			this.$emit("showEditShipment", item);
		},
		handleShowSnoozeDialog(item) {
			this.currentSnoozeItem = item;
			this.showSnoozeShipmentDialog = true;
		},
		handleCloseSnoozeShipmentDialog() {
			this.showSnoozeShipmentDialog = false;
		},
		reloadAllShipments() {
			this.clearShipmentsData({
				pending_quote: this.getAllPendingQuoteShipments,
				pending: this.getAllPendingShipments,
				expired: this.getAllExpiredShipments,
				snooze: this.getAllExpiredShipments,
				consolidated: this.getConsolidatedShipments,
			});

			this.fetchPendingQuoteShipments(1);
			this.fetchConsolidationRequests(1);
			this.fetchPendingShipments(1);
			this.fetchExpiredShipments(1);
			this.fetchSnoozeShipments(1);
			this.fetchConsolidationRequests(1);
		},
		notificationUnsnoozeSuccess() {
			iziToast.success({
				theme: "dark",
				message: `<h4 style="font-weight: 500 !important; color: #fff !important;">Successfully unsnooze the shipment.</h4>`,
				backgroundColor: "#04783A",
				messageColor: "#ffffff",
				iconColor: "#ffffff",
				progressBarColor: "#ADEEBD",
				displayMode: 1,
				position: "bottomCenter",
				timeout: 3000,
			});
		},
		notificationUnsnoozeError(message) {
			iziToast.error({
				title: "Error",
				message,
				displayMode: 1,
				position: "bottomCenter",
				timeout: 3000,
			});
		},
		async handleUnSnoozeShipment(item) {
			item.unsnoozing = true;
			try {
				await this.unSnoozeShipment({
					shipment_id: item.id,
				});
				this.notificationMessage("Successfully unsnooze the shipment.");
				item.unsnoozing = false;
				this.reloadAllShipments();
			} catch (e) {
				item.unsnoozing = false;
				this.notificationError(e);
				console.log(e);
			}
		},
		async handleRequestConsolidation(status) {
			if (
				this.requestConsolidationBookingItemId &&
				this.requestConsolidationBookingItemId !== 0
			) {
				let payloadObject = {
					shipment_id: this.requestConsolidationBookingItemId,
					status,
				};
				this.changeConsolidationStatus(payloadObject)
					.then(() => {
						this.requestConsolidationConfirmDialog = false;
						this.notificationMessage(
							status === 1
								? "Booking request has been submitted for consolidation successfully."
								: "Consolidation request has been moved to regular booking successfully."
						);
						this.reloadAllShipments();
						this.fetchPendingQuoteShipments();
					})
					.catch((e) => {
						console.log(e);
						this.requestConsolidationConfirmDialog = false;
						this.notificationErrorCustom("SOMETHING WENT WRONG!");
					});
			}
		},
		handleCancel(item) {
			item.show_tooltip = false;
		},
		handleView({ id }) {
			this.$router.push(`shipment/${id}`);
		},
		showTooltip(item, index, shipment_type) {
			if (shipment_type == "pendingQuote") {
				if (this.currentPendingQuoteItemIndex !== index) {
					item.show_tooltip = true;
				} else {
					item.show_tooltip = !item.show_tooltip;
				}
				this.currentPendingQuoteItem = item;
				this.currentPendingQuoteItemIndex = index;
			} else if (shipment_type == "expired") {
				if (this.currentExpiredItemIndex !== index) {
					item.show_tooltip = true;
				} else {
					item.show_tooltip = !item.show_tooltip;
				}
				this.currentExpiredItem = item;
				this.currentExpiredItemIndex = index;
			} else if (shipment_type == "snooze") {
				if (this.currentSnoozeItemIndex !== index) {
					item.show_tooltip = true;
				} else {
					item.show_tooltip = !item.show_tooltip;
				}
				this.currentSnoozeItem = item;
				this.currentSnoozeItemIndex = index;
			} else if (shipment_type == "pending") {
				if (this.currentPendingItemIndex !== index) {
					item.show_tooltip = true;
				} else {
					item.show_tooltip = !item.show_tooltip;
				}
				this.currentPendingItem = item;
				this.currentPendingItemIndex = index;
			} else if (shipment_type == "draft") {
				if (this.currentPendingItemIndex !== index) {
					item.show_tooltip = true;
				} else {
					item.show_tooltip = !item.show_tooltip;
				}
				this.currentDraftItem = item;
				this.currentDraftItemIndex = index;
			} else if (shipment_type == "consolidated") {
				if (this.currentPendingItemIndex !== index) {
					item.show_tooltip = true;
				} else {
					item.show_tooltip = !item.show_tooltip;
				}
				this.currentDraftItem = item;
				this.currentDraftItemIndex = index;
			} else {
				if (this.currentPendingQuoteItemIndex !== index) {
					item.show_tooltip = true;
				} else {
					item.show_tooltip = !item.show_tooltip;
				}
				this.currentPendingQuoteItem = item;
				this.currentPendingQuoteItemIndex = index;
			}
		},
		beautify(date) {
			return date !== null ? moment.utc(date).format("MMM DD, YYYY") : "N/A";
		},
		addWheelClickedEventListener(element, callback) {
			var started = false;
			var removed = false;
			var onDocumentMouseup = function() {
				started = false;
			};
			var onElementMousedown = function(e) {
				e.preventDefault(); // prevents auto-scrolling action
				started = e.button === 1;
			};
			var onElementMouseup = function(e) {
				if (started) {
					started = false;
					callback(e);
				}
			};
			var onElementClick = function(e) {
				callback(e);
			};
			document.addEventListener("mouseup", onDocumentMouseup);
			element.addEventListener("mousedown", onElementMousedown);
			element.addEventListener("mouseup", onElementMouseup);
			element.addEventListener("click", onElementClick);

			return function() {
				if (removed) {
					return;
				}
				removed = true;
				document.removeEventListener("mouseup", onDocumentMouseup);
				element.removeEventListener("mousedown", onElementMousedown);
				element.removeEventListener("mouseup", onElementMouseup);
				element.removeEventListener("click", onElementClick);
			};
		},
		handleClick(value) {
			this.$emit("handleClick", value);
		},
		async requestSchedule(id) {
			this.currentIdRequestScheds = id;
			let storePagination = this.$store.state.shipment.shipmentsPagination
				.expiredTab;

			try {
				this.loadingNewScheds = true;
				await this.requestNewSchedule(id);
				this.notificationMessage("Schedule has been requested.");
				this.loadingNewScheds = false;
				this.currentIdRequestScheds = null;
				await this.fetchExpiredShipments(storePagination.current_page);
			} catch (e) {
				this.loadingNewScheds = false;
				this.currentIdRequestScheds = null;
				this.notificationError(e);
			}
		},
		async handlePageChangeSnooze(page) {
			this.$emit("handlePageChangeSnooze", page);
			this.handleScrollToTop();
		},
		async handlePageChangePendingQuote(page) {
			this.$emit("handlePageChangePendingQuote", page);
			this.handleScrollToTop();
		},
		async handlePageChangeConsolidationRequests(page) {
			this.$emit("handlePageChangeConsolidationRequests", page);
			this.handleScrollToTop();
		},
		async handlePageChangePending(page) {
			this.$emit("handlePageChangePending", page);
			this.handleScrollToTop();
		},
		async handlePageChangeExpired(page) {
			this.$emit("handlePageChangeExpired", page);
			this.handleScrollToTop();
		},
		async handlePageChangeDraft(page) {
			this.$emit("handlePageChangeDraft", page);
			this.handleScrollToTop();
		},
		async handlePageSearched(page) {
			let data = { page };

			if (this.pendingShipmentTab === 1) {
				data.tab = "booking_pending_quote";
			} else if (this.pendingShipmentTab == 2) {
				data.tab = "booking_pending_approval";
			} else if (this.pendingShipmentTab == 4) {
				data.tab = "booking_snoozed";
			} else if (this.pendingShipmentTab == 0) {
				data.tab = "booking_draft";
			} else if (this.pendingShipmentTab == 3) {
				data.tab = "consolidated";
			} else {
				data.tab = "booking_expired";
			}
			this.$emit("handlePageSearched", data);
			this.handleScrollToTop();
		},
		handleScrollToTop() {
			var table = this.$refs["my-table"];
			var wrapper = table.$el.querySelector("div.v-data-table__wrapper");

			this.$vuetify.goTo(table); // to table
			this.$vuetify.goTo(table, { container: wrapper }); // to header
		},
		cancelBooking(item) {
			this.cancelBookingItemId = item;
			this.cancelBookingRequestDialog = true;
		},
		requestConsolidation(item) {
			this.consolidationAction = "consolidation_request";
			this.requestConsolidationBookingItemId = item;
			this.requestConsolidationConfirmDialog = true;
		},
		moveToRegularBooking(item) {
			this.consolidationAction = "regular_booking";
			this.requestConsolidationBookingItemId = item;
			this.requestConsolidationConfirmDialog = true;
		},
		submitCancelBooking() {
			if (this.cancelBookingItemId && this.cancelBookingItemId !== 0) {
				let payloadObject = {
					shipmentId: this.cancelBookingItemId,
					type: "booking",
				};
				this.cancelShipment(payloadObject)
					.then((response) => {
						this.cancelBookingRequestDialog = false;
						this.notificationErrorCustom(response.data.message);
						this.reloadAllShipments();
						this.fetchDraftShipments();
					})
					.catch((e) => {
						console.log(e);
						this.cancelBookingRequestDialog = false;
						this.notificationErrorCustom("SOMETHING WENT WRONG!");
					});
			}
		},
		markBookedConsolidate() {
			this.openMarkBookedConsolidateDialog = true;
		},
		handleCloseConsolidateDialog() {
			this.openMarkBookedConsolidateDialog = false;
		},
		calculateCBM(shipment_suppliers) {
			let total = 0;
			shipment_suppliers.forEach((element) => {
				total += parseFloat(element.cbm);
			});
			return total.toFixed(2);
		},
		getMaxDate(shipment_suppliers) {
			let dates = [];
			shipment_suppliers.forEach((element) => {
				element.cargo_ready_date && dates.push(element.cargo_ready_date);
			});
			const maxDate = dates.reduce((acc, date) => {
				return acc && new Date(acc) > new Date(date) ? acc : date;
			}, "");

			return maxDate ? this.beautify(maxDate) : "N/A";
		},
		async consolidateShipment() {
			let shipmentIds = this.selectedShipments.map((item) => item.id);
			let paylods = {
				shipment_ids: shipmentIds,
			};
			let data = await this.$store.dispatch(
				"booking/createConsolidatedShipment",
				paylods
			);
			if (data.status) {
				this.openBookingConsolidationDialog = false;
				this.viewBookingShipmentDialog = data.status;
				this.shipmentData = data.paylods;
			}
		},
		getImgUrl(item, type) {
            if (type === "mode") {
                let shipmentMode = (item !== null && item !== "") ? item.toLowerCase() : "";
                if (shipmentMode !== "") {
                    // shipmentMode = shipmentMode === "air" ? "airplane" : shipmentMode;
                    return require(`@/assets/icons/shipment-table-icons/${shipmentMode}.svg`)
                }
            } else {
                let shipmentTypes = [
                    {
                        text: "LCL",
                        value: "small-container"
                    },
                    {
                        text: "LTL",
                        value: "small-container"
                    },
                    {
                        text: "Air",
                        value: "airplane-shipment"
                    },
                    {
                        text: "FCL",
                        value: "big-container"
                    },
                    {
                        text: "FTL",
                        value: "big-container"
                    }
                ]
                if (item !== null && item !== "") {
                    let findValue = _.find(shipmentTypes, e => ( e.text === item ));
                    if (findValue !== undefined) {
                        return require(`@/assets/icons/shipment-table-icons/${findValue.value}.svg`)
                    } else return ""
                } else {
                    return ""
                }
            }
		},
		checkTbodyWrapper() {
            let dis = this;
            const tbodyWrapper = document.querySelector(".v-data-table__wrapper tbody");
			this.addWheelClickedEventListener(tbodyWrapper, function(e) {
				if (e.target.type !== "checkbox") {
					// not include the checkbox to redirect in shipment details once clicked
					let row = e.target.closest("tr");
					if (e.which === 2) {
						if (row !== null) {
							let link = window.location.origin + `/shipment/${row.id}`;
							var anchor = document.createElement("a");
							anchor.href = link;
							anchor.target = "_blank";
							anchor.click();
						}
					} else {
						if (e.which === 1)
							dis.$router.push(`shipment/${row.id}`).catch(() => {}); // redirect to shipment details						
					}
				}
			});
        }
	},
	updated() {},
};
</script>
