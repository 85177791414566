var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"archived-shipment-wrapper",class:_vm.isMobile ? 'shipment-table-wrapper mobile' : ''},[_c('p',{staticClass:"font-weight-regular goback-archived",staticStyle:{"color":"#1A6D9E","cursor":"pointer","width":"8%"},on:{"click":function($event){return _vm.clickBack()}}},[_c('span',{staticClass:"mdi mdi-chevron-left"}),_vm._v(" Go Back ")]),_c('div',{attrs:{"id":"shipments_header"}},[_c('div',{staticClass:"filters-wrapper"},[_vm._m(0),_c('div',{staticClass:"search-wrapper",staticStyle:{"float":"right"}},[_c('img',{attrs:{"src":require("@/assets/images/search-icon.svg"),"alt":"search icon"}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],ref:"shipmentSearchInput",staticClass:"search",attrs:{"type":"text","id":"search-input","placeholder":"Search Archived","autocomplete":"off"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value.trim()},function($event){return _vm.handleSearch()}],"blur":function($event){return _vm.$forceUpdate()}}})])])]),(!_vm.isMobile)?_c('v-data-table',{ref:"my-table",staticClass:"archived-header archived-shipment-header",attrs:{"headers":_vm.headersPendingQuote,"items":_vm.getArchivedData,"mobile-breakpoint":"769","page":1,"item-key":"name","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.getArchivedBookingsLoading)?_c('div',{staticClass:"no-data-preloader"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e()]},proxy:true},{key:"item.ReferenceID",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"k-flex k-justify-between"},[_c('div',{staticClass:"k-default-color"},[_vm._v(_vm._s(item.ReferenceID))])])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"k-flex k-justify-between flex-column"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.updated_by_name ? item.updated_by_name : "N/A"))]),_c('div',{staticStyle:{"color":"#69758C"}},[_vm._v(_vm._s(_vm.beautify(item.updated_at)))])])]}},{key:"item.location_from_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"k-flex k-justify-between"},[_c('div',{staticClass:"k-default-color"},[_vm._v(_vm._s(item.location_from_name))])])]}},{key:"item.location_to_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"k-flex k-justify-between"},[_c('div',{staticClass:"k-default-color"},[_vm._v(_vm._s(item.location_to_name))])])]}},{key:"item.Suppliers",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"supplier-desktop"},[_vm._l((item.Suppliers),function(name,index){return _c('span',{key:index},[_c('span',[_vm._v(_vm._s(name))]),(index + 1 < item.Suppliers.length)?_c('span',[_vm._v(", ")]):_vm._e()])}),(item.Suppliers == null || item.Suppliers.length == 0)?_c('span',[_c('span',[_vm._v("N/A")])]):_vm._e()],2)]}},{key:"item.mode",fn:function(ref){
var item = ref.item;
return [(item.multipleModes.length > 0)?_c('div',{staticClass:"pending-quote-actions-wrapper shipment-type k-relative"},[_c('div',{staticClass:"k-pr-5 k-flex justify-center k-pl-12"},[_vm._l((item.multipleModes),function(m,k){return _c('span',{key:k,staticClass:"k-flex k-mr-4"},[_c('span',{staticClass:"mr-1"},[(m === 'ship')?_c('img',{attrs:{"src":require("@/assets/icons/ocean.svg"),"width":"20px","height":"20px"}}):_vm._e(),(m === 'air')?_c('img',{attrs:{"src":require("@/assets/icons/airplane.svg"),"width":"20px","height":"20px"}}):_vm._e(),(m === 'truck')?_c('img',{attrs:{"src":require("@/assets/icons/truck.svg"),"width":"20px","height":"20px"}}):_vm._e()])])}),(item.type == 'LCL')?_c('img',{attrs:{"src":require("@/assets/images/small-container.svg"),"width":"20px","height":"20px"}}):_vm._e(),(item.type == 'Air')?_c('img',{attrs:{"src":require("@/assets/images/airplane-shipment.svg"),"width":"20px","height":"20px"}}):_vm._e(),(item.type == 'FCL')?_c('img',{attrs:{"src":require("@/assets/images/big-container.svg"),"width":"20px","height":"20px"}}):_vm._e(),(item.type == 'FCL' && item.container_num_list.length !== 0)?_c('span',{staticStyle:{"padding-bottom":"2px"}},[_vm._v(" ("+_vm._s(item.container_num_list.length)+") ")]):_vm._e()],2)]):_c('div',{staticClass:"k-flex k-items-center justify-center"},[_c('div',{staticClass:"k-flex"},[_c('span',[_vm._v("N/A")])])])]}},{key:"item.PO",fn:function(ref){
var item = ref.item;
return [(1 == 2)?_c('div',{staticClass:"mobile-pos"},[(item.po_list !== null && item.po_list.length > 0)?_c('div',{staticStyle:{"width":"100%","display":"flex"}},[_c('p',_vm._l((item.po_list),function(name,index){return _c('span',{key:index},[_vm._v(" PO #"+_vm._s(name)),(index + 1 < item.po_list.length)?[_vm._v(", ")]:_vm._e()],2)}),0)]):_vm._e(),(item.po_list == null)?_c('div',[_c('p',[_vm._v("N/A")])]):_vm._e()]):_vm._e(),_c('div',{staticClass:"k-flex justify-center"},[_c('div',{staticClass:"k-default-color"},[_vm._v(" "+_vm._s(item.po_list !== null && item.po_list.length > 0 ? item.po_list.join(", ") : "N/A")+" ")])])]}},{key:"item.cancellation_reson",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.consolidation_status == 3 ? 'Due To Consolidation' : 'N/A'))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"shipment-action-button d-flex align-end justify-end mr-1"},[(item.consolidation_status !== 3)?_c('button',{staticClass:"btn-white",staticStyle:{"height":"35px !important","font-size":"12px !important"},on:{"click":function($event){return _vm.shipmentUnArchive(item.id)}}},[_vm._v(" Unarchive ")]):_vm._e()])]}}],null,true)}):_vm._e(),(_vm.isMobile)?_c('v-data-table',{ref:"my-table",staticClass:"table-mobile shipments-table-mobile archived-header archived-shipment-header",class:{
				'no-data-table': (typeof _vm.getArchivedData !== 'undefined' && _vm.getArchivedData.length === 0),
				'has-searched-data' : _vm.search !== '' && _vm.getArchivedData.length === 0
			},attrs:{"headers":_vm.headersPendingQuote,"items":_vm.getArchivedData,"mobile-breakpoint":"769","page":1,"item-key":"name","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.getArchivedBookingsLoading)?_c('div',{staticClass:"no-data-preloader"},[(_vm.getArchivedBookingsLoading)?_c('v-progress-circular',{attrs:{"size":30,"color":"#1A6D9E","indeterminate":""}}):_vm._e()],1):_vm._e(),(!_vm.getArchivedBookingsLoading && _vm.getArchivedData.length === 0)?_c('div',{staticClass:"no-data-wrapper"},[_c('div',{staticClass:"no-data-icon mb-2"},[_c('img',{attrs:{"src":require("@/assets/icons/noShipmentData.svg"),"alt":"","width":"65px"}})]),_c('div',{staticClass:"no-data-heading mb-4"},[(_vm.search === '')?_c('span',[_vm._v("No Archived Shipments")]):_c('span',[_vm._v("No Archived Shipments found. "),_c('br'),_vm._v(" Try searching another keyword.")])])]):_vm._e()]},proxy:true},{key:"item.ReferenceID",fn:function(ref){
			var item = ref.item;
return [_c('div',{staticClass:"k-flex k-justify-between font-medium"},[_c('div',{staticClass:"k-flex k-default-color align-center"},[_c('span',{staticClass:"font-medium mr-2"},[_vm._v(_vm._s(item.ReferenceID))]),(item.multipleModes.length > 0)?_c('div',{staticClass:"shipment-type ml-3"},[_c('div',{staticClass:"k-flex justify-center"},[_vm._l((item.multipleModes),function(m,k){return _c('span',{key:k,staticClass:"d-flex k-mr-4 align-center"},[_c('span',{staticClass:"d-flex align-center mr-1"},[(m === 'ship')?_c('img',{attrs:{"src":require("@/assets/icons/ocean.svg"),"width":"20px","height":"20px"}}):_vm._e(),(m === 'air')?_c('img',{attrs:{"src":require("@/assets/icons/airplane.svg"),"width":"20px","height":"20px"}}):_vm._e(),(m === 'truck')?_c('img',{attrs:{"src":require("@/assets/icons/truck.svg"),"width":"20px","height":"20px"}}):_vm._e()])])}),(item.type == 'LCL')?_c('img',{attrs:{"src":require("@/assets/images/small-container.svg"),"width":"18px","height":"18px"}}):_vm._e(),(item.type == 'Air')?_c('img',{attrs:{"src":require("@/assets/images/airplane-shipment.svg"),"width":"18px","height":"18px"}}):_vm._e(),(item.type == 'FCL')?_c('img',{attrs:{"src":require("@/assets/images/big-container.svg"),"width":"18px","height":"18px"}}):_vm._e(),(item.type == 'FCL' && item.container_num_list.length !== 0)?_c('span',{staticStyle:{"padding-bottom":"2px"}},[_vm._v(" ("+_vm._s(item.container_num_list.length)+") ")]):_vm._e()],2)]):_vm._e()]),_c('div',{staticClass:"shipment-action-button d-flex align-end justify-end"},[(item.consolidation_status !== 3)?_c('button',{staticClass:"btn-white",staticStyle:{"height":"32px !important","font-size":"12px","width":"80px !important"},on:{"click":function($event){return _vm.shipmentUnArchive(item.id)}}},[_vm._v(" Unarchive ")]):_vm._e()])])]}},{key:"item.updated_at",fn:function(ref){
			var item = ref.item;
return [_c('div',{staticClass:"table-mobile-data-content"},[_c('div',{staticClass:"table-mobile-data mb-2"},[_c('div',{staticClass:"mobile-departure-wrapper"},[_c('span',{staticStyle:{"color":"#69758C"}},[_vm._v("Departure")])]),_c('div',{staticClass:"mobile-departure-wrapper",staticStyle:{"text-align":"left !important"}},[_c('div',{staticClass:"flag-wrapper"},[_c('p',{staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(item.location_from_name !== null ? item.location_from_name : 'N/A')+" ")])])])]),_c('div',{staticClass:"table-mobile-data mb-2"},[_c('div',{staticClass:"mobile-arrival-wrapper"},[_c('span',{staticStyle:{"color":"#69758C"}},[_vm._v("Arrival")])]),_c('div',{staticClass:"arrival-wrapper",staticStyle:{"text-align":"right !important"}},[_c('div',{staticClass:"flag-wrapper"},[_c('p',{staticStyle:{"margin-bottom":"0px"}},[_vm._v(" "+_vm._s(item.location_to_name !== null ? item.location_to_name : 'N/A')+" ")])])])]),_c('div',{staticClass:"table-mobile-data mb-2"},[_c('div',{staticClass:"mobile-pos-wrapper"},[_c('span',{staticStyle:{"color":"#69758C"}},[_vm._v("POs")])]),_c('div',{staticClass:"po-num-mobile"},[(item.po_list !== null)?_c('p',[_vm._l((item.po_list),function(name,index){return _c('span',{key:index},[(index === 0 || index === 1)?_c('span',[_vm._v(" "+_vm._s(name)),(index + 1 < item.po_list.length)?[_vm._v(", ")]:_vm._e()],2):_vm._e()])}),(item.po_list.length > 2)?_c('span',{staticStyle:{"color":"#1A6D9E"}},[_vm._v(" +"+_vm._s(item.po_list.length - 2)+" Other ")]):_vm._e()],2):_vm._e()]),(item.po_list == null || item.po_list.length == 0)?_c('div',[_c('p',[_vm._v(" "+_vm._s(item.is_tracking_shipment ? '' : 'N/A')+" ")])]):_vm._e()])])]}},{key:"item.location_from_name",fn:function(ref){
			var item = ref.item;
return [_c('div',{staticClass:"k-flex k-justify-between"},[_c('span',{staticClass:"name text-start"},[_vm._v(" "+_vm._s((item.updated_by_name !== null && item.updated_by_name) ? item.updated_by_name : "N/A")+" ")]),_c('div',{staticClass:"text-start",staticStyle:{"color":"#69758C"}},[_vm._v(_vm._s(_vm.beautify(item.updated_at)))])])]}}],null,true)}):_vm._e(),_c('ConfirmDialog',{attrs:{"dialogData":_vm.unarchiveAlert},on:{"update:dialogData":function($event){_vm.unarchiveAlert=$event},"update:dialog-data":function($event){_vm.unarchiveAlert=$event}},scopedSlots:_vm._u([{key:"dialog_icon",fn:function(){return [_c('div',{staticClass:"header-wrapper-close"},[_c('img',{attrs:{"src":require("@/assets/icons/icon-delete.svg"),"alt":"alert"}})])]},proxy:true},{key:"dialog_title",fn:function(){return [_c('h2',[_vm._v("UnArchive Shipment?")])]},proxy:true},{key:"dialog_content",fn:function(){return [_c('p',[_vm._v(" Are you sure, Want to unacrhive this Shipment? ")])]},proxy:true},{key:"dialog_actions",fn:function(){return [_c('v-btn',{staticClass:"btn-blue",attrs:{"text":"","disabled":_vm.getUnArchiveShipmentLoading},on:{"click":function($event){return _vm.confirmUnArchive()}}},[(_vm.getUnArchiveShipmentLoading)?_c('span',[_vm._v("Yes, Confirming...")]):_c('span',[_vm._v("Yes, Confirm")])]),_c('v-btn',{staticClass:"btn-white",attrs:{"text":"","disabled":_vm.getUnArchiveShipmentLoading},on:{"click":function($event){return _vm.cancelUnArchive()}}},[_vm._v(" No, Cancel ")])]},proxy:true}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"font-weight-black"},[_vm._v("Archive")])])}]

export { render, staticRenderFns }