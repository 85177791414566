<!-- @format -->

<template>
	<div>
		<v-dialog
			v-model="dialog"
			width="560px"
			content-class="booking-request-templates-dialog"
			persistent
			scrollable
			transition="slide-x-transition"
			@click:outside="close"
		>
			<v-card class="view-booking-card">
				<v-card-title class="view-document-title">
					<span class="headline">Booking Information</span>
					<button icon dark class="btn-close" @click="close">
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>
				<v-card-text>
					<div class="booking-info-wrapper">
						<v-row>
							<v-col class="booking-details-section">
								<div class="general-info">
									<p class="mb-0 info-label">Customer Ref#</p>
									<p class="mb-0 info-value">
										{{
											getDetails.customer_reference_number
												? getDetails.customer_reference_number
												: "N/A"
										}}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">From</p>
									<p class="mb-0 info-value">
										{{
											getDetails.location_from
												? getDetails.location_from
												: "N/A"
										}}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">Port of Discharge</p>
									<p class="mb-0 info-value best-option">
										{{
											getDetails.give_me_best_location === 1
												? "(Best Option)"
												: getDetails.port_of_discharge
												? getDetails.port_of_discharge
												: "N/A"
										}}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">To</p>
									<p class="mb-0 info-value">
										{{
											getDetails.location_to ? getDetails.location_to : "N/A"
										}}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">Earliest Shipment Date</p>
									<p class="mb-0 info-value">
										{{ formatDate(getDetails.earliest_shipment_date) }}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">Latest Arrival Date</p>
									<p class="mb-0 info-value">
										{{ formatDate(getDetails.latest_arrival_date) }}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">Mode & Type</p>
									<p class="mb-0 info-value d-flex">
										<ModeIcon
											:iconName="getDetails.mode.toLowerCase()"
											class="ma-0"
											v-if="getDetails.mode"
										/>
										<span class="mr-2"
											>{{ getDetails.mode ? getDetails.mode : "N/A" }},</span
										>
										<img
											:src="shipmentTypeImg(getDetails.type)"
											v-if="getDetails.type"
										/>
										{{ getDetails.type ? getDetails.type : "N/A" }}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">Preferred Carrier</p>
									<p class="mb-0 info-value">
										{{
											getDetails.preferred_carrier &&
											getDetails.preferred_carrier.length > 0
												? getDetails.preferred_carrier.join(", ")
												: "N/A"
										}}
									</p>
								</div>
								<div class="general-info">
									<p class="mb-0 info-label">Warehouse/Delivery Location</p>
									<p class="mb-0 info-value">
										{{
											getDetails.warehouseDeliveryLocation &&
											getDetails.warehouseDeliveryLocation.address
												? getDetails.warehouseDeliveryLocation.address
												: "N/A"
										}}
									</p>
								</div>
								<div class="sub-component" v-if="(getDetails.warehouseDeliveryLocation && getDetails.warehouseDeliveryLocation.contact_name) || (getDetails.warehouseDeliveryLocation && getDetails.warehouseDeliveryLocation.phone) || (getDetails.warehouseDeliveryLocation && getDetails.warehouseDeliveryLocation.email)">
									<div class="general-info">
										<p class="mb-0 info-label">Contact Name</p>
										<p class="mb-0 info-value">
											{{
												getDetails.warehouseDeliveryLocation &&
												getDetails.warehouseDeliveryLocation.contact_name
													? getDetails.warehouseDeliveryLocation.contact_name
													: "N/A"
											}}
										</p>
									</div>
									<div class="general-info">
										<p class="mb-0 info-label">Contact Tel/Mobile</p>
										<p class="mb-0 info-value">
											{{
												getDetails.warehouseDeliveryLocation &&
												getDetails.warehouseDeliveryLocation.phone
													? getDetails.warehouseDeliveryLocation.phone
													: "N/A"
											}}
										</p>
									</div>
									<div class="general-info">
										<p class="mb-0 info-label">Contact Email</p>
										<p class="mb-0 info-value">
											{{
												getDetails.warehouseDeliveryLocation &&
												getDetails.warehouseDeliveryLocation.email
													? getDetails.warehouseDeliveryLocation.email
													: "N/A"
											}}
										</p>
									</div>
								</div>
							</v-col>
						</v-row>
					</div>

					<template v-if="getDetails.userRole === 'shipper'">
						<ShipperInfo :getDetails="getDetails" />
					</template>
					<template v-if="getDetails.userRole === 'consignee'">
						<ConsigneeInfo :getDetails="getDetails" />
					</template>

					<div class="booking-info-wrapper">
						<div class="container-info-wrapper">
							<ShipmentContainer :getDetails="getDetails" />
						</div>
					</div>
					<div class="booking-info-wrapper">
						<div class="container-info-wrapper">
							<ShipmentOtherInformation :getDetails="getDetails" />
						</div>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-btn class="btn-blue" text @click="close()">
						close
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import moment from "moment";
import ModeIcon from "../../../Icons/ModeIcon.vue";
import ShipmentContainer from "@/components/ShipmentComponents/ShipmentInfo/ShipmentContainer";
import ShipmentOtherInformation from "@/components/ShipmentComponents/ShipmentInfo/ShipmentOtherInformation";
import ShipperInfo from "./ShipperInfo.vue";
import ConsigneeInfo from "./ConsigneeInfo.vue";
import { mapGetters } from "vuex";

export default {
	name: "BookingInfoDialog",
	props: ["dialogData"],
	components: {
		ShipmentContainer,
		ShipmentOtherInformation,
		ModeIcon,
		ShipperInfo,
		ConsigneeInfo,
	},
	data: () => ({
		//
	}),
	computed: {
		...mapGetters({
			getBookingDetails: "shipmentDetailsV2/getNewShipmentDetails",
		}),
		console: () => console,
		dialog: {
			get() {
				return this.dialogData;
			},
			set(value) {
				this.$emit("update:dialogData", value);
			},
		},
		getDetails(){
			let shipmentData = {
				...this.getBookingDetails,
				...this.getBookingDetails.header_information,
			}
			return shipmentData
		}
	},
	methods: {
		close() {
			this.$emit("close");
		},
		formatDate(value) {
			return value == "" || value == null || value == "Invalid date"
				? "N/A"
				: moment(value).format("MMM DD, YYYY");
		},
		shipmentTypeImg(type) {
			if (type == "LCL") {
				return require("../../../../assets/images/small-container.svg");
			} else if (type == "Air" || type == "AIR") {
				return require("../../../../assets/images/airplane-shipment.svg");
			} else if (type == "FCL") {
				return require("../../../../assets/images/big-container.svg");
			}
		},
	},
};
</script>

<style lang="scss">
@import "../scss/BookingInfoDialog.scss";
</style>
