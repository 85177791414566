var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipment-container-wrapper shipment-details-card"},[_c('h3',[_vm._v(" Containers ")]),_c('div',{staticClass:"shipment-containers-table"},[_c('v-data-table',{staticClass:"container-table",attrs:{"headers":_vm.getDetails.booking_confirmed == 1
					? _vm.bookingContainerHeader
					: _vm.containerHeader,"items":_vm.getDetails.containers,"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
					var item = ref.item;
					var index = ref.index;
return [_c('span',{class:item.capacity},[_vm._v(_vm._s(index + 1))])]}},{key:"item.capacity",fn:function(ref){
					var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.capacity)+" CBM ")])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }