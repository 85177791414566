<!-- @format -->

<template>
	<div>
		<v-dialog
			v-model="dialog"
			width="1110px"
			@click:outside="close"
			scrollable
            :content-class="`view-statement-dialog-wrapper ${getClassNameDoc}  ${additionalClass ? 'dialog-expanded' : ''}`"
			transition="slide-x-reverse-transition">

			<v-card class="view-statement-card">
				<v-card-title class="view-statement-title" v-if="!isMobile">
					<div class="view-statement-header-wrapper">
                        <div class="view-statement-type-date">
                            <p class="statement-title text-capitalize font-semi-bold mb-0" v-if="statementData.type !== 'summary'">
                                {{ statementData.isFor }} 
                                Statement#: {{ statementData.item ? statementData.item.statement_no : "" }}
                            </p>

                            <p class="statement-title text-capitalize font-semi-bold mb-0" v-else>
                                {{ statementData.title !== undefined ? statementData.title : "--" }} Monthly Statement
                            </p>

                            <span class="statement-date" v-if="statementData.type !== 'summary'">
                                Date: {{ statementData.item ? formatDate(statementData.item.statement_date) : "--" }}
                            </span>

                            <span class="statement-date" v-else>
                                Date: {{ statementData.month !== undefined ?  statementData.month : "--" }}
                            </span>
                        </div>

                        <div class="view-statement-type-date-view">
                            <div class="view-statement-as" v-if="statementData.isFor === 'monthly' && statementData.type !== 'summary'">
                                <p class="mb-0" style="min-width: 60px; color: #69758C;">View As</p>
                                <v-tabs class="view-statement-tabs" @change="onTabChange" v-model="currentTab">
                                    <v-tab>PDF</v-tab>
                                    <v-tab>Excel</v-tab>
                                </v-tabs>
                            </div>

                            <div class="download-statement-wrapper ml-4">
                                <v-menu offset-y bottom left min-width="120px" max-height="90px" 
                                    :content-class="`ach-menu-dropdown ${statementData.isFor === 'monthly' ? 'monthly' : ''}`">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="btn-white btn-download-on-view" color="primary" dark v-bind="attrs" v-on="on">
                                            <span class="btn-title">Download</span>
                                            <v-icon color="#1A6D9E" size="20px">mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click.stop="downloadStatement(statementData.item, 'pdf', statementData.isFor)">
                                            <v-list-item-title>Download PDF</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="downloadStatement(statementData.item, 'xlsx', statementData.isFor)">
                                            <v-list-item-title>Download xlsx</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="exportAsExcel(statementData)" v-if="statementData.isFor === 'monthly'">
                                            <v-list-item-title>Summary Report</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <button
                                class="btn-white btn-edit ml-3"
                                @click="expandDialog()"
                                style="min-width: 40px !important; padding: 8px 10px !important;"
                                v-if="getClassNameDoc === 'excel-type-doc'"
                                :title="!additionalClass ? 'Expand Modal' : 'Collapse Modal'">

                                <img src="@/assets/icons/expand-full-icon.svg"
                                    width="18px" height="18px"
                                    v-if="!additionalClass" />

                                <img src="@/assets/icons/close-expand-icon.svg"
                                    width="18px" height="18px"
                                    v-else />
                            </button>

                            <button icon dark class="btn-close ml-4" @click="close">
                                <v-icon>mdi-close</v-icon>
                            </button>
                        </div>
                    </div>
				</v-card-title>

                <v-card-title class="view-statement-title" v-if="isMobile">
					<div class="view-statement-header-wrapper">
                        <div class="d-flex justify-space-between align-center mb-1" 
                            style="width: 100%; font-size: 14px; color: #BC4410;">
                            <span style="font-family: 'Inter-Regular', sans-serif !important;">
                                Preview
                            </span>

                            <button icon dark class="btn-close ml-4" @click="close">
                                <v-icon color="#253041" size="22px">mdi-close</v-icon>
                            </button>
                        </div>

                        <div class="view-statement-type-date mb-3">
                            <p class="statement-title text-capitalize font-semi-bold mb-0">
                                {{ statementData.isFor }} 
                                Statement#: {{ statementData.item ? statementData.item.statement_no : "" }}
                            </p>
                            <span class="statement-date">
                                Date {{ statementData.item ? formatDate(statementData.item.statement_date) : "" }}
                            </span>
                        </div>

                        <hr class="item-separator mb-3" style="width: 100%; border-color: #F3F4F7; border-style: solid;">

                        <div class="view-statement-type-date-view">
                            <div class="view-statement-as" v-if="statementData.isFor === 'monthly'">
                                <p class="mb-0" style="min-width: 60px; color: #69758C;">View As</p>
                                <v-tabs class="view-statement-tabs" @change="onTabChange" v-model="currentTab">
                                    <v-tab>PDF</v-tab>
                                    <v-tab>Excel</v-tab>
                                </v-tabs>
                            </div>

                            <div class="download-statement-wrapper">
                                <v-menu offset-y bottom left min-width="120px" max-height="90px" content-class="ach-menu-dropdown">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="btn-white btn-download-on-view" color="primary" dark v-bind="attrs" v-on="on">
                                            <span class="btn-title">Download</span>
                                            <v-icon color="#1A6D9E" size="20px">mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click.stop="downloadStatement(statementData.item, 'pdf', statementData.isFor)">
                                            <v-list-item-title>Download PDF</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="downloadStatement(statementData.item, 'xlsx', statementData.isFor)">
                                            <v-list-item-title>Download xlsx</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                    </div>
				</v-card-title>
                
				<v-card-text class="view-statement-content">
                    <div v-if="isFetching" class="d-flex justify-center" style="width: 100%; padding-top: 50px;">
						<v-progress-circular
							:size="35"
							color="#1A6D9E"
							indeterminate
							v-if="isFetching">
						</v-progress-circular>
					</div>
                    
					<div v-if="!isFetching" 
                        :style="`
                            width: 100%; 
                            height: 100%;
                            overflow: auto;`
                        ">
                        <!-- height: ${isMobile && getClassNameDoc !== 'excel-type-doc' ? '140%' : '100%'};  -->

						<div class="forthis" v-for="file in fileList" :key="file.name" style="width: 100%; height: 100%;">
                            <!-- <object :data="getFileURL(file)" frameborder="0" width="100%" height="99%" 
                                v-if="statementData.type === 'pdf'"></object> -->

                            <vue-pdf-embed 
								:source="getFileURL(file)" 
								v-if="statementData.type === 'pdf'" />

                            <v-data-table 
                                v-if="statementData.type === 'summary'"
                                class="table-summary-data"
                                :headers="editorFields"
                                :items="summaryData"
                                hide-default-footer
                                :items-per-page="summaryData.length"
                                fixed-header>                                
                            </v-data-table>

                            <div v-else class="sheet-js-table-wrapper" style="height: 100%;">
								<excel-viewer 
									ref="excelViewer"
									height="100%"
									:first-row-num="firstRowNum"
									:min-col-counts="1" />
							</div>
						</div>
					</div>	
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import globalMethods from '@/utils/globalMethods';
const baseUrl = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import moment from 'moment';
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { editorFields } from './excelEditorFields';

export default {
	name: "ViewStatementsDialog",
	props: ["dialogData", "item", "isMobile"],
	components: { VuePdfEmbed },
	data: () => ({
        fileList: [],
		filenameobj: '',
        isFetching: 0,
        request: null,
        currentTab: 0,
        excel: "",
		firstRowNum: 1,
        additionalClass: false,
        editorFields
	}),
	computed: {
		dialog: {
			get() {
				return this.dialogData;
			},
			set(value) {
				this.$emit("update:dialogData", value);
			},
		},
        statementData() {
            return this.item;            
        },
        getClassNameDoc() {
			if (this.statementData.type !== null && this.statementData.type !== "") {
				if (this.statementData.type !== "pdf") {
					return "excel-type-doc"
				} else {
					return ""
				}
			} else return ""
		},
        summaryData() {
            let value = [];
            if (this.statementData.type === "summary") {
                value = this.statementData.processedData;
                value.unshift({
                    "Daily Statement": "Daily Statement",
                    "Entry No": "Entry No",
                    "Reference": "Reference",
                    "POs": "POs",
                    "Container No": "Container No",
                    "Amount": "Amount"
                });
                value = value.map((v, i) => {
                    return {
                        Index: (i + 1),
                        ...v
                    }
                });
            } 
            return value;
        }
	},
	watch: {
        dialog(c) {
            if (c) {
                document.body.classList.add("modal-open");
				this.previewDoc(this.statementData);
                this.currentTab = this.statementData.type === "pdf" ? 0 : 1;

                if (this.getClassNameDoc === "excel-type-doc") {									
                    this.expandDialog();
                }
            }
        }
    },
	methods: {
		...globalMethods,
        onTabChange(i) {
            this.currentTab = i;
            this.statementData.type = i === 1 ? "xlsx" : "pdf";
            this.previewDoc(this.statementData);
        },
        formatDate(date) {
            if (!this.isMobile) {
                return date !== null && date !== "" ? moment(date).format("MM/DD/YY") : "";
            } else {
                return date !== null && date !== "" ? moment(date).format("MMM DD, YYYY") : "";
            }
        },
        getFileURL(file) {
            // return URL.createObjectURL(file);
			return URL.createObjectURL(file) + "#view=Fit&toolbar=0";
		},
        previewDoc(document) {
            if (document !== null) {
                if (document.type !== "summary") {
                    this.fileList = [];

                    this.filenameobj = document.item.statement_no;
                    this.isFetching = 1;
                    let c = [];
                    let url = "";

                    // cancel
                    this.cancel();
                    let axiosSource = axios.CancelToken.source();
                    this.request = { cancel: axiosSource.cancel };

                    if (document.type === "pdf") {
                        url = document.isFor === "monthly" 
                            // ? `${baseUrl}/ach-monthly-downlod-pdf/${document.item.statement_no}?file=final`
                            ? `${baseUrl}/ach-monthly-downlod-pdf/${document.item.statement_no}`
                            : `${baseUrl}/ach-daily-downlod-pdf/${document.item.statement_no}`
                    } else {
                        url = document.isFor === "monthly" 
                            ? `${baseUrl}/ach-download-final/${document.item.statement_no}` 
                            : `${baseUrl}/ach-download-daily/${document.item.statement_no}`
                    }

                    let passedData = {
                        method: "get",
                        url,
                        responseType: 'blob',
                        cancelToken: axiosSource.token,
                    }

                    axios(passedData).then((response) => {
                        this.fileList = [];
                        this.isFetching = 0;

                        window.URL = window.URL || window.webkitURL;
                        var json = (response.data);

                        let fileType = document.type === "pdf" ? "application/pdf" : "application/vnd.ms-excel";

                        c[0] = new File([json], this.filenameobj , { lastModified: new Date().getTime(), type: fileType })
                        this.fileList = c;
                        URL.revokeObjectURL(c);
                        this.request = null;
                        
                        if (document.type !== "pdf") {
                            let fileReader = new FileReader()
                            fileReader.onload = (e) => {
                                this.$refs.excelViewer[0].openExcelData(e.target.result)
                            };
                            fileReader.readAsBinaryString(json);
                        }
                    }).catch((e) => {
                        this.isFetching = 0;
                        if (e.message !== undefined && e.message === "cancel_previous_request") return;
                        else this.notificationError("Something went wrong, the file might not exist. Please try again later.");
                    });
                } else {
                    let dataArray = document.processedData;
                    var blob = new Blob([dataArray], { type: "application/vnd.ms-excel" });                    
                    this.fileList = [];
                    this.isFetching = 0;
                    let c = [];

                    window.URL = window.URL || window.webkitURL;
                    var json = (blob);

                    let fileType = "application/vnd.ms-excel";
                    let currentMonth = document.month;

                    c[0] = new File(
                        [json], `${currentMonth} Monthly Statement.xlsx`, 
                        { lastModified: new Date().getTime(), type: fileType }
                    )
                    this.fileList = c;
                    URL.revokeObjectURL(c);
                    this.request = null;
                }
            }
        },
        cancel() {
			if (this.request) this.request.cancel("cancel_previous_request");
		},
		close() {
			this.$emit("close");
            this.cancel();
            this.additionalClass = false;
		},
        downloadStatement(item, docType, type) {
            this.$emit("downloadStatement", item, docType, type)
        },
        expandDialog() {
			this.additionalClass = !this.additionalClass
		},
        exportAsExcel(item) {
            this.$emit("exportAsExcel", item, 'view');
        }
	},
};
</script>

<style lang="scss">
@import "@/assets/scss/pages_scss/shipment/viewDocumentDialog.scss";

.view-statement-dialog-wrapper {
    max-height: 100% !important;
    height: 100% !important;
    right: 0 !important;
    margin: 0 !important;
    position: absolute !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    &.dialog-expanded {
        width: 95% !important;
    }

    .v-card.view-statement-card {
        .view-statement-title {
            line-height: 24px;
            border-bottom: 2px solid #F3F4F7 !important;

            .view-statement-header-wrapper {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .view-statement-type-date {
                    .statement-title {
                        font-size: 20px;
                        color: $shifl-neutral-n90;
                    }

                    .statement-date {
                        font-size: 12px;
                        font-family: "Inter-Medium", sans-serif !important;
                        color: $shifl-neutral-n90;
                    }
                }

                .view-statement-type-date-view {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .btn-white.btn-edit {
                        height: 35px !important;
                    }

                    .view-statement-as {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .view-statement-tabs {
                            margin-left: 8px;

                            .v-tabs-slider-wrapper {
                                display: none;
                            }

                            .v-item-group {
                                height: 35px;

                                .v-tab {
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    font-family: "Inter-Medium", sans-serif !important;
                                    padding: 6px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    border-top: 1px solid #B3CFE0;
                                    border-bottom: 1px solid #B3CFE0;
                                    border-left: 1px solid #B3CFE0;
                                    color: $shifl-neutral-n90;
                                    text-transform: capitalize;
                                    min-width: 54px;

                                    &.v-tab--active {
                                        color: $shifl-primary-b90;
                                        background-color: #EBF2F5;
                                    }

                                    &:nth-child(2) {
                                        border-radius: 4px 0 0 4px;
                                    }

                                    &:last-child {
                                        border-right: 1px solid $shifl-neutral-n40;
                                        border-radius: 0 4px 4px 0;
                                    }
                                }
                            }
                        }
                    }

                    .btn-download-on-view {
                        height: 35px !important;
                        font-size: 12px !important;
                        min-width: 80px !important;
                        border-color: #B4CFE0 !important;
                        padding: 4px 4px 4px 8px !important;

                        .btn-title {
                            font-family: "Inter-Medium", sans-serif !important;
                        }

                        i {
                            padding-top: 1px;
                        }
                    }
                }
            }
        }
    }

    .v-card__text.view-statement-content {
        background-color: #4a4a4a !important;
        padding: 12px !important;

        .vue-pdf-embed {
			.vue-pdf-embed__page {
				margin-bottom: 12px;
			}
		}

        .forthis {
            canvas {
                height: 100% !important;
            }

            .table-summary-data {
                height: 100% !important;
                .v-data-table__wrapper {
                    height: 100% !important;
                    table {
                        thead {
                            tr {
                                th {
                                    background-color: #f4f5f8 !important;
                                    border-right: 1px solid #ededef !important;
                                    border-bottom: 1px solid #ededef !important;
                                    padding: 0 !important;
                                    height: 25px !important;
                                    text-align: center !important;
                                    
                                    span {
                                        color: $shifl-neutral-n90 !important;
                                        font-family: "Inter-Medium", sans-serif !important;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    border-right: 1px solid #ededef !important;
                                    border-bottom: 1px solid #ededef !important;
                                    padding: 4px 6px !important;
                                    height: 30px !important;
                                    background-color: #fff !important;
                                    white-space: nowrap;

                                    &:first-child {
                                        background-color: #f4f5f8 !important;
                                        padding: 4px 24px !important;
                                    }

                                    &:hover {
                                        background-color: #fff !important;
                                        cursor: auto !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
    
        /* Track */
        ::-webkit-scrollbar-track {
            background-color: $shifl-neutral-n10; 
            margin-top: 0 !important;
        }
    
        /* Handle */
        ::-webkit-scrollbar-thumb {
            background-color: #d8d9d9;
            border-radius: 0 !important;
        }
    
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
            background-color: #d8d9d9;
        }
    }

    &.excel-type-doc {
		.v-card {
			.v-card__text {
				overflow: auto !important;
				background-color: #fff !important;
				padding: 12px !important;

				.sheet-js-table-wrapper {
					table {
						border-collapse: collapse;
						display: block;

						thead {
							tr {
								th {
									background-color: #f4f5f8 !important;
									font-weight: unset;
									font-family: 'Inter-Medium', sans-serif;

									&.excel-angle {
										border-color: #ededef !important;
									}
								}
							}
						}

						td, th {
							border: none;
							border-bottom: 1px solid #ededef;
							border-right: 1px solid #ededef;
							color: #253041;
							font-size: 14px;
						}

						tbody {
							tr {
								border-top: 1px solid #ededef;
								
								&:nth-child(2n) {
									background-color: #f6f8fa;
								}

								td {
									color: #253041;
									padding: 4px 6px;
									line-height: 18px;

									&.excel-left-num {
										background-color: #f4f5f8 !important;
										padding: 4px 24px;
										border: none;
										border-bottom: 1px solid #ededef;
										font-weight: unset;
										font-family: 'Inter-Medium', sans-serif;
                                        // font-size: 12px;
									}
								}
							}
						}
					}

					.excel-panel {
						&::-webkit-scrollbar {
							width: 12px;
							height: 12px;
						}
					
						/* Track */
						&::-webkit-scrollbar-track {
							background-color: $shifl-neutral-n10; 
						}
					
						/* Handle */
						&::-webkit-scrollbar-thumb {
							background-color: #d8d9d9;
							// border-radius: 4px;
						}
					
						/* Handle on hover */
						&::-webkit-scrollbar-thumb:hover {
							background-color: #d8d9d9;
						}
					}
				}

				&::-webkit-scrollbar {
					width: 12px;
					height: 12px;
				}
			
				/* Track */
				&::-webkit-scrollbar-track {
					background-color: $shifl-neutral-n10; 
				}
			
				/* Handle */
				&::-webkit-scrollbar-thumb {
					background-color: #d8d9d9;
					// border-radius: 4px;
				}
			
				/* Handle on hover */
				&::-webkit-scrollbar-thumb:hover {
					background-color: #d8d9d9;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
    .view-statement-dialog-wrapper {
        width: 100% !important;

        &.dialog-expanded {
            width: 100% !important;
        }

        .v-card {
            .v-card__title.view-statement-title {
                padding: 16px !important;

                .view-statement-header-wrapper {
                    flex-direction: column;
                    align-items: flex-start;

                    .view-statement-type-date {
                        width: 100%;

                        .statement-date {
                            font-size: 14px;
                            color: #69758C;
                        }
                    }

                    .view-statement-type-date-view {
                        justify-content: space-between;
                        width: 100%;
                        flex-direction: row-reverse;
                    }
                }
            }

            .v-card__text.view-statement-content {
                margin-bottom: 0 !important;
                border-radius: 0 !important;
                background-color: #4B586C !important;
                padding: 12px 0 !important;

                .forthis {
                    canvas {
                        width: auto !important
                    }
                }
            }
        }
    }
}
</style>