<template>
    <div id="documents" v-resize="onResize">
        <v-snackbar v-model="shipmentDocumentSubmitted" class="shipmentDocumentSubmitted" timeout="20000">
            <span><img src="@/assets/icons/check-white.svg" width="16px" height="16px"> Documents have successfully been submitted</span>
        </v-snackbar>
    
        <div class="documents-wrapper-header" :class="selected.length !== 0 ? 'has-selected' : ''">
            <div class="documents-title-wrapper">
                <!-- <h3 v-if="selected.length == 0">Documents</h3>
                <h3 v-if="selected.length !== 0 && !isMobile">{{ selected.length }} items selected</h3> -->
                <h3 v-if="getAllRequestDocuments.length === 0">Documents</h3>
                <div v-else class="document-tabs-wrapper">
                    <v-tabs class="document-tabs" @change="onTabChange" v-model="currentTab">
                        <v-tab v-for="(tab, index) in documentTabs" 
                            :key="index" 
                            class="document-tab"
                            :class="index === 1 ? `last ${tab}` : `${tab}`"> 
                            {{ tab }}
                        </v-tab>
                    </v-tabs>
                </div>
            </div>

            <div class="button-wrapper-header">
                <div class="button-wrapper-content">
                    <!-- <button :class="selected.length !== 0 ? 'btn-blue' : 'btn-black'" @click.stop="openSubmitDialog" 
                        v-if="getShipmentDocuments.length > 0">
                        <img src="@/assets/icons/submit.svg" width="16px" height="16px" v-if="selected.length == 0">
                        <img src="@/assets/icons/submit-blue.svg" width="16px" height="16px" v-if="selected.length !== 0">
                        Submit
                    </button> -->
                    <button class="btn-white mr-0" 
                        @click.stop="openRequestDocumentDialog" 
                        v-if="isCurrentSelectedCustomer && (selected.length == 0 && selectedSuppliers.length === 0)">
                        Request
                    </button>

                    <button class="btn-white btn-gray mr-0 dc-red ml-2" 
                        style="min-width: 120px !important; color: #B11B1B !important;" 
                        @click.stop="cancelRequest(null)" 
                        v-if="selectedSuppliers.length !== 0 && currentTab === 1 && isMobile">
                        Cancel Requests
                    </button>

                    <button class="btn-white ml-2 mr-0" @click.stop="openUploadDialog(null)" v-if="selected.length == 0 && currentTab === 0">
                        <!-- && isCurrentSelectedCustomer -->
                        <img src="@/assets/icons/upload.svg" width="16px" height="16px">
                        Upload
                    </button>
                </div>
                
                <div class="button-wrapper-content" v-if="!isMobile">
                    <button class="btn-white" @click="downloadDocuments()" v-if="selected.length !== 0 && currentTab !== 1">
                        <img src="../assets/icons/download.svg" alt="" width="16px" height="16px">
                        Download
                    </button>
                    <button class="btn-white" style="color: #B11B1B !important;" @click.stop="openDeleteDialog" v-if="selected.length !== 0 && currentTab !== 1">
                        <img src="../assets/icons/delete-po.svg" alt="" width="16px" height="16px" class="mt-0 mr-1">
                        Delete
                    </button>

                    <button class="btn-white btn-gray mr-0" @click.stop="cancel()" v-if="selected.length !== 0 && currentTab !== 1">
                        Cancel
                    </button>

                    <button class="btn-white btn-gray mr-0 dc-red ml-2" 
                        style="min-width: 145px !important; color: #B11B1B !important;" 
                        @click.stop="cancelRequest(null)" 
                        v-if="selectedSuppliers.length !== 0 && currentTab === 1">
                        Cancel Requests
                    </button>
                </div>
            </div>

            <div class="mobile-buttons-wrapper" v-if="isMobile && selected.length !== 0">
                <div class="mobile-buttons-actions" v-if="selected.length !== 0"> 
                    <button class="btn-white" @click="downloadDocuments()" v-if="selected.length !== 0">
                        <img src="../assets/icons/download.svg" alt="" width="16px" height="16px">
                    </button>
                    <button class="btn-white" @click.stop="openDeleteDialog" v-if="selected.length !== 0">
                        <img src="../assets/icons/delete-po.svg" alt="" width="16px" height="16px">
                    </button>

                    <button class="btn-white mr-0" @click.stop="cancel()" v-if="selected.length !== 0">
                        <!-- <img src="../assets/icons/documents-close.svg" alt="" width="16px" height="16px"> -->
                         <v-icon color="#B11B1B" size="18">mdi-close</v-icon>
                    </button>
                </div>

                <!-- <v-menu bottom left offset-y content-class="outbound-lists-menu" v-if="selected.length !== 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="btn-white btn-more" icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>

                    <v-list class="outbound-lists">                        
                        <v-list-item @click="downloadDocuments()" v-if="selected.length !== 0">
                            <v-list-item-title>
                                Download
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item @click.stop="openDeleteDialog" v-if="selected.length !== 0">
                            <v-list-item-title class="cancel">
                                Delete
                            </v-list-item-title>
                        </v-list-item>

                        <v-list-item @click.stop="cancel()" v-if="selected.length !== 0">
                            <v-list-item-title>
                                Cancel
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu> -->
            </div>
        </div>

        <div class="document-tab-wrapper" v-if="currentTab === 0">
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="getShipmentDocuments"
                :single-select="singleSelect"
                item-key="id"
                :items-per-page="documentItemsPerPage"
                show-select
                class="elevation-1 documents-table"
                :class="getShipmentDocuments.length == 0 ? 'no-data-table' : ''"
                hide-default-footer 
                v-if="!isMobile">

                <template v-slot:[`item.name`]="{ item }">
                    <div class="document-name-info">
                        <p>{{ item.name }}</p>
                        <!-- <p class="updated-by-name-date" v-if="item.updated_by_name">
                            {{ item.updated_by_name ? item.updated_by_name.company_name : 'N/A' }}, 
                            <span>{{dateFormat(item.updated_at)}}</span>
                        </p> -->
                        <p class="updated-by-name-date">
                            <span>{{ dateFormat(item.updated_at) }}</span>
                        </p>
                    </div>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                    <div class="document-type-info">
                        <p>
                            {{ 
                                typeof item.type !== 'undefined' ? 
                                (item.type === 'Hbl' ? 'OBL Document' : 
                                (item.type === "Invoice And Packing List" ? "Commercial Invoice & Packing List" : item.type)) 
                                : '--' 
                            }}
                        </p>

                        <!-- <p class="supplier-name" v-if="item.type !== 'Delivery Order' && item.type !== 'Other'">
                            Supplier:
                            <span v-for="(supplier, index) in item.supplier_ids" :key="index">
                                {{ supplier.company_name }}
                            </span>
                        </p> -->
                    </div>
                    <div class="document-type-supplier-info">
                        <p class="supplier-name" v-if="item.type !== 'Delivery Order' && item.type !== 'Other'">                            
                            <span v-if="item.supplier_ids.length === 1">
                                <!-- <span v-if="item.supplier_ids[0] !== null">
                                    Supplier:
                                    <span v-for="(supplier, index) in item.supplier_ids" :key="index">
                                        {{ supplier.company_name }}
                                    </span>
                                </span> -->

                                <span v-if="item.supplier_ids[0] !== null">
                                    Supplier: {{ 
                                        typeof item.supplier_ids[0].company_name !== "undefined" ?
                                        item.supplier_ids[0].company_name : ""
                                    }}
                                </span>
                                <span v-else class="text-capitalize">
                                    {{ 
                                        typeof item.request_type !== "undefined" && 
                                        item.request_type !== null ? 
                                        item.request_type : ""
                                    }}
                                </span>
                            </span>

                            <span v-if="item.supplier_ids.length > 1">
                                <span> Multiple </span>
                            </span>

                            <span v-if="item.supplier_ids.length === 0" class="text-capitalize">
                                {{ 
                                    typeof item.request_type !== "undefined" && 
                                    item.request_type !== null ? 
                                    item.request_type : ""
                                }}
                            </span>
                        </p>
                    </div>
                </template>

                <template v-slot:[`item.uploaded_by`]="{ item }">
                    <div class="document-name-info">
                        <p class="mb-0" v-if="
                            getUploadedbyName(item, 'customer') !== '-' && 
                            getUserDetails !== null && getUserDetails.default_customer !== null && 
                            getUserDetails.default_customer.company_name === getUploadedbyName(item, 'customer')">
                            <!-- {{ item.updated_by_name !== null ? item.updated_by_name.company_name : "-" }} -->
                            {{ getUploadedbyName(item, 'customer_admin') }}
                        </p>
                        <p class="mb-0" style="color: #69758C !important;">
                            {{ 
                                getUploadedbyName(item, 'customer') === "" 
                                ? (item.supplier_ids.length === 1 
                                    ? (item.supplier_ids[0] === null ? "Others" 
                                        : (typeof item.supplier_ids[0].company_name !== "undefined" 
                                        ? item.supplier_ids[0].company_name : ""))
                                    : getUploadedbyName(item, 'customer')) 
                                : getUploadedbyName(item, 'customer')
                            }}
                        </p>
                    </div>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions">
                        <button class="btn-white" @click="viewDocument(item)" :disabled="checkFileExtension(item)">
                            <v-icon>mdi-eye</v-icon>
                        </button>

                        <button class="btn-white" @click="download(item.url)">
                            <img src="../assets/icons/download.svg" alt="">
                        </button>
                        
                        <v-menu bottom left offset-y content-class="outbound-lists-menu">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn class="btn-white" icon v-bind="attrs" v-on="on">
                                    <v-icon>mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list class="outbound-lists">
                                <!-- <v-list-item @click="viewDocument(item)">
                                    <v-list-item-title>
                                        View
                                    </v-list-item-title>
                                </v-list-item> -->

                                <v-list-item @click.stop="openSubmitItemDialog(item)" 
                                    v-if="item.type !== 'Delivery Order' && item.type !== 'Other'">
                                    <v-list-item-title>
                                        Submit
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item @click.stop="openUpdateDialog(item)">
                                    <v-list-item-title>
                                        Update Name & Type
                                    </v-list-item-title>
                                </v-list-item>                                

                                <v-list-item @click.stop="openReuploadDialog(item)" 
                                    v-if="isCurrentSelectedCustomer ||
                                        (getUploadedbyName(item, 'customer') !== '-' && 
                                        getUserDetails !== null && getUserDetails.default_customer !== null && 
                                        getUserDetails.default_customer.company_name === getUploadedbyName(item, 'customer'))
                                    ">
                                    <!-- && (getUploadedbyName(item, 'customer_admin') !== '-' && getUserDetails.name === getUploadedbyName(item, 'customer_admin')) -->
                                    <v-list-item-title>
                                        Reupload
                                    </v-list-item-title>
                                </v-list-item>

                                <v-list-item @click.stop="openDeleteItemDialog(item)">
                                    <v-list-item-title class="cancel">
                                        Delete
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>

                <template v-slot:no-data>
                    <div class="no-data-preloader" v-if="shipmentsLoading">
                        <v-progress-circular
                            :size="40"
                            color="#1A6D9E"
                            indeterminate>
                        </v-progress-circular>
                        <!-- <p class="k-mt-8 pt-4">Fetching shipment documents...</p> -->
                    </div>

                    <div class="no-data-wrapper" v-if="getShipmentDocuments.length == 0 && !shipmentsLoading">
                        <div class="no-data-heading">
                            <div>
                                <p class="header-title"> No Document Uploaded </p>
                                <p class="k-col-tablet-58 k-col-mobile-90 no-data-value"> 
                                    No document has been uploaded for this shipment. 
                                    Please Upload the required documents (Commercial Invoice, Packing List etc.)
                                </p>

                                <!-- v-if="isCurrentSelectedCustomer" -->
                                <div class="d-flex justify-center align-center mt-4">
                                    <v-btn color="primary" dark class="btn-white" @click.stop="openUploadDialog(null)">
                                        Upload Documents
                                    </v-btn>
                                </div>
                            </div> 
                        </div>
                    </div>
                </template>
            </v-data-table>

            <v-data-table
                :items="getShipmentDocuments"
                v-model="selected"
                :headers="headersMobile"
                :single-select="singleSelect"
                item-key="id"
                :items-per-page="documentItemsPerPage"
                show-select
                class="elevation-1 documents-table"
                :class="getShipmentDocuments.length == 0 ? 'no-data-table' : ''"
                hide-default-footer
                mobile-breakpoint="0"
                v-if="isMobile">

                <template v-slot:item="props">
                    <tr>
                        <td>
                            <div class="document-name-info">
                                <div class="document-name-checkbox">
                                    <v-checkbox :input-value="props.isSelected" @change="props.select($event)"></v-checkbox>
                                
                                    <div class="doc-name">
                                        <p>{{ props.item.name }}</p>

                                        <div class="mobile-document-type-info">
                                            <p class="document-types">
                                                {{ 
                                                    typeof props.item.type !== 'undefined' ? 
                                                    (props.item.type === 'Hbl' ? 'OBL Document' : props.item.type) 
                                                    : '--' 
                                                }}
                                            </p>

                                            <p class="supplier-name" v-if="props.item.type !== 'Delivery Order' && props.item.type !== 'Other'">
                                                <span v-if="props.item.supplier_ids.length === 1">
                                                    <span v-if="props.item.supplier_ids[0] !== null">
                                                        Supplier: {{ 
                                                            typeof props.item.supplier_ids[0].company_name !== "undefined" ?
                                                            props.item.supplier_ids[0].company_name : ""
                                                        }}
                                                    </span>
                                                    <span v-else class="text-capitalize">
                                                        {{ 
                                                            typeof props.item.request_type !== "undefined" && 
                                                            props.item.request_type !== null ? 
                                                            props.item.request_type : ""
                                                        }}
                                                    </span>
                                                </span>

                                                <span v-if="props.item.supplier_ids.length > 1">
                                                    <span> Multiple </span>
                                                </span>

                                                <span v-if="props.item.supplier_ids.length === 0" class="text-capitalize">
                                                    {{ 
                                                        typeof props.item.request_type !== "undefined" && 
                                                        props.item.request_type !== null ? 
                                                        props.item.request_type : ""
                                                    }}
                                                </span>
                                            </p>

                                            <p class="mb-0">
                                                <!-- <span class="mb-0" v-if="
                                                    getUploadedbyName(props.item, 'customer') !== '-' && 
                                                    getUserDetails !== null && getUserDetails.default_customer !== null && 
                                                    getUserDetails.default_customer.company_name === getUploadedbyName(props.item, 'customer')">
                                                    {{ getUploadedbyName(props.item, 'customer_admin') }}
                                                </span> -->
                                                <span class="mb-0" style="color: #69758C !important;">
                                                    {{ getUploadedbyName(props.item, 'customer') }}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <v-menu bottom left offset-y content-class="outbound-lists-menu outbound-lists-menu-mobile">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="btn-white" icon v-bind="attrs" v-on="on">
                                            <v-icon>mdi-dots-horizontal</v-icon>
                                        </v-btn>
                                    </template>

                                    <v-list class="outbound-lists">
                                        <v-list-item @click.stop="openSubmitDialog()" 
                                            v-if="props.item.type !== 'Delivery Order' && props.item.type !== 'Other'">
                                            <v-list-item-title>
                                                Submit
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click.stop="openUpdateDialog(props.item)">
                                            <v-list-item-title>
                                                Update Name & Type
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click.stop="openReuploadDialog(props.item)" 
                                            v-if="isCurrentSelectedCustomer ||
                                                (getUploadedbyName(props.item, 'customer') !== '-' && 
                                                getUserDetails !== null && getUserDetails.default_customer !== null && 
                                                getUserDetails.default_customer.company_name === getUploadedbyName(props.item, 'customer'))
                                            ">
                                            <v-list-item-title>
                                                Reupload
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item @click.stop="openDeleteItemDialog(props.item)">
                                            <v-list-item-title class="cancel">
                                                Delete
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </td>
                    </tr>
                </template>

                <template v-slot:no-data>
                    <div class="no-data-preloader no-data-preloader-mobile" v-if="shipmentsLoading">
                        <v-progress-circular
                            :size="40"
                            color="#1A6D9E"
                            indeterminate>
                        </v-progress-circular>
                        <!-- <div class="k-mt-8 k-border-b-0 loading-text pt-4">Fetching shipment documents...</div> -->
                    </div>
                    <div class="no-data-wrapper" v-if="getShipmentDocuments.length == 0 && !shipmentsLoading">
                        <div class="no-data-heading">
                            <div>
                                <p class="header-title"> No Document Uploaded </p>
                                <p class="k-col-tablet-58 k-col-mobile-90 no-data-value"> 
                                    No document has been uploaded for this shipment. 
                                    Please Upload the required documents (Commercial Invoice, Packing List etc.)
                                </p>

                                <div class="d-flex justify-center align-center mt-4">
                                    <v-btn color="primary" dark class="btn-white" @click.stop="openUploadDialog(null)">
                                        Upload Documents
                                    </v-btn>
                                </div>
                            </div> 
                        </div>
                    </div>
                </template>
            </v-data-table>
        </div>

        <div class="document-tab-wrapper" v-if="currentTab === 1">
            <v-data-table
                v-model="selectedSuppliers"
                :headers="computedRequestHeaders"
                :items="getAllRequestDocuments"
                :single-select="singleSelect"
                item-key="id"
                :items-per-page="documentItemsPerPage"
                :show-select="isCurrentSelectedCustomer"
                class="elevation-1 documents-table"
                hide-default-footer
                v-bind:class="{
                    'no-data-table': getAllRequestDocuments.length === 0,
                    'supplier-view' : !isCurrentSelectedCustomer
                }"
                v-if="!isMobile">

                <template v-slot:[`item.document_types`]="{ item }">
                    <div class="document-name-info">
                        <p class="mb-0">{{ concatDocuments(item.document_types) }}</p>
                        <p style="color: #69758C;">Requested at {{ item.requested_at }}</p>
                    </div>
                </template>

                <template v-slot:[`item.supplier`]="{ item }">
                    <div class="document-name-info" v-if="item.type === 'Vendor'">
                        <p class="mb-0 d-flex align-center justify-start">
                            {{ item.supplier !== null ? item.supplier.company_name : "N/A" }}
                            <span class="document-request-to ml-2">{{ item.type }}</span>
                        </p>
                        <p style="color: #69758C;">{{ concatEmails(item.emails) }}</p>
                    </div>

                    <div class="document-name-info" v-else>
                        <p class="mb-0 d-flex align-center justify-start">{{ item.type }}</p>
                        <p style="color: #69758C;">{{ concatEmails(item.emails) }}</p>
                    </div>
                </template>

                <template v-slot:[`item.actions`]="{ item }">
                    <div class="actions">
                        <button class="btn-white" style="min-width: 60px !important; width: unset !important;" @click.stop="openUploadDialog(item, 'request')">
                            <span class="d-flex align-center" v-if="isCurrentSelectedCustomer || !checkingDocumentLoading">
                                <img src="@/assets/icons/upload.svg" width="16px" height="16px" class="mr-1">
                                Upload
                            </span>
                            <span v-else-if="!isCurrentSelectedCustomer && checkingDocumentLoading">
                                <!-- check if the selected document id and item id match - show loading -->
                                <v-progress-circular
                                    :size="20"
                                    color="#1A6D9E"
                                    indeterminate
                                    v-if="(selectedDocumentRequest !== null && selectedDocumentRequest.id === item.id)">
                                </v-progress-circular>

                                <span v-else-if="selectedDocumentRequest !== null && selectedDocumentRequest.id !== item.id"
                                    class="d-flex align-center">
                                    <img src="@/assets/icons/upload.svg" width="16px" height="16px" class="mr-1">
                                    Upload
                                </span>
                            </span>
                        </button>

                        <button class="btn-white dc-red" @click="cancelRequest(item)" v-if="isCurrentSelectedCustomer">
                            <v-icon color="#B11B1B">mdi-close</v-icon>
                        </button>
                    </div>
                </template>
            </v-data-table>

            <v-data-table
                :items="getAllRequestDocuments"
                v-model="selectedSuppliers"
                :headers="computedRequestHeaders"
                :single-select="singleSelect"
                item-key="id"
                :items-per-page="documentItemsPerPage"
                show-select
                class="elevation-1 documents-table"
                :class="getShipmentDocuments.length == 0 ? 'no-data-table' : ''"
                hide-default-footer
                mobile-breakpoint="0"
                v-if="isMobile">

                <template v-slot:item="props">
                    <tr>
                        <td>
                            <div class="document-name-info">
                                <div class="document-name-checkbox">
                                    <v-checkbox :input-value="props.isSelected" @change="props.select($event)"></v-checkbox>
                                    <div class="doc-name">
                                        <div class="document-name-info">
                                            <p class="mb-1">{{ concatDocuments(props.item.document_types) }}</p>
                                        </div>

                                        <div class="mobile-document-type-info">
                                            <p class="document-types">
                                                Requested at {{ props.item.requested_at }}
                                            </p>

                                            <div class="document-name-info-mobile" v-if="props.item.type === 'Vendor'">
                                                <p class="mb-0 d-flex align-center justify-start">
                                                    Request to: {{ props.item.supplier.company_name }}
                                                </p>

                                                <span style="color: #69758C; padding-left: 70px; font-size: 12px;"
                                                    v-for="(email, index) in props.item.emails" 
                                                    :key="index">
                                                    <span>{{ email }}</span>
                                                    <template v-if="index + 1 < props.item.emails.length">, <br/></template>
                                                </span>
                                            </div>

                                            <div class="document-name-info-mobile" v-else>
                                                <p class="mb-0 d-flex align-center justify-start">
                                                    Request to: {{ props.item.type }}
                                                </p>
                                                <span style="color: #69758C; padding-left: 70px; font-size: 12px;"
                                                    v-for="(email, index) in props.item.emails" 
                                                    :key="index">
                                                    <span>{{ email }}</span>
                                                    <template v-if="index + 1 < props.item.emails.length">, <br/></template>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div> 

                                <div class="actions">
                                    <button class="btn-white pa-1" style="min-width: 30px !important; height: 30px !important;width: unset !important; padding: 4px !important;" @click.stop="openUploadDialog(props.item, 'request')">
                                        <span class="d-flex align-center" v-if="!checkingDocumentLoading">
                                            <img src="@/assets/icons/upload.svg" width="14px" height="14px">
                                        </span>
                                        <span v-else-if="!isCurrentSelectedCustomer && checkingDocumentLoading">
                                            <!-- check if the selected document id and item id match - show loading -->
                                            <v-progress-circular
                                                :size="15"
                                                color="#1A6D9E"
                                                indeterminate
                                                v-if="(selectedDocumentRequest !== null && selectedDocumentRequest.id === props.item.id)">
                                            </v-progress-circular>

                                            <span v-else-if="selectedDocumentRequest !== null && selectedDocumentRequest.id !== props.item.id"
                                                class="d-flex align-center">
                                                <img src="@/assets/icons/upload.svg" width="14px" height="14px">
                                            </span>
                                        </span>
                                    </button>

                                    <button class="btn-white dc-red" 
                                        style="min-width: 30px !important; height: 30px !important; width: unset !important; padding: 4px !important;" @click="cancelRequest(props.item)" v-if="isCurrentSelectedCustomer">
                                        <v-icon color="#B11B1B">mdi-close</v-icon>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </template>

                <template v-slot:no-data>
                    <div class="no-data-preloader no-data-preloader-mobile" v-if="shipmentsLoading">
                        <v-progress-circular
                            :size="40"
                            color="#1A6D9E"
                            indeterminate>
                        </v-progress-circular>
                        <!-- <div class="k-mt-8 k-border-b-0 loading-text pt-4">Fetching shipment documents...</div> -->
                    </div>
                    <div class="no-data-wrapper" v-if="getShipmentDocuments.length == 0 && !shipmentsLoading">
                        <div class="no-data-heading">
                            <div>
                                <p class="header-title"> No Document Uploaded </p>
                                <p class="k-col-tablet-58 k-col-mobile-90 no-data-value"> 
                                    No document has been uploaded for this shipment. 
                                    Please Upload the required documents (Commercial Invoice, Packing List etc.)
                                </p>

                                <div class="d-flex justify-center align-center mt-4">
                                    <v-btn color="primary" dark class="btn-white" @click.stop="openUploadDialog(null)">
                                        Upload Documents
                                    </v-btn>
                                </div>
                            </div> 
                        </div>
                    </div>
                </template>
            </v-data-table>
        </div>

        <div v-if="getShipmentDocuments.length > 0 && 
            getShipmentDocumentsPage.last_page > 1" 
            class="pagination-wrapper pt-3" >

            <v-pagination
                v-model="getShipmentDocumentsPage.current_page"
                :length="getShipmentDocumentsPage.last_page"
                prev-icon="Previous"
                next-icon="Next"
                :total-visible="!isMobile ? '11' : '5' "
                @input="handleDocumentsPageChange">
            </v-pagination>
        </div>

        <UploadDocumentsDialog 
            :documentsData="documents"
            :shipment_id="id"
            :dialogData.sync="dialogUploadDocs"
            @fetchDocuments="fetchShipmentDocumentsAfter"
            @close="closeUploadDialog"
            :isCurrentSelectedCustomer="isCurrentSelectedCustomer"
            :selectedDocumentRequest="selectedDocumentRequest"
            :getDetails="getDetails"
            :editedUploadIndex="editedUploadIndex"
            :isFrom="'documents'"
            @fetchRequestDocuments="fetchRequestDocuments" />

        <SubmitDocumentsDialog
            :selected="selected"
            :shipment_id="id"
            :submit_item="submitItem"
            :documentsData="documents"
            :dialogData.sync="dialogSubmitDocs"
            @cancelSubmission="cancelSubmission"
            @close="closeSubmitDialog" />

        <DeleteDocumentsDialog 
            :selected="selected"
            :shipment_id="id"
            :delete_item="deleteItem"
            :documentsData="documents"
            :dialogData.sync="dialogDeleteDocs"
            @close="closeDeleteDialog" 
            @fetchDocuments="fetchShipmentDocumentsAfter" />

        <UpdateDocumentsDialog 
            :documentsData="documents"
            :shipment_id="id"
            :editItem.sync="editItem"
            :dialogData.sync="dialogUpdateDocs"
            @fetchDocuments="fetchShipmentDocumentsAfter"
            @close="closeUpdateDialog" />

		<ViewDocumentsDialog
			:dialogData.sync="ViewDocumentDialog"
			:documentData="documentData"
			@close="closeViewDocumentDialog"
            :isFrom="'shipment'" />

        <RequestDocumentDialog
			:dialogData.sync="requestDocumentDialog"
			@close="closeRequestDocumentDialog"
            :getShipmentDetails="getDetails"
            :isFrom="'docs-tab'"
            @fetchRequestDocuments="fetchRequestDocuments" />

        <ConfirmDialog :dialogData.sync="cancelRequestDialog">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Cancel Document Request </h2>
			</template>

			<template v-slot:dialog_content>
				<p> 
					Are you sure you want to cancel
                   <span v-if="selectedDocument.length === 1">
						<span class="name">
                            {{ concatEmails(selectedDocument[0].document_types) }}
                        </span>
                        document request to 
                        <span v-if="selectedDocument[0].type === 'Vendor'">
                            {{ selectedDocument[0].supplier.company_name }}
                        </span>
                        <span v-else>Other Party</span>?
					</span>

                    <span v-if="selectedDocument.length > 1">
						selected document requests?
					</span>                   
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="confirmCancelRequest" :disabled="getRequestsLoading">
					<span v-if="!getRequestsLoading">Yes, Cancel</span>
                    <span v-if="getRequestsLoading">Canceling...</span>
				</v-btn>

				<v-btn class="btn-white" @click="closeRequest" :disabled="getRequestsLoading">
					Close
				</v-btn>
			</template>
		</ConfirmDialog>

        <ConfirmDialog :dialogData.sync="requestCancelledConfirmationDialog" :className="'request-small-dialog'">
            <template v-slot:dialog_icon>
				<div class="header-wrapper-close">
                    <img src="@/assets/icons/alert.svg" alt="alert">
                </div>
			</template>

			<template v-slot:dialog_title>
				<h2> Request has been cancelled </h2>
			</template>

			<template v-slot:dialog_content>
				<p> 
					{{ selectedDocumentRequest !== null && selectedDocumentRequest.supplier 
                        ? selectedDocumentRequest.supplier.company_name : "" 
                    }} has cancelled the request for 
                    <!-- Commercial Invoice & Packing List  -->

                    {{ selectedDocumentRequest !== null && selectedDocumentRequest.document_types && 
                        selectedDocumentRequest.document_types.length > 0 ?
                        concatEmails(selectedDocumentRequest.document_types) : ""
                    }} document upload.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="requestCancelInfoClose">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>
    </div>
</template>

<script>
import UploadDocumentsDialog from '../components/ShipmentComponents/Documents/UploadDocumentsDialog.vue'
import { mapActions, mapGetters } from "vuex";
import globalMethods from '../utils/globalMethods'
import DeleteDocumentsDialog from '../components/ShipmentComponents/Documents/DeleteDocumentsDialog.vue'
import SubmitDocumentsDialog from '../components/ShipmentComponents/Documents/SubmitDocumentsDialog.vue'
import UpdateDocumentsDialog from './ShipmentComponents/Documents/UpdateDocumentsDialog.vue'
import ViewDocumentsDialog from "./ShipmentComponents/Documents/ViewDocumentsDialog.vue";
import RequestDocumentDialog from "./ShipmentComponents/Documents/RequestDocumentDialog.vue";
import axios from 'axios'
import moment from 'moment'
const baseURL = process.env.VUE_APP_BASE_URL;
import ConfirmDialog from '../components/Dialog/GlobalDialog/ConfirmDialog.vue';
import _ from "lodash";
import { allTypesAllowed } from "../constants/mimeTypes";

export default {
    props: ['getDetails', 'id', 'shipmentsLoading', 'isCurrentSelectedCustomer'],
    components: {
        UploadDocumentsDialog,
        SubmitDocumentsDialog,
        DeleteDocumentsDialog,
        UpdateDocumentsDialog,
        ViewDocumentsDialog,
        RequestDocumentDialog,
        ConfirmDialog
    },
    data: () => ({
        page: 1,
        documentTabs: ["Documents", "Requests"],
        editItem: {
            id: 0,
            name: '',
            type: '',
            supplier_ids: []
        },
        deleteItem: [],
        currentCancelToken: {},
        source: {},
        submitItem: [],
        pageCount: 0,
        documentItemsPerPage: 35,
        itemsPerPage: 10,
        isMobile: false,
        singleSelect: false,
        cancelOperation: false,
        selected: [],
        selectedSuppliers: [],
        headers: [
            {
                text: 'Name & Updated at',
                align: 'start',
                sortable: false,
                value: 'name',
                width: '25%',
                fixed: true
            },
            {
                text: 'Type & Tag',
                align: 'start',
                sortable: false,
                value: 'type',
                width: '30%',
                fixed: true
            },
            {
                text: 'Uploaded by',
                align: 'start',
                sortable: false,
                value: 'uploaded_by',
                width: '30%',
                fixed: true
            },
            { 
                text: '', 
                value: 'actions',
                sortable: false,
                align: 'end',
                fixed: true,
                width: '20%'
            }
        ],
        headersMobile: [
            { text: '', value: 'data-table-select', width: "1%"},
            {
                text: 'Name, Type & Tag & Uploaded by',
                align: 'start',
                sortable: true,
                value: 'name',
                width: '45%',
                fixed: true
            }
        ],
        documents: [],
        dialogUploadDocs: false,
        dialogSubmitDocs: false,
        dialogDeleteDocs: false,
        dialogUpdateDocs: false,
        editedDocumentFiles: [],
        shipmentDocumentSubmitted: false,
        ViewDocumentDialog: false,
        documentData: '',
        requestDocumentDialog: false,
        requestDocuments: [],
        hasRequests: false,
        currentTab: 0,
        requestHeaders: [
            {
                text: 'Name & Updated at',
                align: 'start',
                sortable: true,
                value: 'document_types',
                width: '40%',
                fixed: true
            },
            {
                text: 'Requested to',
                align: 'start',
                sortable: true,
                value: 'supplier',
                width: '40%',
                fixed: true
            },
            { 
                text: '', 
                value: 'actions',
                sortable: false,
                fixed: true,
                width: '20%'
            }
        ],
        requestHeadersMobile: [
            { text: '', value: 'data-table-select', width: "1%"},
            {
                text: 'Name, Updated at & Request to',
                align: 'start',
                sortable: true,
                value: 'name',
                width: '45%',
                fixed: true
            }
        ],
        cancelRequestDialog: false,
        selectedDocument: [],
        getRequestsLoading: false,
        selectedDocumentRequest: null,
        requestCancelledConfirmationDialog: false,
        checkingDocumentLoading: false,
        editedUploadIndex: -1,
    }),
    async mounted() {
        let { id } = this;
        let q = this.$route.query;

        if (q.setDocumentTab !== undefined) { // set current tab to request tab
            this.currentTab = parseInt(q.setDocumentTab);
        } else this.currentTab = 0;

        this.documents = this.getShipmentDocuments
        if (this.getShipmentDocuments.length == 0) {
            this.$emit('update:shipmentsLoading', true)
        }

        this.fetchRequestDocuments(id);        
        await this.fetchShipmentDocuments({
            shipment_id: id,
            page: 1
        });
        this.$emit('update:shipmentsLoading', false)
        await this.fetchShipmentSuppliers(this.id)
        // this.$store.dispatch("suppliers/fetchSuppliers")
        if (this.getDetails !== 'undefined') {            
            this.documents = this.getShipmentDocuments
        }
    },
    computed: {
        // ...mapGetters([
        //     'getShipmentDocumentsLoading',
        //     'getShipmentDocuments',
        //     'getShipmentDocumentsSubmitting',
        //     'getShipmentDocumentsPage',
        //     'getUser'
        // ]),
        ...mapGetters({
			getShipmentDocumentsLoading: "getShipmentDocumentsLoading",
			getShipmentDocuments: "getShipmentDocuments",
			getShipmentDocumentsSubmitting: "getShipmentDocumentsSubmitting",
			getShipmentDocumentsPage: "getShipmentDocumentsPage",
            getUser: "getUser",
            getAllRequestDocuments: "documents/getAllRequestDocuments",
            getAllRequestDocumentsLoading: "documents/getAllRequestDocumentsLoading"
		}),
        getBetaUrl() {
            let betaUrl = this.$store.getters['page/getBetaUrl']
            betaUrl = betaUrl.replace('api','')
            return betaUrl
        },
        computedRequestHeaders() {
            let defaultHeaders = this.requestHeaders

            if (!this.isMobile) {
                if (!this.isCurrentSelectedCustomer) {
                    defaultHeaders = defaultHeaders.filter(v => {
                        return v.text !== "Requested to"
                    })
                }
            } else defaultHeaders = this.requestHeadersMobile;

            return defaultHeaders
        },
        getUserDetails() {
            return (typeof this.getUser=='string') ? JSON.parse(this.getUser) : this.getUser
        }
    },
    methods: {
        ...globalMethods,
        // ...mapActions([
        //     'fetchShipmentDocuments',
        //     'submitShipmentDocuments',
        //     'clearShipmentDocuments',            
        //     'fetchShipmentSuppliers',
        //     "fetchRequestDocumentsAPI"
        // ]),
        ...mapActions({
			fetchRequestDocumentsAPI: "documents/fetchRequestDocumentsAPI",
			fetchShipmentDocuments: "fetchShipmentDocuments",
            submitShipmentDocuments: "submitShipmentDocuments",
            clearShipmentDocuments: "clearShipmentDocuments",
            fetchShipmentSuppliers: "fetchShipmentSuppliers"
		}),
        onTabChange(i) {
            this.currentTab = i
        },
        openRequestDocumentDialog() {
            this.requestDocumentDialog = true;
        },
        closeRequestDocumentDialog() {
            this.requestDocumentDialog = false;
        },
        cancelSubmission() {
            this.cancelOperation = true
            this.source.cancel('Submission of token cancelled.')
        },
        async handleDocumentsPageChange(page) {
            console.log('loading true')
            this.$emit('update:shipmentsLoading',true)
            this.$emit('handleDocumentsChange', page)
        },
        async fetchShipmentDocumentsAfter(payload) {
            this.clearShipmentDocuments()
            this.$emit('update:shipmentsLoading',true)
            await this.fetchShipmentDocuments(payload)
            this.selected = []
            this.$emit('update:shipmentsLoading',false)
        },
        onResize() {
            if (window.innerWidth < 769) {
                this.isMobile = true;
            } else {
                this.isMobile = false;
            }
        },
        download(url) {
            //window.location.href = `https://beta.shifl.com/download?link=${encodeURIComponent(url)}`
            window.location.href = `${this.getBetaUrl}download?link=${encodeURIComponent(url)}`
        },
        iterateDocument(index) {            
            if (this.selected.length > 0 && typeof this.selected[index]!=='undefined') {
                window.location.href = `${this.getBetaUrl}download?link=${encodeURIComponent(this.selected[index].url)}`
                index++
                setTimeout(() => {
                    this.iterateDocument(index)
                }, 3000)
            }
        },
        downloadDocuments() {   
            let startIndex = 0      
            this.iterateDocument(startIndex)   
        },
        openUploadDialog(item, isRequest) {
            this.editedUploadIndex = -1;

            if (this.isCurrentSelectedCustomer) {
                this.dialogUploadDocs = true;
                if (item !== null && item.id !== undefined) {
                    this.selectedDocumentRequest = item;
                }

                if (isRequest !== undefined && isRequest === "request") {
                    item.isFromRequest = true;
                    this.checkForCancelDocument(item)
                }
            } else {
                if (isRequest !== undefined && isRequest === "request") {
                    item.isFromRequest = true;
                    this.checkForCancelDocument(item)
                } else {
                    this.dialogUploadDocs = true;
                }
                this.selectedDocumentRequest = item;
            }
        },
        closeUploadDialog() {
            this.dialogUploadDocs = false;
            this.selectedDocumentRequest = null;
            this.editedUploadIndex = -1;
        },
        openReuploadDialog(item) {
            this.editedUploadIndex = _.findIndex(this.getShipmentDocuments, e => (e.id == item.id));
            this.dialogUploadDocs = true;
            this.selectedDocumentRequest = item;
        },
        closeReuploadDialog() {
            this.dialogUploadDocs = true;
            this.selectedDocumentRequest = null;
            this.editedUploadIndex = -1;
        },
        async openSubmitItemDialog(item) {
            try {
                this.dialogSubmitDocs = true
                this.source = axios.CancelToken.source()
                await this.submitShipmentDocuments({
                    shipment_id: this.id,
                    document_ids: [item.id],
                    cancelToken: this.source.token
                })
                this.notificationMessageCustomSuccess('Documents have successfully been submitted.')
                this.dialogSubmitDocs = false
            } catch(e) {
                if (this.cancelOperation) {
                    this.notificationError('Submission cancelled')
                    this.cancelOperation = false
                } else {
                    this.notificationError('An error occured. Please refresh the page and try again.')
                    console.log(e)    
                }

                this.dialogSubmitDocs = false
                this.dialogSubmitDocs = false
            }
        },
        async openSubmitDialog() {
            try {
                let selected_items = []
                if ( this.selected.length > 0 ) {
                    this.dialogSubmitDocs = true
                    this.selected.map( s => {
                        selected_items.push(s.id)
                    })
                    this.source = axios.CancelToken.source()
                    await this.submitShipmentDocuments({
                        shipment_id: this.id,
                        document_ids: selected_items,
                        cancelToken: this.source.token
                    })
                    this.notificationMessageCustomSuccess('Documents have successfully been submitted.')
                    this.dialogSubmitDocs = false
                    this.selected = [] 
                } else {
                    this.notificationError('Make sure to select first a document to submit.')
                }
                
            } catch(e) {
                if (this.cancelOperation) {
                    this.notificationError('Submission cancelled')
                    this.cancelOperation = false
                } else {
                    this.notificationError('An error occured. Please refresh the page and try again.')  
                }
                this.dialogSubmitDocs = false
            }
        },
        openDeleteItemDialog(item) {
            this.deleteItem = [item]
            this.dialogDeleteDocs = true
        },
        openDeleteDialog() {
            this.deleteItem = []
            this.dialogDeleteDocs = true
        },
        closeSubmitDialog() {
            this.dialogSubmitDocs = false
        },
        closeDeleteDialog() {
            this.dialogDeleteDocs = false
        },
        openUpdateDialog(item) {
            this.$store.dispatch('documents/setEditId', item.id)
            let getItem = item
            this.editItem = {
                id: getItem.id,
                name: getItem.name,
                // supplier_ids: getItem.supplier_ids,
                supplier_ids: getItem.supplier_ids[0],
                shipment_id: getItem.shipment_id,
                type: getItem.type,
                otherData: {
                    ...item
                }
            }
            //this.editItem = getItem
            this.$store.dispatch('documents/setEditDocument', getItem)
            this.dialogUpdateDocs = true
        },
        closeUpdateDialog() {
            this.dialogUpdateDocs = false
        },
        cancel() {
            this.selected = [];
        },
        async submitDocs() {
            try {
                await this.submitShipmentDocuments({
                    shipment_id: this.id
                })
                this.notificationMessageCustomSuccess('Documents have successfully been submitted.')
            } catch(e) {
                this.notificationError('Make sure to select first a document to submit.')
                console.log(e)
            }
        },
        viewDocument(item) {
			this.ViewDocumentDialog = true;
			this.documentData = item;
		},
		closeViewDocumentDialog() {
			this.ViewDocumentDialog = false;
		},
        checkFileExtension(file) {
            // var parts = filename.split('.');
            // let fileType = parts[parts.length - 1];
            // return fileType.toLowerCase() !== 'pdf' ? true : false

            if (file.path !== null) {
                var parts = file.path.split('.');
                let fileType = parts[parts.length - 1];

                if (!allTypesAllowed.includes(fileType.toLowerCase())) { // if file type is in above
                    return true
                } else return false
                
            } else return false            
        },
        dateFormat(date){
            return moment(date).format("h:mm A MM/DD/YY");
        },
        async fetchRequestDocuments(id) {
            let cid = (typeof this.getUser=='string') 
                ? JSON.parse(this.getUser).default_customer_id : this.getUser.default_customer_id
            let url = this.isCurrentSelectedCustomer 
                ? `${baseURL}/get-customer-requests/${id}` : `${baseURL}/get-supplier-requests/${id}/customer/${cid}`

            await this.fetchRequestDocumentsAPI(url)
            this.saveDataToState()
        },
        saveDataToState() {
            if (this.getAllRequestDocuments !== undefined) {
                if (this.getAllRequestDocuments.length > 0) {
                    this.requestDocuments = this.getAllRequestDocuments
                    this.hasRequests = true;

                    if (this.requestDocuments.length === 0) {
                        this.currentTab = 0;
                    }
                } else {
                    this.hasRequests = false;
                    this.currentTab = 0;
                    this.selectedSuppliers = [];
                }
            }
        },
        concatDocuments(arr) {
            if (arr.length > 0) {
                if (arr.includes("Commercial Invoice & Packing List")) {
                    if (arr.includes("Commercial Invoice") && arr.includes("Packing List")) {
                        arr = arr.filter(v => {
                            return v !== "Commercial Invoice & Packing List"
                        })
                    }
                }
            }
            return arr !== null && arr.length > 0 ? arr.join(", ") : "Other Documents";
        },
        concatEmails(arr) {
            return arr !== null && arr.length > 0 ? arr.join(", ") : "";
        },
        async cancelRequest(item) {
            this.cancelRequestDialog = true;
            if (item !== null) {                
                this.selectedDocument.push(item);
            } else {
                this.selectedDocument = this.selectedSuppliers;
            }
        },
        async confirmCancelRequest() {
            var searchParams = new URLSearchParams();
            let { id } = this
            this.getRequestsLoading = true

            if (this.selectedDocument.length !== 0) {
                if (this.selectedDocument.length === 1) {
                    searchParams.append(`ids[]`, this.selectedDocument[0].id)
                } else if (this.selectedDocument.length > 1) {
                    for(var ser = 0; ser < this.selectedDocument.length; ser++){
                        searchParams.append(`ids[]`, this.selectedDocument[ser].id)
                    }
                }
            }
            
            await axios.get(`${baseURL}/cancel-requests`, {
                params: searchParams
            }).then((res) => {
                if (res.status === 200) {
                    this.notificationMessage('Documents requested has been cancelled.');
                    this.fetchRequestDocuments(id);
                    this.closeRequest();
                    this.getRequestsLoading = false;
                }
            }).catch(e => {
                this.notificationError(e.message)
                this.getRequestsLoading = false
            }) 
        },
        closeRequest() {
            this.selectedDocument = [];
            this.cancelRequestDialog = false;
            this.selectedSuppliers = [];
        },
        async checkForCancelDocument(item) {
            this.checkingDocumentLoading = true;
            this.selectedDocumentRequest = item;

            await axios.get(`${baseURL}/check-for-cancel/${item.id}`)
            .then((res) => {
                if (res.status === 200) {
                    if (!res.data.status) {
                        this.dialogUploadDocs = true;
                    } else {
                        this.requestCancelledConfirmationDialog = true;
                    }
                    this.checkingDocumentLoading = false;
                }
            }).catch(e => {
                 this.checkingDocumentLoading = false;
                this.notificationError(e.message)
            })
        },
        requestCancelInfoClose() {
            let { id } = this
            this.requestCancelledConfirmationDialog = false;
            this.selectedDocumentRequest = null;
            this.fetchRequestDocuments(id)
        },
        getUploadedbyName(item, field) {
            return item.uploaded_by !== null && typeof item.uploaded_by === "string"
                    ? JSON.parse(item.uploaded_by)[field] : "-"
        }
    },
    watch: {
        getAllRequestDocuments(v) {
            if (v.length === 0) {
                this.currentTab = 0;
            }
        }
    },
    updated() {}
}
</script>

<style lang="scss">
@import '../assets/scss/pages_scss/shipment/shipmentDocuments.scss';
@import '../assets/scss/utilities.scss';

.document-tabs-wrapper {
    height: 36px !important;

    .document-tabs {
        .v-tabs-bar {
            height: 36px !important;
        }

        .v-tabs-slider-wrapper {
            display: none;
        }

        .v-tab {
            font-size: 12px !important;
            text-transform: capitalize!important;
            letter-spacing: 0 !important;
            border: 1px solid $shifl-neutral-n40;
            padding: 0 10px !important;
            font-weight: unset !important;
            min-width: 36px !important;
            font-family: 'Inter-Medium', sans-serif !important;
            
            span {
                color: $shifl-neutral-n90;
                font-family: 'Inter-Medium', sans-serif !important;
            }

            &.v-tab--active {
                background-color: $shifl-neutral-n20 !important;

                span {
                    color: $shifl-primary-b90;					
                }
            }

            &:nth-child(2) {
                border-right: none !important;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }

            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }
}
</style>
