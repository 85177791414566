<!-- @format -->

<template>
	<div class="shipment-other-information-wrapper shipment-details-card">
		<h3>
			Other Informations
		</h3>
		<div class="other-info-details">
			<div class="pa-4 other-details" v-if="valueExist(getDetails.additional_instructions_and_notes)">
				<h4 class="label">
					Instructions for booking team
				</h4>
				<p class="my-2">
					{{ getDetails.additional_instructions_and_notes }}
				</p>
			</div>
			<div
				class="pa-4 additional-details "
				v-if="getDetails.additional_services.length > 0"
			>
				<h4 class="label">
					Additional services requested
				</h4>
				<div class="my-2 d-flex gap-2 services-list">
					<span
						class="services"
						v-for="(item, index) in getDetails.additional_services"
						:key="index"
						><img
							src="../../../assets/icons/Circle Check Outline.svg"
							width="12px"
							height="12px"
						/>
						{{ item }}</span
					>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ShipmentOtherInformation",
	props: ["getDetails"],
	data: () => ({}),
	methods: {
		checkValue(value) {
			return value == "" || value == null || value == "null" ? "N/A" : value;
		},
		valueExist(value) {
			return (
				value !== "" &&
				value !== null &&
				value !== "null" &&
				value !== "undefined"
			);
		},
	},
};
</script>
<style lang="scss">
.shipment-other-information-wrapper {
	.other-info-details {
		margin-top: 16px;
		display: grid;
		gap: 8px;
		.other-details,
		.additional-details {
			border: 1px solid #f3f4f7;
			border-radius: 4px;
			.label {
				font-size: 14px;
				color: #253041;
			}
			.services {
				font-size: 14px;
				color: #253041;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.shipment-other-information-wrapper {
		.other-info-details {
			.additional-details {
				.services-list {
					flex-wrap: wrap;
				}
			}
		}
	}
}
</style>
