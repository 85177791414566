<!-- @format -->

<template>
	<div class="d-flex flex-column first-column sidebar-item-main-wrapper pt-4">
		<div
			:class="
				`d-flex align-center sidebar-items-wrapper ${
					sidebarItem.selected ? 'selected' : ''
				}`
			"
			v-bind:key="`si-${key}`"
			v-for="(sidebarItem, key) in sidebarItems"
		>
			<a
				@click.stop="selectPageItems(sidebarItem)"
				:class="
					`d-flex sidebar-item align-center ${
						sidebarItem.selected ? 'selected' : ''
					}`
				"
			>
				<KeneticIcon
					:paddingTop="`${sidebarItem.icon === 'general' ? 6 : 0}`"
					:color="`${sidebarItem.selected ? '#1A6D9E' : '#253041'}`"
					v-if="sidebarItem.icon !== ''"
					:iconName="sidebarItem.icon"
					:width="sidebarItem.width"
					:height="sidebarItem.height"
				/>
				<div class="sidebar-label ml-3">
					{{ sidebarItem.label }}
				</div>
			</a>
		</div>
	</div>
</template>
<script>
import KeneticIcon from "@/components/Icons/KeneticIcon";

export default {
	name: "ShipmentSideBars",
	props: ["sidebarItems"],
	components: {
		KeneticIcon,
	},
	methods: {
		selectPageItems(item) {
			this.$emit("selectPage", item);
		},
	},
};
</script>
<style lang="scss">
@import "../SubComponents/scss/ShipmentSideBar.scss";
</style>
