<!-- @format -->

<template>
	<div class="archived-shipment-wrapper" :class="isMobile ? 'shipment-table-wrapper mobile' : ''">
		<p
			class="font-weight-regular goback-archived"
			style="color: #1A6D9E; cursor: pointer; width: 8%;"
			@click="clickBack()"
		>
			<span class="mdi mdi-chevron-left"></span> Go Back
		</p>
		<div id="shipments_header">
			<div class="filters-wrapper">
				<div class="d-flex align-center">
					<div class="font-weight-black">Archive</div>
				</div>

				<div class="search-wrapper" style="float: right;">
					<img src="@/assets/images/search-icon.svg" alt="search icon" />
					<input
						class="search"
						type="text"
						id="search-input"
						placeholder="Search Archived"
						v-model.trim="search"
						@input="handleSearch()"
						autocomplete="off"
						ref="shipmentSearchInput"
					/>
				</div>
			</div>
		</div>

		<v-data-table
			:headers="headersPendingQuote"
			:items="getArchivedData"
			mobile-breakpoint="769"
			:page="1"
			item-key="name"
			class="archived-header archived-shipment-header"
			hide-default-footer
			ref="my-table"
			v-if="!isMobile">

			<template v-slot:no-data>
				<div class="no-data-preloader" v-if="getArchivedBookingsLoading">
					<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
					</v-progress-circular>
				</div>
			</template>

			<template v-slot:[`item.ReferenceID`]="{ item }">
				<div class="k-flex k-justify-between">
					<div class="k-default-color">{{ item.ReferenceID }}</div>
				</div>
			</template>

			<template v-slot:[`item.updated_at`]="{ item }">
				<div class="k-flex k-justify-between flex-column">
					<span class="name">{{
							item.updated_by_name ? item.updated_by_name : "N/A"
						}}</span>
					<div style="color: #69758C;">{{ beautify(item.updated_at) }}</div>
				</div>
			</template>

			<template v-slot:[`item.location_from_name`]="{ item }">
				<div class="k-flex k-justify-between">
					<div class="k-default-color">{{ item.location_from_name }}</div>
				</div>
			</template>

			<template v-slot:[`item.location_to_name`]="{ item }">
				<div class="k-flex k-justify-between">
					<div class="k-default-color">{{ item.location_to_name }}</div>
				</div>
			</template>

			<template v-slot:[`item.Suppliers`]="{ item }">
				<div class="supplier-desktop">
					<span v-for="(name, index) in item.Suppliers" :key="index">
						<span>{{ name }}</span
						><span v-if="index + 1 < item.Suppliers.length">, </span>
					</span>

					<span v-if="item.Suppliers == null || item.Suppliers.length == 0">
						<span>N/A</span>
					</span>
				</div>
			</template>

			<template v-slot:[`item.mode`]="{ item }">
				<div
					v-if="item.multipleModes.length > 0"
					class="pending-quote-actions-wrapper shipment-type k-relative"
				>
					<div class="k-pr-5 k-flex justify-center k-pl-12">
						<span
							class="k-flex k-mr-4"
							:key="k"
							v-for="(m, k) in item.multipleModes"
						>
							<span class="mr-1">
								<img
									v-if="m === 'ship'"
									src="@/assets/icons/ocean.svg"
									width="20px"
									height="20px"
								/>
								<img
									v-if="m === 'air'"
									src="@/assets/icons/airplane.svg"
									width="20px"
									height="20px"
								/>
								<img
									v-if="m === 'truck'"
									src="@/assets/icons/truck.svg"
									width="20px"
									height="20px"
								/>
							</span>
						</span>
						<img
							v-if="item.type == 'LCL'"
							src="@/assets/images/small-container.svg"
							width="20px"
							height="20px"
						/>
						<img
							v-if="item.type == 'Air'"
							src="@/assets/images/airplane-shipment.svg"
							width="20px"
							height="20px"
						/>
						<img
							v-if="item.type == 'FCL'"
							src="@/assets/images/big-container.svg"
							width="20px"
							height="20px"
						/>
						<span
							style="padding-bottom:2px;"
							v-if="item.type == 'FCL' && item.container_num_list.length !== 0"
						>
							({{ item.container_num_list.length }})
						</span>
					</div>
				</div>

				<div v-else class="k-flex k-items-center justify-center">
					<div class="k-flex">
						<span>N/A</span>
					</div>
				</div>
			</template>

			<template v-slot:[`item.PO`]="{ item }">
				<div v-if="1 == 2" class="mobile-pos">
					<div
						v-if="item.po_list !== null && item.po_list.length > 0"
						style="width: 100%; display:flex;"
					>
						<p>
							<span v-for="(name, index) in item.po_list" :key="index">
								PO #{{ name
								}}<template v-if="index + 1 < item.po_list.length">, </template>
							</span>
						</p>
					</div>

					<div v-if="item.po_list == null">
						<p>N/A</p>
					</div>
				</div>
				<div class="k-flex justify-center">
					<div class="k-default-color">
						{{
							item.po_list !== null && item.po_list.length > 0
								? item.po_list.join(", ")
								: "N/A"
						}}
					</div>
				</div>
			</template>
			<template v-slot:[`item.cancellation_reson`]="{ item }">
				<div>
					<p class="mb-0">{{item.consolidation_status == 3 ? 'Due To Consolidation' : 'N/A'}}</p>
				</div>
			</template>

			<template v-slot:[`item.actions`]="{ item }">
				<div class="shipment-action-button d-flex align-end justify-end mr-1">
					<button
						class="btn-white"
						style="height: 35px !important; font-size: 12px !important;"
						@click="shipmentUnArchive(item.id)"
						v-if="item.consolidation_status !== 3"
					>
						Unarchive
					</button>
					<!-- <div class="three-dots-wrapper">
          <v-menu
            offset-y
            bottom
            left
            content-class="pending-dropdown-container"
          > -->
					<!-- <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" class="shipment-three-dots-container">
                <custom-icon
                  iconName="three-dots"
                  color="#1A6D9E"
                  width="11"
                  height="3"
                />
              </button>
            </template>
            <v-list> -->
					<!-- <v-list-item>
                <v-list-item-title class="k-fw-sm k-font-inter-regular">
                  Edit Shipment
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="k-fw-sm k-font-inter-regular k-red">
                  Request Cancellation
                </v-list-item-title>
              </v-list-item> -->
					<!-- </v-list> -->
					<!-- </v-menu> -->
					<!-- </div> -->
				</div>
			</template>
		</v-data-table>

		<v-data-table
			:headers="headersPendingQuote"
			:items="getArchivedData"
			mobile-breakpoint="769"
			:page="1"
			item-key="name"
			class="table-mobile shipments-table-mobile archived-header archived-shipment-header"
			v-bind:class="{
				'no-data-table': (typeof getArchivedData !== 'undefined' && getArchivedData.length === 0),
				'has-searched-data' : search !== '' && getArchivedData.length === 0
			}"
			hide-default-footer
			ref="my-table"
			v-if="isMobile">
			
			<template v-slot:no-data>
				<div class="no-data-preloader" v-if="getArchivedBookingsLoading">
					<v-progress-circular
						:size="30"
						color="#1A6D9E"
						indeterminate
						v-if="getArchivedBookingsLoading">
					</v-progress-circular>
				</div>

				<div v-if="!getArchivedBookingsLoading && getArchivedData.length === 0" class="no-data-wrapper">
					<div class="no-data-icon mb-2">
						<img src="@/assets/icons/noShipmentData.svg" alt="" width="65px">
					</div>

					<div class="no-data-heading mb-4">
						<span v-if="search === ''">No Archived Shipments</span>
						<span v-else>No Archived Shipments found. <br/> Try searching another keyword.</span>
					</div>
				</div>
			</template>

			<template v-slot:[`item.ReferenceID`]="{ item }">
				<div class="k-flex k-justify-between font-medium">
					<div class="k-flex k-default-color align-center">
						<span class="font-medium mr-2">{{ item.ReferenceID }}</span>

						<div v-if="item.multipleModes.length > 0" class="shipment-type ml-3">
							<div class="k-flex justify-center">
								<span
									class="d-flex k-mr-4 align-center"
									:key="k"
									v-for="(m, k) in item.multipleModes"
								>
									<span class="d-flex align-center mr-1">
										<img
											v-if="m === 'ship'"
											src="@/assets/icons/ocean.svg"
											width="20px"
											height="20px"
										/>
										<img
											v-if="m === 'air'"
											src="@/assets/icons/airplane.svg"
											width="20px"
											height="20px"
										/>
										<img
											v-if="m === 'truck'"
											src="@/assets/icons/truck.svg"
											width="20px"
											height="20px"
										/>
									</span>
								</span>
								<img
									v-if="item.type == 'LCL'"
									src="@/assets/images/small-container.svg"
									width="18px"
									height="18px"
								/>
								<img
									v-if="item.type == 'Air'"
									src="@/assets/images/airplane-shipment.svg"
									width="18px"
									height="18px"
								/>
								<img
									v-if="item.type == 'FCL'"
									src="@/assets/images/big-container.svg"
									width="18px"
									height="18px"
								/>
								<span
									style="padding-bottom:2px;"
									v-if="item.type == 'FCL' && item.container_num_list.length !== 0"
								>
									({{ item.container_num_list.length }})
								</span>
							</div>
						</div>					
					</div>

					<div class="shipment-action-button d-flex align-end justify-end">
						<button
							class="btn-white"
							style="height: 32px !important; font-size: 12px; width: 80px !important;"
							@click="shipmentUnArchive(item.id)"
							v-if="item.consolidation_status !== 3">
							Unarchive
						</button>
					</div>
				</div>
			</template>

			<template v-slot:[`item.updated_at`]="{ item }">
				<div class="table-mobile-data-content">
					<div class="table-mobile-data mb-2">
						<div class="mobile-departure-wrapper">
							<span style="color: #69758C;">Departure</span>
						</div>

						<div class="mobile-departure-wrapper" style="text-align: left !important;">
							<div class="flag-wrapper">
								<p style="margin-bottom: 0px;">
									{{ item.location_from_name !== null ? item.location_from_name : 'N/A' }}                             
								</p>
							</div>
						</div>      
					</div>

					<div class="table-mobile-data mb-2">
						<div class="mobile-arrival-wrapper">
							<span style="color: #69758C;">Arrival</span>
						</div>

						<div class="arrival-wrapper" style="text-align: right !important;">
							<div class="flag-wrapper">
								<p style="margin-bottom: 0px">
									{{ item.location_to_name !== null ? item.location_to_name : 'N/A' }}
								</p>
							</div>
						</div>
					</div>

					<div class="table-mobile-data mb-2">
						<div class="mobile-pos-wrapper">
							<span style="color: #69758C;">POs</span>
						</div>

						<div class="po-num-mobile">
							<p v-if="item.po_list !== null">
								<span v-for="(name, index) in item.po_list" :key="index">
									<span v-if="index === 0 || index === 1">
										{{ name }}<template v-if="index + 1 < item.po_list.length">, </template>
									</span>
								</span>

								<span v-if="item.po_list.length > 2" style="color: #1A6D9E;">
									+{{ item.po_list.length - 2 }} Other
								</span>
							</p>
						</div>

						<div v-if="item.po_list == null || item.po_list.length == 0">
							<p> {{ item.is_tracking_shipment ? '' : 'N/A'}} </p>
						</div>
					</div>
				</div>
			</template>

			<template v-slot:[`item.location_from_name`]="{ item }">
				<div class="k-flex k-justify-between">
					<span class="name text-start">
						{{ (item.updated_by_name !== null && item.updated_by_name) ? item.updated_by_name : "N/A" }}
					</span>
					<div class="text-start" style="color: #69758C;">{{ beautify(item.updated_at) }}</div>
				</div>
			</template>
		</v-data-table>

		<ConfirmDialog :dialogData.sync="unarchiveAlert">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>UnArchive Shipment?</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Are you sure, Want to unacrhive this Shipment?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="confirmUnArchive()"
					:disabled="getUnArchiveShipmentLoading"
				>
					<span v-if="getUnArchiveShipmentLoading">Yes, Confirming...</span>
					<span v-else>Yes, Confirm</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="cancelUnArchive()"
					:disabled="getUnArchiveShipmentLoading"
				>
					No, Cancel
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>

<style scoped>
@import "../../../assets/scss/pages_scss/shipment/archivedShipmentTable.scss";
</style>
<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
// import CustomIcon from "../../Icons/CustomIcon";
import ConfirmDialog from "@/components/Dialog/GlobalDialog/ConfirmDialog.vue";
import globalMethods from "@/utils/globalMethods";

export default {
	name: "ArchivedShipment",
	props: ["selectedTab", "isMobile"],
	components: {
		// CustomIcon,
		ConfirmDialog,
	},
	data() {
		return {
			itemsPerPage: 50,
			headersPendingQuote: [
				{
					text: "Reference",
					align: "start",
					sortable: false,
					value: "ReferenceID",
					fixed: true,
					class: "pending-quote-table-header",
					width: "8%",
				},
				{
					text: "Updated By & At",
					align: "start",
					sortable: false,
					value: "updated_at",
					fixed: true,
					class: "pending-quote-table-header",
					width: "12%",
				},
				{
					text: "From",
					value: "location_from_name",
					sortable: false,
					fixed: true,
					class: "pending-quote-table-header",
					width: "11%",
				},
				{
					text: "To",
					value: "location_to_name",
					sortable: false,
					fixed: true,
					class: "pending-quote-table-header",
					width: "11%",
				},
				{
					text: "Suppliers",
					value: "Suppliers",
					sortable: false,
					fixed: true,
					class: "pending-quote-table-header",
					width: "11%",
				},
				{
					text: "Mode/Type",
					align: "center",
					value: "mode",
					sortable: false,
					fixed: true,
					class: "pending-quote-table-header",
					width: "9%",
				},
				{
					text: "PO",
					align: "center",
					value: "PO",
					sortable: false,
					fixed: true,
					class: "pending-quote-table-header",
					width: "11%",
				},
				{
					text: "cancellation reason",
					value: "cancellation_reson",
					align: "center",
					sortable: false,
					fixed: true,
					class: "pending-quote-table-header",
					width: "13%",
				},
				{
					text: "",
					align: "center",
					sortable: false,
					value: "actions",
					width: "8%",
					fixed: true,
				},
			],
			search: "",
			unarchiveAlert: false,
			unarchiveShipmentId: false,
		};
	},
	async mounted() {
		let payload = {
			search: "",
			tab: this.selectedTab,
		};
		this.fetchArchivedBookings(payload);
	},
	computed: {
		...mapGetters([
			"getArchivedBookingsLoading",
			"getArchivedBookings",
			"getUnArchiveShipmentLoading",
		]),
		getArchivedData() {
			return this.getArchivedBookings ? this.getArchivedBookings.shipments : [];
		},
	},
	methods: {
		...globalMethods,
		...mapActions([
			"fetchArchivedBookings",
			"unArchiveShipment",
			"fetchPendingQuoteShipments",
			"fetchConsolidationRequests",
			"fetchDraftShipments",
			"fetchShipments",
		]),
		clickBack() {
			this.$emit("hideArchivedShipment");
		},
		beautify(date) {
			return date !== null ? moment.utc(date).format("MMM DD, YYYY") : "N/A";
		},
		async handleSearch() {
			clearTimeout(this.typingTimeout);
			this.typingTimeout = setTimeout(() => {
				let payload = {
					search: this.search,
					tab: this.selectedTab,
				};
				this.searchPendingData = this.search;
				this.fetchArchivedBookings(payload);
			}, 800);
		},
		shipmentUnArchive(shipmentId) {
			this.unarchiveAlert = true;
			this.unarchiveShipmentId = shipmentId;
		},
		confirmUnArchive() {
			let payloadObject = {
				shipmentId: this.unarchiveShipmentId,
				type: this.selectedTab,
			};
			this.unArchiveShipment(payloadObject)
				.then((response) => {
					this.unarchiveAlert = false;
					this.notificationErrorCustom(response.data.message);
					let payload = {
						search: "",
						tab: this.selectedTab,
					};
					let payloadShipments = {
						page: 1,
						order: "asc",
					};
					this.fetchArchivedBookings(payload);
					this.fetchPendingQuoteShipments(1);
					this.fetchConsolidationRequests(1);
					this.fetchDraftShipments(1);
					this.fetchShipments(payloadShipments);
				})
				.catch((e) => {
					console.log(e);
					this.unarchiveAlert = false;
					this.notificationErrorCustom("SOMETHING WENT WRONG!");
					let payload = {
						search: "",
						tab: this.selectedTab,
					};
					this.fetchArchivedBookings(payload);
				});
		},
		cancelUnArchive() {
			this.unarchiveAlert = false;
			this.unarchiveShipmentId = "";
		},
	},
};
</script>

<style lang="scss">
.containerMain {
	.archived-shipment-wrapper {
		#shipments_header {
			.filters-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.search-wrapper {
					input {
						width: 300px;
					}
				}
			}			
		}

		.v-data-table {
			&.archived-shipment-header {
				.v-data-table__wrapper {
					table {
						thead {
							tr {
								th {
									&:first-child {
										padding-left: 16px !important;
									}
								}
							}
						}

						tbody {
							tr {
								td {
									&:first-child {
										padding-left: 16px !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 768px) { 
	.containerMain {
		.archived-shipment-wrapper {
			.goback-archived {
				width: 100% !important;
				padding: 16px;
				margin-bottom: 0;
			}

			#shipments_header {
				.filters-wrapper {
					.search-wrapper {
						input {
							width: 100% !important;
						}
					}
				}			
			}

			.v-data-table {
				.v-data-table__wrapper {
					height: calc(100vh - 200px) !important;
				}
			}
		}
	}
}
</style>
