<!-- @format -->

<template>
	<div class="booking-request-template-wrapper">
		<v-dialog
			v-model="dialog"
			width="480px"
			:content-class="'booking-request-template'"
			persistent
		>
			<v-card>
				<v-card-title>
					<span class="headline">Save Booking Request as Template</span>
					<button icon dark class="btn-close" @click="close">
						<v-icon>mdi-close</v-icon>
					</button>
				</v-card-title>
				<v-card-text class="card-content">
					<div class="d-flex align-start pa-2 info-text">
						<img
							src="@/assets/icons/info-blue.svg"
							alt="info-blue"
							width="18px"
						/>
						<p class="mb-0 info-desc">
							Save your filled-out details as a reusable booking template.
							(Except for orders and containers)
						</p>
					</div>
					<div class="input-field-wrapper mt-4">
						<p class="input-field-label mb-1  d-flex align-center">
							Template name
						</p>
						<v-text-field
							v-model="template_name"
							:height="40"
							placeholder="Enter Template name"
							width="200px"
							type="text"
							dense
							outlined
							hide-details="auto"
						/>
					</div>

					<div class="input-field-wrapper  vendor-checkbox">
						<v-checkbox
							class="checkbox-wrapper pt-0 shifl-ui-checkbox"
							:class="template_to_vendor ? 'front-info' : ''"
							v-model="template_to_vendor"
							hide-details
						>
							<template slot="label">
								<span class="d-flex align-center">
									Associate Template to Vendor
								</span>
								<span
									class="ml-2"
									v-tooltip="{
										text:
											'By Associating it with a vendor this will be the default Booking template for that vendor',
										position: 'top',
									}"
									><GenericIcon :color="'#4A4A4A'" :iconName="'info-icon'"
								/></span>
							</template>
						</v-checkbox>
						<div class="child-input-field pt-2 pl-2" v-if="template_to_vendor">
							<div class="input-field-wrapper">
								<span class="input-field-label">Vendors </span>
								<v-autocomplete
									v-model="vendors"
									:height="40"
									color="#002F44"
									class="template-to-vendor"
									:items="filteredShipperOptions"
									item-text="name"
									item-value="id"
									dense
									outlined
									hide-details="auto"
									placeholder="Select vendor"
									spellcheck="false"
									multiple
									:menu-props="{
										contentClass: 'vendor-name-wrapper shifl-ui-checkbox',
										bottom: true,
										offsetY: true,
									}"
									small-chips
									clearable
									deletable-chips
								/>
							</div>
						</div>
					</div>
					<div class="input-field-wrapper">
						<span class="input-field-label"
							>Description <small>(Optional)</small>
						</span>
						<v-textarea
							class="text-fields"
							outlined
							v-model="description"
							placeholder="Enter description"
							hide-details="auto"
							autocomplete="off"
						/>
					</div>
				</v-card-text>
				<v-card-actions>
					<div class="d-flex footer">
						<v-btn
							class="save-btn btn-blue mr-2"
							text
							@click="submitBookingTemplateRequest()"
							:disabled="
								getCreateBookingTemplateLoading ||
									template_name == null ||
									template_name == ''
							"
						>
							Save as Template
						</v-btn>

						<v-btn
							class="btn-white"
							text
							@click="close()"
							:disabled="getCreateBookingTemplateLoading"
						>
							Cancel
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import GenericIcon from "../../../Icons/GenericIcon.vue";

export default {
	name: "BookingTemplateDialog",
	props: ["showDialog", "renameTemplateData", "fromBookingTemplates"],
	components: {
		GenericIcon,
	},
	data: () => ({
		template_name: null,
		template_to_vendor: false,
		vendors: [],
		description: null,
	}),
	async mounted() {
		if (this.renameTemplateData !== null && this.fromBookingTemplates) {
			//fetch shipper options
			await this.$store.dispatch("booking/getShipperOptions", {
				page: 1,
			});

			const {
				vendor_ids,
				template_description,
				template_name,
			} = this.renameTemplateData;

			this.template_name = template_name;

			this.description = template_description;
			this.vendors =
				vendor_ids !== null
					? vendor_ids.split(";").map((id) => parseInt(id))
					: [];

			if (this.vendors.length > 0) {
				this.template_to_vendor = true;
			}
		}
	},
	computed: {
		...mapGetters({
			getCreateBookingTemplateLoading:
				"bookingTemplate/getCreateBookingTemplateLoading",
		}),
		dialog: {
			get() {
				return this.showDialog;
			},
			set(value) {
				this.$emit("update:showDialog", value);
			},
		},
		filteredShipperOptions() {
			//shippers array container
			let newShippers = [];

			newShippers = this.$store.getters["booking/getShippers"];

			//leaves only company name and address
			if (newShippers.length > 0) {
				newShippers =
					newShippers &&
					newShippers.map(({ company_name, id }) => {
						return {
							name: company_name,
							id,
						};
					});
			}
			return newShippers;
		},
	},
	methods: {
		close() {
			this.$emit("close");
			this.template_name = null;
			this.template_to_vendor = false;
			this.vendors = [];
			this.description = null;
		},
		submitBookingTemplateRequest() {
			let payload = {
				template_name: this.template_name,
				template_description: this.description,
				vendor_id: this.vendors,
			};
			if (this.fromBookingTemplates) {
				payload.id = this.renameTemplateData.id;
			}
			this.$emit("saveAsTemplate", payload);
		},
	},
};
</script>

<style lang="scss">
@import "./scss/BookingTemplateDialog.scss";
</style>
