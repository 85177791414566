var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cargo-details-wrapper mt-4"},[_c('div',{staticClass:"cargo-details mb-4"},[_c('h2',[_vm._v("Cargo Details")]),_c('div',{staticClass:"pa-4 mt-4 cargo-back"},[_c('div',{staticClass:"cargo-details-table-wrapper"},[_c('div',[_c('v-data-table',{staticStyle:{"box-shadow":"none !important"},attrs:{"headers":_vm.tableHeader,"items":_vm.shipmentItem.cargoDetailsData,"mobile-breakpoint":"769","page":1,"fixed-header":"","items-per-page":100,"hide-default-footer":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(item,index){return _c('th',{key:index,staticClass:"op-",style:(("color: #69758C !important;padding-right: 12px;padding-left: 12px !important;width: " + (item.width) + ";text-align: " + (item.textAlign))),attrs:{"role":"column-header","aria-label":item.text,"scope":"col"}},[_vm._v(" "+_vm._s(item.text)+" "),(
												item.text == 'Total Volume' ||
													item.text == 'Total Weight' ||
													item.text == 'Incoterm'
											)?_c('span',{staticClass:"required-asterisk"},[_vm._v("*")]):_vm._e()])}),0)])]}},{key:"item.consignee",fn:function(ref){
											var item = ref.item;
return [_c('v-select',{staticClass:"text-fields selected-input-field selected-fields",attrs:{"items":_vm.filteredConsigneeOptions,"height":"40px","outlined":"","item-text":"name","item-value":"id","hide-details":"auto","disabled":""},model:{value:(item.consignee),callback:function ($$v) {_vm.$set(item, "consignee", $$v)},expression:"item.consignee"}})]}},{key:"item.shipper",fn:function(ref){
											var item = ref.item;
return [_c('v-select',{staticClass:"text-fields selected-input-field selected-fields",attrs:{"items":_vm.filteredShipperOptions,"height":"40px","outlined":"","item-text":"name","item-value":"id","hide-details":"auto","disabled":""},model:{value:(item.shipper),callback:function ($$v) {_vm.$set(item, "shipper", $$v)},expression:"item.shipper"}})]}},{key:"item.total_carton",fn:function(ref){
											var item = ref.item;
return [_c('v-text-field',{staticClass:"input-item",attrs:{"height":"40px","color":"#002F44","type":"number","dense":"","placeholder":"0","outlined":"","hide-details":"auto","min":"0"},model:{value:(item.total_carton),callback:function ($$v) {_vm.$set(item, "total_carton", $$v)},expression:"item.total_carton"}})]}},{key:"item.total_volume",fn:function(ref){
											var item = ref.item;
return [_c('v-text-field',{staticClass:"input-item",attrs:{"height":"40px","color":"#002F44","type":"number","dense":"","placeholder":"0","outlined":"","hide-details":"auto","min":"0","rules":_vm.fieldRequired,"suffix":"CBM","validate-on-blur":""},model:{value:(item.total_volume),callback:function ($$v) {_vm.$set(item, "total_volume", $$v)},expression:"item.total_volume"}})]}},{key:"item.total_weight",fn:function(ref){
											var item = ref.item;
return [_c('v-text-field',{staticClass:"input-item",attrs:{"height":"40px","color":"#002F44","type":"number","dense":"","placeholder":"0","outlined":"","hide-details":"auto","min":"0","rules":_vm.fieldRequired,"suffix":"KG","validate-on-blur":""},model:{value:(item.total_weight),callback:function ($$v) {_vm.$set(item, "total_weight", $$v)},expression:"item.total_weight"}})]}},{key:"item.commodity_description",fn:function(ref){
											var item = ref.item;
return [_c('v-text-field',{staticClass:"input-item",attrs:{"height":"40px","color":"#002F44","type":"text","dense":"","placeholder":"Enter Description","outlined":"","hide-details":"auto"},model:{value:(item.commodity_description),callback:function ($$v) {_vm.$set(item, "commodity_description", $$v)},expression:"item.commodity_description"}})]}},{key:"item.incoterm",fn:function(ref){
											var item = ref.item;
return [_c('v-select',{staticClass:"text-fields selected-input-field",attrs:{"items":_vm.getShipmentTerms,"item-text":"name","item-value":"id","placeholder":"Incoterm","outlined":"","hide-details":"auto","height":"40px","rules":[function (v) { return !!v || ''; }],"validate-on-blur":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
											var item = ref.item;
return [_c('span',{staticClass:"booking-tooltip-wrapper "},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
											var on = ref.on;
											var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"incoterm-name"},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.incotermTooltip(item.name)))])])],1)]}}],null,true),model:{value:(item.incoterm),callback:function ($$v) {_vm.$set(item, "incoterm", $$v)},expression:"item.incoterm"}})]}}],null,true)})],1),(_vm.shipmentItem.cargoDetailsData.length > 0)?_c('div',{staticClass:"total-amount pa-4 d-flex"},[_c('span',[_vm._v(" Total Cartons: "+_vm._s(_vm.totalCartons())+" ")]),_c('span',[_vm._v(" Total Volume: "+_vm._s(_vm.totalVolume())+" ")]),_c('span',[_vm._v(" Total Weight: "+_vm._s(_vm.totalWeight())+" ")])]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }