<template>
	<div v-if="getEmailDailog" class="text-center">
		<v-dialog
			persistent
			v-model="getEmailDailog"
			fullscreen
			max-width="590px"
			:retain-focus="false"
			content-class="notification-user-dailog-m">
			<v-card class="add-supplier-card-m">
				<v-form
					id="createdriver"
					ref="form"
					v-model="valid"
					action="#"
					@submit.prevent="">
					<v-card-title style="caret-color: transparent">
						<span class="headline">Send Email to</span>

						<button icon dark class="btn-close" @click="close">
							<v-icon>mdi-close</v-icon>
						</button>
					</v-card-title>
					<v-spacer></v-spacer>
					<v-card-text>
						<v-row>
							<v-col class="selectBox" cols="12" md="12">
								<label class="text-item-label">Email Notification for</label>
								<p class="notifi-type">
									{{ getCurrentEditItem }}
								</p>
							</v-col>

							<v-col
								class="selectBox"
								cols="12"
								md="12"
								style="padding-top: 3px;">
								<label class="text-item-label">
									Email Recipients</label
								>
								<div class="tags-email-wrapper">
                                    <vue-tags-input
                                        hide-details="auto"
                                        :rules="arrayNotEmptyRules"
                                        :tags="options"
                                        :add-on-blur="true"
                                        :add-on-key="[13, ',']"
                                        v-model="emailRecipts"
                                        @before-adding-tag="checkEmailBeforeAdding"
                                        placeholder="Enter email address"
                                        class="DialogInputfieldM"
                                        @tags-changed="
                                            (newTags) => {
                                                this.options = newTags;
                                                this.tagsInput.touched = true;
                                                this.tagsInput.hasError =
                                                    this.options.length > 0 ? false : true;
                                                let el = this.documentProto.getElementsByClassName(
                                                    'ti-input'
                                                )[0];
                                                if (typeof el !== 'undefined') {
                                                    if (this.tagsInput.hasError)
                                                        el.classList.add('ti-new-tag-input-error');
                                                    else el.classList.remove('ti-new-tag-input-error');
                                                }
                                            }
                                        "
                                    />
                                </div> <!-- :validation="tagsValidation" -->

                                <div style="min-height: 12px;" class="mt-1 mb-1">
                                    <div v-if="errorMessage === ''">
                                        <p class="my-0" style="color: #819FB2; font-size: 12px;">
                                            Separate multiple email addresses with comma
                                        </p>
                                    </div>

                                    <div v-else class="v-text-field__details">
                                        <span class="v-messages theme--light error--text">{{ errorMessage }}</span>
                                    </div>
                                </div>
							</v-col>

							<v-col cols="12" sm="12" md="12" class="pt-0 pl-1">
								<!-- <v-checkbox
									class="email-users ml-1 mt-0"
									label="Same emails for all notifications"
								></v-checkbox> -->

								<v-checkbox
									class="email-users ml-1 mt-0"
									v-model="sameEmailsForAll"
									label="Same emails for all notifications"
                                    hide-details="auto"
									v-if="!currentCustomer.email_recipient_setting.same_email_for_all"
								></v-checkbox>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions>
						<div class="d-flex align-center justify-start">
							<button class="save-btn btn-blue" @click="save" :disabled="getLoading">
								<span class="d-flex align-center justify-start">
									<span color="#fff" class="d-flex align-center justify-start">
										<v-progress-circular
											:size="15"
											color="#fff"
											:width="2"
											indeterminate
											v-if="getLoading"
											style="margin-right: 6px">
										</v-progress-circular>
										{{ getLoading ? "Saving..." : "Save Email" }}
									</span>
								</span>
							</button>
							<button class="cancel-btn btn-white ml-2" @click="close" :disabled="getLoading">
								Cancel
							</button>
						</div>
					</v-card-actions>
				</v-form>
				<v-row style="margin-top: 0px"> </v-row>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import VueTagsInput from "@johmun/vue-tags-input";
import globalMethods from '../../../../utils/globalMethods';
import jQuery from "jquery";
import { notificationsItems } from "../staticData";
import _ from "lodash";

export default {
	props: ["id", "currentCustomer"],
	components: {
		VueTagsInput,
	},
	data: () => {
		return {
			sameEmailsForAll: false,
			errorMessage: "",
			emailsCount: "small",
			myclass: "",
			options: [],
			hiddenEmails: [],
			valid: false,
			customerReferrenceNumber: "",
			documentProto: document,
			tagsInput: {
				touched: false,
				hasError: false,
				errorMessage:
					'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.',
			},
			tagsValidation: [
				{
					classes: "t-new-tag-input-text-error",
					rule: /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
					disableAdd: true,
				},
			],
			arrayNotEmptyRules: [
				//(v) => !!v || "Email is required",
				() =>
					this.optionsFiltered.length > 0 ||
					"Make sure to supply at least 1 email.",
			],
			emailRecipts: "",
			showError: false,
		};
	},
	computed: {
		...mapGetters("notificationNew", [
			"getEmailDailog",
			"getLoading",
			"getCurrentEditItem",
			"getCurrentEmailRecpients",
		]),
	},
	watch: {
		tagsInput(value) {
			if (value.hasError) {
				jQuery(".ti-input").addClass("ti-new-tag-input-error")
			} else {
				jQuery(".ti-input").removeClass("ti-new-tag-input-error")
			}
		},
		getEmailDailog(currState) {
			this.tagsInput.hasError = false;
			this.tagsInput.touched = false;
			jQuery(".ti-input").removeClass("ti-new-tag-input-error")

			if (currState) {
				this.fillFormField();
			}
		},
		emailRecipts() {
			this.errorMessage = "";
			jQuery('.ti-input').removeClass('ti-new-tag-input-error');
			jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
		},
	},
	methods: {
		...mapActions("notificationNew", ["addRecipients"]),
		...mapMutations("notificationNew", ["SET_EMAIL_DAILOG"]),
		...globalMethods,
        generateErrorMessage() {
			this.tagsInput.hasError = this.options.length > 0 ? false : true
			if (this.tagsInput.hasError)
				jQuery(".ti-input").addClass("ti-new-tag-input-error")
			else jQuery(".ti-input").removeClass("ti-new-tag-input-error")
			
			if (this.errorMessage !== "" || this.options.length === 0) {
				jQuery('.ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
				this.errorMessage = this.options.length === 0 && this.emailRecipts === ""
					? "Please provide at least 1 valid email address" 
					: (this.emailRecipts !== "" 
						? "Please make sure to enter a valid email address" 
						: this.errorMessage)
			} else {
				this.errorMessage = "";
				jQuery('.ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
			}
		},
		fillFormField() {
			if (this.getCurrentEmailRecpients) {
				let validEmailAddress = [];
				let hiddenMails = [];
				let updatedEmails = typeof this.getCurrentEmailRecpients === "string" 
					? JSON.parse(this.getCurrentEmailRecpients) : this.getCurrentEmailRecpients;

				updatedEmails.forEach((value) => {
					if (/@([Ss]hifl\.com|[Ss]hifl\.cn)/.test(value)) {
						hiddenMails.push(value);
						return;
					}
					validEmailAddress.push({
						text: value,
						tiClasses: ["ti-valid"],
					});
				});

				this.options = validEmailAddress;
				this.adjustUI(validEmailAddress);
				this.hiddenEmails = hiddenMails;
				this.sameEmailsForAll = this.currentCustomer.email_recipient_setting.same_email_for_all === 1 ? true : false;
			}
		},
		adjustUI(mails) {
			const count = mails.length;
			if (count < 3) {
				this.emailsCount = "small";
				return;
			} else if (count < 4) {
				this.emailsCount = "medium";
				return;
			} else {
				this.emailsCount = "large";
			}
		},
		close() {
			this.$refs.form.reset();
			this.$refs.form.resetValidation();
			this.options = [];
			this.showError = false;
			this.$emit("close", this.options);
			this.errorMessage = "";
			this.emailRecipts = "";
			this.tagsInput.hasError = false;
			this.tagsInput.touched = false;
			jQuery(".ti-input").removeClass("ti-new-tag-input-error");
			// this.SET_EMAIL_DAILOG(false);
		},
		async save() {
			if (!this.tagsInput.touched) this.tagsInput.touched = true;

            this.generateErrorMessage();
			this.tagsInput.hasError = this.options.length > 0 ? false : true

			setTimeout(async () => {
                let form = new FormData();

                if (!this.tagsInput.hasError && this.errorMessage === "") {
                    try {
                        jQuery(".ti-new-tag-input").trigger(
                            jQuery.Event("keyup", { keyCode: 13, which: 13 })
                        )

                        let finalEmailAddress =
							this.options.length > 0 ? this.options.map((o) => { return o.text; }) : [];
						const totalEmails = this.hiddenEmails.concat(finalEmailAddress);

						form.append("email_recipients[]", totalEmails);
						form.append("customer_id", this.id);
						if (this.sameEmailsForAll) {
                            form.append("same_email_for_all", this.sameEmailsForAll);
                        }

						let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
						let findData = _.find(notificationsItems, e => (e.notificationTitle === currentTitle));

						if (findData !== undefined) {
							form.append("type", findData.emailField);
							// form.append("email_booking", 1);
						}
						
						// switch (this.getCurrentEditItem) {
						// 	case "Booking":
						// 		form.append("type", "booking");
						// 		form.append("email_booking", 1);
						// 		break;

						// 	case "New Schedules & Updates":
						// 		form.append("type", "booking_updates");
						// 		form.append("email_booking_updates", 1);
						// 		break;

						// 	case "Document Upload":
                        //         form.append("type", "document_upload");
						// 		form.append("email_document_upload", 1);
                        //         break;

						// 	case "Departure Notice":
						// 		form.append("type", "departure_notice");
						// 		form.append("email_departure_notice", 1);
						// 		break;

						// 	case "Arrival Notice":
						// 		form.append("type", "arrival_notice");
						// 		form.append("email_arrival_notice", 1);
						// 		break;

						// 	case "Delivery Order":
						// 		form.append("type", "delivery_order");
						// 		form.append("email_delivery_order", 1);
						// 		break;

						// 	case "Booking Request":
                        //         form.append("type", "booking_request");
						// 		form.append("email_booking_request", 1);
                        //         break;

                        //     case "Consolidation Request":
                        //         form.append("type", "consolidation_request");
						// 		form.append("email_consolidation_request", 1);
                        //         break;

						// 	default:
						// 		form.append("type", "eta_change_alert");
						// 		form.append("email_eta_change_alert", 1);
						// 		break;
						// }
						await this.addRecipients(form);
						this.showError = false;
						this.$emit("getNotificationData");
                    } catch(e) {
                        this.notificationError(e)
                        console.log(e);
						this.showError = false;
                    }                    
                }                
            }, 200);			
		},
		checkEmailBeforeAdding(item) {
			if ((/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(item.tag.text)) {
                item.addTag();
                this.errorMessage = "";
                jQuery('.ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
            } else {
                this.errorMessage = "Please make sure to enter a valid email address";
                jQuery('.ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
            }
		},
	},
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: #d0d5dd;
	border-width: 1px;
}
</style>

<style lang="scss">
.DialogInputfieldM {
	.ti-input {
		padding: 4px 6px !important;
		border-radius: 4px !important;
		min-height: 40px !important;
		border: 1px solid $shifl-neutral-n40 !important;

		.ti-tags {
			.ti-tag {
				background-color: $shifl-neutral-n20;
				color: $shifl-neutral-n90;
				font-size: 12px;
				padding: 3px 8px;
				border: 1px solid $shifl-neutral-n20;
				margin: 2px 3px;
				border-radius: 4px;
			}
		}

		input {
			font-size: 14px;
		}

		&.ti-new-tag-input-error {
			border: 1px solid #fc8686 !important;

			input {
				color: $shifl-error-r80 !important;
			}
		}
	}

	.ti-new-tag-input::placeholder,
	.ti-input::placeholder{
		color: $shifl-neutral-n40 !important;
	}
}
.notification-user-dailog-m {
	.tags-email-wrapperM.small {
		margin-bottom: -15px;
	}
	.tags-email-wrapperM.medium {
		margin-bottom: 2px;
	}
	.tags-email-wrapperM.large {
		margin-bottom: 50px;
	}

	.tags-email-wrapper .vue-tags-input {
		width: 100% !important;
		max-width: 100%;
	}

	height: 100vh !important;
	.email-users .v-input__slot .v-label {
		color: $shifl-neutral-n90;
		font-size: 14px;
		font-family: "Inter-Regular";
	}

	.v-card {
		.v-card__actions {
			position: fixed;
			width: 100%;
			bottom: -1px;
			padding: 8px 16px !important;
		}
	}
}
.add-supplier-card-m {
	height: 100vh !important;
}
.notifi-type {
	font-size: 14px;
	color: $shifl-neutral-n90;
	font-family: "Inter-Medium";
}
.cancel-btn {
	padding: 10px 20px;
	border: 1px solid $shifl-primary-b90;
	color: $shifl-primary-b90 !important;
	border-radius: 4px;
	margin-left: 20px;
}
.save-btn {
	background-color: $shifl-primary-b90 !important;
	padding: 10px;
	border-radius: 4px;
	color: #fff;
}
</style>
