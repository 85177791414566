var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"560px","content-class":"email-dialog","retain-focus":false},on:{"click:outside":_vm.close},model:{value:(_vm.dialogCreate),callback:function ($$v) {_vm.dialogCreate=$$v},expression:"dialogCreate"}},[_c('v-card',{staticClass:"email-dialog-card"},[_c('v-form',{ref:"form",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Email Sales Order to Buyer")]),_c('button',{staticClass:"btn-close",attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"vendor-wrapper"},[_c('div',{staticClass:"vendor-details"},[_c('p',{staticClass:"email-title"},[_vm._v("Buyer")]),_c('p',{staticClass:"email-data",domProps:{"innerHTML":_vm._s(_vm.getBuyer(_vm.emailItem.po.buyer_id))}})])])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"email-wrapper"},[_c('div',{staticClass:"email-details mb-3"},[_c('p',{staticClass:"email-title"},[_vm._v("EMAIL")]),_c('div',{staticClass:"tags-email-wrapper mb-1"},[_c('vue-tags-input',{attrs:{"hide-details":"auto","rules":_vm.arrayNotEmptyRules,"tags":_vm.options,"add-on-blur":true,"add-on-key":[13, ','],"validation":_vm.tagsValidation,"placeholder":"e.g. name@email.com"},on:{"tags-changed":function (newTags) {
                                            this$1.options = newTags
                                            this$1.tagsInput.touched = true
                                            this$1.tagsInput.hasError = (this$1.options.length > 0) ? false : true
                                            var el = this$1.documentProto.getElementsByClassName('ti-input')[0]
                                            if (typeof el!=='undefined') {
                                                if (this$1.tagsInput.hasError)
                                                    { el.classList.add('ti-new-tag-input-error') }
                                                else
                                                    { el.classList.remove('ti-new-tag-input-error') }
                                            }
                                        }},model:{value:(_vm.poEmailAddress),callback:function ($$v) {_vm.poEmailAddress=$$v},expression:"poEmailAddress"}})],1),(_vm.tagsInput.touched)?_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[((_vm.options.length > 0) && _vm.poEmailAddress!=='')?_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.tagsInput.errorMessage)+" ")]):_vm._e(),(_vm.options.length == 0 && _vm.poEmailAddress!=='')?_c('div',{staticClass:"v-messages__message"},[_vm._v(" "+_vm._s(_vm.tagsInput.errorMessage)+" ")]):_vm._e(),(_vm.options.length == 0 && _vm.poEmailAddress=='')?_c('div',{staticClass:"v-messages__message"},[_vm._v(" Please provide at least 1 valid email address. ")]):_vm._e()])])]):_vm._e(),_c('span',{staticClass:"separate-info"},[_vm._v("Separate multiple email addresses with comma")])])])])],1)],1),_c('v-card-actions',{staticClass:"po-button-actions"},[_c('button',{staticClass:"btn-blue",attrs:{"disabled":_vm.getEmailLoading},on:{"click":function($event){return _vm.sendBuyerEmail()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.getEmailLoading ? 'Sending Email...' : 'Send Email')+" ")])]),_c('button',{staticClass:"btn-white",on:{"click":_vm.close}},[_vm._v(" Cancel ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }