import axios from "axios";
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import jQuery from "jquery";
// let baseUrl = process.env.VUE_APP_BASE_URL
// import Shipment from "../../../../custom/ShipmentResource";

const compactViewMethods = {}

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

function removeValue(value, index, arr) {    
    if (value === "and") { // If the value at the current array index matches the specified value (2)
        arr.splice(index, 1); // Removes the value from the original array
        return true;
    }
    return false;
}

// getting the data for the datagrid using custom store
export function GetStore(url) { // calling API using devExtreme Custom Store
	var data = {
		key: 'id',
		load(loadOptions) {
			let params = '';
			[   'skip',
				'take',
				'requireTotalCount',
				'requireGroupCount',
				'sort',
				'filter',
				'totalSummary',
				'group',
				'groupSummary',
                'filters',
                'compact_view',
			].forEach((i) => {
                loadOptions['compact_view'] = true;
                
				if (typeof loadOptions['filter'] !== "undefined" && loadOptions['filter'] !== null) {
					if (loadOptions['filter'].length > 0) {
						loadOptions['filter'].filter(removeValue);
                        loadOptions['isFilter'] = true

                        let filterData = loadOptions['filter'];

                        if (loadOptions['filter'].length === 1) {
                            loadOptions['filters'] = filterData;
                        } else {
                            if (loadOptions['filter'].length > 1) {
                                let newArray = []

                                filterData.map(v => {
                                    v.map(a => {
                                        newArray.push(a)
                                    })
                                })

                                loadOptions['filters'] = newArray;
                            }
                        }
                        
					}
				}

				if (i in loadOptions && isNotEmpty(loadOptions[i])) {
                    // params += `${i}=${i !== 'filter' ? JSON.stringify(loadOptions[i]) : ''}&`; 
					params += `${i}=${JSON.stringify(loadOptions[i])}&`;
				}
			});
			params = params.slice(0, -1);
            
			return axios.get(`${url}&${params}`)
            .then(res => {
                if (res.data) {
                    return {
                        data: res.data.data,
                        totalCount: res.data.total,
                        summary: res.data.summary,
                        groupCount: res.data.groupCount
                    };
                }
            })
            .catch(() => {
				throw new Error('Data Loading Error');
			});
		},
	}

	return new CustomStore(data)
}

// datagrid methods starts here
export function onCellPreparedFunction(e) {
    if (e.rowType === "header") {
        if (e.columnIndex === 3 || e.columnIndex === 4 ||e.columnIndex === 5) {
            e.cellElement.style.textAlign = "center";
        }
    } else if (e.rowType === "filter") {
        if (e.column.dataField === "shifl_ref" || e.column.dataField === "mode" || e.column.dataField === "shipment_type" ||
            e.column.dataField === "location_from_name" || e.column.dataField === "location_to_name" || e.column.dataField === "PO") {
            jQuery(e.cellElement).addClass('no-search-icon-input')
        }
    }

    // if (e.rowType === "data") {        
    //     console.log(e, 'from extrenal function');
    // }
}

export function onContentReadyFunction(e, prop) {
    // console.log(e, 'content ready');

    var dataGridHeaders = document.querySelector(".dx-datagrid-headers");
    var noData = document.querySelector(".dx-datagrid-nodata");
    if (noData) {
        if (prop.compactViewDataSource.length === 0) {
            // noData.classList.remove('dx-hidden')
            noData.innerText = 'No available data'
            dataGridHeaders.classList.add('dx-hidden')
        }
    }

    // var gridData = document.querySelector("#gridContainer");
    // if (e.component.getVisibleRows().length > 0) {
    //     dataGridHeaders.classList.remove('dx-hidden')

    //     if (this.pagination.shipmentTab.total > 1) {
    //         setTimeout(() => {
    //             gridData.classList.add('has-multiple-pages')
    //         }, 50);
    //     } else {
    //         gridData.classList.remove('has-multiple-pages')
    //     }
    // } else {
    //     gridData.classList.remove('has-multiple-pages')
    // }
}

export function onEditorPreparingFunction(e, prop) {
    if (e.parentType === "filterRow") {
        e.editorOptions.showClearButton = true;
        
        if (e.editorName === "dxDateBox") {
            e.editorOptions.openOnFieldClick = true;
        }

        if (e.dataField === "shipment_type" && e.editorOptions) {
            e.editorName = "dxSelectBox";
            let options = [
                {
                    value: null,
                    name: "(All)"
                },
                {
                    value: "FCL",
                    name: "FCL"
                },
                {
                    value: "LCL",
                    name: "LCL"
                },
                {
                    value: "Air",
                    name: "Air"
                },
            ];
            e.editorOptions.dataSource = options;
            e.editorOptions.searchEnabled = true;
            e.editorOptions.displayExpr = "name";  
            e.editorOptions.valueExpr = "value";
        }

        if (e.dataField === "mode" && e.editorOptions) {
            e.editorName = "dxSelectBox";
            let options = [
                {
                    value: null,
                    name: "(All)"
                },
                {
                    value: "Ocean",
                    name: "Ocean"
                },
                {
                    value: "Air",
                    name: "Air"
                },
                {
                    value: "Rail",
                    name: "Rail"
                },
                {
                    value: "Truck",
                    name: "Truck"
                },
            ];
            e.editorOptions.dataSource = options;
            e.editorOptions.displayExpr = "name";  
            e.editorOptions.valueExpr = "value";            
        }

        if (e.dataField === "shipment_status" && e.editorOptions) {
            console.log(prop);
            e.editorName = "dxSelectBox";
            e.editorOptions.dropDownOptions = {
                width: "240px"
            };

            let options = []

            if (prop.tab === 'shipments') {
                options = [
                    {
                        value: null,
                        name: "(All)"
                    },
                    {
                        value: "Awaiting Departure",
                        name: "Awaiting Departure"
                    },
                    {
                        value: "In Transit",
                        name: "In Transit"
                    },
                    {
                        value: "In Transit - Pending Release",
                        name: "In Transit - Pending Release"
                    },
                    {
                        value: "In Transit - Released",
                        name: "In Transit - Released"
                    },
                    {
                        value: "Discharged",
                        name: "Discharged"
                    },
                    {
                        value: "Partial Discharged - Released",
                        name: "Partial Discharged - Released"
                    },
                    {
                        value: "Partial Discharged - Hold",
                        name: "Partial Discharged - Hold"
                    },
                ];
            } else if (prop.tab === 'completed') {
                options = [
                    {
                        value: null,
                        name: "(All)"
                    },
                    {
                        value: "Completed",
                        name: "Completed"
                    },
                    {
                        value: "Full Out",
                        name: "Full Out"
                    },
                    {
                        value: "Empty In",
                        name: "Empty In"
                    },
                ];
            }
            e.editorOptions.dataSource = options;
            e.editorOptions.displayExpr = "name";  
            e.editorOptions.valueExpr = "value";            
        }
    }
}

// function for customizing filter data
export function calculateFilterExpressionFunction(filterValue, selectedFilterOperations, target, fieldName, dataFromTable) {
    if (target === "filterRow") {
        var filterExpression = [
            { 
                "field": fieldName,
                "operation": selectedFilterOperations,
                "value": filterValue
            },
        ];
        return filterExpression;
    }
    return dataFromTable.defaultCalculateFilterExpression.apply(dataFromTable, arguments);
}

export function getCombinedFilterFunction(e) {
    console.log(e, 'combined filter');
}

export default compactViewMethods;