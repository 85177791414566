<!-- @format -->

<template>
	<div class="edit-warehouse-delivery-location">
		<v-dialog
			v-model="dialogShow"
			max-width="480px"
			content-class="edit-warehouse-dialog"
			:retain-focus="false"
			persistent
		>
			<v-card class="warehouse-location">
				<v-form ref="myForm">
					<v-card-title>
						<h2 class="headline">Edit Warehouse/Delivery Location</h2>
						<button
							icon
							dark
							class="btn-close"
							@click="close"
							:disabled="getShipmentWarehouseLoading"
						>
							<v-icon>mdi-close</v-icon>
						</button>
					</v-card-title>
					<v-card-text class="warehouse-details">
						<div
							class="preloader d-flex w-full justify-center align-center pt-4 pb-4 warehouse-loder"
							v-if="getDeliveryLocationLoading"
						>
							<v-progress-circular :size="40" color="#0171a1" indeterminate>
							</v-progress-circular>
						</div>
						<v-row v-if="!getDeliveryLocationLoading">
							<v-col cols="12">
								<div class="input-field-wrapper mb-0">
									<p class="input-field-label mb-1  d-flex align-center">
										Warehouse/Delivery Location
									</p>
									<v-text-field
										v-if="
											warehouseSavingStatus ||
												(!contactSavingStatus && warehouseSavingStatus)
										"
										v-model="warehouse_pick_up_location"
										placeholder="Enter Warehouse Pickup Location"
										:height="40"
										width="200px"
										type="text"
										dense
										outlined
										hide-details="auto"
										class="contact-delivery-address"
										:rules="warehouseRules"
									/>
									<v-autocomplete
										v-if="
											!warehouseSavingStatus ||
												(contactSavingStatus && !warehouseSavingStatus)
										"
										:height="40"
										class="warehouse-delivery-input-field"
										:items="WarehouseLocationItems"
										:filter="customFilterWarehouse"
										v-model="warehouse_pick_up_location"
										:item-text="selectedText"
										item-value="id"
										dense
										outlined
										hide-details="auto"
										placeholder="Select Warehouse"
										spellcheck="false"
										:menu-props="{
											contentClass:
												'input-field-name-wrapper warehouse-selection-list',
											bottom: true,
											offsetY: true,
										}"
										@change="
											addSelectedWarehouseContact(warehouse_pick_up_location)
										"
										:rules="warehouseRules"
									>
										<template v-slot:item="{ item }">
											<div class="warehouse-details-info">
												<h4 class="warehouse-name mb-1">
													{{ item.location_name }}
												</h4>
												<p class="warehouse-address mb-1">{{ item.type }}</p>
												<p class="warehouse-address mb-0">
													{{
														item.address
															? item.address
															: "No available Warehouse details"
													}}
												</p>
											</div>
										</template>
										<template v-slot:no-data>
											<div
												class="no-data-found"
												v-if="searchWarehouses && !warehouseSearchText"
											>
												<img
													src="@/assets/icons/empty-document-blue.svg"
													width="24px"
													height="24px"
													alt="icon"
												/>
												<h3 class="text-center">No Data Available</h3>
												<p class="mb-0 text-center">
													There is no Warehouse/Delivery Location.
												</p>
											</div>
											<div class="no-data-found" v-else>
												<img
													src="@/assets/icons/empty-document-blue.svg"
													width="24px"
													height="24px"
													alt="icon"
												/>
												<h3 class="text-center">No Match Found</h3>
												<p class="mb-0 text-center">
													There is no Warehouse/Delivery Location as ‘
													{{ warehouseSearchText }}’.
												</p>
											</div>
										</template>
										<v-list
											slot="append-item"
											class="px-4 add-new-orders-options quick-add-content"
										>
											<div class="add-new-orders-buttons mb-2">
												<p
													class="mb-0 quick-order"
													@click="addDeliveryLocation()"
												>
													Add & Save Location
												</p>
												<div>
													<img
														src="@/assets/icons/invite-arrow.svg"
														class="mr-0"
														width="12px"
														height="12px"
														alt="icon"
													/>
												</div>
											</div>
											<div class="add-new-orders-buttons saving-location-btn">
												<p
													class="mb-0 quick-order add-without-saving-location-btn mt-2"
													@click="addWithoutSavingLocation()"
												>
													Add Without Saving
												</p>
												<div>
													<img
														src="@/assets/icons/invite-arrow.svg"
														class="mr-0"
														width="12px"
														height="12px"
														alt="icon"
													/>
												</div>
											</div>
										</v-list>
									</v-autocomplete>
								</div>
							</v-col>
							<v-col cols="12" v-if="!updateLocation && warehouse_type">
								<div class="input-field-wrapper mb-0">
									<p class="input-field-label mb-1  d-flex align-center">
										Type
									</p>
									<v-autocomplete
										v-model="warehouse_type"
										:items="[
											'Company Owned Warehouse',
											'3PL Warehouse',
											'Amazon Fulfillment',
											'Retail Warehouse',
											'Others',
										]"
										:height="40"
										width="200px"
										type="text"
										dense
										outlined
										hide-details="auto"
										disabled
									/>
								</div>
							</v-col>
							<v-col cols="12">
								<div class="input-field-wrapper mb-0">
									<p class="input-field-label mb-1  d-flex align-center">
										Contact Person Name
									</p>
									<v-text-field
										v-if="
											warehouseSavingStatus ||
												(contactSavingStatus && !warehouseSavingStatus)
										"
										v-model="contact_person_name"
										placeholder="Enter Personal Name"
										:height="40"
										width="200px"
										type="text"
										dense
										outlined
										hide-details="auto"
										class="contact-delivery-address"
										:rules="contactPersonRules"
									/>
									<v-autocomplete
										v-if="
											!warehouseSavingStatus &&
												!contactSavingStatus &&
												!warehouseSavingStatus
										"
										:height="40"
										class="warehouse-delivery-input-field"
										:items="deliveryContacts"
										:filter="customFilterWarehouseContacts"
										v-model="contact_person_name"
										item-text="contact_name"
										item-value="id"
										dense
										outlined
										hide-details="auto"
										placeholder="Select Contact"
										spellcheck="false"
										:menu-props="{
											contentClass:
												'input-field-name-wrapper warehouse-selection-list',
											bottom: true,
											offsetY: true,
										}"
										@change="changeContactName(contact_person_name)"
										:rules="contactPersonRules"
									>
										<template v-slot:item="{ item }">
											<div class="warehouse-details-info">
												<h4 class="warehouse-name mb-1">
													{{ item.contact_name ? item.contact_name : "N/A" }}
												</h4>
												<p class="warehouse-address mb-1">
													{{ item.phone_number ? item.phone_number : "N/A" }}
												</p>
												<p class="warehouse-address mb-0">
													{{ item.email ? item.email : "N/A" }}
												</p>
											</div>
										</template>
										<template v-slot:no-data>
											<div
												class="no-data-found"
												v-if="
													searchWarehouseContacts && !warehouseContactSearchText
												"
											>
												<img
													src="@/assets/icons/empty-document-blue.svg"
													width="24px"
													height="24px"
													alt="icon"
												/>
												<h3 class="text-center">No Data Available</h3>
												<p class="mb-0 text-center">
													There is no Warehouse/Delivery Contact.
												</p>
											</div>
											<div class="no-data-found" v-else>
												<img
													src="@/assets/icons/empty-document-blue.svg"
													width="24px"
													height="24px"
													alt="icon"
												/>
												<h3 class="text-center">No Match Found</h3>
												<p class="mb-0 text-center">
													There is no Warehouse/Delivery Contacts as ‘
													{{ warehouseContactSearchText }}’.
												</p>
											</div>
										</template>
										<v-list
											slot="append-item"
											class="px-4 add-new-orders-options quick-add-content"
										>
											<div class="add-new-orders-buttons">
												<p class="mb-0 quick-order ml-2" @click="addNewContact()">
													<img
														src="@/assets/icons/plus.svg"
														class="mr-1"
														width="12px"
														height="12px"
														alt="icon"
													/>
													Add New Contact
												</p>
											</div>
										</v-list>
									</v-autocomplete>
								</div>
							</v-col>
							<v-col cols="12">
								<div class="input-field-wrapper mb-0">
									<p class="input-field-label mb-1 d-flex align-center ">
										Contact Person Tel/Mobile
									</p>
									<vue-tel-input
										v-model="contact_person_mobile_num"
										mode="international"
										defaultCountry="us"
										validCharactersOnly
										:autoDefaultCountry="true"
										:dropdownOptions="vueTelDropdownOptions"
										:inputOptions="vueTelInputOptions"
										:disabled="
											!warehouseSavingStatus &&
												!contactSavingStatus &&
												!warehouseSavingStatus
										"
										class="contact-delivery-address"
										:class="!updateLocation ? 'edit-tel-number' : ''"
										:rules="contactNumberRules"
									>
										<template v-slot:arrow-icon>
											<v-icon class="ml-1">mdi-chevron-down</v-icon>
										</template>
									</vue-tel-input>
								</div>
							</v-col>
							<v-col cols="12">
								<div class="input-field-wrapper mb-0">
									<p class="input-field-label mb-1  d-flex align-center">
										Contact Person Email
									</p>
									<v-text-field
										v-model="email"
										:disabled="
											!warehouseSavingStatus &&
												!contactSavingStatus &&
												!warehouseSavingStatus
										"
										:height="40"
										width="200px"
										type="text"
										dense
										outlined
										hide-details="auto"
										:rules="contactEmailRules"
									/>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<div class="d-flex footer">
							<v-btn
								class="save-btn btn-blue mr-2"
								text
								@click="saveChanges()"
								:disabled="getShipmentWarehouseLoading"
							>
								<span>Confirm</span>
							</v-btn>
							<v-btn
								class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
								text
								@click="close()"
								:disabled="getShipmentWarehouseLoading"
							>
								Cancel
							</v-btn>
						</div>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<AddDeliveryLocationDialog
			:dialogImportName.sync="addNewDeliveryLocationDialog"
			@closelocation="closeLocationDialog"
			:defaultLocationItems="defaultLocationItems"
			@warehousePayload="fetchDeliveryLocations"
			:defaultCustomer="userDataDetails.default_customer_id"
		/>
	</div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { mapActions, mapGetters } from "vuex";
import globalMethods from "../../../utils/globalMethods";
import AddDeliveryLocationDialog from "../../SettingsComponents/Dialog/AddDeliveryLocationDialog.vue";

export default {
	name: "EditWareHouseLocation",
	props: [
		"dialog",
		"deliveryAddressData",
		"isMobile",
		"shipment_id",
		"updateLocation",
		"userDataDetails",
	],
	components: {
		VueTelInput,
		AddDeliveryLocationDialog,
	},
	data: () => ({
		warehouse_pick_up_location: null,
		warehouse_type: null,
		contact_person_name: null,
		contact_person_mobile_num: null,
		email: null,
		vueTelDropdownOptions: {
			showDialCodeInSelection: true,
			showDialCodeInList: true,
			showFlags: true,
			showSearchBox: true,
		},
		vueTelInputOptions: {
			autocomplete: false,
			placeholder: "Type phone number",
			styleClasses: "tel-input-class",
			required: true,
		},
		deliveryContacts: [],
		editDeliveryLocationIndex: false,
		addNewDeliveryLocationDialog: false,
		warehouseSavingStatus: false,
		contactSavingStatus: false,
		warehouseSearchText: "",
		searchWarehouses: true,
		warehouseContactSearchText: "",
		searchWarehouseContacts: true,
		defaultLocationItems: {
			location_name: "",
			type: "",
			country: "",
			state: "",
			city: "",
			address: "",
			zip_code: "",
			facility_operation_schedule: 0,
			special_delivery_instruction: "",
			contacts: [
				{
					contact_name: "",
					phone_number: "",
					email: "",
					role: "",
					on_site: 0,
					primary: "",
				},
			],
			facility_operation_hour: {
				type: "weekly",
				days: [],
				mon_open: "",
				mon_close: "",
				tue_open: "",
				tue_close: "",
				wed_open: "",
				wed_close: "",
				thu_open: "",
				thu_close: "",
				fri_open: "",
				fri_close: "",
				sat_open: "",
				sat_close: "",
				sun_open: "",
				sun_close: "",
			},
		},
		warehouseRules: [
			(value) => !!value || 'Delivery Location is required'
		],
		contactPersonRules: [
			(value) => !!value || 'Contact Person is required'
		],
		contactNumberRules: [
			(value) => !!value || 'Contact Number is required'
		],
		contactEmailRules: [
			(value) => !!value || 'Contact Email is required'
		]
	}),
	beforeMount() {
		try {
			this.userDetails = JSON.parse(this.getUser);
		} catch (e) {
			this.userDetails = this.getUser;
		}
	},
	async mounted() {
		// await this.fetchDeliveryLocations();
		if (!this.updateLocation) {
			const {
				warehouse_id,
				phone,
				email,
				contact_id,
				type,
			} = this.deliveryAddressData;

			let deliveryContacts = await this.WarehouseLocationItems.find(
				(item) => item.id == warehouse_id
			);

			if (
				deliveryContacts !== undefined &&
				deliveryContacts !== null &&
				deliveryContacts !== ""
			) {
				this.deliveryContacts = deliveryContacts.contacts;
			}

			this.warehouse_pick_up_location = warehouse_id;
			this.warehouse_type = type;
			this.contact_person_name = contact_id;
			this.contact_person_mobile_num = phone;
			this.email = email;
		}
	},
	computed: {
		...mapGetters({
			getShipmentWarehouseLoading:
				"shipmentDetailsV2/getShipmentWarehouseLoading",
			getDeliveryLocations: "settings/getDeliveryLocations",
			getDeliveryLocationLoading: "settings/getDeliveryLocationLoading",
			getUser: "getUser",
		}),
		dialogShow: {
			get() {
				return this.dialog;
			},
			set(value) {
				this.$emit("update:dialog", value);
			},
		},
		WarehouseLocationItems() {
			return this.$store.getters["settings/getDeliveryLocations"];
		},
		selectedText() {
			return (item) => {
				return item.location_name ? item.location_name : item.address;
			};
		},
	},
	methods: {
		...globalMethods,
		...mapActions({
			editWareHouseLocation: "shipmentDetailsV2/editWareHouseLocation",
			addWareHouseLocation: "shipmentDetailsV2/addWareHouseLocation",
			fetchNewShipmentDetails: "shipmentDetailsV2/fetchNewShipmentDetails",
		}),
		close() {
			this.$emit("close");
		},
		addSelectedWarehouseContact(warehouse_id) {
			let warehouseData = this.WarehouseLocationItems.find(
				(item) => item.id == warehouse_id
			);
			let { contacts, type } = warehouseData;

			this.deliveryContacts = contacts;
			this.warehouse_type = type;
		},
		changeContactName(contact_id) {
			let contactdetails = this.deliveryContacts.find(
				(item) => item.id == contact_id
			);
			this.contact_person_mobile_num = contactdetails.phone_number;
			this.email = contactdetails.email;
		},
		addDeliveryLocation() {
			this.$store.dispatch("warehouse/fetchCountries", {
				is_paginate: 0,
			});
			this.editDeliveryLocationIndex = false;
			this.addNewDeliveryLocationDialog = true;
		},
		closeLocationDialog() {
			this.addNewDeliveryLocationDialog = false;

			this.editDeliveryLocationIndex = false;
			this.defaultLocationItems = {
				location_name: "",
				address: "",
				country: "",
				city: "",
				state: "",
				location_contacts: [],
			};
		},
		addWithoutSavingLocation() {
			this.warehouseSavingStatus = true;
		},
		fetchDeliveryLocations() {
			this.$store.dispatch("settings/fetchDeliveryLocationsApi", {
				page: 1,
			});
		},
		customFilterWarehouse(item, queryText, itemText) {
			const address = item.location_name ? item.location_name : "";
			const data =
				item.phone + address.toLowerCase() + itemText.toLocaleLowerCase();
			this.warehouseSearchText = queryText;

			this.searchWarehouses = data.indexOf(queryText.toLowerCase()) > -1;
			return this.searchWarehouses;
		},
		customFilterWarehouseContacts(item, queryText, itemText) {
			const contact = item.contact_name ? item.contact_name : "";
			const data =
				item.phone_number +
				contact.toLowerCase() +
				itemText.toLocaleLowerCase();
			this.warehouseContactSearchText = queryText;

			this.searchWarehouseContacts = data.indexOf(queryText.toLowerCase()) > -1;
			return this.searchWarehouseContacts;
		},
		addNewContact() {
			this.contactSavingStatus = true;
		},
		async saveChanges() {
			if (this.$refs.myForm.validate()) {
				let payload = null;
				if (!this.contactSavingStatus && !this.warehouseSavingStatus) {
					payload = {
						warehouse_pick_up_location_id: this.warehouse_pick_up_location,
						contact_person_id: this.contact_person_name,
						shipment_id: this.shipment_id,
					};
				} else if (this.contactSavingStatus) {
					payload = {
						warehouse_pick_up_location_id: this.warehouse_pick_up_location,
						contact_person_name: this.contact_person_name,
						contact_person_mobile_num: this.contact_person_mobile_num,
						email: this.email,
						shipment_id: this.shipment_id,
					};
				} else {
					payload = {
						warehouse_pick_up_location: this.warehouse_pick_up_location,
						contact_person_name: this.contact_person_name,
						contact_person_mobile_num: this.contact_person_mobile_num,
						email: this.email,
						type: this.warehouse_type,
						shipment_id: this.shipment_id,
						customer_id: this.userDetails.customer.id,
					};
				}
				console.log("payload", payload);

				if (this.updateLocation === true) {
					await this.addWareHouseLocation(payload).then((response) => {
						console.log("response", response);
						if (response.status == 200) {
							this.notificationMessage("Warehouse/Delivery Location created");
							this.close();
						}
					});
				} else {
					await this.editWareHouseLocation(payload).then((response) => {
						console.log("response", response);
						if (response.status == 200) {
							this.notificationMessage("Warehouse/Delivery Location updated");
							this.close();
						}
					});
				}
				await this.fetchNewShipmentDetails(this.shipment_id);
			} else {
				console.log('Form not validated');
			}
		},
	},
};
</script>

<style lang="scss">
@import "../../ShipmentComponents/BookingShipment/SubComponents/scss/Common.scss";
.edit-warehouse-dialog {
	.warehouse-location {
		.warehouse-details {
			padding: 6px 12px;
			.row {
				margin: 0 !important;
				.col {
					padding: 6px 12px;
					.warehouse-type {
						.v-input__control {
							.v-input__slot {
								min-height: 40px !important;
							}
						}
					}
					.edit-tel-number {
						&.v-input--is-disabled {
							.v-input__slot {
								background: $shifl-neutral-n20;
							}
						}
						.vti__input:disabled {
							background: $shifl-neutral-n20;
						}
						.vti__dropdown {
							background: $shifl-neutral-n20;
						}
					}
				}
				.location-info{
					font-size: 12px;
					color: $shifl-neutral-n60;
				}
			}
			.warehouse-loder {
				height: 450px;
			}
		}
	}
}
.v-menu__content {
	&.warehouse-selection-list {
		.v-list {
			.v-list-item {
				padding: 6px 12px;
				.warehouse-name {
					font-family: "inter";
					font-size: 14px;
					line-height: 20px;
					font-weight: 500;
				}
				.warehouse-address {
					font-family: "inter";
					font-size: 14px;
					line-height: 20px;
					font-weight: 400;
					color: $shifl-neutral-n60;
				}
			}
		}
	}
}
@media screen and (min-width: 320px) and (max-width: 767px) {
	.edit-warehouse-dialog {
		&.v-dialog {
			position: absolute;
			height: 100%;
			max-height: 100%;
			margin: 0;
			top: 0;
			.v-card {
				height: 100%;
			}
		}
	}
}
</style>
