<template>
	<div>
		<v-dialog
			fullscreen
			persistent
			:retain-focus="false"
			v-model="getCustomTimeDailog"
			content-class="notification-user-dailog">

			<v-card class="add-supplier-card-m">
				<v-form ref="form" v-model="valid" action="#" @submit.prevent="">
					<v-card-title style="caret-color: transparent">
						<span class="headline">Custom Time</span>

						<button icon dark class="btn-close" @click="close">
							<v-icon>mdi-close</v-icon>
						</button>
					</v-card-title>

					<v-spacer></v-spacer>

					<v-card-text>
						<v-row>
							<v-col
								style="color: red"
								v-if="errorFound"
								cols="12"
								sm="12"
								md="12">
								{{ errorMessage }}
							</v-col>

							<v-col cols="12" sm="12" md="12">
								<label class="text-item-label">Push Notification Time for</label>
								<p class="notifi-type mb-1">{{ getCurrentEditItem }}</p>
							</v-col>
							<v-col
								class="selectBox pt-1"
								cols="12"
								md="12">

								<label class="text-item-label">From</label>
								<!-- <DxDateBox :input-attr="{ 'aria-label': 'Time' }" 
									placeholder="Select Time" 
									:openOnFieldClick="true"
									type="time" 
									v-model="fromTime" 
									class="custom-time-m"
									:class="
										showError1
											? 'error-input-m'
											: 'cus-time-picker-m'
									"
									:use-mask-behavior="true"
									display-format="hh:mm a" 
									:show-clear-button="true" 
									picker-type="list" /> -->

									<v-autocomplete 
										v-model="fromTime" 
										:items="fromTimeItems"
										placeholder="Select Time"
										outlined
										class="text-field select-items cust-time-picker-select"
										item-text="text"
										item-value="text"
										return-object
										:menu-props="{ bottom: true, offsetY: true }"
										@change="onChangeFromTimeValue"
										hide-details="auto"
										append-icon="mdi-clock-outline"
										:rules="[(v) => !!v || 'Input is required.']">
									</v-autocomplete>
							</v-col>

							<v-col class="selectBox pt-0" cols="12" md="12">
								<div class="datetime-field-groups">
									<label class="text-item-label">To</label>
									<!-- <DxDateBox :input-attr="{ 'aria-label': 'Time' }" 
										placeholder="Select Time" 
										:openOnFieldClick="true"
                          				type="time" 
										v-model="toTime" 
										:class="
											showError2
												? 'error-input-m'
												: 'cus-time-picker-m'
										"
										:use-mask-behavior="true"
                          				display-format="hh:mm a" 
										:show-clear-button="true" 
										picker-type="list" /> -->

									<v-autocomplete 
										v-model="toTime" 
										:items="toTimeItems"
										placeholder="Select Time"
										outlined
										class="text-field select-items cust-time-picker-select"
										item-text="text"
										item-value="text"
										return-object
										:menu-props="{ bottom: true, offsetY: true }"
										hide-details="auto"
										@change="onChangeToTimeValue"
										@click="getToTimeItems"
										append-icon="mdi-clock-outline"
										:rules="[(v) => !!v || 'Input is required.']">
									</v-autocomplete>

									<!-- <div v-if="showError" class="v-text-field__details mt-1">
										<div class="v-messages theme--light error--text" role="alert">
											<div class="v-messages__wrapper">
												<div class="v-messages__message">
													Please provide From time and
													End time.
												</div>
											</div>
										</div>
									</div> -->
								</div>
							</v-col>

							<!-- checkbox for setting same time for all notification type -->
							<v-col cols="12" sm="12" md="12">
								<v-checkbox
									class="email-users mt-0 mb-0"
									v-model="sameTime"
									label="Same time for all notifications"
									hide-details="auto"
									v-if="!getCustomer.push_notification_setting.same_time_for_all">
								</v-checkbox>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions style="border-top: 2px solid #F3F4F7;">
						<div class="d-flex align-center justify-start">
							<button
								class="save-btn btn-blue"
								@click="updateNotification"
								:disabled="getCustomTimeLoading">

								<span class="d-flex align-center justify-start">
									<span color="#fff" class="d-flex align-center justify-start">
										<v-progress-circular
											:size="15"
											color="#fff"
											:width="2"
											indeterminate
											v-if="getCustomTimeLoading"
											style="margin-right: 6px;">
										</v-progress-circular>
										{{ getCustomTimeLoading ? "Setting..." : "Set Time" }}
									</span>
								</span>
							</button>
							<button class="cancel-btn btn-white ml-2" @click="close" :disabled="getCustomTimeLoading">
								Cancel
							</button>
						</div>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
// import DxDateBox from "devextreme-vue/date-box";
import { notificationsItems } from "../staticData";
import _ from "lodash";

export default {
	props: ["dialogData", "dialog", "currentEditItem", "stateData"],
	components: {
		// DxDateBox,
	},
	data: () => {
		return {
			errorMessage: "",
			errorFound: 0,
			errorSaving: 0,
			dd: true,
			valid: false,
			sameTime: false,
			showError: false,
			showError1: false,
			showError2: false,
			firstTime: null,
			secondTime: null,
			fromTimeItems: [],
			toTimeItems: [],
			fromTime: null,
			toTime: null
		};
	},

	computed: {
		...mapGetters("notificationNew", [
			"getCurrentEditItem",
			"getLoading",
			"getCustomTimeDailog",
			"getCustomer",
			"getTempPeriod",
			"getCustomTimeLoading"
		]),
		pushSettings() {
			return this.getCustomer.push_notification_setting;
		},
	},
	watch: {
		dialog(c) {
			if (!c) {
				this.$refs.form.resetValidation();
			} else {
				this.errorMessage = "";
			}
		},
		getCustomTimeDailog(v) {
			if (v) {
				this.sameTime = this.getCustomer.push_notification_setting.same_time_for_all === 1 ? true : false;
				this.fromTime = this.getTimeValue("fromTime");
				this.toTime = this.getTimeValue("toTime")
			}
		}
	},
	methods: {
		...mapActions("notificationNew", ["updateNotificationTime"]),
		...mapMutations("notificationNew", [
			"SET_CUSTOM_TIME_DAILOG",
			"SET_BOX_OPEN",
			"SET_CUSTOMER",
			"SET_CURRENT_EDIT_ITEM",
		]),
		updateTime(item, val) {
			this[item] = val;
		},
		close() {
			// let customer = this.getCustomer;
			let payload = {
				show: null,
				ref: null,
				state: null,
			};
			this.SET_BOX_OPEN(payload);
			this.fromTime = "";
			this.toTime = "";
			this.sameTime = false;
			this.firstTime = "";
			this.secondTime = "";

			let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
			let findData = _.find(notificationsItems, e => (e.notificationTitle === currentTitle));

			if (findData !== undefined) {
				const menuComponent = this.$parent.$refs[findData.refName][0];
				setTimeout(() => {
					menuComponent.blur();
				}, 200);
			}

			// let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
			// let findData = _.find(notificationsItems, e => (e.notificationTitle === currentTitle));
			// let timeField = `time_${findData.pushField}`;

			// switch (this.getTempPeriod) {
			// 	case "AA":
			// 		customer.push_notification_setting[findData.pushField] = "AA";
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// 	case "WH":
			// 		customer.push_notification_setting[findData.pushField] = "WH";
			// 		this.SET_CUSTOMER(customer);
			// 		break;
			// 	default:
			// 		if (!customer.push_notification_setting[timeField]) {
			// 			customer.push_notification_setting[findData.pushField] = "OFF";
			// 			this.stateData[findData.refName].unshift(
			// 				"Push Notification Turned Off"
			// 			);
			// 			this.SET_CUSTOMER(customer);
			// 		}
			// 		break;
			// }
			this.SET_CURRENT_EDIT_ITEM("");
			this.SET_CUSTOM_TIME_DAILOG(false);
			this.$emit("getNotificationData");
			this.$refs.form.resetValidation();
		},
		async updateNotification() {
			this.$refs.form.validate();
			// if (!this.fromTime && !this.toTime) {
			// 	if (!this.firstTime || !this.secondTime) {
			// 		this.showError = true;
			// 		if (!this.firstTime) {
			// 			this.showError1 = true;
			// 			return;
			// 		}
			// 		this.showError2 = true;
			// 		return;
			// 	}
			// }

			setTimeout(async () => {
				if (this.$refs.form.validate()) {
					let fromHr = this.firstTime ? this.firstTime : this.fromTime;
					let toHr = this.secondTime ? this.secondTime : this.toTime;

					let form = new FormData();
					form.append("period", JSON.stringify([fromHr, toHr]));
					form.append("custom_time", true);
					form.append("customer_id", this.getCustomer.id);

					if (this.sameTime) {
						form.append("same_time_for_all", this.sameTime);
					}

					let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
					let findData = _.find(notificationsItems, e => (e.notificationTitle === currentTitle));

					if (findData !== null) {
						form.append("type", findData.pushField);
					}
					await this.updateNotificationTime(form);
					this.fromTime = null;
					this.toTime = null;
					this.sameTime = false;
					this.$emit("getNotificationData");
				}
			})
		},
		getFromTimeItems() {
			let arr = [];
			const start = new Date();
			start.setHours(0, 0, 0); // 12 AM
			const end = new Date();
			end.setHours(23, 45, 0); // 11:30 PM

			while (start <= end) {
				let timeIncrement = start.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' })
				start.setMinutes(start.getMinutes() + 15);

				let value = start.getHours();
				arr.push({
					text: timeIncrement,
					timeValue: value
				});
			}
			this.fromTimeItems = arr;
		},
		getToTimeItems() {
			let arr = [];
			if (this.firstTime !== null || this.fromTime !== null) {
				let time = this.firstTime !== null && this.firstTime !== "" ? this.firstTime : this.fromTime;

				let date = moment(new Date()).format("YYYY-MM-DD");
				let newTime = `${date} ${time}`;
				let start = new Date(newTime);
				start.setHours(start.getHours(), start.getMinutes(), 0);
				const end = new Date(newTime);
				end.setHours(23, 45, 0); // 11:30 PM

				while (start <= end) {
					let timeIncrement = start.toLocaleString('en-US', { hour: '2-digit', minute: '2-digit' })
					start.setMinutes(start.getMinutes() + 15);

					let value = start.getHours();
					arr.push({
						text: timeIncrement,
						timeValue: value
					});
				}
			} else {
				arr = this.fromTimeItems;
			}			
			this.toTimeItems = arr;
		},
		onChangeFromTimeValue(e) {
			this.fromTime = e.text;
			this.getToTimeItems();
		},
		onChangeToTimeValue(e) {
			this.toTime = e.text;
		},
		getTimeValue(isFor) {
			let data = null;
			let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
			let findData = _.find(notificationsItems, e => (e.notificationTitle === currentTitle));
			if (findData !== undefined) {
				let timeField = `time_${findData.pushField}`;
				if (this.pushSettings[timeField] && this.pushSettings[timeField] !== "null") {
					let init = JSON.parse(this.pushSettings[timeField]);
					if (isFor === "fromTime") {
						data = init[0];
					} else {
						data = init[1];
					}
				}
			}
			return data;
		}
	},
	mounted() {
		this.getFromTimeItems();
		this.getToTimeItems(null);
	},
	updated() {
		if (typeof this.$refs.form !== 'undefined') {
			if (typeof this.$refs.form.resetValidation() !== 'undefined') {
				this.$refs.form.resetValidation()
			}
		}
	}
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
	border-color: #d0d5dd;
	border-width: 1px;
}
</style>
<style lang="scss">
.notification-user-dailog {
	.email-users .v-input__slot .v-label {
		color: #253041;
		font-size: 14px;
		font-family: "Inter-Regular";
	}
}
.add-supplier-card-m {
	height: 100vh;
	.custom-time-m {
		.dx-toolbar, .dx-toolbar-item-content {
			display: none;
		}
	}
	.error-input-m {
		border: 1px solid #f93131;
		border-radius: 4px;
		width: 95%;
		height: 40px;
	}
	.cus-time-picker-m {
		width: 100%;
		border: 1px solid #b4cfe0;
		border-radius: 4px;
		height: 40px;

		.dx-placeholder {
			color: $shifl-neutral-n40 !important;
		}

		.dx-texteditor-buttons-container {
			.dx-clear-button-area {
				width: 23px;
				min-width: 23px;
	
				.dx-icon.dx-icon-clear {
					font-size: 18px;
					width: 23px;
					min-width: 23px;
					padding: 8px 6px 8px 0;

					&::before {
						content: "\e932";
						background-image: url('/close-red.svg') !important;
						background-position: center !important;
						background-repeat: no-repeat !important;
						background-size: cover !important;
						color: $shifl-neutral-n90 !important;
						padding: 0px;
					}
				}
			}
		}
	}
	i {
		&.mdi-checkbox-blank-outline {
			width: 26px !important;
			padding: 3px !important;

			&::before {
				content: '' !important;
				background-image: url('/checkbox-empty-icon-1.svg') !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				background-size: cover !important;
				width: 16px !important;
				height: 16px !important;
			}
		}

		&.mdi-checkbox-marked {
			width: 26px !important;
			padding: 3px !important;

			&::before {
				content: '' !important;
				background-image: url('/checkbox-filled-icon-1.svg') !important;
				background-position: center !important;
				background-repeat: no-repeat !important;
				background-size: cover !important;
				width: 16px !important;
				height: 16px !important;
			}
		}
	}
	.dx-texteditor-input {
		font-family: "Inter-Regular", sans-serif !important;
	}
	.dx-popup-title.dx-toolbar {
		display: none !important;
	}

	.cust-time-picker-select {
		.v-input__control {
			.v-input__slot {
				min-height: 45px !important;
				font-size: 14px !important;

				.v-input__append-inner {
					.v-input__icon {
						i.v-icon {
							color: $shifl-neutral-n90 !important;
							transform: none !important;
							transition: none !important;
						}
					}
				}
			}
		}
	}
}
</style>
