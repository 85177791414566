var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.schedulingData.length > 0)?_c('div',{staticClass:"shipment-scheduling-options-wrapper shipment-details-card"},[_c('h3',[_vm._v(" "+_vm._s(!_vm.isChosen ? "Scheduling Options" : "Chosen Quote")+" ")]),_vm._l((_vm.schedulingData),function(itemData,index){return _c('div',{key:index,staticClass:"shipment-schedule-detail"},[(itemData.haveSellRate)?_c('v-row',{staticClass:"schedule-detail"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h3',{staticClass:"d-flex gap-2 align-center"},[_vm._v(" "+_vm._s(itemData.carrier_name ? itemData.carrier_name : "N/A")+" "),(
							itemData.cheapest &&
								!_vm.isConfirmed(itemData) &&
								_vm.schedulingData.length > 1
						)?_c('span',{staticClass:"status-label"},[_vm._v("Cheapest")]):_vm._e(),(
							itemData.fastest &&
								!_vm.isConfirmed(itemData) &&
								_vm.schedulingData.length > 1
						)?_c('span',{staticClass:"status-label"},[_vm._v("Fastest")]):_vm._e(),(_vm.isConfirmed(itemData))?_c('span',{staticClass:"selected-status"},[_c('img',{attrs:{"src":require("../../../assets/icons/checkMark-green.svg"),"width":"12px","height":"12px"}}),_vm._v(" Selected")]):_vm._e()]),(!_vm.isConfirmed(itemData))?_c('v-btn',{staticClass:"btn-blue",on:{"click":function($event){return _vm.confirmSchedule(itemData)}}},[_vm._v(" Choose This ")]):_vm._e()],1)],1):_vm._e(),(itemData.haveSellRate)?_c('v-row',{staticClass:"shipment-general-info"},[_c('v-col',{staticClass:"general-info",attrs:{"sm":"12","md":"4"}},[_c('div',[_c('p',[_vm._v("From & ETD")]),_c('p',[_vm._v(_vm._s(itemData.location_from)+", "+_vm._s(itemData.etd))])]),_c('div',[_c('p',[_vm._v("To & ETA")]),_c('p',[_vm._v(_vm._s(itemData.location_to)+", "+_vm._s(itemData.eta))])]),(itemData.legs && itemData.legs.length > 0)?_c('div',_vm._l((itemData.legs),function(leg,i){return _c('div',{key:i},[_c('p',{staticClass:"mb-0"},[_vm._v("To & ETA")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(leg.location_to_name)+", "+_vm._s(leg.eta_readable)+" ")])])}),0):_vm._e(),_c('div',[_c('p',[_vm._v("Transit Time")]),_c('p',[_vm._v(_vm._s(itemData.transit_time)+" days")])]),(itemData.container_free_days)?_c('div',[_c('p',[_vm._v("Container free days")]),_c('p',[_vm._v(" "+_vm._s(itemData.container_free_days ? itemData.container_free_days + " days (Business days)" : "N/A")+" ")])]):_vm._e()]),_c('v-col',{staticClass:"schedule-info",attrs:{"sm":"12","md":"8"}},[_c('v-data-table',{staticClass:"schedule-table",attrs:{"headers":_vm.scheduleHeader,"items":_vm.schedulesSellRateData(itemData.sell_rates),"hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"body",fn:function(ref){
						var items = ref.items;
return [(items.length > 0)?_c('tbody',[_vm._l((items),function(item,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-left item-name"},[_vm._v(" "+_vm._s(item.service_name)+" "),(item.qty)?_c('span',[_vm._v("("+_vm._s(item.qty)+"x)")]):_vm._e()]),_c('td',{staticClass:"text-right"},[(item.label)?_c('p',{staticClass:"mb-0 schedule-label"},[_vm._v(" "+_vm._s(item.label)+" ")]):_vm._e(),(item.valid_to)?_c('p',{staticClass:"mb-0 schedule-label"},[_vm._v(" Valid to "+_vm._s(item.valid_to)+" ")]):_vm._e(),(item.total)?_c('span',{staticClass:"schedule-amount"},[_vm._v("$"+_vm._s(item.total ? item.total : "N/A")+" ")]):_c('span',{staticClass:"schedule-status"},[_vm._v("Pending")])])])}),_c('tr',{staticClass:"total-amount"},[_c('td',{staticClass:"text-right"},[_vm._v("Total")]),_c('td',{staticClass:"text-right"},[_vm._v("$"+_vm._s(itemData.total_amount))])])],2):_vm._e()]}}],null,true)})],1)],1):_vm._e()],1)}),_c('ConfirmDialog',{attrs:{"dialogData":_vm.BookingConfirmDialog,"carrier":_vm.currentSelectedSchedule !== null
				? _vm.currentSelectedSchedule.carrier_name
				: '',"loading":_vm.loading},on:{"update:dialogData":function($event){_vm.BookingConfirmDialog=$event},"update:dialog-data":function($event){_vm.BookingConfirmDialog=$event},"confirm":_vm.handleChoose,"update:loading":function($event){_vm.loading=$event}}})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }