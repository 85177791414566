<template>
  <div>
    <NotificationNew v-if="!isMobile" />
    <NotificationMobile v-if="isMobile" />
  </div>
</template>
<script>
import NotificationNew from "./NotificationNew.vue";
import NotificationMobile from "./Mobile/NotificationMobile.vue";
export default {
  props: ["isMobile"],
  components: {
    NotificationNew,
    NotificationMobile,
  },
};
</script>
