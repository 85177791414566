<!-- @format -->

<template>
	<div id="shipment-dropdown-booking-wrapper">
		<v-btn
			v-if="!isMobile"
			class="btn-white archive-shipment mr-4 shipment-header-button-btn d-none"
		>
			<div>
				<custom-icon iconName="archive" />
			</div>
			<div class="pl-1">Archived</div>
		</v-btn>
		<v-menu
			offset-y
			bottom
			left
			close-on-content-click
			close-on-click
			@input="updateInput"
			attach="#shipment-dropdown-booking-wrapper"
			content-class="shipment-dropdown-button-menu"
		>
			<template v-slot:activator="{ on, attrs }">
				<button
					@click.stop="toggleMenu"
					v-bind="attrs"
					v-on="on"
					:class="`booking-shipment-btn ${getClass()}`"
				>
					<div>{{ !isMobile ? "Add Shipment" : "Add" }}</div>
					<div class="icon-wrapper" :class="getClass()">
						<generic-icon color="#ffffff" :iconName="`chevron-${getClass()}`">
						</generic-icon>
					</div>
				</button>
			</template>
			<v-list>
				<v-list-item
					v-bind:key="`bitem-${key}`"
					@click.stop="doAction(item)"
					v-for="(item, key) in items"
				>
					<v-list-item-title class="d-flex">
						<div :class="item.id == 3 ? 'black--text' : ''">
							{{ item.title }}
						</div>
						<div>
							<generic-icon color="#1A6D9E" iconName="chevron-right">
							</generic-icon>
						</div>
					</v-list-item-title>
					<v-list-item-content>
						{{ item.content }}
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-menu>

		<BookingShipmentDialogV2
			:show.sync="viewBookingShipmentDialog"
			:reference="'create-booking-shipment'"
			@close="viewBookingShipmentDialog = false"
		/>

		<BookingLinkDialog
			v-if="viewBookingLinkDialog"
			:showDialog.sync="viewBookingLinkDialog"
			@close="viewBookingLinkDialog = false"
		/>
	</div>
</template>
<style lang="scss">
@import "./scss/shipmentDropdown.scss";
</style>
<script>
import GenericIcon from "../Icons/GenericIcon";
import CustomIcon from "../../components/Icons/CustomIcon";
import BookingShipmentDialogV2 from "@/components/ShipmentComponents/BookingShipment/BookingShipmentDialogV2.vue";
import BookingLinkDialog from "../ShipmentComponents/BookingShipment/BookingLinkDialog.vue";
export default {
	name: "ShipmentDropdown",
	components: {
		GenericIcon,
		CustomIcon,
		BookingShipmentDialogV2,
		BookingLinkDialog,
	},
	props: ["isMobile"],
	data: () => ({
		carretDirection: false,
		showMenu: false,
		items: [
			{
				id: 1,
				title: "Create Booking Request",
				content: "Request for a booking and get a quote.",
			},
			{
				id: 2,
				title: "Add Shipment for Tracking",
				content:
					"We will track the shipment for you and you can add information if needed.",
			},
			{
				id: 3,
				title: "Generate Link for Booking",
				content: "Generate a booking link to share with your vendors .",
			},
		],
		viewBookingShipmentDialog: false,
		viewBookingLinkDialog: false,
	}),
	methods: {
		updateInput() {
			//this.showMenu = i
			this.showMenu = false;
		},
		getClass() {
			return this.showMenu ? "up" : "down";
		},
		toggleMenu() {
			this.carretDirection = !this.carretDirection;
		},
		setMarkingBookedExternal(b) {
			this.$store.dispatch("page/setMarkingBookedExternal", b);
		},
		setEditingShipment(b) {
			this.$store.dispatch("page/setEditingShipment", b);
		},
		setAddingShipmentTracking(b) {
			this.$store.dispatch("page/setAddingShipmentTracking", b);
		},
		setCreatingBookingRequest(b) {
			this.$store.dispatch("page/setCreatingBookingRequest", b);
		},
		doAction(item) {
			this.$emit("update:shipmentDropDownClicked", true);
			if (item.title === "Add Shipment for Tracking") {
				// this.showAddShipmentDialog()
				this.showSingleAddShipmentDialog();
			} else if (item.title === "Create Booking Request") {
				this.viewBookingShipmentDialog = true;
			} else {
				this.viewBookingLinkDialog = true;
			}
		},
		showBookingShipmentDialog() {
			this.$emit("update:isEdit", false);
			this.$emit("showBookingShipmentDialog");

			this.setEditingShipment(false);
			this.setMarkingBookedExternal(false);
			this.setAddingShipmentTracking(false);
			this.setCreatingBookingRequest(true);
		},
		// showAddShipmentDialog() {
		//     this.$emit('update:isEdit', false)
		// 	this.$emit('showAddShipmentDialog')

		//     this.setEditingShipment(false)
		//     this.setMarkingBookedExternal(false)
		//     this.setAddingShipmentTracking(true)
		//     this.setCreatingBookingRequest(false)
		// },
		showSingleAddShipmentDialog() {
			this.$emit("update:isEdit", false);
			this.$emit("showSingleAddShipmentDialog");
			this.setEditingShipment(false);
			this.setMarkingBookedExternal(false);
			this.setAddingShipmentTracking(true);
			this.setCreatingBookingRequest(false);
		},
	},
};
</script>
