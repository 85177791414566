<template>
    <div class="upload-component-wrapper">
        <div class="upload-content-wrapper">
            <div class="document-lists upload-component" v-if="documentLists.length > 0">
                <div class="document-title text-capitalize" v-if="uploadDocType !== 'other'">{{ replaceTitle() }}</div>

                <hr v-if="uploadDocType === 'other' && editedUploadIndex === -1" style="width: 100%; margin: 0 0 18px; border-color: #F3F4F7; border-style: solid;" />
                
                <div class="lists-items"
                    v-for="(documentFile, index) in documentLists"
                    :key="`d-files-${index}`">
                    <div class="items">
                        <img
                            src="@/assets/icons/shipment-document-blue.svg"
                            width="36px"
                            height="36px"
                            class="mr-2"
                            v-if="!isMobile" />
                        <div>
                            <div
                                :class="
                                    `document-info ${
                                        (documentFile.nameError &&
                                        (documentFile.name === '' ||
                                        documentFile.name === null)) ||
                                        documentFile.fileError
                                            ? 'div-has-error'
                                            : ''
                                    }`
                                ">
                                <div>
                                    <img
                                        src="@/assets/icons/shipment-document-blue.svg"
                                        width="36px"
                                        height="36px"
                                        class="mr-2"
                                        v-if="isMobile"
                                    />
                                    <input v-model="documentFile.name" />
                                </div>
                                <div v-if="!isMobile">
                                    <div class="file-size-info">
                                        <span>
                                            [ {{ getFileSize(documentFile.file.size) }}
                                            <span class="separator mx-1">*</span>
                                            <span v-if="editedUploadIndex > -1" class="mr-1">
                                                {{ getReuploadDocumentExtension(documentFile) }}
                                            </span>
                                            <span v-else class="mr-1">
                                                {{ documentFile.file.name.split(".").pop() }}
                                            </span>
                                            ]
                                        </span>
                                    </div>
                                    <a href="#"
                                        class="file-remove"
                                        @click="removeFile(index)">
                                        <img
                                            src="@/assets/icons/close-red.svg"
                                            width="16px"
                                            height="16px"
                                        />
                                    </a>
                                </div>
                            </div>

                            <div class="document-info-below mobile-upload-component" v-if="isMobile && uploadDocType !== 'other'">
                                <div class="document-file-size-wrapper">
                                    <div class="file-size-info">
                                        <span>
                                            [ {{ getFileSize(documentFile.file.size) }}
                                            <span class="separator mx-1">*</span>
                                            <span v-if="editedUploadIndex > -1">
                                                {{ getReuploadDocumentExtension(documentFile) }}
                                            </span>
                                            <span v-else>
                                                {{ documentFile.file.name.split(".").pop() }}
                                            </span>
                                            ]
                                        </span>
                                    </div>
                                    <a href="#" class="file-remove" @click="removeFile(index)">
                                        <img
                                            src="@/assets/icons/close-red.svg"
                                            width="16px"
                                            height="16px"
                                        />
                                    </a>
                                </div>
                            </div>

                            <div class="document-info-below mt-1 type-is-other" v-if="uploadDocType === 'other'">
                                <div class="document-info-content first-content">
                                    <span class="document-supplier-title" v-if="isMobile">Type</span>
                                    <div class="document-info-content">
                                        <div
                                            :class="
                                                documentFile.typeError &&
                                                (documentFile.type === '' ||
                                                documentFile.type === null)
                                                    ? 'has-error w-100'
                                                    : 'w-100'
                                            ">
                                            <v-select
                                                class="select-product"
                                                :class="uploadType !== '' ? 'has-value' : ''"
                                                :items="
                                                    !dialogMessage
                                                    ? documentTypesUpdated
                                                    : documentTypesToDos
                                                "
                                                item-text="name"
                                                item-value="value"
                                                placeholder="Select Document Type"
                                                outlined
                                                v-model="documentFile.type"
                                                hide-details="auto"
                                                :menu-props="{
                                                    contentClass:
                                                        'supplier-lists-contents types fixedWidth',
                                                    bottom: true,
                                                    offsetY: true,
                                                }">
                                                <template v-slot:item="{ item }">
                                                    <div class="option-items">
                                                        <span>{{ item.name }}</span>
                                                        <img
                                                            src="@/assets/icons/check-blue.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="isMobile" class="document-file-size-wrapper">
                                    <div class="file-size-info">
                                        <span class="mr-0">
                                            [ {{ getFileSize(documentFile.file.size) }}
                                            <span class="separator mx-1">*</span>
                                            <span v-if="editedUploadIndex > -1" class="mr-0">
                                                {{ getReuploadDocumentExtension(documentFile) }}
                                            </span>
                                            <span v-else class="mr-0">
                                                {{ documentFile.file.name.split(".").pop() }}
                                            </span>
                                            ]
                                        </span>
                                    </div>
                                    <a href="#" class="file-remove" @click="removeFile(index)">
                                        <img
                                            src="@/assets/icons/close-red.svg"
                                            width="16px"
                                            height="16px"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="request-supplier-wrapper mb-5" 
                v-bind:class="{
                    'has-document-lists': documentLists.length > 0 && 
                        ((editedUploadIndex === -1 && uploadDocType !== 'other') || (editedUploadIndex > -1 )),
                    'other-section': uploadDocType === 'other'
                }">
                <div class="upload-supplier-title-wrapper">
                    <span class="upload-supplier-title" v-if="documentLists.length === 0 && uploadDocType !== 'other'">
                        {{ uploadDocTitle }} 
                    </span> 

                    <span class="upload-supplier-title" v-else-if="uploadDocType === 'other'">
                        <span v-if="editedUploadIndex === -1" class="upload-supplier-title">{{ uploadDocTitle }}</span>
                        <span class="text-capitalize" v-if="uploadDocType === 'other' && editedUploadIndex === -1"> (Optional)</span>
                    </span>
                </div>
                <div class="upload-request-body-wrapper upload-documents">
                    <!-- :class="fieldErrors ? 'has-error' : ''" -->
                    <div id="upload-documents-select-box-id" class="upload-documents-select-box"
                        @click.stop="addDocuments">
                        <span>Choose file to upload</span>
                    </div>
                    <button class="btn-white" @click.stop="addDocuments">
                        Browse File
                    </button>
                    <input
                        :ref="refName"
                        type="file"
                        :id="`upload_documents_other`"
                        @change="(e) => inputChanged(e)"
                        name="upload_documents"
                        accept=""
                        multiple
                    />
                </div>
            </div>
        </div>

        <!-- for logged out (commented not finalized) -->
        <!-- <div class="for-loggedin-wrapper" v-if="fromLoggedOut">
            <div class="document-lists upload-component" v-if="documentLists.length > 0">
                <div class="document-title text-capitalize" v-if="uploadDocType !== 'other'">{{ replaceTitle() }}</div>

                <hr v-if="uploadDocType === 'other'" style="width: 100%; margin: 0 0 18px; border-color: #F3F4F7; border-style: solid;" />
                <div class="lists-items"
                    v-for="(documentFile, index) in documentLists"
                    :key="`d-files-${index}`">
                    <div class="items">
                        <img
                            src="@/assets/icons/shipment-document-blue.svg"
                            width="36px"
                            height="36px"
                            class="mr-2"
                            v-if="!isMobile" />
                        <div>
                            <div
                                :class="
                                    `document-info ${
                                        (documentFile.nameError &&
                                        (documentFile.name === '' ||
                                        documentFile.name === null)) ||
                                        documentFile.fileError
                                            ? 'div-has-error'
                                            : ''
                                    }`
                                ">
                                <div>
                                    <img
                                        src="@/assets/icons/shipment-document-blue.svg"
                                        width="36px"
                                        height="36px"
                                        class="mr-2"
                                        v-if="isMobile"
                                    />
                                    <input v-model="documentFile.name" />
                                </div>
                                <div v-if="!isMobile">
                                    <div class="file-size-info">
                                        <span>
                                            [ {{ getFileSize(documentFile.file.size) }}
                                            <span class="separator mx-1">*</span>
                                            <span v-if="editedUploadIndex > -1" class="mr-1">
                                                {{ getReuploadDocumentExtension(documentFile) }}
                                            </span>
                                            <span v-else class="mr-1">
                                                {{ documentFile.file.name.split(".").pop() }}
                                            </span>
                                            ]
                                        </span>
                                    </div>
                                    <a href="#"
                                        class="file-remove"
                                        @click="removeFile(index)">
                                        <img
                                            src="@/assets/icons/close-red.svg"
                                            width="16px"
                                            height="16px"
                                        />
                                    </a>
                                </div>
                            </div>

                            <div class="document-info-below mt-1" v-if="uploadDocType === 'other'">
                                <div class="document-info-content first-content">
                                    <span class="document-supplier-title" v-if="isMobile"
                                        >Type</span
                                    >
                                    <div class="document-info-content">
                                        <div
                                            :class="
                                                documentFile.typeError &&
                                                (documentFile.type === '' ||
                                                documentFile.type === null)
                                                    ? 'has-error w-100'
                                                    : 'w-100'
                                            "
                                        >
                                            <v-select
                                                class="select-product"
                                                :class="uploadType !== '' ? 'has-value' : ''"
                                                :items="
                                                    !dialogMessage
                                                    ? documentTypes
                                                    : documentTypesToDos
                                                "
                                                item-text="name"
                                                item-value="name"
                                                placeholder="Select Document Type"
                                                outlined
                                                v-model="documentFile.type"
                                                hide-details="auto"
                                                :menu-props="{
                                                    contentClass:
                                                        'supplier-lists-contents types fixedWidth',
                                                    bottom: true,
                                                    offsetY: true,
                                                }"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="option-items">
                                                        <span>{{ item.name }}</span>

                                                        <img
                                                            src="@/assets/icons/check-blue.svg"
                                                            alt=""
                                                        />
                                                    </div>
                                                </template>
                                            </v-select>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="isMobile" class="document-file-size-wrapper">
                                    <div class="file-size-info">
                                        <span>
                                            [ {{ getFileSize(documentFile.file.size) }}
                                            <span class="separator mx-1">*</span>
                                            <span v-if="editedUploadIndex > -1" class="mr-1">
                                                {{ getReuploadDocumentExtension(documentFile) }}
                                            </span>
                                            <span v-else class="mr-1">
                                                {{ documentFile.file.name.split(".").pop() }}
                                            </span>
                                            ]
                                        </span>
                                    </div>
                                    <a
                                        href="#"
                                        class="file-remove"
                                        @click="removeFile(index)"
                                    >
                                        <img
                                            src="@/assets/icons/close-red.svg"
                                            width="16px"
                                            height="16px"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="request-supplier-wrapper mb-5" 
                v-bind:class="{
                    'has-document-lists': documentLists.length > 0 && 
                        ((editedUploadIndex === -1 && uploadDocType !== 'other') || (editedUploadIndex > -1 )),
                    'other-section': uploadDocType === 'other'
                }">
                <div class="upload-supplier-title-wrapper">
                    <span class="upload-supplier-title" v-if="documentLists.length === 0 && uploadDocType !== 'other'">
                        {{ uploadDocTitle }} 
                    </span> 

                    <span class="upload-supplier-title" v-else-if="uploadDocType === 'other'">
                        <span v-if="editedUploadIndex === -1" class="upload-supplier-title">{{ uploadDocTitle }}</span>
                        <span class="text-capitalize" v-if="uploadDocType === 'other' && editedUploadIndex === -1"> (Optional)</span>
                    </span>
                </div>
                <div class="upload-request-body-wrapper upload-documents">
                    <div id="upload-documents-select-box-id" class="upload-documents-select-box"
                        @click.stop="addDocuments">
                        <span>Choose file to upload</span>
                    </div>
                    <button class="btn-white" @click.stop="addDocuments">
                        Browse File
                    </button>
                    <input
                        :ref="refName"
                        type="file"
                        :id="`upload_documents_other`"
                        @change="(e) => inputChanged(e)"
                        name="upload_documents"
                        accept=""
                        multiple
                    />
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
export default {
    name: "UploadComponent",
    props: [
        "docTitle",
        "uploadDocTitle",
        "uploadDocType",
        "refName",
        "documentLists",
        "isMobile",
        "dialogMessage",
        "type",
        "fieldErrors",
        "editedUploadIndex",
        "filePath",
        "fromLoggedOut",
        "getSelectedDocumentUpload"
    ],
    data: () => ({
        documentTypes: [
			{
				id: 0,
				value: "commercial invoice",
				name: "Commercial Invoice",
			},
			{
				id: 1,
				value: "packing list",
				name: "Packing List",
			},
			{
				id: 2,
				value: "invoice and packing list",
				name: "Invoice and Packing List",
			},
			{
				id: 3,
				value: "hbl",
				name: "OBL Document",
			},
			{
				id: 4,
				value: "other commercial docs",
				name: "Other Commercial Docs",
			},
			{
				id: 5,
				value: "delivery order",
				name: "Delivery Order",
			},
			{
				id: 6,
				value: "other",
				name: "Other",
			},
		],
		documentTypesToDos: [
			{
				id: 0,
				value: "commercial invoice",
				name: "Commercial Invoice",
			},
			{
				id: 1,
				value: "packing list",
				name: "Packing List",
			},
			{
				id: 2,
				value: "invoice and packing list",
				name: "Invoice and Packing List",
			},
			{
				id: 3,
				value: "hbl",
				name: "OBL Document",
			},
			{
				id: 4,
				value: "other commercial docs",
				name: "Other Commercial Docs",
			},
		],
    }),
    computed: {
        uploadType: {
            get() {
                return this.type
            },
            set(val) {
                this.$emit("update:type", val)
            }
        },
        documentTypesUpdated() {
            let arr = []

            if (typeof this.getSelectedDocumentUpload.isFromRequest !== "undefined" &&
                this.getSelectedDocumentUpload.isFromRequest) {

                let docTypes = this.getSelectedDocumentUpload.document_types;
                docTypes = Array.isArray(docTypes) 
                    ? docTypes 
                    : (typeof docTypes === "string" ? JSON.parse(docTypes) : docTypes)

                if (docTypes.length > 0) {
                    if (this.getSelectedDocumentUpload.type === "BO") {
                        docTypes.map((v) => {
                            if (v !== "Commercial Invoice" && v !== "Packing List") {
                                if (v === "Invoice & Packing List") {
                                    v = "Invoice and Packing List"
                                } else if (v === "Other Commercial Documents") {
                                    v = "Other Commercial Docs"
                                } else if (v === "Hbl Copy") {
                                    v = "OBL Document"
                                }

                                arr.push({ 
                                    value: v === "OBL Document" ? "hbl" : v.toLowerCase(), 
                                    name: v 
                                })
                            }                            
                        })
                    } else {
                        arr = this.documentTypes
                    }
                }
            } else {
                arr = this.documentTypes
            }	

            return arr
        }
    },
    methods: {
        inputChanged(e) {
            this.$emit("inputChanged", e)
        },
        addDocuments() {
            this.$emit("addDocuments")
        },
        getFileSize(size) {
			let base = 1024;
			let decimal = 2;

			var kiloBytes = base;
			var megaBytes = base * base;
			var gigaBytes = base * base * base;

			if (size < kiloBytes) {
				return size + " Bytes";
			} else if (size < megaBytes) {
				return (size / kiloBytes).toFixed(decimal) + " kb";
			} else if (size < gigaBytes) {
				return (size / megaBytes).toFixed(decimal) + " mb";
			}
		},        
        replaceTitle() {
            let title = this.uploadDocType

            if (title !== "") {
                if (title !== "commercial_and_packing") {
                    title = title.replace("_", " ");
                } else {
                    title = "Commercial Invoice & Packing List"
                }
            }

            return title
        },
        removeFile(index) {
            this.$emit("removeFile", index)
        },
        getReuploadDocumentExtension(document) {
			if (!document.is_document_new) {
				return this.filePath.split(".").pop()
			} else {
				return document.file.name.split(".").pop()
			}
        },
    },
}
</script>

<style lang="scss">
    
</style>