export let shipmentFiltersLists = [
    {}, // default
    // awaiting
    {
        "field": "status", 
        "operation": "equals", 
        "value": [
            "Booking Confirmed", 
            "Pending Departure",
            "Tracking Started",
            "Loaded",
            "Partially Loaded"
        ]
    },
    // in transit
    {
        "field": "status", 
        "operation": "equals", 
        "value": [
            "In Transit", 
            "In Transit - Hold",
            "In Transit - Released",
            "Trans-Shipment in Progress",
            "On Rail",
            "On Rail - Hold",
            "On Rail - Released"
        ]
    },
    // arrived
    {
        "field": "status", 
        "operation": "equals", 
        "value": [
            "Vessel Arrived", 
            "Vessel Arrived - Hold",
            "Vessel Arrived - Released",
            "Discharged ",
            "Partially Discharged",
            "Discharged - Released",
            "Partially Discharged - Released",
            "Discharged - Hold",
            "Partially Discharged - Hold",
            "Past last free day",
            "Pickup Scheduled",
            "Pickup Scheduled Partially"
        ]
    },
    // picked up
    {
        "field": "status", 
        "operation": "equals", 
        "value": [
            "Full Out Partially", 
            "Empty In Partially",
        ]
    },
    // requires attention
    {
        "field": "status", 
        "operation": "equals", 
        "value": [
            "Past last free day", 
            "In Transit - Hold",
            "On Rail - Hold",
            "Vessel Arrived - Hold",
            "Discharged - Hold",
            "Partially Discharged - Hold"
        ]
    },
]

// static data for shipments
export let shipmentSubtabs = [
    {
        name: "All Shipments",
        class: "all-shipments-tab",
        count: 0,
        countValue: "allCount",
        subTabValue: ""
    },
    {
        name: "Awaiting Departure",
        class: "awaiting-departure-tab",
        count: 0,
        countValue: "awaitingDepartureCount",
        subTabValue: "awaiting_departure"
    },
    {
        name: "In Transit",
        class: "in-transit-tab",
        count: 0,
        countValue: "inTransitCount",
        subTabValue: "in_transit"
    },
    {
        name: "Arrived",
        class: "arrived-tab",
        count: 0,
        countValue: "arrivedCount",
        subTabValue: "arrived"
    },
    {
        name: "Picked Up",
        class: "picked-up-tab",
        count: 0,
        countValue: "pickedUpCount",
        subTabValue: "pickedup"
    },
    {
        name: "Requires Attention",
        class: "requires-attention-tab",
        count: 0,
        countValue: "requiresCount",
        subTabValue: "required_attention"
    },
]

export const viewTabsName = [
    {
        name: "Default View",
        image: "default-view",
    },
    {
        name: "Calendar View",
        image: "calendar-view",
    },
    {
        name: "Milestone View",
        image: "list-view",
    },
    // {
    //     name: "Report View",
    //     image: "compact-view",
    // },
]

export let calendarFilterItems = [
    {
        id: 0,
        value: "etd",
        name: "ETD",
        class: "",
        isDefault: true,
        isSelected: true
    },
    {
        id: 1,
        value: "eta",
        name: "ETA",
        class: "",
        isDefault: true,
        isSelected: true
    },
    {
        id: 2,
        value: "pickup_lfd",
        name: "LFD",
        class: "",
        isDefault: true,
        isSelected: true
    },
    {
        id: 3,
        value: "appointment",
        name: "Appt",
        class: "",
        isDefault: true,
        isSelected: true
    },
    {
        id: 4,
        value: "return_lfd",
        name: "Out-Not Returned",
        class: "",
        isDefault: false,
        isSelected: false
    },
]

export const calendarBgColors = {
    defaultBgColor: "#FFFFFF",
    etdColored: "#F5F0FC",
    etaColored: "#E6F6FB",
    lfdColored: "#FFF7EB",
    apptColored: "#FAE6FB",
    returnLfdColored: "#FFF0F0"
}

export const calendarType = [
    {
        name: "Weekly",
        value: "week",
    },
    {
        name: "Monthly",
        value: "month",
    },
]

export const tabs = [
    {
        value: 0,
        label: "Booking",
    },
    {
        value: 1,
        label: "Shipments",
    },
    {
        value: 2,
        label: "Completed",
    },
]

export const pendingSubTabs = [
    "Draft",
    "Pending Quote",
    "Pending Approval",
    "Consolidation",
    "Snoozed",
    "Expired",
]

export const pendingSubTabsClasses = [
    "draft",
    "pending-quote",
    "pending",
    "consolidation",
    "snooze",
    "expired",
]