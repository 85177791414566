var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"milestone-shipments-wrapper-v2"},[_c('div',{staticClass:"milestone-ordering-details"},[_c('div',{staticClass:"milestone-title-status"},[_c('h2',{staticClass:"milestone-name"},[_vm._v(" Milestones "),(this.getDetails.tracking_status)?_c('span',{staticClass:"shipment-status"},[_vm._v(_vm._s(this.getDetails.tracking_status))]):_vm._e()])]),_c('p',{staticClass:"mb-0 milestone-note"},[_vm._v(" The displayed timings are adjusted to users local time zone ")])]),_c('v-stepper',{attrs:{"vertical":""},model:{value:(_vm.e13),callback:function ($$v) {_vm.e13=$$v},expression:"e13"}},[(_vm.loading)?_c('div',{staticClass:"preloader"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e(),(!_vm.loading && _vm.milestonesData.length > 0)?_c('div',{staticClass:"milestone-content"},_vm._l((_vm.milestonesData),function(item,index){return _c('div',{key:index,staticClass:"milestone-items"},[(item.display)?_c('v-stepper-step',{staticClass:"custom-vue-stepper-v2",class:("d-flex " + (item.event) + " " + (_vm.getTerminalHoldsClass(item))),attrs:{"step":index + 1,"color":"#1A6D9E","complete":item.highlighted}},[(
							item.event !== 'released_at_terminal' &&
								item.event !== 'eta_updates'
						)?_c('span',[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.event == 'eta_updates')?_c('span',{staticStyle:{"color":"#EB9B26 !important"}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.event == 'released_at_terminal')?_c('div',{staticClass:"released-event",class:_vm.getStatus(item)},[_c('span',[_vm._v(_vm._s(item.title))]),(!item.isReleased && item.isReleased !== undefined)?_c('span',{staticClass:"status no"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../../assets/icons/released-no.svg"),"width":"10px","height":"10px","alt":""}}),_vm._v(" No ")]):_vm._e(),(item.isReleased && item.isReleased !== undefined)?_c('span',{staticClass:"status yes"},[_c('img',{staticClass:"mr-1",attrs:{"src":require("../../../assets/icons/checkMark.png"),"alt":"","width":"12px","height":"12px"}}),_vm._v(" Yes ")]):_vm._e()]):_vm._e()]):_vm._e(),(
						item.event === 'booking_created' ||
							(item.event === 'booking_confirmed' && item.date !== null) ||
							item.event === 'departed'
					)?_c('v-stepper-content',{attrs:{"step":index + 1}},[_c('small',[_vm._v(_vm._s(_vm.convertDate(item.title, item.created_at))+" ")])]):_vm._e(),(
						typeof item.data !== 'undefined' &&
							item.event !== 'released_at_terminal' &&
							item.event !== 'eta_updates'
					)?_c('v-stepper-content',{attrs:{"step":index + 1}},_vm._l((item.data),function(data,index){return _c('div',{key:index},[_c('div',{staticClass:"milestone-container-wrapper"},[_c('div',{staticClass:"milestone-container-num"},[_c('p',[_vm._v(_vm._s(data.title))])]),_c('div',{staticClass:"milestone-container-dates"},[_c('span',[(data.updated_at)?_c('small',[_vm._v(_vm._s(_vm.convertDate(item.title, data.updated_at)))]):_vm._e(),(
											item.event == 'last_free_day' &&
												(data.updated_at == null || data.updated_at == '')
										)?_c('small',{staticClass:"pr-1"},[_vm._v("N/A")]):_vm._e()]),(_vm.isContainerDateComplete(data.updated_at))?_c('small',{staticClass:"ml-1"},[(item.event !== 'last_free_day')?_c('img',{staticClass:"completed-icon",attrs:{"src":require("../../../assets/icons/checkMark.png"),"width":"10px","height":"10px"}}):_vm._e()]):_vm._e()])])])}),0):_vm._e(),(item.event === 'released_at_terminal')?_c('v-stepper-content',{attrs:{"step":index + 1}},_vm._l((item.data),function(newItem,index){return _c('div',{key:index,staticClass:"milestone-container-wrapper released"},[_c('div',{staticClass:"milestone-container-num"},[_c('p',[_vm._v(_vm._s(Object.keys(newItem)[0]))])]),(newItem && Object.values(newItem)[0].length > 0)?_c('div',{staticClass:"milestone-container-status"},_vm._l((Object.values(newItem)[0]),function(status,secondIndex){return _c('div',{key:secondIndex,staticClass:"status"},[_c('span',[_vm._v(_vm._s(status.title))])])}),0):_vm._e()])}),0):_vm._e(),(item.event === 'eta_updates')?_c('v-stepper-content',{attrs:{"step":index + 1}},[(item.data && item.data.length > 0)?_c('div',{staticClass:"milestone-container-wrapper released"},[_c('div',{staticClass:"milestone-container-num"},[_c('p',[_vm._v(_vm._s(item.data[0].title))])]),_c('div',[_c('small',[_vm._v(_vm._s(_vm.convertDate(item.data[0].title, item.data[0].updated_at)))])]),(item.data.length > 1)?_c('div',{staticClass:"eta-expansion-content"},[_c('v-expansion-panels',{attrs:{"accordion":"","flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
										var open = ref.open;
return [_c('span',{staticClass:"font-medium"},[_vm._v(" ("+_vm._s(item.data.length - 1)+") "+_vm._s(open ? "Hide ETA" : "View More ETA")+" ")])]}}],null,true)}),_c('v-expansion-panel-content',_vm._l((item.data.slice(1)),function(newItem,i){return _c('div',{key:i,staticClass:"other-eta-items"},[_c('div',{staticClass:"milestone-container-num"},[_c('p',[_vm._v(" "+_vm._s(newItem.title)+" ")])]),_c('div',{staticClass:"pt-1"},[_c('small',[_vm._v(_vm._s(_vm.convertDate(null, newItem.updated_at)))])])])}),0)],1)],1)],1):_vm._e()]):_vm._e()]):_vm._e(),(item.data == null)?_c('v-stepper-content',{attrs:{"step":index + 1}},[_c('span')]):_vm._e()],1)}),0):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }